import styled from 'styled-components/macro';

const TermsConditionsStyled = styled.div`
  font-size: 14px;
  margin-top: 1.5rem;
  p {
    margin-top: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  b {
    font-weight: 600;
  }
  h5 {
    margin-bottom: 0;
  }
  h6 {
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .card {
    > div {
      margin-top: 2rem;
    }
  }
  ol {
    margin: 0;
    padding-left: 16px;
    li {
      margin-top: 1rem;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
    margin: 1rem;
    padding: 1rem;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  
  ol > li:before {
    font-weight: 600;
    content: counters(item, ".") ".";
    display: table-cell;
    padding-right: 0.6em;    
  }
  
  li ol > li {
    margin: 0;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
  }
  
  }
`;

export default TermsConditionsStyled;

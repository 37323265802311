import { PayloadAction } from '@reduxjs/toolkit';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { orderActions as actions } from '.';
import { OrdersPayload } from './types';

function* getOrder(action: PayloadAction<OrdersPayload>) {
  try {
    const requestURL = `/api/v3/user/orders/${action.payload.id}?showAllBills=true`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: action.payload.authToken
          ? `Bearer ${action.payload.authToken}`
          : header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getOrderSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getOrderError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getOrderError(err.response));
    } else {
      yield put(actions.getOrderError(err));
    }
  }
}

function* getStoreOrderGenerator(action: PayloadAction<OrdersPayload>) {
  // /api/v4/user/store/orders/175851
  try {
    const requestURL = `/api/v4/user/store/orders/${action.payload.id}`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: action.payload.authToken
          ? `Bearer ${action.payload.authToken}`
          : header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getStoreOrderSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableStoreOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getStoreOrderError(true));
    } else if (err.message === 'Server Error') {
      yield put(actions.getStoreOrderError(err.response));
    } else {
      yield put(actions.getStoreOrderError(err));
    }
  }
}

export function* orderSaga() {
  yield takeLatest(actions.getOrder.type, getOrder);
  yield takeLatest(actions.getStoreOrder.type, getStoreOrderGenerator);
}

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { searchSaga } from './saga';
import { SearchState } from './types';

export const initialState: SearchState = {
  search: {
    data: [],
    loading: true,
    error: false,
  },
  query: '',
};

const slice = createSlice({
  name: 'searchSL',
  initialState,
  reducers: {
    getSearch(state, action: PayloadAction<any>) {
      state.search.data = [];
      state.search.loading = true;
      state.search.error = null;
    },
    getSearchSuccess(state, action: PayloadAction<any>) {
      state.search.data = action.payload;
      state.search.loading = false;
    },
    getSearchError(state, action: PayloadAction<any>) {
      state.search.error = action.payload;
      state.search.loading = false;
    },
    setQuery(state, action: PayloadAction<any>) {
      state.query = action.payload;
    },
  },
});

export const { actions: searchActions } = slice;

export const useSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: searchSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.maintenance || initialState;

export const selectMaintenance = createSelector([selectSlice], state => state);
export const selectDisableOrder = createSelector(
  [selectSlice],
  state => state.disableOrder,
);
export const selectRetrying = createSelector(
  [selectSlice],
  state => state.retrying,
);

import { createGlobalStyle } from 'styled-components';
import AuthenticationBg from '../assets/authentication-bg.svg';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
    html,
    body {
        height: 100%;
        width: 100%;
        line-height: 1.5;
    }
    body {
        font-family: 'Poppins', sans-serif;
        background-color: ${p => p.theme.background};
        color: #232827;
        @media screen and (max-width: 991px) {
            background-color: #fafafa;
        }
    }
    body.fontLoaded {
        font-family: 'Poppins', sans-serif;
    }
    body {
        &.no-scroll {
            overflow: hidden;
        }
    }
    h5 {
        font-size: 18px;
        font-weight: 500;
    }
    h6 {
        font-size: 14px;
        font-weight: 500;
    }
    textarea {
        resize: none;
    }
    input,
    textarea,
    select {
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &.form-control {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .popover-arrow:after, .bs-popover-top>.popover-arrow:after {
        border-top-color: black;
    }
    .dropdown {
        .dropdown-menu {
            border: 0;
            transition: none;
            box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
            .dropdown-item {
                font-size: 14px;
                color: #acacac;
                font-weight: 500;
                &.active,
                &:hover {
                    background-color: transparent;
                    color: #00855B;
                }
            }
        }
    }
    .loading {
        @keyframes shimmer {
            0% {
                transform: translate3d(-100%, 0, 0);
            }
            100% {
                transform: translate3d(100%, 0, 0);
            }
        }
        width: 100%;
        position: relative;
        background-color: #f0f0f0;
        border-radius: 5px;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transform: translateX(-100%);
            animation: shimmer 1s ease-in-out infinite;
            background-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0.3) 20%,
                rgba(255, 255, 255, 0.6) 60%,
                rgba(255, 255, 255, 0)
            );
        }
    }
    .app-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        img {
            max-width: 80px;
        }
    }
    input,
    select,
    button {
        font-family: inherit;
        font-size: inherit;
    }
    .accordion {
        .accordion-item {
            .accordion-button {
                font-weight: 500;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &:after {
                    width: 15px;
                    height: 15px;
                    background-size: 15px;
                }
            }
            .accordion-body {
                button {
                    width: 100%;
                    margin-top: 16px;
                }
                form {
                    label {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
                a {
                    color: #00855B;
                    text-decoration: none;
                }
            }
            &:first-of-type {
                border-radius: 5px 5px 0 0;
            }
            &:last-of-type {
                border-radius: 0 0 5px 5px;
            }
        }
    }
    button {
        &.btn {
            &.done {
                border: 0;
                padding: 0;
            }
            &:active:focus,
            &:focus {
                border: 0;
                outline: none;
                box-shadow: none !important;
            }
        }
    }
    button {
        &.button-icon {
            padding: 0;
            border: 0;
            box-shadow: none;
            background-color: transparent;
        }
        &.btn {
            flex-shrink: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.45;
            &.btn-link {
                padding: 0;
                text-decoration: none;
                height: initial;
                &.warning {
                    color: #00855B;
                    img {
                        margin-left: 4px;
                    }
                }
                &.success {
                    color: #00855B;
                }
            }
            &.btn-sm {
                padding: 5px 10px;
            }
            &.btn-success {
                background-color: #00855B;
                &:active:focus,
                &:hover {
                    background-color: #016344;
                }
            }
            &.btn-warning {
                background-color: #00855B;
                border-radius: 5px;
                color: white;
                border: none;
                &:active:focus,
                &:hover {
                    background-color: #00a672;
                }
            }
            &.btn-outline-warning {
                border-color: #efa504;
                color: #232827;
            }
            &.btn-light {
                background-color: rgba(35, 40, 39, 0.07);
                border-width: 0;
            }
        }
    }
    input {
        &.form-control {
            height: 50px;
            font-size: 14px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    input,
    textarea {
        &.form-control {
            background: #ffffff;
            border: 1px solid #f5f5f5;
            box-sizing: border-box;
            border-radius: 5px;
        }
    }
    textarea {
        &.form-control {
            font-size: 12px;
            padding: 16px;
        }
    }
    form {
        margin-top: 16px;
        label {
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }
        .form-group {
            &.password {
                .input-group {
                    .input-group-text {
                        padding: 0;
                        border: 0;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        z-index: 4;
                        border-radius: 0 5px 5px 0;
                        background-color: transparent;
                    }
                    input {
                        padding-right: 60px;
                        &:hover,
                        &:focus {
                            border-radius: 5px !important;
                        }
                    }
                    img,
                    svg {
                        width: 20px;
                    }
                }
            }
        }
        .phone-number,
        .form-group {
            display: flex;
            width: 100%;
            padding-bottom: 1.5rem;
            margin-bottom: 8px;
            position: relative;
            .required {
                position: absolute;
                bottom: 4px;
                left: 0;
                margin: 0;
                color: #dc3545;
            }
            &:not(.terms) {
                flex-direction: column;
            }
        }
    }
    .form-check {
        &.checkbox {
            position: relative;
            height: 18px;
            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked {
                    & + label {
                        &:before {
                            background-color: #00855c;
                            border-color: #00855c;
                        }
                        &:after {
                            content: '✓';
                        }
                    }
                }
            }
            label {
                margin-bottom: 0;
                padding-left: 12px;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                }
                &:after {
                    transform: rotate(20deg);
                    left: 2px;
                    color: white;
                }
                &:before {
                    left: 0;
                    background-color: white;
                    border-radius: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #acacac;
                }
            }
        }
        &.radio {
            position: relative;
            @keyframes RadioEffect {
                from {
                    transform: scale(2);
                    opacity: 0;
                }
                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked {
                    & + label {
                        &:before {
                            border-color: #00855B;
                        }
                        &:after {
                            animation: mymove 0.25s alternate;
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
            }
            label {
                margin-bottom: 0;
                padding-left: 12px;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }
                &:after {
                    width: 10px;
                    height: 10px;
                    background-color: #00855B;
                    left: 5px;
                    top: 5px;
                    opacity: 0;
                    transform: scale(2);
                }
                &:before {
                    border: 1px solid #acacac;
                    width: 20px;
                    height: 20px;
                    left: 0;
                }
            }
        }
    }
    .PhoneInput {
        position: relative;
        .PhoneInputCountry {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            .PhoneInputCountrySelectArrow {
                margin-left: 8px;
            }
            .PhoneInputCountryIcon {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                box-shadow: none;
                overflow: hidden;
                .PhoneInputCountryIconImg {
                    object-fit: cover;
                }
            }
        }
        input {
            padding-left: calc(16px + 16px + 22px + 8px);
            &:hover,
            &:focus {
                outline: none;
            }
        }
    }
    form {
            > * {
                &:not(:first-child) {
                    margin-top: 12px;
                }
                small {
                    font-size: 13px;
                    &.required {
                        color: #dc3545;
                    }
                }
            }
        }
    .authentication-page {
        min-height: 100vh;
        background-color: #00855b;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 3.75rem 16px;
        a {
            &.terms-conditions {
                text-decoration: none;
                margin-left: 8px;
            }
        }
        button {
            width: 100%;
            height: 50px;
        }
        > * {
            position: relative;
        }
        .logo {
            img {
                height: 78px;
            }
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            background: url(${AuthenticationBg}) repeat-x center bottom;
            opacity: 0.2;
        }
        h4 {
            margin-bottom: 0;
            margin-top: 1.5rem;
            text-align: center;
        }
        .info {
            text-align: center;
            max-width: 300px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem;
            span {
                line-height: 2;
                font-size: 14px;
                color: #acacac;
            }
        }
        .terms {
            display: flex;
            align-items: center;
            flex-direction: row;
            a {
                color: #00855C;
                font-size: 14px;
            }
        }
        .box-edit-order{
            max-width: 600px;
            width: 100%;
            min-height: 620px;
            background: #ffffff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            // align-items: center;
        }
        .box {
            max-width: 600px;
            width: 100%;
            min-height: 540px;
            background: #ffffff;
            padding: 2.5rem 16px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .send-otp {
                flex-grow: 0.5;
                justify-content: space-between;
                display: flex;
                flex-direction: column;
            }
            .inner-width,
            .send-otp {
                max-width: 500px;
                width: 100%;
            }
            @media screen and (max-width: 575px) {
              min-height: initial;
            }
        }
        @media screen and (max-width: 575px) {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
    .PhoneInputInput {
        height: 50px;
        border: 1px solid #F5F5F5;
        border-radius: 5px;
    }
    .app-wrapper {
        display: flex;
    }
    .modal-custom {
        .modal-fullscreen {
            .modal-content {
                justify-content: center;
                align-items: center;
            }
        }
    }
    img {
        &.loading-logo {
            max-width: 90px !important;
            margin: auto;
        }
    }
    .modal-style {
        &.lottie-transition {
            transition: none;
            * {
                transition: none;
            }
        }
        .modal-dialog {
            max-width: 420px;
            position: relative;
            .modal-content {
                padding: 16px;
                text-align: center;
                p {
                    margin-top: 1.5rem;
                    margin-bottom: 0;
                }
            }
        }
    }
    .custom-text-color-profile {
        color: #00855C;
        font-weight: 500;
    }
`;

/**
 *
 * FailureModal
 *
 */
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import componentConstants from 'app/mixpanel/constants/components';
import React, { memo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';

import OrderlacedJSON from './assets/payment_failed.json';

interface Props {
  isFailure: boolean;
  message?: string;
  retryFunction?: Function;
}

export const FailureModal = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.FAILURE_MODAL);
  });
  const {
    isFailure,
    retryFunction,
    message = 'Payment failed, pls do complete your payment in order details view',
  } = props;
  return (
    <Modal
      className="modal-style confirm-modal lottie-transition"
      show={isFailure}
      centered
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: OrderlacedJSON,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={200}
      />
      <p>{message}</p>
      {Boolean(retryFunction) ? (
        <Button
          onClick={() => {
            Mixpanel.track(actionConstants.RETRY);
            const retry: any = retryFunction;
            if (Boolean(retryFunction)) retry();
          }}
        >
          Retry
        </Button>
      ) : (
        <></>
      )}
    </Modal>
  );
});

import styled from 'styled-components/macro';

const Login = styled.a`
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  color: white;
  @media screen and (max-width: 991px) {
    font-size: 16px;
    position: relative;
    z-index: 2;
  }
  &:hover {
    color: white;
    text-decoration: underline;
  }
`;
const Brand = styled.a`
  img {
    height: 70px;
    @media screen and (max-width: 991px) {
      height: 50px;
    }
  }
  @media screen and (max-width: 991px) {
    position: relative;
    left: 72px;
    z-index: 2;
  }
`;
const Header = styled.header`
  padding: 16px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  > .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    > * {
      margin-left: 12px;
      margin-right: 12px;
      @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .navbar {
    padding-top: 12px;
    padding-bottom: 12px;
    .navbar-toggler {
      background-color: #00855b;
      .navbar-toggler-icon {
        filter: invert(1);
      }
      &:focus {
        outline: none;
        border: 0;
        box-shadow: none;
      }
    }
    .navbar-collapse {
      @media screen and (max-width: 991px) {
        position: absolute;
        top: 50px;
        left: -12px;
        right: -12px;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.65);
      }
    }
    .navbar-nav {
      a {
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        margin-left: 12px;
        margin-right: 12px;
        &:hover,
        &.active {
          color: #00855b;
        }
        &.login {
          color: #00855b;
        }
        @media screen and (max-width: 991px) {
          font-size: 16px;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .navbar-collapse {
      justify-content: flex-end;
    }
    @media screen and (max-width: 991px) {
      padding-top: 5px;
      padding-bottom: 5px;
      position: absolute;
      right: 0;
      left: 0;
      margin: 0;
    }
  }
  @media screen and (max-width: 991px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export { Login, Brand, Header };

/**
 *
 * SearchView
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Image } from 'react-bootstrap';
import SearchIcon from './assets/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuery, selectSearch } from './slice/selectors';
import { useSearchSlice } from './slice';

import { SearchItem } from './Components/SearchItem';
import { homePageActions } from '../../slice';
import { selectShowSearch } from '../../slice/selectors';
var debounce = require('lodash.debounce');

interface Props {}

export function SearchView(props: Props) {
  const dispatch = useDispatch();
  const { actions } = useSearchSlice();
  const searchItems = useSelector(selectSearch);
  const query = useSelector(selectQuery);
  const showSearch = useSelector(selectShowSearch);
  const searchRef = useRef(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    dispatch(actions.getSearch(query));
  }, [query]);

  const onClose = () => {
    dispatch(homePageActions.setShowSearch(false));
  };

  const handleOutsideClick = (e: any) => {
    const element: any = searchRef.current;
    if (element && !element.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopImmediatePropagation();
      onClose();
    }
  };

  useEffect(() => {
    if (showSearch) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleKeyDown, false);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', handleKeyDown, false);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [showSearch]);

  const debouncedSearch = useCallback(
    debounce(
      nextValue => {
        dispatch(actions.setQuery(nextValue));
      },
      500,
      { leading: false, trailing: true },
    ),
    [],
  );

  const renderItems = () => {
    if (searchItems.loading) {
      return (
        <Block className="block">
          <List className="list">
            <LoadingListItem>
              <div className="loading" />
            </LoadingListItem>
            <LoadingListItem>
              <div className="loading" />
            </LoadingListItem>
          </List>
        </Block>
      );
    }
    if (searchItems.data.length === 0) {
      return <></>;
    }
    return (
      <Block className="block">
        <List className="list">
          {searchItems.data.map(item => {
            return <SearchItem item={item} key={`search-${item.id}`} />;
          })}
        </List>
      </Block>
    );
  };

  if (!showSearch) {
    return <></>;
  }

  return (
    <SearchViewStyled className="search-view">
      <ContentArea className="content-area" ref={searchRef}>
        <SearchBar className="search-bar">
          <input
            placeholder="Search..."
            type="text"
            value={value}
            autoFocus
            onChange={(e: any) => {
              setValue(e.target.value);
              debouncedSearch(e.target.value);
            }}
          />
          <SearchIconStyled>
            <Image src={SearchIcon} alt="search" />
          </SearchIconStyled>
        </SearchBar>
        {renderItems()}
      </ContentArea>
    </SearchViewStyled>
  );
}

const LoadingListItem = styled.li`
  padding-top: 12px;
  padding-bottom: 12px;
  .loading {
    height: 90px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
`;

const Block = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
  margin-top: 12px;
  padding: 16px 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  max-height: 60vh;
  overflow: auto;
  @media screen and (max-width: 575px) {
    max-height: 30vh;
  }
`;

const ContentArea = styled.div`
  max-width: 980px;
  width: 100%;
  @media screen and (max-width: 1199px) {
    max-width: 920px;
  }
  @media screen and (max-width: 991px) {
    max-width: 700px;
  }
  @media screen and (max-width: 767px) {
    max-width: 520px;
  }
`;

const SearchIconStyled = styled.figure`
  margin-bottom: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SearchBar = styled.div`
  position: relative;
  input {
    height: 60px;
    background-color: white;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: calc(60px + 12px);
    width: 100%;
    border: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
    font-weight: 500;
    font-size: 18px;
  }
`;

const SearchViewStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { CareersPage } from 'app/pages/CareersPage/Loadable';
import { ChallengePage } from 'app/pages/ChallengePage/Loadable';
import { ComingSoonPage } from 'app/pages/ComingSoonPage/Loadable';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { LoginPage } from 'app/pages/LoginPage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { PrivacyPage } from 'app/pages/PrivacyPage/Loadable';
import { RegisterPage } from 'app/pages/RegisterPage';
import { TermsPage } from 'app/pages/TermsPage/Loadable';

import PrivateRoute from 'app/components/PrivateRoute';
import PublicRoute from 'app/components/PublicRoute';
import RegisterRoute from 'app/components/RegisterRoute';

import { useAuthSlice } from 'app/pages/slice';
import { GlobalStyle } from '../styles/global-styles';

import { useCategorySlice } from 'app/pages/CategoryPage/slice';
import { useHelpSlice } from 'app/pages/HelpPage/slice';
import { useItemSlice } from 'app/pages/ItemPage/slice';
import { useCarouselSlice } from 'app/pages/MainPage/components/Carousel/slice';
import { useMainPageSlice } from 'app/pages/MainPage/slice';
import { useOrderSlice } from 'app/pages/OrderPage/slice';
import { useOrdersSlice } from 'app/pages/OrdersPage/slice';

import { useCartSlice } from 'app/pages/Cart/slice';
import { useSearchSlice } from 'app/pages/HomePage/components/SearchView/slice';
import { useHomePageSlice } from 'app/pages/HomePage/slice';
import { LaunchPage } from 'app/pages/LaunchPage';
import { useLaunchPageSlice } from 'app/pages/LaunchPage/slice';
import { useMaintenanceSlice } from 'app/pages/Maintenance/slice';
import ErrorSvg from 'assets/error.svg';
import { Image, Modal } from 'react-bootstrap';
import { AboutPage } from './pages/AboutPage/Loadable';
import { AddressList } from './pages/AddressList';
import { ContactUsPage } from './pages/ContactUsPage/Loadable';
import { DeliveryTypeOption } from './pages/DeliveryTypeOption';
import { HowItWorksPage } from './pages/HowItWorksPage/Loadable';
import { useAddressSlice } from './pages/ManageAddress/slice';
import { ManageAddressList } from './pages/ManageAddressList';
import { useReviewSlice } from './pages/OrderReviewPage/slice';
import { OurClientalPage } from './pages/OurClientalPage/Loadable';
import { RefundsPage } from './pages/RefundsPage';
import { ShippingAndDeliveryPage } from './pages/ShippingAndDeliveryPage';

// React-Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckDeliveryLocation } from './pages/CheckDeliveryLocation';
import { Onboarding } from './pages/Onboarding';
import { OrderPage } from './pages/OrderPage';
import { RedeemCoinPage } from './pages/RedeemCoinPage';
import { SkipLoginPage } from './pages/SkipLogin';
import { useFeaturedBannerSlice } from './pages/MainPage/components/FeatureBanner/slice';

const TRACKING_ID = 'G-ETX5CKNCJX';
ReactGA.initialize(TRACKING_ID);

export function App() {
  useLaunchPageSlice();
  useAuthSlice();
  useMainPageSlice();
  useCategorySlice();
  useCarouselSlice();
  useItemSlice();
  useOrderSlice();
  useOrdersSlice();
  useHelpSlice();
  useSearchSlice();
  useCartSlice();
  useHomePageSlice();
  useMaintenanceSlice();
  useAddressSlice();
  useReviewSlice();
  useFeaturedBannerSlice();
  const { i18n } = useTranslation();

  const [isOnline, setisOnline] = useState(true);
  useEffect(() => {
    window.addEventListener('online', () => setisOnline(true), false);
    window.addEventListener('offline', () => setisOnline(false), false);
    return () => {
      window.removeEventListener('online', () => setisOnline(true), false);
      window.removeEventListener('offline', () => setisOnline(false), false);
    };
  }, []);
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - MadrasMandi"
        defaultTitle="MadrasMandi"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="An online grocery shop" />
      </Helmet>
      <Switch>
        <Route exact path="/" component={LaunchPage} />
        <Route path="/terms-and-conditions" component={TermsPage} />
        {/* <Route path="/help-and-faqs" component={HelpFAQPage} /> */}
        <Route path="/privacy-policy" component={PrivacyPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/how-it-works" component={HowItWorksPage} />
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/careers" component={CareersPage} />
        <Route path="/our-clients" component={OurClientalPage} />
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute
          exact
          path="/check-delivery-location"
          component={CheckDeliveryLocation}
        />
        <PublicRoute exact path="/orders/:id" component={OrderPage} />

        <PrivateRoute path="/home" component={HomePage} />
        <Route path="/sl" component={SkipLoginPage} />
        <Route exact path="/manage-address" component={ManageAddressList} />
        <Route exact path="/delivery-type" component={DeliveryTypeOption} />
        <Route path="/coming-soon" component={ComingSoonPage} />
        <Route path="/return-refund" component={RefundsPage} />
        <Route path="/challenge" component={ChallengePage} />
        <Route path="/shipping-delivery" component={ShippingAndDeliveryPage} />

        <RegisterRoute exact path="/register" component={RegisterPage} />
        <RegisterRoute path="/onboarding" component={Onboarding} />
        <Route exact path="/address-list" component={AddressList} />
        <Route exact path="/rewards" component={RedeemCoinPage} />

        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
      <ToastContainer />
      <Modal className="modal-style no-connection" show={!isOnline} centered>
        <Image src={ErrorSvg} alt="Connection error" />
        <p>
          Connection Error Please check your internet connection and try again
        </p>
      </Modal>
    </BrowserRouter>
  );
}

/**
 *
 * LaunchPage
 *
 */
import React, { memo, useState, useEffect } from 'react';

import { PageHeader } from 'app/components/PageHeader';
import { PageFooter } from 'app/components/PageFooter';
import { DownloadApp } from 'app/components/DownloadApp';
import { DownloadAppMobile } from 'app/components/DownloadAppMobile';
import { WhatsApp } from 'app/components/WhatsApp';
import { GetInTouch } from 'app/components/GetInTouch';
import { Home } from 'app/components/Home';
import { DownloadSection } from './components/DownloadSection';
import { CarouselSection } from './components/CarouselSection';
import { PickOfTheMonthSection } from './components/PickOfTheMonthSection';
import { WhyMadrasMandiSection } from './components/WhyMadrasMandiSection';
import { TestimonialSection } from './components/TestimonialSection';
import { VisitOurExclusiveSection } from './components/VisitOurExclusiveSection';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';

interface Props {}

export const LaunchPage = memo((props: Props) => {
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    Mixpanel.track(pageConstants.LAUNCH_PAGE);
  });

  useEffect(() => {
    getShow();
    window.addEventListener('resize', getShow);
    return () => {
      window.removeEventListener('resize', getShow);
    };
  }, []);

  useEffect(() => {
    if (show && window.innerWidth < 991) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [show]);

  const getShow = () => {
    if (window.innerWidth > 991) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <Home className="home">
      <PageHeader />
      <CarouselSection />
      <PickOfTheMonthSection />
      {/* <CategorySliders /> */}
      <WhyMadrasMandiSection />
      <VisitOurExclusiveSection />
      <TestimonialSection />
      <DownloadSection />
      <GetInTouch />
      <PageFooter />
      <DownloadApp show={show} setShow={setShow} />
      <DownloadAppMobile setShow={setShow} />
      <WhatsApp />
    </Home>
  );
});

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * AddressItem
 *
 */
import React, { memo } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CheckAddress from 'assets/check-address.svg';
import Home from 'assets/home.png';
import Office from 'assets/office.png';
import Other from 'assets/other.png';
import Watch from 'assets/truck_address.svg';
import Instant from 'assets/instant_img.svg';
import Not_Delivery from 'assets/not_deliverable.svg';
import styled from 'styled-components/macro';
import {
  selectDefaultAddress,
  selectLaunchAddressList,
} from 'app/pages/ManageAddress/slice/selectors';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import actionConstants from 'app/mixpanel/constants/action';
import EditIcon from 'assets/edit.svg';
import DeleteIcon from 'assets/delete.svg';
import useNextDayDelivery from 'app/hooks/useNextDayDelivery';
interface Props {
  item: any;
  checked: boolean;
  setShowModal: Function;
  setDeleteModal: Function;
  setEditAddress: Function;
  onChangeDefault: Function;
}

export const AddressItem = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.ADDRESS_LIST);
  });
  const {
    item,
    checked,
    setShowModal,
    setDeleteModal,
    setEditAddress,
    onChangeDefault,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedAddress = useSelector(selectDefaultAddress);
  const launchAddressList = useSelector(selectLaunchAddressList);
  const { checkIsNextDayDelivery } = useNextDayDelivery();

  const renderAddressImage = () => {
    if (item.address_name === 'Home') {
      return <Image className="img" src={Home} alt="home" />;
    } else if (item.address_name === 'office') {
      return <Image className="img" src={Office} alt="home" />;
    } else {
      return <Image className="img" src={Other} alt="home" />;
    }
  };

  return (
    <>
      <AddressWrapperBlock
        className="address-wrapper-block"
        disabled={!item.deliverable}
      >
        <AddressListItem className="address-list-item">
          <AddressKey className="address-key">
            {renderAddressImage()}
            <Form.Check
              key={item.address_id}
              type={'radio'}
              label={item.address_name}
              value={item.address_id}
              id={item.address_id}
              disabled={
                !item.deliverable
                // (!item.show_instant_delivery &&
                //   item.next_day_delivery_availability === true)
              }
              className="name"
              onChange={e => {
                e.stopPropagation();
                onChangeDefault(item);
              }}
            />
          </AddressKey>
          <AddressStyled className="address">{item.address}</AddressStyled>
        </AddressListItem>
        {!item.deliverable && (
          <AddressListItem
            center={!item.deliverable}
            className="address-list-item"
          >
            <AddressKey
              className={`address-key ${!item.deliverable ? 'disabled' : ''}`}
            >
              <p className="availability">Availability:</p>
            </AddressKey>

            <div
              className="address_type"
              onClick={e => {
                Mixpanel.track(actionConstants.ADDRESS_SELECT);
                e.stopPropagation();
              }}
            >
              <div className="delivery_type_item">
                <figure className="delivery_type_img not-delivery">
                  <Image
                    className="delivery_type_img"
                    src={Not_Delivery}
                    alt="Not_Delivery"
                  />
                </figure>
                <span className="address_status red-text">
                  {' '}
                  Not Deliverable
                </span>
              </div>
            </div>
          </AddressListItem>
        )}
        {checkIsNextDayDelivery() && item.deliverable && (
          <AddressListItem>
            <AddressKey
              className={`address-key ${!item.deliverable ? 'disabled' : ''}`}
            >
              <p className="availability">Availability: </p>
            </AddressKey>
            <div>
              {item.show_instant_delivery && item.deliverable && (
                <div className="delivery_type delivery_type_item instant">
                  <figure className="delivery_type_img instant">
                    <Image
                      className="delivery_type_img"
                      src={Instant}
                      alt="instant"
                    />
                  </figure>
                  <span className="address_status">
                    {' '}
                    {launchAddressList.data.instant_delivery_title}
                  </span>
                </div>
              )}
              {item.deliverable && item.next_day_delivery_availability && (
                <div className="delivery_type_item app">
                  <figure className="delivery_type_img watch">
                    <Image
                      style={{
                        width: '40px',
                        flexShrink: 0,
                      }}
                      className="delivery_type_img"
                      src={Watch}
                      alt="watch"
                    />
                  </figure>
                  <span className="address_status">Next day delivery</span>
                </div>
              )}
            </div>
            {!item.show_instant_delivery && <span></span>}
          </AddressListItem>
        )}
        {!item.is_default && (
          <Options className="options">
            <Button
              className="green"
              variant=""
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setEditAddress(item);
              }}
            >
              Edit
            </Button>
            <Button
              variant=""
              className="red"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteModal(item.address_id);
              }}
            >
              Delete
            </Button>
          </Options>
        )}
      </AddressWrapperBlock>
    </>
  );
});

const AddressStyled = styled.address`
  margin: 0;
  font-size: 14px;
`;

const AddressKey = styled.div`
  display: flex;
  align-items: start;
  max-width: 120px;
  width: 100%;
  height: 50%;
  .img {
    margin-top: 0.3rem;
  }
  .form-check {
    display: flex;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 12px;
  }
`;

const Options = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: end;
  border-top: 1px solid #0000000a;
  padding-top: 0.3rem;
  button {
    margin-left: 6px;
  }
  img {
    height: 16px;
  }
  .green {
    color: #359e7d;
  }
  .red {
    color: #ed3947;
  }
  @media screen and (max-width: 991px) {
    display: flex;
  }
`;

const AddressWrapperBlock = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border: 0.2px solid rgba(236, 236, 236, 1);
  padding: 12px;
  border-radius: 5px;
  margin-top: 16px;

  ${props =>
    props.disabled &&
    `box-shadow: 0px 0px 4px #0000001a; 
    background: #00000010;`}

  .disabled {
    .address {
      color: #00000066;
    }
  }
`;

const AddressListItem = styled.li<{ center?: boolean }>`
  display: flex;
  align-items: flex-start;
  position: relative;
  ${props => props.center && `align-items: center!important;`}
  .address_type {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .delivery_type_item {
    display: flex;
    border-radius: 100px;
    border: none;

    align-items: center;
    figure {
      margin-bottom: 0;
      width: 40px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99px;
      &.not-delivery {
        border: 1px solid #850000;
        img {
          width: 20px;
          height: auto;
        }
      }
      &.instant {
        border: 1px solid #ffcc93;
        img {
          width: 20px;
          height: auto;
        }
      }
    }
    & + .delivery_type_item {
      margin-top: 8px;
    }
  }
  p {
    margin: 0;
  }
  .address_name {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .address_img {
      display: flex;
    }
  }
  .name {
    margin-left: 12px;
  }
  .form-check {
    padding: 0;
    min-height: initial;
    margin-bottom: 0;
    label {
      width: 100%;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      width: 100%;
      display: block;
      height: 100%;
      border-radius: 5px;
      margin: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  &.checked {
    border: 1px solid #00855b;
    background-image: url(${CheckAddress});
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right top;
  }
  &:hover {
    .options {
      display: flex;
    }
  }
  .addresses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .address_type {
      z-index: 5;
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      width: auto;
      .delivery_type_item {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      display: flex;
      margin-bottom: 1rem;
    }
    .delivery_type {
      .delivery_type_img {
        padding: 0.2rem 0.8rem;
        border: 1px solid #ff9900;
        border-radius: 15px;
      }
    }
  }
  .availability {
    font-size: 16px;
    font-weight: 500;
  }
  .address_status {
    width: max-content;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 13px;
    padding: 0.1rem 0.5rem;
    &.red-text {
      color: #850000;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

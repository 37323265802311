import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { mainPageActions as actions } from '.';
function* getCategories() {
  try {
    let options;

    options = {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const requestURL = `/api/v3/user/categories`;

    const response = yield call(request, requestURL, options);

    let deviceRequestURL = `/api/v1/user/devices`;
    yield call(request, deviceRequestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app_version: '1.0.0',
        os_version: '11',
        type: 'web',
        device_id: 'web',
        device_model: 'web',
        device_token: 'web',
      }),
    });
    yield put(actions.getCategoriesSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getCategoriesError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCategoriesError(err.response));
    } else {
      yield put(actions.getCategoriesError(err));
    }
  }
}

export function* mainPageSaga() {
  yield takeLatest(actions.getCategories.type, getCategories);
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { carouselActions as actions } from '.';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { maintenanceActions } from 'app/pages/Maintenance/slice';

function* getFeaturedImages() {
  try {
    const requestURL = `/api/public/skip-login/featured-items`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getFeaturedImagesSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getFeaturedImagesError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getFeaturedImagesError(err.response));
    } else {
      yield put(actions.getFeaturedImagesError(err));
    }
  }
}
export function* carouselSaga() {
  yield takeLatest(actions.getFeaturedImages.type, getFeaturedImages);
}

import styled from 'styled-components/macro';

export const ReviewStyled = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background-color: #00855b;
  border-radius: 5px;
  max-width: 1018px;
  width: 100%;
  .close {
    margin-left: auto;
  }
  button {
    font-size: 13px;
    color: white;
    margin-left: 1.5rem;
    &:focus,
    &:hover {
      color: white;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 12px;
      margin-left: 0;
    }
  }
`;
export const DeliverySuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  span {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  time {
    font-size: 10px;
    color: white;
    font-weight: 500;
  }
  .btn-link {
    gap: 4px;
    margin-left: 0;
    margin-top: 0;
  }
`;

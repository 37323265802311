/**
 *
 * ManageAddress
 *
 */
import React, { memo, useState, useEffect, useRef } from 'react';
import { SideSheet } from 'app/components/SideSheet';
import {
  LoadingWrapper,
  SideSheetFooter,
  SideSheetHeader,
  AddressListStyled,
  PriceChange,
  ButonGroup,
} from 'app/components/SideSheet/index.styled';
import { useDispatch, useSelector } from 'react-redux';
import { homePageActions } from '../HomePage/slice';
import { selectShowAddress } from '../HomePage/slice/selectors';
import Close from 'assets/close.svg';
import { SideSheetBody } from '../Cart/index.styled';
import {
  selectAddAddress,
  selectAddressList,
  selectDefaultAddress,
  selectEditAddress,
} from './slice/selectors';
import { AddressItem } from './components/AddressItem';
import { AddAddress } from './components/AddAddress';
import { Form, Button, Image, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addressActions } from './slice';
import Back from 'assets/back-white.svg';
import Alert from 'assets/alert.svg';
import { EditAddress } from './components/EditAddress';
import { mainPageActions } from '../MainPage/slice';
import { categoryActions } from '../CategoryPage/slice';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';
import { toast } from 'react-toastify';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};

interface Props {}

export const ManageAddress = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.MANAGE_ADDRESS_PAGE);
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const showAddress = useSelector(selectShowAddress);
  const addressList = useSelector(selectAddressList);
  const addAddress = useSelector(selectAddAddress);
  const [addNew, setAddNew] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [checked, setChecked] = useState(false);
  const [editAddress, setEditAddress] = useState(null);
  const modalRef: any = useRef();
  const deleteModalRef: any = useRef();
  const editAddressRequest = useSelector(selectEditAddress);
  const defaultAddress = useSelector(selectDefaultAddress);

  useEffect(() => {
    if (addAddress.success) {
      setAddNew(false);
      dispatch(addressActions.getAddressList());
    }
  }, [addAddress]);

  useEffect(() => {
    if (
      (editAddressRequest.success || addAddress.success) &&
      defaultAddress?.deliverable
    ) {
      // This will re render the same page again
      history.go(0);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (editAddressRequest.success) {
      setEditAddress(null);
      dispatch(addressActions.getAddressList());
      dispatch(addressActions.resetEditAddressSuccess());
    }
  }, [editAddressRequest]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setAddNew(false);
    setDeleteModal(null);
    setShowModal(null);
    setEditAddress(null);
    dispatch(homePageActions.setShowAddress(false));
  };

  const handleOutsideClick = (e: any) => {
    if (modalRef.current || deleteModalRef.current) {
      const element: any = modalRef.current;
      if (element && !element.contains(e.target)) {
        setShowModal(null);
      }
      const deleteElement: any = deleteModalRef.current;
      if (deleteElement && !deleteElement.contains(e.target)) {
        setDeleteModal(null);
      }
    }
  };

  useEffect(() => {
    if (showModal || deleteModal) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleKeyDown, false);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick, false);
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }
  }, [showModal, deleteModal]);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopImmediatePropagation();
      setShowModal(null);
      setDeleteModal(null);
    }
  };

  const onChangeDefault = item => {
    dispatch(
      mainPageActions.setEnableInstantDelivery({
        // show_instant_delivery: item.show_instant_delivery,
        show_instant_delivery: false,
      }),
    );
    dispatch(categoryActions.clearActiveTab());

    dispatch(addressActions.setSelectedAddress(item));
    dispatch(
      addressActions.editAddress({
        id: item.address_id,
        object: {
          ...item,
          is_default: true,
        },
      }),
    );
  };

  const onSubmit = async val => {
    try {
      const verify = await getCoordinates(val.addressVal.address);
      if (!verify) {
        toast.error(`Please provide full address`);
        return;
      }

      // let custom_address = '#' + val.flat + ', ' + val.locality;
      // if (val.landmark !== '') {
      //   custom_address = custom_address + ', ' + val.landmark;
      // }
      // custom_address = custom_address + ', ' + val.addressVal.address;
      if (
        val.addressVal.address !== '' &&
        val.addressVal.lat !== 0 &&
        val.addressVal.lng !== 0 &&
        val.flat !== '' &&
        val.locality !== ''
      ) {
        dispatch(
          addressActions.addAddress({
            address_name: val.name,
            address: val.addressVal.address,
            door_no: val.flat,
            locality: val.locality,
            landmark: val.landmark,
            latitude: val.addressVal.lat,
            longitude: val.addressVal.lng,
          }),
        );
      } else {
        setError('addressVal', {
          type: 'manual',
          message: 'Invalid Address',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderAddressList = () => {
    if (addressList.loading) {
      return (
        <LoadingWrapper className="loading-wrapper">
          <li className="loading" />
          <li className="loading" />
          <li className="loading" />
        </LoadingWrapper>
      );
    }
    return (
      <>
        <AddressListStyled className="address-list">
          {addressList.data.map(item => (
            <AddressItem
              item={item}
              key={item.address_id}
              checked={checked}
              setShowModal={setShowModal}
              setDeleteModal={setDeleteModal}
              setEditAddress={setEditAddress}
              onChangeDefault={onChangeDefault}
            />
          ))}
        </AddressListStyled>
        <Modal
          className="modal-style price-change-modal"
          show={!!showModal}
          centered
        >
          <PriceChange className="price-change" ref={modalRef}>
            <Image src={Alert} alt="Alert" />
            <h5 className="mt-4">
              Prices and availability might change based on location
            </h5>
            <Button
              variant="warning mt-3"
              onClick={() => {
                Mixpanel.track(actionConstants.PROCEED);
                onChangeDefault(showModal);
                setShowModal(null);
              }}
            >
              Yes, I understand and proceed
            </Button>
            <Form.Group className="mt-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Don't show again"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </Form.Group>
          </PriceChange>
        </Modal>
        <Modal
          className="modal-style delete-modal"
          show={!!deleteModal}
          centered
        >
          <PriceChange className="price-change" ref={deleteModalRef}>
            <Image src={Alert} alt="Alert" />
            <h5 className="mt-4">Are your sure to delete this address?</h5>
            <ButonGroup className="button-group">
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.CANCEL);

                  setDeleteModal(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.DELETE);

                  dispatch(addressActions.deleteAddress(deleteModal));
                  setDeleteModal(null);
                }}
              >
                Yes, remove it
              </Button>
            </ButonGroup>
          </PriceChange>
        </Modal>
      </>
    );
  };

  const renderContent = () => {
    if (editAddress) {
      return (
        <EditAddress
          editAddress={editAddress}
          setEditAddress={setEditAddress}
          onClose={onClose}
        />
      );
    }
    if (addNew) {
      return (
        <>
          <SideSheetHeader className="sidesheet-header">
            <h5>
              <button className="button-icon" onClick={() => setAddNew(false)}>
                <Image src={Back} alt="back" />
              </button>
              Add new address
            </h5>
            <button className="button-icon" onClick={() => onClose()}>
              <Image src={Close} alt="close" />
            </button>
          </SideSheetHeader>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SideSheetBody className="sidesheet-body">
              <AddAddress
                register={register}
                errors={errors}
                setValue={setValue}
                control={control}
              />
            </SideSheetBody>
            <SideSheetFooter className="sidesheet-footer">
              <Button
                variant="warning"
                type="submit"
                disabled={addAddress.loading}
              >
                {addAddress.loading ? 'Adding' : 'Confirm Location'}
              </Button>
            </SideSheetFooter>
          </Form>
        </>
      );
    }
    return (
      <>
        <SideSheetHeader className="sidesheet-header">
          <h5>Manage Address</h5>
          <button className="button-icon" onClick={() => onClose()}>
            <Image src={Close} alt="close" />
          </button>
        </SideSheetHeader>
        <SideSheetBody className="sidesheet-body">
          {renderAddressList()}
        </SideSheetBody>
        <SideSheetFooter className="sidesheet-footer">
          <Button
            variant="warning"
            onClick={() => {
              Mixpanel.track(actionConstants.ADDRESS_ADD);

              setAddNew(true);
            }}
          >
            Add new address
          </Button>
        </SideSheetFooter>
      </>
    );
  };
  return (
    <SideSheet isOpen={showAddress} onClose={onClose}>
      {renderContent()}
    </SideSheet>
  );
});

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.mainPage || initialState;

export const selectMainPage = createSelector([selectSlice], state => state);

export const selectCategories = createSelector(
  [selectSlice],
  state => state.categories,
);

export const selectIsEnableInstantDelivery = createSelector(
  [selectSlice],
  state => state.show_instant_delivery,
);

export const selectDeliveryType = createSelector(
  [selectSlice],
  state => state.deliveryType,
);

/**
 *
 * Asynchronously loads the component for HowItWorksPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const HowItWorksPage = lazyLoad(
  () => import('./index'),
  module => module.HowItWorksPage,
);

/* eslint-disable no-sequences */
/**
 
*
 * RegisterPage
 *
 */
import styled from 'styled-components/macro';
import { Company } from './components/Company';
import { Individual } from './components/Individual';
import { Button } from 'react-bootstrap';
import { useRegisterPageSlice } from './slice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import actionConstants from 'app/mixpanel/constants/action';
import { Mixpanel } from 'app/mixpanel';

interface Props {
  type: string;
  onProceed: Function;
}

export function Register(props: Props) {
  const { type, onProceed } = props;
  useRegisterPageSlice();

  const backToLogin = () => {
    localStorage.removeItem('user_token');
    window.location.reload();
  };

  const renderForm = () => (
    <>
      <InnerWidth className="inner-width">
        {type === 'individual' ? (
          <Individual type={type} onProceed={onProceed} />
        ) : (
          <Company type={type} onProceed={onProceed} />
        )}
      </InnerWidth>
      <span
        className="text-center"
        style={{
          marginTop: '12px',
        }}
      >
        or
      </span>
      <Button
        onClick={() => (
          Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN), backToLogin()
        )}
        style={{
          marginTop: '12px',
        }}
        variant="link"
        className="success"
      >
        Back to Login
      </Button>
    </>
  );

  return renderForm();
}

const InnerWidth = styled.div`
  margin-top: 2rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 100%;
  margin-left: -12px;
  margin-right: -12px;
  > * {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

import { call, put, takeLatest } from 'redux-saga/effects';
import { registerPageActions as actions } from '.';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

function* saveProfile(action: PayloadAction<any>) {
  try {
    const formData = new FormData();
    let requestURL = `/api/v1/user/profile`;

    Object.keys(action.payload).forEach(key => {
      if (!!action.payload[key]) formData.append(key, action.payload[key]);
    });

    formData.append('scope', 'client');
    formData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    formData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '');

    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
      body: formData,
    });
    let deviceRequestURL = `/api/v1/user/devices`;
    yield call(request, deviceRequestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app_version: '1.0',
        os_version: '11',
        type: 'web',
        device_id: 'web',
        device_model: 'web',
        device_token: 'web',
      }),
    });
    yield put(actions.saveProfileSuccess(response));
  } catch (err: any) {
    let response = yield err.response.json();
    console.log(response);
    if (response) {
      Object.keys(response.errors).forEach(key => {
        const errorMessages = response.errors[key];
        if (errorMessages) {
          errorMessages.forEach(message => {
            toast.error(message);
          });
        }
      });
    }
    yield put(actions.saveProfileError(err.response));
  }
}

export function* registerPageSaga() {
  yield takeLatest(actions.saveProfile.type, saveProfile);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * UOM Modal
 *
 */

import { useEffect, useRef } from 'react';
import { UomModal } from './index.styled';
// import { ReactImage } from 'app/components/ReactImage';
import { Card } from 'app/components/Card';
import { CounterUOM } from 'app/components/CounterUOM';

import { Modal, Image } from 'react-bootstrap';
import Cancel from 'assets/uomCancel.svg';
import { ReactImage } from 'app/components/ReactImage';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCartItemByItemCode,
  selectCartItems,
} from 'app/pages/Cart/slice/selectors';
import { cartActions } from 'app/pages/Cart/slice';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import React from 'react';
import actionConstants from 'app/mixpanel/constants/action';
import useAnalyticsEventTracker from 'utils/hooks/useAnalyticsEventTracker';
import { AnalyticsConstants } from 'utils/constants';
// Type Interface
interface Props {
  isOpen: boolean;
  onClose: () => void;
  item: any;
  refresh?: any;
}

export const UOM = (props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.UOM_MODAL);
  });
  const { isOpen, onClose, item, refresh } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const modalEl = useRef(null);

  const handleClose = () => {
    if (refresh) refresh();
    Mixpanel.track(actionConstants.CANCEL);
    onClose();
  };

  const handleOutsideClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const element: any = modalEl.current;
    if (element && !element.contains(e.target)) {
      handleClose();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopImmediatePropagation();
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick, true);
      document.addEventListener('keydown', handleKeyDown, true);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isOpen]);

  const cartItem: any = useSelector(state =>
    selectCartItemByItemCode(state, item.item_code),
  );

  const getValueByUomId = (uomId: number) => {
    if (!cartItem || !cartItem.uom) return 0;

    const uomItem = cartItem.uom.find(uomType => uomType.id === uomId);

    if (!uomItem) return 0;
    return uomItem.qty;
  };

  const getTotalItemInUomId = (): Number => {
    let totalValue = 0;
    [...item?.multiple_uoms]
      .sort((a, b) => a.uom_id - b.uom_id)
      .forEach((multiUom, index) => {
        totalValue += Number(getValueByUomId(multiUom.uom_id));
      });

    return totalValue;
  };

  const eventTracker = useAnalyticsEventTracker(AnalyticsConstants.ADD_TO_CART);

  const generateUomPayload = (uomPayload: any) => {
    if (!cartItem || !cartItem.uom) return [uomPayload];
    const restPayload = cartItem.uom.filter(item => item.id !== uomPayload.id);
    // If Qty is 0 then we will remove that uom from the array
    if (uomPayload.qty === 0) {
      return [...restPayload];
    }
    return [...restPayload, uomPayload];
  };

  const renderButtons = (multiUom, index) => {
    const enableAddButton = !parseInt(
      cartItem?.uom.find(item => item.id === multiUom.uom_id)?.qty || 0,
    );

    if (enableAddButton)
      return (
        <button
          className="add_btn"
          onClick={() => {
            Mixpanel.track(actionConstants.ADDING_ITRM_CART);
            if (window.fbq) {
              window.fbq('track', 'AddToCart', {
                content_ids: item.id,
                content_type: 'product',
                value: parseFloat(multiUom.price),
                currency: 'INR',
              });
            }

            dispatch(
              cartActions.setCartItem({
                id: item.id,
                item_code: item.item_code,
                availability: item.availability,
                uom: generateUomPayload({
                  id: multiUom.uom_id,
                  variant_id: multiUom.variant_id,
                  price: parseFloat(multiUom.price),
                  qty: multiUom.minimum_quantity,
                  availability: multiUom.is_available || true,
                  visibility: multiUom.is_visible || true,
                }),
              }),
            );
          }}
        >
          Add
        </button>
      );
    return (
      <CounterUOM
        value={getValueByUomId(multiUom.uom_id)}
        unit={multiUom.unit}
        minValue={multiUom.minimum_quantity}
        maxValue={multiUom.maximum_quantity}
        onChange={val => {
          dispatch(
            cartActions.setCartItem({
              ...item,
              id: item.id,
              item_code: item.item_code,
              availability: item.availability,
              uom: generateUomPayload({
                id: multiUom.uom_id,
                variant_id: multiUom.variant_id,
                price: parseFloat(multiUom.price),
                qty: val,
                availability: multiUom.is_available || true,
                visibility: multiUom.is_visible || true,
              }),
            }),
          );
        }}
      />
    );
  };

  const renderCard = (multiUom: any, index: number) => {
    if (multiUom.is_available || multiUom.availability) {
      return (
        <Card className="card cart-item cart-add" key={multiUom.uom_id}>
          <div className="add-unit">
            <p className="unit">
              In {multiUom.unit}
              {multiUom.minimum_quantity > 1 ? (
                <span className="qty">
                  MIN {multiUom.minimum_quantity} {multiUom.unit}
                </span>
              ) : (
                <></>
              )}
            </p>
            <p className="price">
              {multiUom.formatted_price} / {multiUom.unit}
            </p>
          </div>
          <div className="add-button-wrapper">
            {multiUom.conversation_text}
            {renderButtons(multiUom, index)}
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="card cart-item" key={multiUom.uom_id}>
          <div>
            <p className="unit">
              In {multiUom.unit}{' '}
              <span className="qty">(MAX {multiUom.maximum_quantity})</span>
            </p>
            <p className="price">
              {multiUom.formatted_price} / {multiUom.unit}
            </p>
          </div>
          <div className="out_of_stock">
            <span> Out of Stock</span>
          </div>
        </Card>
      );
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      animation={true}
      backdrop="static"
      dialogClassName="modal-width-max_content"
      centered
    >
      <Modal.Body ref={modalEl}>
        <UomModal>
          <div className="uom_modal">
            <ReactImage
              className="logo"
              src={
                item.logo.includes('https://')
                  ? item.logo
                  : `${process.env.REACT_APP_API_URI}/${item.logo}`
              }
              alt="product image"
            />

            <div className="item_details">
              <p className="item_name">{item.name}</p>
              {/* <p className="item">Essential</p> */}
            </div>
            <button
              className={`btn ${
                getTotalItemInUomId() === 0 ? 'cancel' : 'done'
              }`}
              onClick={handleClose}
            >
              {getTotalItemInUomId() === 0 ? (
                <Image className="cancel_img" src={Cancel} alt="cancel" />
              ) : (
                <p className="done_btn">Done</p>
              )}
            </button>
          </div>
          <p className="text">Choose a Pack Size</p>

          {[...item?.multiple_uoms]
            // .sort((a, b) => a.uom_id - b.uom_id)
            .map((multiUom, index) => renderCard(multiUom, index))}
        </UomModal>
      </Modal.Body>
    </Modal>
  );
};

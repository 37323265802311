import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useIsAuthenticatedHook from 'app/hooks/useIsAuthenticatedHook';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import useCountLogger from 'app/hooks/useCountLogger';

const PublicRoute = (props: any) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.PUBLIC_ROUTE);
  });
  const { checkToken } = useIsAuthenticatedHook();

  const { logger } = useCountLogger(PublicRoute.name);

  if (!checkToken()) {
    return <Route {...props} />;
  }

  logger('Redirecting', { tok: checkToken() });

  return <Redirect to="/address-list" />;
  // return <Redirect to="/register" />;
};

export default PublicRoute;

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ordersSaga } from './saga';
import { OrdersState } from './types';

export const initialState: OrdersState = {
  orders: {
    data: [],
    meta: [],
    loading: true,
    error: false,
  },
  onGoingOrders: {
    data: [],
    meta: [],
    loading: true,
    error: false,
  },
  nextPage: {
    loading: false,
    error: false,
  },
  reorder: {
    loading: false,
    error: false,
  },
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrders(state, action: PayloadAction<any>) {
      state.orders.data = [];
      state.orders.loading = true;
      state.orders.error = null;
    },
    getOrdersSuccess(state, action: PayloadAction<any>) {
      state.orders.data = action.payload.data;
      state.orders.meta = action.payload.meta;
      state.orders.loading = false;
    },
    getOrdersError(state, action: PayloadAction<any>) {
      state.orders.error = action.payload;
      state.orders.loading = false;
    },
    getOnGoingOrders(state, action: PayloadAction<any>) {
      state.onGoingOrders.data = [];
      state.onGoingOrders.loading = true;
      state.onGoingOrders.error = null;
    },
    getOnGoingOrdersSuccess(state, action: PayloadAction<any>) {
      state.onGoingOrders.data = action.payload.data;
      state.onGoingOrders.meta = action.payload.meta;
      state.onGoingOrders.loading = false;
    },
    getOnGoingOrdersError(state, action: PayloadAction<any>) {
      state.onGoingOrders.error = action.payload;
      state.onGoingOrders.loading = false;
    },
    loadNextPageOrders(state) {
      state.nextPage.loading = true;
      state.nextPage.error = null;
    },
    loadNextPageOrdersSuccess(state, action: PayloadAction<any>) {
      state.orders.data = [...state.orders.data, ...action.payload.data];
      state.orders.meta = action.payload.meta;
      state.nextPage.loading = false;
    },
    loadNextPageOrdersError(state, action: PayloadAction<any>) {
      state.nextPage.error = action.payload;
      state.nextPage.loading = false;
    },
    reorder(state, action: PayloadAction<any>) {
      state.reorder.loading = true;
      state.reorder.error = null;
    },
    reorderSuccess(state) {
      state.reorder.loading = false;
      state.reorder.error = null;
    },
    reorderError(state, action: PayloadAction<any>) {
      state.reorder.loading = true;
      state.reorder.error = null;
    },
    reorderByDeliveryType(state, action: PayloadAction<any>) {
      state.reorder.loading = true;
      state.reorder.error = null;
    },
    reorderByDeliveryTypeSuccess(state) {
      state.reorder.loading = false;
      state.reorder.error = null;
    },
    reorderByDeliveryTypeError(state, action: PayloadAction<any>) {
      state.reorder.loading = true;
      state.reorder.error = null;
    },
  },
});

export const { actions: ordersActions } = slice;

export const useOrdersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ordersSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useOrdersSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * BannerList
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
// import { messages } from './messages';

interface Props {
  list: Array<{
    icon: any;
    name: string;
    title: string;
    type?: string;
  }>;
}

export function BannerList(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { list } = props;

  return (
    <List>
      {list.map(item => (
        <ListItem key={item.name}>
          <a
            href="/"
            style={{ cursor: item.type === 'Download' ? 'pointer' : 'default' }}
            onClick={e => {
              Mixpanel.track(actionConstants.DOWNLOAD);
              e.preventDefault();
              if (item.type === 'Download') {
                if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
                  window.location.href =
                    'market://details?id=com.madrasmandi.user';
                } else {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.madrasmandi.user',
                  );
                }
              }
            }}
          >
            <figure>
              <img src={item.icon} alt={item.name} />
            </figure>
            {item.title}
          </a>
        </ListItem>
      ))}
    </List>
  );
}

const List = styled.ul`
  list-style-type: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 1.5rem 16px 0;
  padding: 0 12px;
`;

const ListItem = styled.li`
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #232827;
    padding: 12px 0;
    text-decoration: none;
    figure {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }
`;

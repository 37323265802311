import styled from 'styled-components';

export const Container = styled.div`
  .box {
    max-width: 600px;
    width: 100%;
    min-height: 540px;
    background: #ffffff;
    padding: 2.5rem 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .send-otp {
      flex-grow: 0.5;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
    .main_heading {
    }
    .inner-width,
    .send-otp {
      max-width: 500px;
      width: 100%;
    }
  }
  .continue_btn {
    background-color: #00855b;
    cursor: pointer;
    max-width: 450px;
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
  }
  @media screen and (max-width: 575px) {
    min-height: initial;
  }
`;

/**
 *
 * Change Delivery Modal
 *
 */

// Styled Compenent
import { ChangeDeliveryType } from './index.styled';

// React-Redux
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Card, Modal } from 'react-bootstrap';

// PNG Image
import watch from 'assets/truck_next_day.svg';
import Thunder from 'assets/thunder.svg';
import { selectProfile } from 'app/pages/slice/selectors';
import { selectCartItems } from 'app/pages/Cart/slice/selectors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { cartActions } from 'app/pages/Cart/slice';
import {
  selectDefaultAddress,
  selectLaunchAddressList,
} from 'app/pages/ManageAddress/slice/selectors';
import React from 'react';
import { addressActions } from 'app/pages/ManageAddress/slice';
import { selectIsEnableInstantDelivery } from '../../slice/selectors';

// Type Interface
interface Props {
  changedDeliveryType: boolean;
  openChangeDeliveryTypeModal: boolean;
  handleChangeDeliveryModalClose: () => void;
  setChangedDeliveryType: Function;
  setOpenChangeDeliveryTypeModal: Function;
  handleSelectEventType: Function;
}

export const ChangeDeliveryModal = (props: Props) => {
  const {
    openChangeDeliveryTypeModal,
    handleChangeDeliveryModalClose,
    handleSelectEventType,
  } = props;

  const isInstantDelivery = useSelector(selectIsEnableInstantDelivery);
  const launchAddressList = useSelector(selectLaunchAddressList);

  React.useEffect(() => {
    dispatch(addressActions.getAddressList());
  }, []);

  const defaultAddress = useSelector(selectDefaultAddress);

  const profile = useSelector(selectProfile);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cartItems = useSelector(selectCartItems);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  const onClick = instantDeliveryType => () => {
    handleSelectEventType(instantDeliveryType)();
  };

  return (
    <Modal
      show={openChangeDeliveryTypeModal}
      onHide={handleChangeDeliveryModalClose}
      animation={true}
      dialogClassName="modal-width-max_content"
      centered
    >
      <Modal.Body>
        {/* {addressList.data.map(item => { */}
        <ChangeDeliveryType className="change-delivery-type">
          <div className="title">Select Delivery Option</div>
          <Card
            className={`card ${
              !defaultAddress?.enable_instant_delivery &&
              defaultAddress?.show_instant_delivery
                ? 'gray'
                : ''
            } ${isInstantDelivery ? 'isActiveInstant' : ''}`}
            onClick={e => {
              if (
                !defaultAddress?.enable_instant_delivery &&
                defaultAddress?.show_instant_delivery
              )
                return;
              onClick(true)();
            }}
          >
            <input
              type="checkbox"
              checked={isInstantDelivery}
              value="instant"
              name="instant-delivery"
              onChange={e => {
                if (
                  !defaultAddress?.enable_instant_delivery &&
                  defaultAddress?.show_instant_delivery
                )
                  return;
                onClick(true)();
              }}
            />

            <div className="heading">
              <img className="img" src={Thunder} alt="instant" />

              <h5>{launchAddressList.data.instant_delivery_title}</h5>
            </div>
            <div className="content">
              <p>
                Get
                <span> {profile.data.items_range_instant}+ </span>
                items delivered instantly{' '}
              </p>
            </div>
            <div className="timeslot">
              <p className="timeslot_text">
                {defaultAddress?.earliest_timeslot_instant}
              </p>
            </div>
          </Card>

          {/* <hr className="solid" /> */}
          <Card
            className={`card ${
              defaultAddress?.next_day_delivery_availability === false
                ? 'gray'
                : ''
            }`}
            onClick={e => {
              if (defaultAddress?.next_day_delivery_availability === false)
                return;
              onClick(false)();
            }}
          >
            <input
              type="checkbox"
              checked={!isInstantDelivery}
              value="app"
              name="next-day-delivery"
              onChange={e => {
                if (defaultAddress?.next_day_delivery_availability === false)
                  return;
                onClick(false)();
              }}
            />

            <div className="heading">
              <img src={watch} alt="next-day" />
              <h5>Next day Delivery</h5>
            </div>
            <div className="content">
              <p>
                Get it <span>{profile.data.items_range_next_day}+ items</span>{' '}
                delivered nextday{' '}
              </p>
            </div>
            {!!defaultAddress.earliest_timeslot &&
            defaultAddress.earliest_timeslot !== null ? (
              <div
                className="timeslot"
                style={{
                  background: 'rgba(219, 238, 232, 0.5)',
                  margin: '-2rem',
                }}
              >
                <p className="timeslot_text">
                  Earliest slot -{defaultAddress?.earliest_timeslot}
                </p>
              </div>
            ) : (
              ''
            )}
          </Card>

          {/* {changedDeliveryType !== enableInstantDelivery ? (
            <Fragment>
              <div className="change_heading">
                <img src={changeAlert} alt="" />
                <div className="change_details">
                  <h6 className="change_line">
                    Prices and item availability may differ in case of delivery
                    option change
                  </h6>
                </div>
              </div>
              <div className="change_button">
                <span
                  className="cancel"
                  onClick={() => setOpenChangeDeliveryTypeModal(false)}
                >
                  Cancel
                </span>
                <span className="change" onClick={onChangeDeliveryType}>
                  Change Delivery Mode
                </span>
              </div>
            </Fragment>
          ) : (
            ''
          )} */}
        </ChangeDeliveryType>
        {/* })} */}
      </Modal.Body>
    </Modal>
  );
};

import styled from 'styled-components';

export const StepperWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  width:100%;
  margin-bottom: 4rem;
}`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
  .current_step {
    border: 2px solid #00855b;
  }
  .step {
    background-color: #d4d4d4;
    margin: 0;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    h4 {
      font-size: 18px;
      margin: 0;
      padding: 0;
    }
  }
  .isDone {
    background-color: #00855b;
    color: white;
  }
  .heading {
    font-size: 0.7rem;
    position: absolute;
    margin-top: 0.5rem;
    top: 3rem;
    width: max-content;
  }
`;

export const HR = styled.hr`
  height: 2px !important;
  width: 15%;
  background: #d4d4d4;
  opacity: 1;
  border: none;
`;

export const ActiveHR = styled.hr`
  height: 2px !important;
  width: 15%;
  background: #00855b;
  opacity: 1;
  border: none;
`;

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { ordersActions as actions } from '.';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectOrders } from '../slice/selectors';
import { cartActions } from 'app/pages/Cart/slice';
import { mainPageActions } from 'app/pages/MainPage/slice';
import { categoryActions } from 'app/pages/CategoryPage/slice';

function* getOrders(action: PayloadAction<any>) {
  try {
    // const requestURL = `/api/v3/user/orders?page=${
    //   action.payload.pageNumber || 1
    // }`;
    const requestURL = `/api/v4/user/orders`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getOrdersSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getOrdersError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getOrdersError(err.response));
    } else {
      yield put(actions.getOrdersError(err));
    }
  }
}

function* getOnGoingOrders(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/user/orders?page=${
      action.payload.pageNumber || 1
    }`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getOnGoingOrdersSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getOnGoingOrdersError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getOnGoingOrdersError(err.response));
    } else {
      yield put(actions.getOnGoingOrdersError(err));
    }
  }
}

function* getNextOrdersPage(action: PayloadAction<any>) {
  try {
    const orders = yield select(selectOrders);
    const currentPage = orders.meta?.current_page + 1;
    const requestURL = `/api/v4/user/orders?page=${currentPage}`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.loadNextPageOrdersSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.loadNextPageOrdersError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.loadNextPageOrdersError(err.response));
    } else {
      yield put(actions.loadNextPageOrdersError(err));
    }
  }
}

function* reorderAPIs(action: PayloadAction<any>) {
  try {
    // Get Order
    const requestURL = `/api/v3/user/orders/${action.payload}`;
    const order = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });

    const generatePayload = item => {
      return {
        id: item.id,
        item_code: item.item_code,
        uom: item.ordered_uoms.map(multiUom => ({
          id: multiUom.uom_id,
          variant_id: multiUom.variant_id,
          price: parseFloat(multiUom.price),
          qty: multiUom.cart_quantity,
        })),
      };
    };

    // Set CartItem to the Redux State
    yield put(cartActions.repeatOrder(order.data.items.map(generatePayload)));

    yield put(actions.reorderSuccess());
  } catch (err: any) {
    yield put(actions.reorderError(err));
  }
}

function* reorderInstantAPIs(action: PayloadAction<any>) {
  try {
    const orderId = action.payload.orderId;
    const isInstantDeliveryEnable = action.payload.isInstantDeliveryEnable;

    // SEtting Delivery to {launchAddressList.data.instant_delivery_title}
    yield put(
      mainPageActions.setEnableInstantDelivery({
        show_instant_delivery: isInstantDeliveryEnable,
      }),
    );
    yield put(categoryActions.clearActiveTab());
    // Loading getCategories
    yield put(mainPageActions.getCategories());

    // Get Order
    const requestURL = `/api/v3/user/orders/${orderId}`;
    const order = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });

    const generatePayload = item => {
      return {
        id: item.id,
        item_code: item.item_code,
        uom: item.ordered_uoms.map(multiUom => ({
          id: multiUom.uom_id,
          variant_id: multiUom.variant_id,
          price: parseFloat(multiUom.price),
          qty: multiUom.cart_quantity,
        })),
      };
    };

    // Set CartItem to the Redux State
    yield put(cartActions.repeatOrder(order.data.items.map(generatePayload)));

    yield put(actions.reorderSuccess());
  } catch (err: any) {
    yield put(actions.reorderError(err));
  }
}

export function* ordersSaga() {
  yield takeLatest(actions.getOrders.type, getOrders);
  yield takeLatest(actions.getOnGoingOrders.type, getOnGoingOrders);
  yield takeLatest(actions.loadNextPageOrders.type, getNextOrdersPage);
  yield takeLatest(actions.reorder.type, reorderAPIs);
  yield takeLatest(actions.reorderByDeliveryType.type, reorderInstantAPIs);
}

/**
 *
 * RegisterPage
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import LogoSvg from 'assets/logo.svg';
import { Company } from './components/Company';
import { Individual } from './components/Individual';
import { Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useRegisterPageSlice } from './slice';
import { SelectAccountType } from './components/SelectAccountType';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';

interface Props extends RouteComponentProps {}

export function RegisterPage(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.REGISTER_PAGE);
  });
  useRegisterPageSlice();
  const [type, setType] = useState('');
  const [isProceedToForm, setIsProceedToForm] = useState(false);

  const backToLogin = () => {
    localStorage.removeItem('user_token');
    window.location.reload();
  };

  const renderForm = () => (
    <>
      <InnerWidth className="inner-width">
        {type === 'individual' ? (
          <Individual type={type} {...props} />
        ) : (
          <Company type={type} {...props} />
        )}
      </InnerWidth>
      <span
        className="text-center"
        style={{
          marginTop: '12px',
        }}
      >
        or
      </span>
      <Button
        onClick={() => {
          Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN);
          backToLogin();
        }}
        style={{
          marginTop: '12px',
        }}
        variant="link"
        className="success"
      >
        Back to Login
      </Button>
    </>
  );

  const renderContent = () => {
    if (isProceedToForm) return renderForm();
    return (
      <SelectAccountType
        onProceed={() => {
          setIsProceedToForm(true);
        }}
        setType={setType}
        type={type}
      />
    );
  };

  return (
    <div className="authentication-page register-page">
      <div className="box">
        <figure className="logo">
          <img src={LogoSvg} alt="" />
          <h4 style={{ margin: '2rem' }}>Registration</h4>
        </figure>
        {renderContent()}
      </div>
    </div>
  );
}

const InnerWidth = styled.div`
  margin-top: 2rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 100%;
  margin-left: -12px;
  margin-right: -12px;
  > * {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

/**
 *
 * HomePage
 *
 */
import React from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Maintenance } from '../Maintenance';
import { ManageAddress } from '../ManageAddress';
import { SkipLoginMainPage } from '../SkipLoginMainPage';
import styled from 'styled-components';
import { SideBar } from '../SideBar';
import { SkipLoginCategoryPage } from '../SkipLoginCategoryPage';
import { Header } from 'app/components/Header';
import { SearchView } from './components';
import { SkipLoginItemPage } from '../SkipLoginItemPage';
import { selectShowCart } from '../HomePage/slice/selectors';
import { useSelector } from 'react-redux';
import { CartSL } from '../SkipLoginCart';
import { TermsConditions } from 'app/components/TermsConditions';
import { PrivacyPolicy } from 'app/components/PrivacyPolicy';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';

interface Props extends RouteComponentProps {}

export function SkipLoginPage(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.SKIP_LOGIN);
  });
  const showSideBar = useSelector(selectShowCart);

  return (
    <Container className="app-wrapper">
      <SideBar isDisable {...props} />
      <Main className="main">
        <Header />
        <Route exact path="/sl" component={SkipLoginMainPage} />
        <Route
          exact
          path="/sl/category/:id"
          component={SkipLoginCategoryPage}
        />
        <Route exact path="/sl/item/:id" component={SkipLoginItemPage} />
        <Route path="/sl/terms" component={TermsConditions} />
        <Route path="/sl/privacy" component={PrivacyPolicy} />
      </Main>
      <SearchView {...props} />
      {showSideBar && <CartSL />}
      <Maintenance />
      <ManageAddress />
    </Container>
  );
}

const Main = styled.main`
  background-color: #fafafa;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
  padding: 16px 1.5rem 1.5rem;
  @media screen and (max-width: 991px) {
    border-radius: 0;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
    max-width: 100%;
  }
`;

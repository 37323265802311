/**
 *
 * Profile
 *
 */

interface Props {}

export function Profile(props: Props) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M8.50007 1.0769C6.14209 1.0769 4.22388 2.99512 4.22388 5.3531C4.22388 7.71109 6.14209 9.6293 8.50007 9.6293C10.8581 9.6293 12.7763 7.71109 12.7763 5.3531C12.7763 2.99512 10.8581 1.0769 8.50007 1.0769ZM8.50007 8.3716C6.8357 8.3716 5.48158 7.01748 5.48158 5.3531C5.48158 3.68873 6.8357 2.33461 8.50007 2.33461C10.1644 2.33461 11.5186 3.68873 11.5186 5.3531C11.5186 7.01748 10.1644 8.3716 8.50007 8.3716Z"
          className="fill-grey"
        />
        <path
          d="M8.5 10.4746C4.73862 10.4746 1.79224 13.5222 1.79224 17.413H3.04994C3.04994 14.1744 5.39305 11.7323 8.5 11.7323C11.607 11.7323 13.9501 14.1746 13.9501 17.413H15.2078C15.2078 13.5223 12.2614 10.4746 8.5 10.4746Z"
          className="fill-grey"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect y="0.744873" width="17" height="17" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

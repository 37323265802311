import { PayloadAction } from '@reduxjs/toolkit';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { header } from 'utils/auth';
import { request } from 'utils/request';
import { categoryActions as actions } from '.';
import { selectCurrentCategoryId, selectFavourites } from './selectors';

function* getSubCategories(action: PayloadAction<any>) {
  try {
    let requestURL = '';
    if (action.payload === '6') {
      requestURL = `/api/v3/user/categories/${action.payload}`;
    } else {
      requestURL = `/api/v3/user/categories/${action.payload}`;
      // if (uom_enabled) {
      //   requestURL += `?uom_type=${type ? 'kgs' : 'pcs'}`;
      // }
    }
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    const favouriteResponse = yield call(request, `/api/v3/user/favourites`, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    if (window.fbq) {
      window.fbq('trackCustom', 'ViewCategory', action.payload);
    }
    yield put(actions.setFavourites(favouriteResponse.data));
    yield put(actions.getSubCategoriesSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getSubCategoriesError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getSubCategoriesError(err.response));
    } else {
      yield put(actions.getSubCategoriesError(err));
    }
  }
}

function* updateFavourites(action: PayloadAction<any>) {
  try {
    let tempFavorites = yield select(selectFavourites);
    let favorites = tempFavorites.map(e => ({ item_code: e.item_code }));

    let currentCategoryId = yield select(selectCurrentCategoryId);

    let tempFav: any = [];
    if (action.payload.isFavourite) {
      tempFav = [
        ...favorites,
        {
          item_code: action.payload.itemCode,
        },
      ];
    } else {
      tempFav = favorites.filter(e => e.item_code !== action.payload.itemCode);
    }

    yield put(actions.setFavourites(tempFav));
    const requestURL = `/api/v3/user/favourites`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: tempFav,
      }),
    });

    // Refresh category page if current category page is favourites
    const isCurrentSubCategoryExist =
      Boolean(currentCategoryId) || Number(currentCategoryId) === 0;
    const isSubCategoryIsFavourites = Number(currentCategoryId) === 6;
    if (isCurrentSubCategoryExist && isSubCategoryIsFavourites) {
      yield put(actions.getSubCategories(Number(currentCategoryId)));
    }
  } catch (err: any) {
    console.log(err);
  }
}

export function* categorySaga() {
  yield takeLatest(actions.getSubCategories.type, getSubCategories);
  yield takeLatest(actions.updateFavourites.type, updateFavourites);
}

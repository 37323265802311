import { PayloadAction } from '@reduxjs/toolkit';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { skipLoginCategoryPageActions as actions } from '.';

function* getSubCategories(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/public/skip-login/categories/${action.payload}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    };

    /* Get Response */
    const response = yield call(request, requestURL, options);

    yield put(actions.getSubCategoriesSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getSubCategoriesError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getSubCategoriesError(err.response));
    } else {
      yield put(actions.getSubCategoriesError(err));
    }
  }
}

export function* skipLoginCategoryPageSaga() {
  yield takeLatest(actions.getSubCategories.type, getSubCategories);
}

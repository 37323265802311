import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.rewards || initialState;

export const selectRewards = createSelector(
  [selectSlice],
  state => state.rewards,
);

import styled from 'styled-components/macro';

const LoadingWrapper = styled.div`
  .loading {
    margin-top: 1.5rem;
    height: 180px;
  }
`;

const OrderDetailsStyled = styled.div`
  > * {
    margin-top: 1.5rem;
    font-size: 14px;
    &:not(.order-details-intro) {
      time {
        font-size: 12px;
      }
    }
  }
  .card {
    margin-top: 16px;
  }
  h6 {
    margin-bottom: 0;
  }
  time {
    color: #c4c4c4;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .delivery-details {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .bottom-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    background-color: #00855b;
    border-radius: 5px;
    width: 100%;
  }
  &.alert-active {
  }
  .edit_order_bill {
    .edit_order_status {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .edit_order_mode {
      margin-top: 1rem;
      .payment_text {
        display: flex;
        margin-top: 0.5rem;
      }
      .mode_text {
        margin-top: 0.3rem;
        margin-left: 0.5rem;
        text-transform: capitalize;
      }
    }
    .pending_status {
      background: red;
      border: 1px solid red;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
    img {
      margin-right: 6px;
    }
    .paid_status {
      background: #00855b;
      border: 1px solid #00855b;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      width: 70px;
      text-align: center;
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;

const Bill = styled.div`
  .bill_no {
    background: #00855b;
    border: 1px solid #00855b;
    border-radius: 5px;
    color: #ffffff;
    padding: 0.4rem;
    cursor: pointer;
    font-size: 12px;
    width: 190px;
    text-align: center;
    margin: auto 0.4rem;
  }
`;
const PaymentDetailsList = styled.ul``;
const PaymentDetailsListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const DeliveryDetail = styled.div`
  .delivery-detail {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .address_section {
      .address_name {
        margin-top: 0.5rem;
      }
      .address {
        color: black;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`;

const PaymentDetails = styled.div`
  padding-bottom: 5rem;
  > * {
    margin-top: 1.5rem;
    font-size: 14px;
    &:not(.order-details-intro) {
      time {
        font-size: 12px;
      }
    }
  }
  .card {
    margin-top: 16px;
  }
  h6 {
    margin-bottom: 0;
  }
  time {
    color: #c4c4c4;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .delivery-details {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .bottom-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    background-color: #00855b;
    border-radius: 5px;
    max-width: 1018px;
    width: 100%;
  }
  &.alert-active {
  }
  .edit_order_bill {
    .edit_order_status {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .edit_order_mode {
      margin-top: 1rem;
      .payment_text {
        display: flex;
        margin-top: 0.5rem;
        justify-content: space-between;

        .img-div {
          display: flex;
          align-items: center;
          .mode_text {
            margin-top: 0.3rem;
            margin-left: 0.5rem;
            text-transform: capitalize;
          }
        }
      }
    }
    .pending_status {
      background: red;
      border: 1px solid red;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
    img {
      margin-right: 6px;
    }
    .paid_status {
      background: #00855b;
      border: 1px solid #00855b;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      width: 70px;
      text-align: center;
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;
const PaymentMethod = styled.div`
  .method-div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 12px;
    .pending_status {
      background: red;
      border: 1px solid red;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
    img {
      margin-right: 6px;
    }
    .paid_status {
      background: #00855b;
      border: 1px solid #00855b;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      height: 30px;
      width: 70px;
      text-align: center;
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
  .subpayment-method {
    display: flex;
    flex-direction: column;
    .img-div {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      h6 {
        margin-left: 1rem;
      }
    }
  }

  .method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img-div {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      h6 {
        margin-left: 1rem;
      }
    }
    .price {
      margin-top: 1rem;
    }
  }
`;

const SelectDeliveryOption = styled.div`
  display: flex;
  img {
    margin-left: 1rem;
  }
  .title {
    margin-left: 1rem;
    padding-top: 1rem;
    font-weight: 600;
  }
`;
const DeliveryOption = styled.div`
  h6 {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 16px;
  }
`;

const DeliveryType = styled.div`
  h6 {
    margin-top: 1rem;
  }
`;

const SelectScheduleDelivery = styled.div`
  h6 {
    font-weight: 600;
    font-size: 16px;
  }
  .time {
    color: black;
    padding-top: 1rem;
    font-weight: 600;
    font-size: 16px;
  }
`;

const ScheduleDeliveryDetail = styled.div`
  h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Refunds = styled.div`
  .refund {
    display: flex;
    .refund_text {
      margin-top: 1rem;
    }
    justify-content: space-between;
    .refund_amount {
      display: flex;
      flex-direction: column-reverse;
      .refund_status {
        color: #00855b;
        font-size: 16px;
      }
      .refund_price {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .refund-message {
    color: #acacac;
  }
`;

const DelayMessage = styled.div`
  .delay-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    .delay-detail {
      p {
        margin-bottom: 0;
      }
    }
  }
`;

const Intro = styled.div`
  .heading {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  time {
    margin-top: 4px;
    display: block;
  }
  .store-img-wrapper {
    margin: 1rem auto;
    height: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    .store-img {
      max-height: 100%;
    }
  }
`;

const StoreOrderDetailsStyled = styled.div``;
const PurchasedItem = styled.div``;

export {
  LoadingWrapper,
  OrderDetailsStyled,
  PaymentDetailsList,
  PaymentDetailsListItem,
  DeliveryDetail,
  PaymentDetails,
  PaymentMethod,
  Intro,
  DeliveryOption,
  SelectDeliveryOption,
  DeliveryType,
  SelectScheduleDelivery,
  ScheduleDeliveryDetail,
  Refunds,
  DelayMessage,
  Bill,
  StoreOrderDetailsStyled,
  PurchasedItem,
};

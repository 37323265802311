import { call, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { reviewActions as actions } from '.';
import { maintenanceActions } from 'app/pages/Maintenance/slice';

function* sendFeedback(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/user/ratings`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    yield put(actions.sendFeedbackSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.sendFeedbackError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.sendFeedbackError(err.response));
    } else {
      yield put(actions.sendFeedbackError(err));
    }
  }
}

export function* reviewSaga() {
  yield takeLatest(actions.sendFeedback.type, sendFeedback);
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.orders || initialState;

export const selectOrders = createSelector(
  [selectSlice],
  state => state.orders,
);

export const selectOnGoingOrders = createSelector(
  [selectSlice],
  state => state.onGoingOrders,
);

export const selectNextPage = createSelector(
  [selectSlice],
  state => state.nextPage,
);

import styled from 'styled-components/macro';

const MetaBarBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  h6 {
    margin-bottom: 0;
    flex-shrink: 0;
    b {
      color: #00855b;
    }
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    > * {
      width: 100%;
    }
  }
`;
const MetaBarTop = styled.div`
  display: flex;
  align-items: center;
`;
const DeliveringTo = styled.div`
  display: flex;
  align-items: center;
  img {
    flex-shrink: 0;
    margin-left: 6px;
    margin-right: 6px;
    @media screen and (max-width: 575px) {
      &.pin {
        margin-left: 0;
      }
    }
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SkipDeliveringTo = styled.div`
  display: flex;
  align-items: center;
  img {
    flex-shrink: 0;
    margin-left: 6px;
    margin-right: 6px;
    @media screen and (max-width: 575px) {
      &.pin {
        margin-left: 0;
      }
    }
  }
`;
const Address = styled.div`
  width: 100%;
  @keyframes scroll {
    0% {
      left: 400px;
    }
    100% {
      left: -400px;
    }
  }
  address {
    height: 20px;
    font-size: 14px;
    margin-bottom: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 575px) {
      width: 600px;
      display: block;
      -webkit-line-clamp: initial;
      -webkit-box-orient: initial;
      animation: scroll 10s linear infinite;
    }
  }
  @media screen and (max-width: 575px) {
    overflow: hidden;
    max-width: 100%;
  }
  &:hover {
    address {
      animation-play-state: paused;
    }
  }
`;
const RoundedIcon = styled.figure`
  width: 40px;
  height: 40px;
  border: 1px solid #acacac;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  cursor: pointer;
  &.back-navigation {
    margin-right: 8px;
  }
`;
const MetabarLeft = styled.div`
  display: flex;
  align-items: center;
`;
const MetaBarRight = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .search-bar {
    margin-right: 16px;
    .search {
      padding-left: 50px;
    }
  }
`;
const Hamburger = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 991px) {
    display: flex;
  }
`;

const SearchStyled = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
  input {
    &.form-control {
      height: 40px;
      padding: 4px 54px 4px 8px;
    }
    width: 100%;
    border: none;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    font-size: 14px;
    @media screen and (max-width: 991px) {
      height: 40px;
      padding: 8px 48px 8px 8px;
    }
  }
  figure {
    width: 32px;
    height: 32px;
    background-color: #00855b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 0;
    margin-right: 16px;
    @media screen and (max-width: 991px) {
      right: 8px;
    }
  }
  @media screen and (max-width: 991px) {
    max-width: 260px;
    width: 100%;
    margin-left: auto;
  }
`;

export {
  MetaBarTop,
  DeliveringTo,
  Address,
  SearchStyled,
  MetaBarRight,
  MetabarLeft,
  Hamburger,
  RoundedIcon,
  MetaBarBottom,
  SkipDeliveringTo,
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { loginPageActions as actions } from '.';
import { maintenanceActions } from 'app/pages/Maintenance/slice';

function* sendOtp(action: PayloadAction<any>) {
  try {
    const formData = new FormData();
    let requestURL = `/api/v1/auth/otp`;
    formData.append('phone', action.payload);
    formData.append('scope', 'client');
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
      body: formData,
    });
    yield put(
      actions.sendOtpSuccess({
        success: true,
        sentPhoneNo: action.payload,
      }),
    );
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.sendOtpError(err.response));
    } else if (err.response?.status === 419) {
      const error = yield err.response.json();

      yield put(actions.sendOtpIOSError(error));
    } else if (err.message === 'Server Error') {
      yield put(actions.sendOtpError(err.response));
    } else {
      yield put(actions.sendOtpError(err));
    }
  }
}

function* enterOtp(action: PayloadAction<any>) {
  try {
    const formData = new FormData();
    let requestURL = `/api/v1/oauth/token`;
    formData.append('grant_type', 'password');
    formData.append('username', action.payload.sentPhoneNo);
    formData.append('password', action.payload.otp);
    formData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    formData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '');
    formData.append('scope', '*');

    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
      body: formData,
    });
    yield put(actions.enterOtpSuccess(response));
  } catch (err: any) {
    let error = yield err.response.json();
    if (error.message) {
      yield put(actions.enterOtpError(error.message));
    }
  }
}

function* loginWithPassword(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/oauth/token`;

    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: action.payload.username,
        password: action.payload.password,
        scope: '*',
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID || '',
        client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
      }),
    });
    yield put(actions.loginWithPasswordSuccess(response));
  } catch (err: any) {
    let response = yield err.response.json();
    yield put(actions.loginWithPasswordError(response.message));
  }
}

function* checkDeliveryLocationSaga(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/public/check-deliverable`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    yield put(actions.checkDeliveryLocationSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.checkDeliveryLocationError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.checkDeliveryLocationError(err.response));
    } else {
      yield put(actions.checkDeliveryLocationError(err));
    }
  }
}

export function* loginPageSaga() {
  yield takeLatest(actions.sendOtp.type, sendOtp);
  yield takeLatest(actions.enterOtp.type, enterOtp);
  yield takeLatest(actions.loginWithPassword.type, loginWithPassword);
  yield takeLatest(
    actions.checkDeliveryLocation.type,
    checkDeliveryLocationSaga,
  );
}

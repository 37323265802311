import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.carouselSL || initialState;

export const selectCarousel = createSelector([selectSlice], state => state);

export const selectFeaturedImages = createSelector(
  [selectSlice],
  state => state.featuredImages,
);

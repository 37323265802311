import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loginPageSaga } from './saga';
import { LoginPageState } from './types';

export const initialState: LoginPageState = {
  sendOtp: {
    loading: false,
    success: false,
    error: null,
    sentPhoneNo: '',
  },
  loginWithPassword: {
    loading: false,
    success: false,
    error: null,
  },
  enterOtp: {
    loading: false,
    success: false,
    error: null,
  },
  accountDeleteIOSError: null,
  locationCheck: {
    data: null,
    loading: false,
    success: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'loginPage',
  initialState,
  reducers: {
    sendOtp(state, action: PayloadAction<any>) {
      state.sendOtp.loading = true;
      state.sendOtp.error = null;
      state.sendOtp.success = false;
    },
    sendOtpSuccess(state, action: PayloadAction<any>) {
      state.sendOtp.loading = false;
      state.sendOtp.success = action.payload.success;
      state.sendOtp.sentPhoneNo = action.payload.sentPhoneNo;
    },
    sendOtpError(state, action: PayloadAction<any>) {
      state.sendOtp.loading = false;
      state.sendOtp.error = action.payload;
      state.sendOtp.success = false;
    },
    sendOtpIOSError(state, action: PayloadAction<any>) {
      state.sendOtp.loading = false;
      state.sendOtp.success = false;
      state.sendOtp.error = false;
      state.accountDeleteIOSError = action.payload;
    },
    setSentPhoneNo(state, action: PayloadAction<any>) {
      state.sendOtp.sentPhoneNo = '';
      state.sendOtp.success = false;
      state.sendOtp.error = null;
    },
    enterOtp(state, action: PayloadAction<any>) {
      state.enterOtp.loading = true;
      state.enterOtp.error = null;
      state.enterOtp.success = false;
    },
    enterOtpSuccess(state, action: PayloadAction<any>) {
      state.enterOtp.loading = false;
      state.enterOtp.success = action.payload;
    },
    enterOtpError(state, action: PayloadAction<any>) {
      state.enterOtp.loading = false;
      state.enterOtp.error = action.payload;
      state.enterOtp.success = false;
    },
    loginWithPassword(state, action: PayloadAction<any>) {
      state.loginWithPassword.loading = true;
      state.loginWithPassword.error = null;
      state.loginWithPassword.success = false;
    },
    loginWithPasswordSuccess(state, action: PayloadAction<any>) {
      state.loginWithPassword.loading = false;
      state.loginWithPassword.success = action.payload;
    },
    loginWithPasswordError(state, action: PayloadAction<any>) {
      state.loginWithPassword.loading = false;
      state.loginWithPassword.error = action.payload;
      state.loginWithPassword.success = false;
    },
    checkDeliveryLocation(state, action: PayloadAction<any>) {
      state.locationCheck.loading = true;
      state.locationCheck.error = false;
      state.locationCheck.success = false;
    },
    checkDeliveryLocationSuccess(state, action: PayloadAction<any>) {
      state.locationCheck.data = action.payload;
      state.locationCheck.loading = false;
      state.locationCheck.error = false;
      state.locationCheck.success = false;
    },
    checkDeliveryLocationError(state, action: PayloadAction<any>) {
      state.locationCheck.error = action.payload;
      state.locationCheck.data = null;
      state.locationCheck.loading = false;
      state.locationCheck.success = false;
    },
  },
});

export const { actions: loginPageActions } = slice;

export const useLoginPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginPageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLoginPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { RewardsInfoSaga } from './saga';
import { RewardsInfoState } from './types';

export const initialState: RewardsInfoState = {
  rewardsInfo: {
    data: {},
    loading: true,
    error: false,
    // success: false,
  },
};

const slice = createSlice({
  name: 'rewardsInfo',
  initialState,
  reducers: {
    getRewardsInfo(state) {
      state.rewardsInfo.data = {};
      state.rewardsInfo.loading = true;
      state.rewardsInfo.error = null;
    },
    getRewardsInfoSuccess(state, action: PayloadAction<any>) {
      state.rewardsInfo.data = action.payload;
      state.rewardsInfo.loading = false;
    },
    getRewardsInfoError(state, action: PayloadAction<any>) {
      state.rewardsInfo.error = action.payload;
      state.rewardsInfo.loading = false;
    },
  },
});

export const { actions: rewardsInfoActions } = slice;

export const useRewardsInfoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: RewardsInfoSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useChallengeSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * TeamsConditions
 *
 */

interface Props {}

export function TeamsConditions(props: Props) {
  return (
    <svg width="17" height="18" viewBox="0 0 15 15" fill="none">
      <path
        d="M7.50008 12.9116C9.00298 12.9116 10.4443 12.3146 11.507 11.2519C12.5697 10.1892 13.1667 8.74785 13.1667 7.24496C13.1667 5.74206 12.5697 4.30072 11.507 3.23802C10.4443 2.17531 9.00298 1.57829 7.50008 1.57829C5.99719 1.57829 4.55585 2.17531 3.49314 3.23802C2.43044 4.30072 1.83341 5.74206 1.83341 7.24496C1.83341 8.74785 2.43044 10.1892 3.49314 11.2519C4.55585 12.3146 5.99719 12.9116 7.50008 12.9116ZM7.50008 14.3283C3.58796 14.3283 0.416748 11.1571 0.416748 7.24496C0.416748 3.33283 3.58796 0.161621 7.50008 0.161621C11.4122 0.161621 14.5834 3.33283 14.5834 7.24496C14.5834 11.1571 11.4122 14.3283 7.50008 14.3283ZM6.79175 10.0783H8.20842V11.495H6.79175V10.0783ZM6.79175 2.99495H8.20842V8.66162H6.79175V2.99495Z"
        className="fill-grey"
      />
    </svg>
  );
}

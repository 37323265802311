import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { referAndEarnActions as actions } from '.';

function* getRewards() {
  try {
    const requestURL = `/api/v1/user/rewards`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getRewardsSuccess(response.data));
  } catch (err: any) {
    yield put(actions.getRewardsError(err.response));
  }
}
function* getRewardsWithToken(data) {
  try {
    const requestURL = `/api/v1/user/rewards`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${data.payload.token}`,
        Accept: 'application/json',
      },
    });
    yield put(actions.getRewardsSuccess(response.data));
  } catch (err: any) {
    yield put(actions.getRewardsError(err.response));
  }
}

export function* authSaga() {
  yield takeLatest(actions.getRewards.type, getRewards);
  yield takeLatest(actions.getRewardsWithToken.type, getRewardsWithToken);
}

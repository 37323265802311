/**
 *
 * CartItem
 *
 */
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Card } from 'app/components/Card';
import { Image } from 'react-bootstrap';
import { CounterUOM } from 'app/components/CounterUOM';
import { useDispatch, useSelector } from 'react-redux';
import Delete from './assets/delete.svg';
import { ReactImage } from 'app/components/ReactImage';
import { cartActions } from 'app/pages/Cart/slice';
import { UOM } from 'app/pages/CategoryPage/components/CardList/components/UomModal';
import {
  selectCartItemByItemCode,
  selectCartItems,
} from 'app/pages/Cart/slice/selectors';
import { Small } from 'app/pages/CategoryPage/components/CardList/components/ItemCard/index.styled';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {
  item: any;
}

export function CartItem(props: Props) {
  const { item } = props;
  const [isOpenUomModal, setIsOpenUomModal] = React.useState(false);
  const cartItems = useSelector(selectCartItems);
  const cartItem = useSelector(state =>
    selectCartItemByItemCode(state, item.item_code),
  );
  const dispatch = useDispatch();

  useEffect(() => {}, [cartItem]);

  const onOpenUomModal = () => {
    setIsOpenUomModal(true);
  };
  const onCloseUomModal = () => {
    setIsOpenUomModal(false);
  };

  const getSubTotal = useMemo(() => {
    return cartItem?.uom.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.price * currentValue.qty;
    }, 0);
  }, [cartItem]);

  const isCartItemVisible = useMemo(() => {
    if (!cartItem) return true;
    const totalQtyCount = cartItem?.uom.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.qty;
      },
      0,
    );
    return !(totalQtyCount > 0);
  }, [cartItems]);

  if (isCartItemVisible) {
    return <></>;
  }

  const getQty = variant_id =>
    cartItem?.uom.find(uom => uom.variant_id === variant_id)?.qty || 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getUnit = uom_id =>
    item?.multiple_uoms.find(uom => uom.id === uom_id)?.unit;

  const renderButton = () => {
    let tempValue = 0;
    if (cartItem) {
      if (cartItem.uom.length > 1) {
        tempValue = cartItem.uom.reduce((acc, curr) => {
          return acc + parseFloat(curr.qty);
        }, 0);
      } else {
        tempValue = parseFloat(cartItem.uom[0].qty);
      }
    }
    // If multiple_uom data is one and one cart item added in the cart
    return (
      <CounterUOM
        isMultipleUomInCart={cartItem.uom.length > 1}
        isMultipleUomInAPI={item?.multiple_uoms.length > 1}
        value={tempValue}
        onOpenUomModal={onOpenUomModal}
        unit={item.multiple_uoms[0].unit}
        minValue={item.multiple_uoms[0].minimum_quantity}
        maxValue={item.multiple_uoms[0].maximum_quantity}
        onChange={val => {
          dispatch(
            cartActions.setCartItem({
              ...item,
              uom: [
                {
                  price: parseFloat(item.multiple_uoms[0].price),
                  variant_id: item.multiple_uoms[0].variant_id,
                  id: item.multiple_uoms[0].uom_id,
                  qty: val,
                  availability: item.multiple_uoms[0].is_available || true,
                  visibility: item.multiple_uoms[0].is_visible || true,
                },
              ],
            }),
          );
        }}
      />
    );
  };

  const renderConversationText = () => {
    if (item?.multiple_uoms.length !== 1) return '';
    if (!!item.multiple_uoms[0].conversion_text)
      return (
        <Small className="small">{item.multiple_uoms[0].conversion_text}</Small>
      );

    return <></>;
  };

  const renderOrderQty = () => {
    if (
      !item &&
      !item?.multiple_uoms &&
      !Array.isArray(item.multiple_uoms) &&
      item.multiple_uoms.length <= 0
    )
      return;
    const iterateThrough = [...item.multiple_uoms].sort(
      (a, b) => a.uom_id - b.uom_id,
    );
    return iterateThrough.map(item => {
      const qty = getQty(item.variant_id);
      if (qty === 0) return '';
      return (
        <p className="cart_item_price" key={item.id}>
          ₹ {item.price} /{item.unit} x{qty}
        </p>
      );
    });
  };

  const renderCartItem = () => (
    <Card className="card cart-item" key={item.item_code}>
      <CardItemDetails className="cart-item-details">
        <Figure className="figure">
          <ReactImage
            src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
            alt="product image"
          />
        </Figure>
        <ItemDetails className="item-details">
          <button
            className="button-icon"
            onClick={() => {
              Mixpanel.track(actionConstants.DELETE_ITEM);

              dispatch(
                cartActions.removeItem({
                  item_code: item.item_code,
                }),
              );
            }}
          >
            <Image src={Delete} alt="delete" />
          </button>
          <h5>{item.name}</h5>
          <Limit>{renderOrderQty()}</Limit>
        </ItemDetails>
      </CardItemDetails>
      <Total className="total">
        <div className="text">
          {renderConversationText()}
          <b>
            SubTotal: <span>₹{Number(getSubTotal).toFixed(2)}</span>
          </b>
        </div>
        {renderButton()}
      </Total>
      {isOpenUomModal && (
        <UOM isOpen={isOpenUomModal} onClose={onCloseUomModal} item={item} />
      )}
    </Card>
  );

  const renderOutOfStockCartItem = () => (
    <Card className="card cart-item">
      <CardOutOfStockItemDetails className="cart-item-details">
        <Figure>
          <ReactImage
            src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
            alt="product image"
          />
        </Figure>
        <ItemDetails className="item-details">
          <button
            className="button-icon"
            onClick={() => {
              Mixpanel.track(actionConstants.DELETE_ITEM);

              dispatch(
                cartActions.removeItem({
                  item_code: item.item_code,
                }),
              );
            }}
          >
            <Image className="img_out_of_stock" src={Delete} alt="delete" />
          </button>

          <h5>{item.name}</h5>
          <Limit>{renderOrderQty()}</Limit>
        </ItemDetails>
      </CardOutOfStockItemDetails>
      <Bottom className="card-bottom">
        <OutOfStock>
          <span> Out of Stock</span>
        </OutOfStock>
      </Bottom>
    </Card>
  );

  // if (item.visibility) {
  if (item.availability) {
    return renderCartItem();
  }
  return renderOutOfStockCartItem();
  // }
  // return <></>;
}

const ItemDetails = styled.div`
  width: 100%;
  padding-left: 12px;
  > button {
    display: flex;
    position: absolute;
    top: 4px;
    right: 0;
  }
  h5 {
    margin-bottom: 0;
    padding-right: 16px;
  }
  .uom_tag {
    display: flex;
    cursor-pointer;
    justify-content: space-around;
    background-color: #f0f0f0;
    border-radius: 15px;
    width: 200px;
    margin: 0.5rem 0 0.3rem 0;
    .uom_name {
      font-weight: 400;
      color: #8a8a8e;
      margin: 0.3rem 3rem 0.3rem 0;
    }
    .arrow {
      color: #8a8a8e;
      width: 15px;
    }
  }
`;

const Total = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    @media screen and (max-width: 575px) {
      margin-left: 0;
      margin-right: 0;
    }
    &.counter {
      @media screen and (max-width: 575px) {
        margin-left: auto;
      }
    }
  }
  .text {
    display: flex;
    flex-direction: column;
  }
  b {
    font-size: 16px;
    font-weight: 500;
    span {
      font-size: 14px;
    }
  }
  .custom_btn {
    width: 120px;
    height: 40px;
    border: 2px solid #00855b;
    border-radius: 5px;
    color: #00855b;
    background-color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    .custom_btn {
      width: 100px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 575px) {
    .custom_btn {
      width: 90px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 575px) {
    // flex-direction: column;
    // margin-left: initial;
    // margin-right: 0;
    // width: 100%;
    // align-items: flex-start;
  }
`;

const CardItemDetails = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  h5 {
    margin-bottom: 0;
  }
`;

const Figure = styled.figure`
  max-width: 120px;
  width: 100%;
  margin-bottom: 0;
  flex-shrink: 0;
  img {
    height: 80px;
    max-width: 100%;
    object-fit: contain;
    @media screen and (max-width: 575px) {
      height: 60px;
    }
  }
  @media screen and (max-width: 575px) {
    max-width: 80px;
    height: 60px;
  }
`;

const Limit = styled.div`
  width: 50%;
  .cart_item_price {
    margin-top: 4px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid #acacac;
    :last-child {
      border-bottom: none;
    }
  }
`;

const OutOfStock = styled.div`
  background: rgba(200, 53, 50, 0.1);
  display: flex;
  border-radius: 5px;
  padding: 0.5rem;
  z-index: 2;
  span {
    font-size: 0.8rem;
    font-weight: 600;
    color: #c83532;
    display: flex;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 0 16px 16px;
`;
const CardOutOfStockItemDetails = styled.div`
  opacity: 0.5;
  display: flex;
  width: 100%;
  .img_out_of_stock {
    z-index: 5;
  }
  h5 {
    margin-top: 2px;
    margin-bottom: 0;
  }
`;

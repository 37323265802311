/**
 *
 * ItemPage
 *
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useItemSlice } from './slice';
import { ItemDetails } from './ItemDetails';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export function SkipLoginItemPage(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.SKIP_LOGIN_ITEM_DETAILS);
  });
  const { match } = props;
  const dispatch = useDispatch();
  const { actions } = useItemSlice();

  useEffect(() => {
    dispatch(actions.getItem(match.params.id));
  }, []);

  return <ItemDetails />;
}

/**
 * Skip Login Category Page
 */
import React from 'react';
import { Image } from 'react-bootstrap';
import Back from 'assets/back-white.svg';

import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router-dom';
import { CardList } from './components/CardList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSkipLoginCategoryPageSlice } from './slice';
import { CartPreview } from './components/CartPreview';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import { selectItems } from './slice/selectors';

interface Props extends RouteComponentProps {}
export function SkipLoginCategoryPage(props: Props) {
  const dispatch = useDispatch();
  const params: any = useParams();
  const { actions } = useSkipLoginCategoryPageSlice();
  const { id } = params;
  const items: any = useSelector(selectItems);

  React.useEffect(() => {
    Mixpanel.track(pageConstants.SKIP_LOGIN_CATEGORY_PAGE);
  });

  React.useEffect(() => {
    dispatch(actions.setCurrentCategoryId(id));
    return () => {
      dispatch(actions.setCurrentCategoryId(null));
    };
  }, []);

  React.useEffect(() => {
    dispatch(actions.getSubCategories(id));
  }, []);

  const scrollToTop = () => {
    const div = document.getElementById('listTop');
    if (!!div) {
      div.scrollIntoView();
    }
  };

  return (
    <CategoryPageStyled className="category-page">
      <HomePage className="home-page">
        <div id="listTop" />
        {/* <Header /> */}
        <CardList />
        <CartPreview />

        {items.length !== 0 ? (
          <button className="top" onClick={scrollToTop}>
            <Image className="back" src={Back} alt="back" />
          </button>
        ) : (
          <></>
        )}
      </HomePage>
    </CategoryPageStyled>
  );
}

const CategoryPageStyled = styled.div`
  .top {
    position: fixed;
    bottom: 70px;
    right: 12px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 75px;
    border: none;
    border-radius: 50%;
    font-size: 17px;
    color: white;
    background: #00855b;
    .back {
      height: 18px;
      transform: rotate(90deg);
    }
  }
`;

const HomePage = styled.div`
  flex-direction: column;
  .product-list {
    .card {
      padding: 0;
      height: 100%;
      .counter {
        margin-left: auto;
      }
    }
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * TermsConditions
 *
 */
import React, { memo } from 'react';
import TermsConditionsStyled from './index.styled';
import { useDispatch, useSelector } from 'react-redux';
import { useRewardsInfoSlice } from 'app/pages/HelpFAQPage/slice';
import { selectRewardsInfo } from 'app/pages/HelpFAQPage/slice/selectors';

interface Props {}

export const TermsConditions = memo((props: Props) => {
  const { actions } = useRewardsInfoSlice();
  const dispatch = useDispatch();
  const rewardsInfo = useSelector(selectRewardsInfo);

  React.useEffect(() => {
    dispatch(actions.getRewardsInfo());
  }, []);

  const renderRewardsTable = () => {
    if (
      !rewardsInfo?.data?.rewards ||
      rewardsInfo?.data?.enable_reward === false
    )
      return <></>;

    const renderTableRow = item => (
      <tr>
        <td>{item.name}</td>
        <td>{item.min_cart_value}</td>
        {/* <td>{item.max_cart_value}</td> */}
        {item.max_reward_value && item.discount_type === 'percentage' ? (
          <td>{item.discount_value}% of coins on order value</td>
        ) : (
          <td>{item.discount_value} MM Coins</td>
        )}

        <td>{item.order_type}</td>
      </tr>
    );

    return (
      <table>
        <tr>
          <th>Rewards</th>
          <th>Min Cart Value</th>
          {/* <th>Max Cart Value</th> */}
          <th>Coin Earned on order</th>
          <th>Platform</th>
        </tr>
        {rewardsInfo?.data?.rewards.map(renderTableRow)}
      </table>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderRewardRedemptionTable = () => {
    if (!rewardsInfo?.data?.redemption_ranges) return <></>;

    const renderTableRow = item => (
      <tr>
        {/* <td>{item.min_order_count}</td> */}
        <td>{item.from_cart_value}</td>
        <td>{item.to_cart_value}</td>
        <td>{item.max_redemption_value}</td>
        <td>{item.order_type}</td>
      </tr>
    );

    return (
      <table
        style={{
          marginBottom: '-.1rem',
        }}
      >
        <tr>
          {/* <th>Order Count</th> */}
          <th>Min order value</th>
          <th>Max cart value</th>
          <th>Redeem points</th>
          <th>Platform</th>
        </tr>
        {rewardsInfo?.data?.redemption_ranges.map(renderTableRow)}
      </table>
    );
  };
  return (
    <TermsConditionsStyled className="terms-condition">
      <h5>Terms & Conditions</h5>
      <div className="intro">
        <p>
          By subscribing to or using the services of Madrasmandi you agree that
          you have read, understood and are bound by the Terms, regardless of
          how you subscribe to or use the services. If you do not want to be
          bound by the Terms, you must not subscribe to or use our services.
          Madrasmandi and its authorized agents reserves the right to add,
          delete, alter or modify these terms and conditions at any time.
          Therefore the Users are advised to read these terms and conditions
          very carefully as your use of service is subject to your acceptance of
          and compliance with these terms and conditions.These terms and various
          other policies are binding as per the provisions of the Information
          Technology (Intermediaries guidelines) Rules, 2011 formulated under
          the Information Technology Act of 2000 and modified or amended from
          time to time.
        </p>
        <p>
          These terms and conditions supersede all previous representations,
          understandings, or agreements and shall prevail notwithstanding any
          variance with any other terms of any order submitted. By using
          Madrasmandi You agree to be bound by the Terms and Conditions. Not
          having read these terms and conditions is no reason to be no bound by
          these terms and conditions.
        </p>
      </div>
      <div className="introduction">
        <h6>Introduction</h6>
        <ol>
          <li>
            <b>“MADRASMANDI”:</b> is an Internet based content and e-commerce
            portal having its Registered Office at Chennai, Tamilnadu-India.
          </li>
          <li>
            <b>“You” and “User”:</b> shall mean to refer the end user accessing
            the Website,its content and using the Services offered through the
            Website.
          </li>
          <li>
            <b>“Service Providers”:</b> means an independent third party service
            providers.
          </li>
        </ol>
      </div>
      <div className="place-order">
        <h6>Placing an Order</h6>
        <p>
          The user assures that while placing any order he/she does not place an
          order without any intention to buy /use or make any payment to us.If
          we find or have sufficient reason to believe that any user has
          intentionally placed an order solely with an intention to play
          mischief or mislead then Madrasmandi has the sole right to recover all
          incidental and ancillary costs along with any other damages legally
          entitled to be recovered from the user.
        </p>
        <p>
          You agree to receive text messages and phone calls (from us or our
          third-party providers) to register for our services and using our
          services.
        </p>
      </div>
      <section id="wallets">
        <h5 style={{ marginTop: '1rem' }}>Rewards</h5>
        <ol>
          <li>
            All the previously accumulated rewards have expired, and rewards
            will not be valid on the app from 1st Jan 2024.
          </li>

          <li
            style={{
              marginBottom: '-0.3rem',
            }}
          >
            The new rewards program applies exclusively to in-store purchases.
            Explore our store to discover more about the rewards.
          </li>
          <span
            style={{
              fontSize: '12px',
              fontWeight: '500 ',
              marginLeft: '1rem',
              fontStyle: 'italic',
            }}
          >
            *We reserve the right to terminate the rewards program at any given
            time.
          </span>
        </ol>
      </section>
    </TermsConditionsStyled>
  );
});

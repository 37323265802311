import { PayloadAction } from '@reduxjs/toolkit';
import { useInjectSaga } from 'redux-injectors';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { MaintenanceState } from './types';
import { maintenanceSaga } from './saga';

export const initialState: MaintenanceState = {
  disableOrder: false,
  retrying: false,
  disableStoreOrder: false,
};

const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setDisableOrder(state, action: PayloadAction<any>) {
      state.disableOrder = action.payload;
    },
    setDisableStoreOrder(state, action: PayloadAction<any>) {
      state.disableStoreOrder = action.payload;
    },
    retry(state) {
      state.retrying = true;
    },
    setRetry(state, action: PayloadAction<any>) {
      state.retrying = action.payload;
    },
  },
});

export const { actions: maintenanceActions } = slice;

export const useMaintenanceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: maintenanceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useMaintenanceSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * VisitOurExclusiveSection
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import VisitOurExclusiveStyled, {
  Tabs,
  StoreCard,
  Tab,
  NoStore,
} from './index.styled';
import Slider from 'react-slick';

interface Props {}

let availableStores = [
  {
    area: 'Palavakkam',
    city: 'Chennai',
    address: `Ground Floor,No.2/183-A,Periyar Salai, (Next building to Medplus Pharmacy), Palavakkam, Chennai-600041`,
    mobileNo: '+91 9638527410',
    lat: 12.96436,
    lng: 80.25409,
  },
  {
    area: 'Choolaimedu',
    city: 'Chennai',
    address: `Ground Floor, No.63 (77), Choolaimedu High Road, Choolaimedu, Chennai-600094 (Next building to Apollo Pharmacy)`,
    mobileNo: '+91 9638527410',
    lat: 13.05946,
    lng: 80.23047,
  },
  {
    area: 'Anna Nagar East',
    city: 'Chennai',
    address: `Ground Floor, No.30, L Block, 1st avenue,Anna Nagar East, Chennai-600102 (Opposite Ganga Sweets)`,
    mobileNo: '+91 9638527410',
    lat: 13.09274,
    lng: 80.22479,
  },
  {
    area: 'Perungalathur',
    city: 'Chennai',
    address: `Ground Floor,No.7, Kamaraj High Road, New Perungalathur, Chennai-600063 (Near Railway Gate)`,
    mobileNo: '+91 9638527410',
    lat: 12.90927,
    lng: 80.09579,
  },
];

export const VisitOurExclusiveSection = memo((props: Props) => {
  const [tabs] = useState(['All', 'Chennai', 'Bangalore']);
  const [activeTab, setActiveTab] = useState('All');
  const [stores, setStores] = useState(availableStores);

  const [settings] = useState({
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  useEffect(() => {
    if (activeTab === 'All') {
      setStores(availableStores);
    } else {
      setStores(availableStores.filter(store => store.city === activeTab));
    }
  }, [activeTab]);

  const renderTabs = () => {
    return tabs.map(tab => (
      <Tab
        key={tab}
        role="presentation"
        className={`tab ${activeTab === tab ? 'active' : ''}`}
        onClick={() => {
          setActiveTab(tab);
        }}
      >
        <span>{tab}</span>
      </Tab>
    ));
  };

  const renderStores = () => {
    return stores.map(store => (
      <div key={`${store.area} - ${store.city}`}>
        <StoreCard className="store-card">
          <h5>{`${store.area} - ${store.city}`}</h5>
          <address>{store.address}</address>
          <a href={`mailto:${store.mobileNo}`}>{store.mobileNo}</a>
          <Button
            variant="success"
            className="success btn-sm w-100"
            onClick={() => {
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${store.lat},${store.lng}`,
              );
            }}
          >
            View on map
          </Button>
        </StoreCard>
      </div>
    ));
  };
  const renderStoresSlider = () => {
    if (stores.length > 0) {
      return <Slider {...settings}>{renderStores()}</Slider>;
    }
    return (
      <NoStore className="no-store">
        <h3>No Stores Available Yet</h3>
      </NoStore>
    );
  };
  return (
    <VisitOurExclusiveStyled className="visit-our-exclusive">
      <Container>
        <h2>
          Visit our exclusive <br />
          Madras Mandi Store near you
        </h2>
        <Tabs className="tabs">{renderTabs()}</Tabs>
        {renderStoresSlider()}
      </Container>
    </VisitOurExclusiveStyled>
  );
});

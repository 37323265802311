/**
 *
 * GoogleMap
 *
 */
import React from 'react';
import { GoogleMap as ReactGoogleMap, Marker } from '@react-google-maps/api';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';

/* Create map with withGoogleMap HOC */
/* https://github.com/tomchentw/react-google-maps */

export const GoogleMap = props => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.GOOGLE_MAP);
  });
  const {
    position,
    handleMarkerDragEnd,
    onZoomChanged,
    shouldRecenterMap,
    zoom,
    onLoad,
    mapContainerStyle,
    mapOptions,
    children,
    ...rest
  } = props;

  const mapExtraProps = shouldRecenterMap ? { center: position } : {};
  return (
    <ReactGoogleMap
      options={mapOptions}
      mapContainerStyle={mapContainerStyle}
      onZoomChanged={onZoomChanged}
      zoom={zoom}
      center={position}
      onLoad={onLoad}
      {...mapExtraProps}
      {...rest}
    >
      <Marker draggable position={position} onDragEnd={handleMarkerDragEnd} />
      {children}
    </ReactGoogleMap>
  );
};

import styled from 'styled-components/macro';

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  flex: 100%;
  > * {
    margin: 12px 6px 0;
  }
  .form-check {
    padding-left: 0;
    label {
      background-color: #f0f0f0;
      border-radius: 5px;
      font-size: 14px;
      color: #000;
      padding: 12px;
      &:before,
      &:after {
        display: none;
      }
    }
    input {
      display: none;
      &:checked {
        & + label {
          background-color: #00855b;
          color: white;
        }
      }
    }
  }
`;

export { RadioGroup };

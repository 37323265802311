/**
 *
 * MapPicker
 *
 */
import React, { memo } from 'react';
import { GoogleMap } from '../GoogleMap';
import { Col, Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components/macro';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);

    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};

interface Props {
  mapContainerStyle: any;
  mapOptions: any;
  onChange: Function;
  defaultPosition: any;
  zoom?: number;
  radius?: number;
  register: any;
  position: any;
  address: any;
  onReset?: Function;
}

export const MapPicker = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.MAP_PICKER);
  });
  const {
    zoom = 18,
    radius = 10,
    mapContainerStyle,
    mapOptions,
    onChange,
    register,
    position,
    address,
  } = props;

  const handleMarkerDragEnd = async mouseEvent => {
    const lat = mouseEvent.latLng.lat();
    const lng = mouseEvent.latLng.lng();
    const position = { lat, lng };
    let response: any = await geocodePosition(position);
    onChange({
      position,
      address: response.length > 0 ? response[0].formatted_address : '',
    });
  };

  const geocodePosition = position => {
    // @ts-ignore
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: position }, (results, status) => {
        //@ts-ignore
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  };
  const goToCurrent = () => {
    navigator?.geolocation.getCurrentPosition(
      async ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        let response: any = await geocodePosition(pos);
        onChange({
          position: pos,
          address: response.length > 0 ? response[0].formatted_address : '',
        });
      },
      () => {
        // alert('Sorry, browser does not support geolocation!');
      },
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true },
    );
  };
  const onLoad = map => {
    if (!address) {
      goToCurrent();
    }
  };
  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={val => {
          onChange({
            position: position,
            address: val,
          });
        }}
        onSelect={async address => {
          let values = await getCoordinates(address);
          onChange({
            position: {
              lat: values ? parseFloat(values.latitude) : 0,
              lng: values ? parseFloat(values.longitude) : 0,
            },
            address: address,
          });
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const { onChange, onBlur } = register('addressVal', {
            required: true,
          });
          let inputProps = getInputProps({
            placeholder: 'Search Places ...',
            className: 'location-search-input',
          });

          return (
            <TextareaAutocomplete>
              <Form>
                <Form.Group as={Col}>
                  <InputGroup>
                    <Form.Control
                      {...inputProps}
                      as="textarea"
                      rows={2}
                      onChange={e => {
                        inputProps.onChange(e);
                        onChange(e);
                      }}
                      onBlur={e => {
                        inputProps.onBlur(e);
                        onBlur(e);
                      }}
                      aria-autocomplete="none"
                    />
                    {props.onReset && (
                      <InputGroup.Text
                        style={{
                          background: 'none',
                          border: 'none',
                          margin: 'auto 0.5rem',
                        }}
                      >
                        <button
                          onClick={e => {
                            e.preventDefault();
                            if (props.onReset) props.onReset();
                          }}
                          style={{
                            fontSize: '12px',
                            background: 'none',
                            border: 'none',
                            margin: 'auto 0.5rem',
                          }}
                        >
                          Clear
                        </button>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              </Form>

              <AutoCompleteDropdownList className="autocomplete-dropdown-container">
                {loading && (
                  <LoadingWrapper className="loading-wrapper">
                    <li className="loading" />
                    <li className="loading" />
                    <li className="loading" />
                  </LoadingWrapper>
                )}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <AutoCompleteDropdownListItem
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </AutoCompleteDropdownListItem>
                  );
                })}
              </AutoCompleteDropdownList>
            </TextareaAutocomplete>
          );
        }}
      </PlacesAutocomplete>
      <GoogleMap
        onLoad={onLoad}
        goToCurrent={goToCurrent}
        mapOptions={mapOptions}
        mapContainerStyle={mapContainerStyle}
        handleMarkerDragEnd={handleMarkerDragEnd}
        position={position}
        radius={radius}
        defaultZoom={zoom}
        zoom={zoom}
        shouldRecenterMap={false}
      />
    </>
  );
});

const LoadingWrapper = styled.ul`
  padding: 12px;
  height: 200px;
  margin-bottom: 0;
  list-style-type: none;
  li {
    &:not(:first-child) {
      margin-top: 12px;
    }
    &.loading {
      height: 24px;
    }
  }
`;
const AutoCompleteDropdownListItem = styled.li`
  padding: 8px 16px;
  font-size: 14px;
`;

const AutoCompleteDropdownList = styled.ul`
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-radius: 0 0 8px 8px;
  overflow: auto;
  max-height: 200px;
`;

const TextareaAutocomplete = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const storageKeys = {
  LOCATION_INFO: 'location_info',
};

const setLocationInfo = locationInfo => {
  localStorage.setItem(storageKeys.LOCATION_INFO, JSON.stringify(locationInfo));
};

const getLocationInfo = () => {
  let locationInfo = {};
  const locationInfoLS = localStorage.getItem(storageKeys.LOCATION_INFO);
  if (locationInfoLS) {
    locationInfo = JSON.parse(locationInfoLS);
  }
  return locationInfo;
};

const removeLocationInfo = () => {
  localStorage.removeItem(storageKeys.LOCATION_INFO);
};

export const storage = Object.freeze({
  setLocationInfo,
  getLocationInfo,
  removeLocationInfo,
});

const componentConstants = {
  ADDRESS_AUTO_COMPLETE: 'address auto complete',
  COUNTER: 'counter',
  COUNTER_UOM: 'counter uom',
  DELIVERY_STATUS_MODAL: 'delivery status modal',
  DOWNLOAD_APP: 'download app',
  DOWNLOAD_APP_MOBILE: 'download app mobile',
  FAILURE_MODAL: 'failure modal',
  FAVORITE: 'favorite',
  GOOGLE_MAP: 'google map',
  MAP_PICKER: 'map picker',
  NOT_DELIVERABLE: 'not deliverable',
  PRIVATE_ROUTE: 'private route',
  PUBLIC_ROUTE: 'public route',
  RADIO: 'radio',
  REGISTER_ROUTE: 'register route',
  REVIEW: 'review',
  SIDE_SHEET: 'side sheet',
  SUCCESS_MODAL: 'success modal',
  ADDRESS_LIST: 'address list',
  EDIT_ADDRESS: 'edit address',
  APPLY_COUPON: 'apply coupon',
  CART_ITEM: 'cart item',
  CHECKOUT: 'checkout',
  CHECKOUT_CART_ITEM: 'checkout cart item',
  EDIT_NEW_ORDER: 'edit new order',
  PAYMENT_METHOD: 'payment method',
  PAYMENT_METHOD_RETRY: 'payment method retry',
  PAYMENT_OPTIONS: 'payment option',
  REMOVE_AUT_OF_STOCK_ITEM: 'remove out of stock',
  UOM_MODAL: 'uom modal',
  SEARCH_ITEM: 'search item',
  ITEM_DETAILS: 'item details',
  INSTANT_DELIVERY_DISABLED_POPUP:
    '{launchAddressList.data.instant_delivery_title} disabled popup',
};

export default componentConstants;

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * Address
 *
 */
import { addressActions } from 'app/pages/ManageAddress/slice';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { AddAddress } from '../../../ManageAddressList/components/AddAddress';
import {
  selectAddAddress,
  selectAddressList,
  selectDefaultAddress,
} from 'app/pages/ManageAddress/slice/selectors';
import { AddressWrapper } from './index.styled';
import { useHistory } from 'react-router-dom';
import { authActions } from 'app/pages/slice';
import { selectProfile } from 'app/pages/slice/selectors';
import { mainPageActions } from 'app/pages/MainPage/slice';
import { categoryActions } from 'app/pages/CategoryPage/slice';
import { cartActions } from 'app/pages/Cart/slice';
import { selectCartItems } from 'app/pages/Cart/slice/selectors';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { toast } from 'react-toastify';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};

interface Props {
  onProceed: Function;
}

export function Address(props: Props) {
  const { onProceed } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const addAddress = useSelector(selectAddAddress);
  const addressList = useSelector(selectAddressList);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const address = useSelector(selectDefaultAddress);
  const profile = useSelector(selectProfile);
  const cartItems = useSelector(selectCartItems);
  const [disableButton, setDisableButton] = React.useState(false);

  useEffect(() => {
    dispatch(authActions.getProfile());
  }, []);

  useEffect(() => {
    if (addAddress.success) {
      setDisableButton(true);
      setTimeout(() => {
        history.push('/address-list');
        dispatch(addressActions.resetAddAddressSuccess());
        setDisableButton(false);
      }, 800);
      // if (!addressList.data) {
      //   dispatch(addressActions.resetAddAddressSuccess());
      //   return history.push('/address-list');
      // }

      // // If only one address exist
      // if (addressList.data && addressList.data.length === 1) {
      //   // Deliverable address
      //   if (addressList.data[0]?.deliverable) {
      //     // If not beta user
      //     if (
      //       profile?.data?.enable_instant_delivery &&
      //       addressList.data[0]?.show_instant_delivery
      //     ) {
      //       return onProceed();
      //     }
      //     // If beta user
      //     else {
      //       dispatch(
      //         mainPageActions.setEnableInstantDelivery({
      //           show_instant_delivery: false,
      //         }),
      //       );
      //       dispatch(categoryActions.clearActiveTab());
      //       // Loading getCategories
      //       dispatch(mainPageActions.getCategories());
      //       dispatch(cartActions.addItemsToCart(cartItems));

      //       // Redirecting to home page
      //       history.push('/home');
      //     }
      //   }
      //   // Not deliverable address
      //   else {
      //     history.push('/address-list');
      //   }
      // }

      // // If Multiple Address Exist
      // else {
      //   // If Default address is deliverable
      //   if (addressList.data.find(item => item.is_default)?.deliverable) {
      //     // If not beta user
      //     if (
      //       profile?.data?.enable_instant_delivery &&
      //       addressList.data.find(item => item.is_default)
      //         ?.show_instant_delivery
      //     ) {
      //       onProceed();
      //     }
      //     // If beta user
      //     else {
      //       // Set delivery type next day
      //       dispatch(
      //         mainPageActions.setEnableInstantDelivery({
      //           show_instant_delivery: false,
      //         }),
      //       );
      //       dispatch(categoryActions.clearActiveTab());
      //       // Loading getCategories
      //       dispatch(mainPageActions.getCategories());
      //       dispatch(cartActions.addItemsToCart(cartItems));
      //       // Redirecting to home page
      //       history.push('/home');
      //     }
      //   } else {
      //     history.push('/address-list');
      //   }
      // }

      // dispatch(addressActions.resetAddAddressSuccess());
    }
  }, [addAddress]);

  const onSubmit = async val => {
    try {
      const verify = await getCoordinates(val.addressVal.address);
      if (!verify) {
        toast.error(`Please provide full address`);
        return;
      }
      // let custom_address = '#' + val.flat + ', ' + val.locality;
      // if (val.landmark !== '') {
      //   custom_address = custom_address + ', ' + val.landmark;
      // }
      // custom_address = custom_address + ', ' + val.addressVal.address;

      if (
        val.addressVal.address !== '' &&
        val.addressVal.lat !== 0 &&
        val.addressVal.lng !== 0 &&
        val.flat !== '' &&
        val.locality !== ''
      ) {
        dispatch(
          addressActions.addAddress({
            address_name: val.name,
            address: val.addressVal.address,
            door_no: val.flat,
            locality: val.locality,
            landmark: val.landmark,
            latitude: val.addressVal.lat,
            longitude: val.addressVal.lng,
          }),
        );
      } else {
        setError('addressVal', {
          type: 'manual',
          message: 'Invalid Address',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AddressWrapper>
      <Form className="form" onSubmit={handleSubmit(onSubmit)}>
        <AddAddress
          register={register}
          errors={errors}
          setValue={setValue}
          control={control}
        />
        <Button
          variant="warning"
          type="submit"
          disabled={addAddress.loading || disableButton}
        >
          {addAddress.loading || disableButton ? 'Adding' : 'Confirm Location'}
        </Button>
      </Form>
    </AddressWrapper>
  );
}

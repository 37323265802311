import styled from 'styled-components/macro';

export const MetaBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 2;
  .logo {
    display: none;
    img {
      height: 54px;
      @media screen and (max-width: 991px) {
        height: 40px;
      }
      @media screen and (max-width: 767px) {
        flex-shrink: 0;
      }
    }
    @media screen and (max-width: 991px) {
      display: inline-flex;
    }
    @media screen and (max-width: 767px) {
      width: 40px;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
  .avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 1px solid #acacac;
    margin-left: 16px;
  }
`;

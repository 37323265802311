/**
 *
 * DownloadApp
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { Image, Button } from 'react-bootstrap';
import AppStore from 'assets/app-store.png';
import PlayStore from 'assets/play-store.png';
import LogoSvg from 'assets/logo.svg';
import CloseDownload from 'assets/close-download.svg';
import BgDownload from 'assets/bg-download.svg';
import MobileBgDownload from 'assets/mobile-bg-download.svg';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export function DownloadApp(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.DOWNLOAD_APP);
  });
  const { show, setShow } = props;
  if (!show) {
    return <></>;
  }

  return (
    <DownloadAppStyled className="mobile-active">
      <Brand href="/" className="brand">
        <img src={LogoSvg} alt="" />
      </Brand>
      <h6 className="text-center">
        Order farm fresh fruits and vegetables with our exclusive app
      </h6>
      <ButtonGroup className="button-group">
        <DownloadMe
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.madrasmandi.user"
          onClick={() => {
            Mixpanel.track(actionConstants.DOWNLOAD);
            if (window.fbq) {
              window.fbq('trackCustom', 'AppInstallAndroid', {
                type: 'android',
              });
            }
          }}
        >
          <Image src={PlayStore} alt="play store" className="img-fluid" />
        </DownloadMe>
        <DownloadMe
          target="_blank"
          href="https://apps.apple.com/in/app/madras-mandi/id1584806709"
          onClick={() => {
            Mixpanel.track(actionConstants.DOWNLOAD);
            if (window.fbq) {
              window.fbq('trackCustom', 'AppInstallIos', {
                type: 'ios',
              });
            }
          }}
        >
          <Image src={AppStore} alt="app store" className="img-fluid" />
        </DownloadMe>
      </ButtonGroup>
      <Button variant="link" className="close" onClick={() => setShow(false)}>
        <Image src={CloseDownload} alt="close" className="img-fluid" />
      </Button>
    </DownloadAppStyled>
  );
}
const Brand = styled.a`
  img {
    height: 60px;
    @media screen and (max-width: 991px) {
      height: 70px;
    }
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const DownloadMe = styled.a`
  margin-top: 12px;
`;
const DownloadAppStyled = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 220px;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-image: url(${BgDownload});
  background-size: cover;
  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    @media screen and (max-width: 991px) {
      right: 1.5rem;
      top: 1.5rem;
    }
  }
  h6 {
    color: #00855b;
    font-weight: 600;
    margin-top: 12px;
    @media screen and (max-width: 991px) {
      font-size: 1.5rem;
    }
  }
  img {
    max-width: 120px;
    @media screen and (max-width: 991px) {
      max-width: 150px;
    }
  }
  @media screen and (max-width: 991px) {
    display: none;
    top: 0;
    left: 0;
    max-width: initial;
    justify-content: space-evenly;
    background-image: url(${MobileBgDownload});
  }
  &.mobile-active {
    @media screen and (max-width: 991px) {
      display: flex;
    }
  }
`;

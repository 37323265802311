/**
 *
 * PageHeader
 *
 */
import * as React from 'react';
import { Container } from 'react-bootstrap';
import LogoSvg from 'assets/logo-white.svg';
import { Brand, Header } from './index.styled';

interface Props {}

export function TopHeader(props: Props) {
  return (
    <Header className="header">
      <Container className="d-flex align-items-center justify-content-between">
        <Brand href="/" className="brand">
          <img src={LogoSvg} alt="" />
        </Brand>
      </Container>
    </Header>
  );
}

import styled from 'styled-components/macro';

const Figure = styled.figure`
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
`;
const CenterBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 1.5rem;
  }
`;

export { CenterBlock, Figure };

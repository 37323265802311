/**
 *
 * EditAddress
 *
 */
import React, { memo } from 'react';
import { SideSheetFooter } from 'app/components/SideSheet/index.styled';
import { SideSheetBody } from 'app/pages/Cart/index.styled';
import { useForm } from 'react-hook-form';
import Back from 'assets/back-white.svg';
import { Form, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addressActions } from 'app/pages/ManageAddress/slice';
import { AddAddress } from 'app/pages/ManageAddressList/components/AddAddress';
import { selectEditAddress } from 'app/pages/ManageAddress/slice/selectors';
import { toast } from 'react-toastify';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};

interface Props {
  setEditAddress: Function;
  onClose: Function;
  editAddress: any;
}

export const EditAddress = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setEditAddress, onClose, editAddress } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      addressVal: {
        address: editAddress.address,
        lat: editAddress.latitude,
        lng: editAddress.longitude,
      },
      name: editAddress.address_name,
    },
  });
  const dispatch = useDispatch();
  const editAddressRequest = useSelector(selectEditAddress);

  const onSubmit = async val => {
    try {
      const verify = await getCoordinates(val.addressVal.address);
      if (!verify) {
        toast.error(`Please provide full address`);
        return;
      }
      // let custom_address = val.flat + ', ' + val.locality;
      // if (val.landmark !== '') {
      //   custom_address = custom_address + ', Landmark: ' + val.landmark;
      // }
      if (
        val.addressVal.address !== '' &&
        val.addressVal.lat !== 0 &&
        val.addressVal.lng !== 0 &&
        val.flat !== '' &&
        val.locality !== ''
      ) {
        dispatch(
          addressActions.editAddress({
            id: editAddress.address_id,
            object: {
              ...editAddress,
              address_name: val.name,
              address: val.addressVal.address,
              door_no: val.flat,
              locality: val.locality,
              landmark: val.landmark,
              latitude: val.addressVal.lat,
              longitude: val.addressVal.lng,
            },
          }),
        );
      } else {
        setError('addressVal', {
          type: 'manual',
          message: 'Invalid Address',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <button
          style={{
            margin: '0.5rem 1rem',
            width: 'max-content',
            padding: '0.5rem',
          }}
          className="button-icon"
          onClick={() => setEditAddress(false)}
        >
          <Image
            style={{
              filter: 'brightness(0) invert(0)',
            }}
            src={Back}
            alt="back"
          />
        </button>
        <h5
          style={{
            width: '90%',
            margin: 'auto',
          }}
        >
          Edit Address
        </h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SideSheetBody className="sidesheet-body">
          <AddAddress
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
          />
        </SideSheetBody>
        <SideSheetFooter className="sidesheet-footer">
          <Button
            variant="warning"
            type="submit"
            disabled={editAddressRequest.loading}
          >
            {editAddressRequest.loading ? 'Updating' : 'Confirm Location'}
          </Button>
        </SideSheetFooter>
      </Form>
    </>
  );
});

import styled from 'styled-components/macro';

const CartPreviewStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #00855b;
  border-radius: 5px;
  position: sticky;
  bottom: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  max-width: 1040px;
  > * {
    margin-left: 12px;
    margin-right: 12px;
    color: white;
  }
  .cart_btn {
    :hover {
      color: #fcb415;
    }
    img {
      margin-left: 8px;
    }
  }
  @media screen and (max-width: 1399px) {
    max-width: 860px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 658px;
  }
  @media screen and (max-width: 991px) {
    max-width: 696px;
  }
  @media screen and (max-width: 767px) {
    max-width: 516px;
  }
  @media screen and (max-width: 575px) {
    max-width: initial;
    width: initial;
    left: 12px;
    right: 12px;
    padding-left: 0;
    padding-right: 0;
    bottom: 16px;
  }
`;
const ItemPrice = styled.div`
  display: flex;
  span {
    font-weight: 600;
    position: relative;
    &:not(:first-child) {
      &:before {
        content: '|';
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
`;

export { CartPreviewStyled, ItemPrice };

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { registerPageSaga } from './saga';
import { RegisterPageState } from './types';

export const initialState: RegisterPageState = {
  saveProfile: {
    loading: false,
    success: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'registerPage',
  initialState,
  reducers: {
    saveProfile(state, action: PayloadAction<any>) {
      state.saveProfile.loading = true;
      state.saveProfile.error = null;
      state.saveProfile.success = false;
    },
    saveProfileSuccess(state, action: PayloadAction<any>) {
      state.saveProfile.loading = false;
      state.saveProfile.success = action.payload;
    },
    saveProfileError(state, action: PayloadAction<any>) {
      state.saveProfile.loading = false;
      state.saveProfile.error = action.payload;
      state.saveProfile.success = false;
    },
  },
});

export const { actions: registerPageActions } = slice;

export const useRegisterPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: registerPageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRegisterPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.homePage || initialState;

export const selectHomePage = createSelector([selectSlice], state => state);
export const selectShowSideBar = createSelector(
  [selectSlice],
  state => state.showSideBar,
);

export const selectShowCart = createSelector(
  [selectSlice],
  state => state.showCart,
);

export const selectShowSearch = createSelector(
  [selectSlice],
  state => state.showSearch,
);

export const selectShowAddress = createSelector(
  [selectSlice],
  state => state.showAddress,
);

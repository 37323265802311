/**
 *
 * Onboarding Page
 *
 */

import { useState } from 'react';
import { Address } from './components/Address';
import { DeliveryType } from './components/DeliveryType';
import { Register } from './components/Register';
import { SelectAccountType } from './components/SelectAccountType';
import { stepTypeConstant } from './helper/stepTypeConstant';
import { Container } from './styled';
import LogoSvg from 'assets/logo.svg';
import { useHistory } from 'react-router-dom';
import { Stepper } from './components/Stepper';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import React from 'react';

const defaultState = [
  // {
  //   id: 1,
  //   stepName: stepTypeConstant.USER_TYPE,
  //   isDone: false,
  // },
  {
    id: 1,
    stepName: stepTypeConstant.PROFILE,
    isDone: false,
  },
  {
    id: 2,
    stepName: stepTypeConstant.ADDRESS,
    isDone: false,
  },
  {
    id: 3,
    stepName: stepTypeConstant.DELIVERY_TYPE,
    isDone: false,
  },
];

interface StepData {
  id: number;
  stepName: string;
  isDone: boolean;
}

export function Onboarding() {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.ONBOARDING_SCREEN);
  });
  const [type, setType] = useState('individual');
  const [step, setStep] = useState<StepData[]>(defaultState);
  const [currentStep, setCurrentStep] = useState<String>(
    stepTypeConstant.PROFILE,
  );
  const history = useHistory();

  const markAsDone = stepId => {
    const temp: StepData[] = [...step];
    const findStepAndModify: StepData[] = temp.map(item => {
      if (item.id === stepId) {
        item.isDone = true;
      }
      return item;
    });
    let currentStepIndex = temp.findIndex(item => item.id === stepId);
    let currentStepName = temp[currentStepIndex + 1]?.stepName;

    // Set State
    if (!!currentStepName) {
      setCurrentStep(currentStepName);
    }
    setStep(findStepAndModify);
  };

  const getStepInfo = stepName => step.find(item => item.stepName === stepName);

  const renderStep = () => {
    switch (currentStep) {
      case stepTypeConstant.USER_TYPE:
        return (
          <SelectAccountType
            onProceed={() => {
              markAsDone(getStepInfo(stepTypeConstant.USER_TYPE)?.id);
            }}
            setType={setType}
            type={type}
          />
        );

      case stepTypeConstant.PROFILE:
        return (
          <Register
            type={type}
            onProceed={() => {
              markAsDone(getStepInfo(stepTypeConstant.PROFILE)?.id);
            }}
          />
        );

      case stepTypeConstant.ADDRESS:
        return (
          <Address
            onProceed={() => {
              history.push('/address-list');
              // markAsDone(getStepInfo(stepTypeConstant.ADDRESS)?.id);
            }}
          />
        );

      case stepTypeConstant.DELIVERY_TYPE:
        return <DeliveryType />;

      default:
        return (
          <SelectAccountType
            onProceed={() => {
              markAsDone(getStepInfo(stepTypeConstant.USER_TYPE)?.id);
            }}
            setType={setType}
            type={type}
          />
        );
    }
  };

  return (
    <Container>
      <div className="authentication-page login-page">
        <div className="box">
          <figure className="logo">
            <img src={LogoSvg} alt="" />
          </figure>
          <Stepper stepDetails={step} currentStep={currentStep} />
          {renderStep()}
        </div>
      </div>
    </Container>
  );
}

/**
 *
 * TestimonialSection
 *
 */
import React, { memo } from 'react';
import { Container, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import { ReactImage } from 'app/components/ReactImage';

import ProductImage from 'assets/products.svg';
import filledStar from 'assets/order-successfull.svg';
import TestimonialBg from 'assets/dragon-fruit.png';
import { Rating, CustomerInfo, Indicator, AvatarInfo } from './index.styled';
import { SliderSection } from '../../index.styled';

interface Props {}

export const TestimonialSection = memo((props: Props) => {
  const settings = {
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SliderSection className="slider-testimonial bg-grey">
      <Container>
        <Image
          src={TestimonialBg}
          alt=""
          className="section-bg testimonial-bg"
        />
        <h3 className="text-center">Testimonials</h3>
        <Slider {...settings}>
          <>
            <Rating className="rating">
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
            </Rating>
            <p>
              Madras Mandi is an effective and reliable place to get fresh local
              and exotic veggies from. I always order from here, even if I have
              to order just for myself.
            </p>
            <CustomerInfo className="customer-info">
              <ReactImage
                src={`${process.env.REACT_APP_API_URI}/${ProductImage}`}
                alt="avatar"
              />
              <AvatarInfo className="avatar-info">
                <h5>Ashwin Dobri</h5>
              </AvatarInfo>
            </CustomerInfo>
            <Indicator className="indicator" />
          </>
          <>
            <Rating className="rating">
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
            </Rating>
            <p>
              I have never seen such a wide range of fruits and vegetables on
              any other platform. And the best part? Delivery within 24 hours. I
              love it!
            </p>
            <CustomerInfo className="customer-info">
              <ReactImage
                src={`${process.env.REACT_APP_API_URI}/${ProductImage}`}
                alt="avatar"
              />
              <AvatarInfo className="avatar-info">
                <h5>Simran Nagma</h5>
              </AvatarInfo>
            </CustomerInfo>
            <Indicator className="indicator" />
          </>
          <>
            <Rating className="rating">
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
              <Image src={filledStar} alt="star" />
            </Rating>
            <p>
              A wonderful way to get fresh vegetables. Love the user-friendly
              interface of the app and timely delivery. The way you treat food
              items is very impressive. Kudos team !!
            </p>
            <CustomerInfo className="customer-info">
              <ReactImage
                src={`${process.env.REACT_APP_API_URI}/${ProductImage}`}
                alt="avatar"
              />
              <AvatarInfo className="avatar-info">
                <h5>Raghu S Ramki</h5>
              </AvatarInfo>
            </CustomerInfo>
            <Indicator className="indicator" />
          </>
        </Slider>
      </Container>
    </SliderSection>
  );
});

import styled from 'styled-components/macro';

const PrivacyPolicyStyled = styled.div`
  font-size: 14px;
  margin-top: 1.5rem;
  p {
    margin-top: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  b {
    font-weight: 600;
  }
  h5 {
    margin-bottom: 0;
  }
  h6 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 2rem;
  }
  .card {
    > div {
      margin-top: 2rem;
    }
  }
`;

export default PrivacyPolicyStyled;

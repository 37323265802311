import ReactGA from 'react-ga';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { homePageActions } from 'app/pages/HomePage/slice';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { cartActions as actions } from '.';
import { localStorageActions } from '../helpers/storage';
import {
  selectCartItems,
  selectSubTotalForDiscount,
  selectTimeslotDeliveryType,
} from './selectors';
import useAnalyticsEventTracker from 'utils/hooks/useAnalyticsEventTracker';
import { AnalyticsConstants } from 'utils/constants';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

function* getCart() {
  try {
    const requestURL = `/api/v3/user/cart`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getCartSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getCartError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCartError(err.response));
    } else {
      yield put(actions.getCartError(err));
    }
  }
}

function* getCartWithoutLoading() {
  try {
    const requestURL = `/api/v3/user/cart`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getCartWithoutLoadingSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getCartWithoutLoadingError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCartWithoutLoadingError(err.response));
    } else {
      yield put(actions.getCartWithoutLoadingError(err));
    }
  }
}

function* removeItem(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v3/user/cart/items/remove`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [
          {
            item_code: action.payload.item_code,
            uom: action.payload.uom,
          },
        ],
      }),
    });
    yield put(actions.setCart(response));
  } catch (err: any) {
    console.log(err);
  }
}

function* addItemsToCart(action: PayloadAction<any>) {
  try {
    yield call(request, `/api/v1/user/cart/clear`, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (Array.isArray(action.payload) && action.payload.length > 0) {
      let requestURL = `/api/v3/user/cart/items/add`;
      const addPayload = {
        items: action.payload.map(e => ({
          item_code: e.item_code,
          uom: e.uom.map(uomItem => {
            return {
              id: uomItem.variant_id,
              qty: uomItem.qty,
            };
          }),
        })),
      };
      yield call(request, requestURL, {
        method: 'POST',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(addPayload),
      });

      // Setting the cartItems to the local storage.
      localStorageActions.setCartItem(action.payload);

      // if (window.fbq) {
      //   window.fbq('track', 'AddToCart', action.payload);
      // }
    }
    yield put(actions.getCart());
  } catch (err: any) {
    console.log(err);
  }
}
function* addItemsToCartRealTime() {
  try {
    const cartItems = yield select(selectCartItems);
    yield call(request, `/api/v1/user/cart/clear`, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      let requestURL = `/api/v3/user/cart/items/add`;
      const addPayload = {
        items: cartItems.map(e => ({
          item_code: e.item_code,
          uom: e.uom.map(uomItem => {
            return {
              id: uomItem.variant_id,
              qty: uomItem.qty,
            };
          }),
        })),
      };
      yield call(request, requestURL, {
        method: 'POST',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(addPayload),
      });

      // Setting the cartItems to the local storage.
      localStorageActions.setCartItem(cartItems);

      if (window.fbq) {
        window.fbq('track', 'AddToCart', cartItems);
      }
      Mixpanel.track(actionConstants.ADDING_ITRM_CART);

      cartItems.forEach(item => {
        ReactGA.event({
          category: AnalyticsConstants.ADD_TO_CART,
          action: 'AddToCart',
          label: `${item.item_code} - ${item.uom.length} units`,
        });
      });
    }
    yield put(actions.getCartWithoutLoading());
  } catch (err: any) {
    console.log(err);
  }
}

function* repeatOrder(action: PayloadAction<any>) {
  try {
    yield put(homePageActions.setShowCart(true));
  } catch (err: any) {
    console.log(err);
  }
}

function* getCheckOut() {
  try {
    const cartItems = yield select(selectCartItems);

    yield call(request, `/api/v1/user/cart/clear`, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      let requestURL = `/api/v3/user/cart/items/add`;
      const addPayload = {
        items: cartItems.map(e => ({
          item_code: e.item_code,
          uom: e.uom.map(uomItem => {
            return {
              id: uomItem.variant_id,
              qty: uomItem.qty,
            };
          }),
        })),
      };
      yield call(request, requestURL, {
        method: 'POST',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(addPayload),
      });
      if (window.fbq) {
        window.fbq('track', 'AddToCart', cartItems);
      }
      Mixpanel.track(actionConstants.ADDING_ITRM_CART);

      cartItems.forEach(item => {
        ReactGA.event({
          category: AnalyticsConstants.ADD_TO_CART,
          action: 'AddToCart',
          label: `${item.item_code} - ${item.uom.length} units`,
        });
      });
    }

    const requestURL = `/api/v3/user/checkout`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    if (window.fbq) {
      window.fbq('track', 'checkout');
    }
    Mixpanel.track(actionConstants.PROCEED_CHECKOUT);

    yield put(actions.getCheckOutSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getCheckOutError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCheckOutError(err.response));
    } else {
      yield put(actions.getCheckOutError(err));
    }
  }
}

function* getCoupons(action: PayloadAction<any>) {
  try {
    const subTotalv1 = yield select(selectSubTotalForDiscount);
    const subTotal = action.payload;
    const timeslotDeliveryType = yield select(selectTimeslotDeliveryType);
    let requestURL = `/api/v1/user/coupons?sub_total=${subTotal}`;
    if (timeslotDeliveryType.instantDelivery) {
      requestURL += `&timeslot_id=999`;
    }
    if (
      timeslotDeliveryType.timeSlot &&
      !timeslotDeliveryType.instantDelivery
    ) {
      requestURL += `&timeslot_id=${timeslotDeliveryType.timeSlot}`;
    }
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getCouponsSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getCouponsError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCouponsError(err.response));
    } else {
      yield put(actions.getCouponsError(err));
    }
  }
}

function* validateCoupon(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/user/coupons/validate`;
    const timeslotDeliveryType = yield select(selectTimeslotDeliveryType);
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...action.payload.data,
        timeslot_id: timeslotDeliveryType.timeSlot,
      }),
    });
    yield put(actions.validateCouponSuccess(response.data));
  } catch (err: any) {
    let response = yield err.response.json();
    yield put(actions.validateCouponError(response.message));
  }
}

function* resetPaymentMethod(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/user/order/update/payment-method`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_id: action.payload,
        payment_method: 'cash',
      }),
    });
    yield put(actions.resetPaymentMethodSuccess(response.data));
  } catch (err: any) {
    let response = yield err.response.json();
    yield put(actions.resetPaymentMethodError(response.message));
  }
}
export function* cartSaga() {
  yield takeLatest(actions.getCart.type, getCart);
  yield takeLatest(actions.getCartWithoutLoading.type, getCartWithoutLoading);
  yield takeEvery(actions.addItemsToCart.type, addItemsToCart);
  yield takeEvery(actions.updateCartItems.type, addItemsToCartRealTime);
  yield takeLatest(actions.repeatOrder.type, repeatOrder);
  yield takeLatest(actions.removeItem.type, removeItem);
  yield takeLatest(actions.getCheckOut.type, getCheckOut);
  yield takeLatest(actions.getCoupons.type, getCoupons);
  yield takeLatest(actions.validateCoupon.type, validateCoupon);
  yield takeLatest(actions.resetPaymentMethod.type, resetPaymentMethod);
}

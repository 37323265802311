/**
 *
 * Help
 *
 */

interface Props {}

export function Help(props: Props) {
  return (
    <svg width="17" height="18" viewBox="0 0 15 15" fill="none">
      <path
        d="M6.79175 11.495H8.20842V10.0783H6.79175V11.495ZM7.50008 0.161621C3.59008 0.161621 0.416748 3.33495 0.416748 7.24496C0.416748 11.155 3.59008 14.3283 7.50008 14.3283C11.4101 14.3283 14.5834 11.155 14.5834 7.24496C14.5834 3.33495 11.4101 0.161621 7.50008 0.161621ZM7.50008 12.9116C4.37633 12.9116 1.83341 10.3687 1.83341 7.24496C1.83341 4.1212 4.37633 1.57829 7.50008 1.57829C10.6238 1.57829 13.1667 4.1212 13.1667 7.24496C13.1667 10.3687 10.6238 12.9116 7.50008 12.9116ZM7.50008 2.99495C5.93467 2.99495 4.66675 4.26287 4.66675 5.82829H6.08342C6.08342 5.04912 6.72092 4.41162 7.50008 4.41162C8.27925 4.41162 8.91675 5.04912 8.91675 5.82829C8.91675 7.24496 6.79175 7.06787 6.79175 9.36996H8.20842C8.20842 7.77621 10.3334 7.59912 10.3334 5.82829C10.3334 4.26287 9.0655 2.99495 7.50008 2.99495Z"
        className="fill-grey"
      />
    </svg>
  );
}

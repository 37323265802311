const getDeliveryType = (): string => {
  return localStorage.getItem('Delivery-Type') || 'app';
};

const setStoreId = (payload): void => {
  const key = `${getDeliveryType()}_storeId`; // <- eg., app_cartItem, instant_cartItem
  localStorage.setItem(key, JSON.stringify(payload));
};

const getStoreId = (parsed = false) => {
  const key = `${getDeliveryType()}_storeId`;
  const storeId = localStorage.getItem(key);
  if (parsed) return JSON.parse(storeId || '');
  return storeId;
};

export const localStorageActions = Object.freeze({
  getDeliveryType,
  setStoreId,
  getStoreId,
});

/**
 *
 * AddressAutoComplete
 *
 */
import React from 'react';
import { MapPicker } from 'app/pages/CheckDeliveryLocation/components/MapPicker';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Controller } from 'react-hook-form';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};
interface Props {
  register: Function;
  control: any;
  setValue: any;
}

export function AddressAutoComplete(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.ADDRESS_AUTO_COMPLETE);
  });
  const { register, control, setValue } = props;

  return (
    <Controller
      name="addressVal"
      control={control}
      defaultValue={''}
      rules={{ required: true }}
      render={({ field }) => {
        return (
          <MapPicker
            position={{
              lat: field.value ? field.value.lat : 0,
              lng: field.value ? field.value.lng : 0,
            }}
            address={field.value ? field.value.address : ''}
            register={register}
            mapContainerStyle={{ height: '400px', width: '100%' }}
            mapOptions={{
              fullscreenControl: false,
              streetViewControl: false,
              zoom: 18,
              mapTypeId: 'roadmap',
              gestureHandling: 'greedy',
            }}
            defaultPosition={{
              lat: 27.9878,
              lng: 86.925,
            }}
            onChange={({ position, address, flat, locality }: any) => {
              setValue(
                'addressVal',
                {
                  lat: position.lat,
                  lng: position.lng,
                  address: address,
                  flat: flat,
                  locality: locality,
                },
                { shouldValidate: true },
              );
            }}
            onReset={() => {
              setValue('addressVal', {
                lat: 0,
                lng: 0,
                address: '',
                flat: '',
                locality: '',
              });
            }}
          />
        );
      }}
    ></Controller>
  );
}

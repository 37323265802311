import { call, put, takeLatest } from 'redux-saga/effects';
import { maintenanceActions as actions } from '.';
import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';

function* retry(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/user/categories`;
    yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.setDisableOrder(true));
    yield put(actions.setRetry(false));
  } catch (err: any) {
    yield put(actions.setRetry(false));
    console.log(err);
  }
}

export function* maintenanceSaga() {
  yield takeLatest(actions.retry.type, retry);
}

/**
 *
 * Individual
 *
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { selectSaveProfile } from '../../slice/selectors';
import { registerPageActions } from '../../slice';
import { RadioGroup } from '../../index.styled';
import { selectLaunchAddressList } from 'app/pages/ManageAddress/slice/selectors';
import { selectProfile } from 'app/pages/slice/selectors';

interface Props {
  type: string;
  onProceed: Function;
}

interface Payload {
  name: string;
  email?: string;
  user_type: string;
  referral_code?: string;
}

export function Individual(props: Props) {
  const { type, onProceed } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const saveProfile = useSelector(selectSaveProfile);
  const launchAddressList = useSelector(selectLaunchAddressList);

  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (!!profile?.data?.name) {
      let [prefix, ...name] = profile?.data?.name.trim().split(' ');

      if (prefix === 'Ms' || prefix === 'Mr') {
        setTitle(prefix);
        name = name.join(' ');
      } else {
        name = [prefix, ...name].join(' ');
      }

      setValue('name', name);
    }
    if (!!profile?.data?.email) {
      setValue('email', profile.data.email);
    }
  }, [profile]);

  const [title, setTitle] = useState('Ms');

  const handleTypeChange = (e: any) => {
    setTitle(e.target.value);
  };

  const userNameFormat = /^[\.a-zA-Z0-9!? ]*$/; //eslint-disable-line
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line

  useEffect(() => {
    if (saveProfile.success) {
      onProceed();
    }
  }, [saveProfile]);

  const onSubmit = async val => {
    try {
      const payload: Payload = {
        name: `${title} ${val.name}`,
        user_type: type,
        referral_code: val.referral_code,
      };
      if (!!val?.email) {
        payload.email = val.email;
      }

      if (Boolean(val.referral_code)) {
        payload.referral_code = val.referral_code.toUpperCase();
      }
      dispatch(registerPageActions.saveProfile(payload));
    } catch (err) {
      console.log(err);
    }
  };

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Form
      style={{ marginTop: '-2rem' }}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={e => checkKeyDown(e)}
    >
      <RadioGroup className="radio-group type-wrapper">
        <Form.Check
          className="radio"
          type="radio"
          value="Ms"
          label={`Ms`}
          id={`Ms`}
          checked={title === 'Ms'}
          onChange={handleTypeChange}
        />
        <Form.Check
          className="radio"
          type="radio"
          label={`Mr`}
          value="Mr"
          checked={title === 'Mr'}
          id={`Mr`}
          onChange={handleTypeChange}
        />
      </RadioGroup>
      <Form.Group className="user-name">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          {...register('name', { required: true, pattern: userNameFormat })}
          type="text"
          placeholder="User Name"
        />
        {errors.name && (
          <Form.Text className="required">Name is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="email">
        <Form.Label>Email ID</Form.Label>
        <Form.Control
          {...register('email', { pattern: mailformat, required: false })}
          type="text"
          placeholder="Your Email ID(Optional)"
        />
        {errors.email && (
          <Form.Text className="required">Invalid Email Id.</Form.Text>
        )}
      </Form.Group>
      {!!launchAddressList.data?.profile?.reward_enabled ? (
        <Form.Group className="email">
          <Form.Label>Have a referral code?</Form.Label>
          <Form.Control
            {...register('referral_code', { required: false })}
            type="text"
            placeholder="Enter referral code(Optional)"
          />
          {errors.referral_code && (
            <Form.Text className="required">
              The referral code is invalid
            </Form.Text>
          )}
        </Form.Group>
      ) : (
        <></>
      )}

      <Form.Group className="terms">
        <Form.Check
          className="checkbox"
          type="checkbox"
          label={``}
          id={`terms-checkbox`}
          {...register('terms', { required: true })}
        />
        <a
          href="https://api.madrasmandi.in/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          className="terms-conditions"
        >
          I agree to terms & conditions
        </a>
      </Form.Group>
      <Button type="submit" variant="warning" disabled={saveProfile.loading}>
        Save
      </Button>
    </Form>
  );
}

import styled from 'styled-components';

const CheckDeliveryLocationStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .selected-location-container {
    margin: 6rem 1rem 0;

    .heading {
      font-size: 14px;
    }
    .address {
      border: 1px solid #f0f0f0;
      background: #ffffff;
      border-radius: 5px;
      padding: 0.5rem;
      font-size: 14px;
    }
  }
  .delivery-status-wrapper {
    margin: 1rem;

    .heading {
      font-size: 14px;
    }
    .status {
      display: flex;
      border: 1px solid #f0f0f0;
      background: #ffffff;
      border-radius: 5px;
      padding: 0.5rem;
      .img {
        width: 30px;
      }
      .text {
        font-size: 16px;
        margin: 0.5rem;
      }
    }
    .not-deliverable-text {
      font-size: 12px;
      margin-top: 5px;
      color: gray;
    }
  }
`;

export { CheckDeliveryLocationStyle };

import styled from 'styled-components/macro';

const SideSheetHeader = styled.div<{ styles?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00855b;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  h5 {
    margin-bottom: 0;
    color: white;
    display: flex;
    align-items: center;
    button {
      padding: 0;
      margin-right: 12px;
      img {
      }
    }
  }
`;
const SideSheetModal = styled.div<{ styles?: string }>`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
`;
const SideSheetFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 1.5rem;
  justify-content: flex-end;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  flex-shrink: 0;
  background-color: white;
  z-index: 1;
  gap: 12px;
  button {
    width: 100%;
  }
  .checkout_btn {
    :hover {
      background-color: 00a672;
    }
  }
`;
const SideSheetStyled = styled.div`
  height: 100%;
  max-width: 480px;
  width: 100%;
  margin-left: auto;
  background-color: #fafafa;
  position: relative;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  > form {
    margin: 0;
  }
  .card {
    &.cart-item {
      margin-top: 1.5rem;
      overflow: hidden;
      &:last-child {
        margin-bottom: 1.5rem;
        @media screen and (max-width: 575px) {
          margin-bottom: 12px;
        }
      }
      @media screen and (max-width: 575px) {
        margin-top: 12px;
      }
    }
  }
`;
const AddressListStyled = styled.ul`
  max-width: 510px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;
const ButonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -6px;
  margin-right: -6px;
  > * {
    margin-left: 6px;
    margin-right: 6px;
  }
`;
const PriceChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    max-width: 50px;
  }
`;
const LoadingWrapper = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  li {
    margin-top: 1.5rem;
    &.loading {
      height: 144px;
    }
  }
`;

const TitleWithBack = styled.div`
  display: flex;
  align-items: center;
  max-width: 510px;
  width: 100%;
  position: relative;
  > a {
    position: absolute;
    left: 0;
    top: -2pxpx;
  }
  h5 {
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const AddAdressTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  .icon {
    width: 16px;
    height: 14px;
    display: flex;
    align-items: center;
  }
  h5 {
    margin-bottom: 0;
    margin-left: 12px;
  }
`;

const AddAddressStyled = styled.div`
  .box-edit-order {
    padding: 1.5rem;
    > button {
      margin-top: 1.5rem;
    }
  }
  form {
    margin-top: 0;
  }
`;

const AddressLists = styled.div`
  .box {
    max-width: 600px;
    width: 100%;
    min-height: 540px;
    background: #ffffff;
    padding: 2.5rem 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .button-icon {
      display: flex;
      img {
        color: black;
      }
    }
    > button {
      max-width: 510px;
      width: 100%;
      margin-top: 1.5rem;
    }
    .send-otp {
      flex-grow: 0.5;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
    .main_heading {
    }
    .inner-width,
    .send-otp {
      max-width: 500px;
      width: 100%;
    }
  }
  .continue_btn {
    background-color: #00855b;
    cursor: pointer;
    max-width: 450px;
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
  }
  @media screen and (max-width: 575px) {
    min-height: initial;
  }
`;
export {
  SideSheetFooter,
  SideSheetHeader,
  SideSheetModal,
  SideSheetStyled,
  LoadingWrapper,
  AddressListStyled,
  PriceChange,
  ButonGroup,
  AddressLists,
  AddAddressStyled,
  AddAdressTitle,
  TitleWithBack,
};

/**
 *
 * CartIcon
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Image } from 'react-bootstrap';

import Cart from './assets/cart.svg';
import { useDispatch, useSelector } from 'react-redux';
import { homePageActions } from 'app/pages/HomePage/slice';
import { selectCartItems } from 'app/pages/Cart/slice/selectors';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export function CartIcon(props: Props) {
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  return (
    <CartStyled
      onClick={() => {
        Mixpanel.track(actionConstants.CART);
        dispatch(homePageActions.setShowCart(true));
      }}
    >
      <figure>
        <Image src={Cart} alt="cart" />
      </figure>
      <Indicator className="indicator">{cartItems.length}</Indicator>
    </CartStyled>
  );
}

const Indicator = styled.div`
  background-color: #00855c;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -6px;
`;
const CartStyled = styled.div`
  position: relative;
  margin-left: auto;
  cursor: pointer;
  figure {
    width: 40px;
    height: 40px;
    border: 1px solid #acacac;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    margin-left: 8px;
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * SearchItem
 *
 */
import * as React from 'react';
import { CounterUOM } from 'app/components/CounterUOM';
import { useDispatch, useSelector } from 'react-redux';
import { ReactImage } from 'app/components/ReactImage';
import { cartActions } from 'app/pages/Cart/slice';
import { selectCartItemByItemCode } from 'app/pages/Cart/slice/selectors';
import {
  InfoTop,
  ListItem,
  Left,
  Value,
  Details,
  Info,
  OutOfStockListItem,
  OutOfStock,
  MoreOption,
} from './index.styled';
import SeasonalSVG from 'assets/seasonal.svg';
import { UOM } from 'app/pages/CategoryPage/components/CardList/components/UomModal';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { AnalyticsConstants } from 'utils/constants';
import useAnalyticsEventTracker from 'utils/hooks/useAnalyticsEventTracker';
interface Props {
  item: any;
}

export function SearchItem(props: Props) {
  const [isOpenUomModal, setIsOpenUomModal] = React.useState(false);

  const onOpenUomModal = () => {
    setIsOpenUomModal(true);
  };
  const onCloseUomModal = () => {
    setIsOpenUomModal(false);
  };

  const eventTracker = useAnalyticsEventTracker(AnalyticsConstants.ADD_TO_CART);

  const { item } = props;
  const dispatch = useDispatch();

  const cartItem: any = useSelector(state =>
    selectCartItemByItemCode(state, item.item_code),
  );

  const renderMinMax = () => {
    if (item?.multiple_uoms.length !== 1) return '';
    if (!!item.multiple_uoms[0].conversation_text)
      return <h6>{item.multiple_uoms[0].conversation_text}</h6>;

    return <></>;
  };

  const renderButtons = () => {
    // If multiple uom is there
    if (item?.multiple_uoms.length > 1 && !cartItem) {
      return (
        <button
          className="add_btn"
          onClick={(Mixpanel.track(actionConstants.OPEN_MODAL), onOpenUomModal)}
        >
          {item?.multiple_uoms.length > 1 && (
            <MoreOption>
              <p>More Options</p>
            </MoreOption>
          )}
          Add
        </button>
      );
    }

    // If item is not there in cart
    else if (parseFloat(cartItem?.uom ? cartItem?.uom[0].qty : 0) === 0) {
      return (
        <button
          className="add_btn"
          onClick={() => {
            Mixpanel.track(actionConstants.ADDING_ITRM_CART);
            if (window.fbq) {
              window.fbq('track', 'AddToCart', {
                content_ids: item.id,
                content_type: 'product',
                value: parseFloat(item?.multiple_uoms[0].price),
                currency: 'INR',
              });
            }

            dispatch(
              cartActions.setCartItem({
                ...item,
                uom: [
                  {
                    id: item.multiple_uoms[0].uom_id,
                    price: parseFloat(item.multiple_uoms[0].price),
                    variant_id: item.multiple_uoms[0].variant_id,
                    qty: item.multiple_uoms[0].minimum_quantity,
                    availability: item.multiple_uoms[0].is_available || true,
                    visibility: item.multiple_uoms[0].is_visible || true,
                  },
                ],
              }),
            );
          }}
        >
          Add
        </button>
      );
    }

    let tempValue = 0;
    if (cartItem) {
      if (cartItem.uom.length > 1) {
        tempValue = cartItem.uom.reduce((acc, curr) => {
          return acc + parseFloat(curr.qty);
        }, 0);
      } else {
        tempValue = parseFloat(cartItem.uom[0].qty);
      }
    }

    // If multiple_uom data is one and one cart item added in the car
    return (
      <CounterUOM
        isMultipleUomInCart={cartItem.uom.length > 1}
        isMultipleUomInAPI={item?.multiple_uoms.length > 1}
        value={tempValue}
        onOpenUomModal={onOpenUomModal}
        unit={item.multiple_uoms[0].unit}
        minValue={item.multiple_uoms[0].minimum_quantity}
        maxValue={item.multiple_uoms[0].maximum_quantity}
        onChange={(val: any) => {
          const unit: any = [...item.multiple_uoms][0];
          dispatch(
            cartActions.setCartItem({
              ...item,
              uom: [
                {
                  price: parseFloat(unit.price),
                  id: unit.uom_id,
                  variant_id: unit.variant_id,
                  qty: val,
                  availability: unit.is_available || true,
                  visibility: unit.is_visible || true,
                },
              ],
            }),
          );
        }}
      />
    );
  };

  const renderPriceUOMDetail = () => {
    if (cartItem) {
      let priceObj = item.multiple_uoms.find(
        e => e.uom_id === cartItem.uom[0].id,
      );
      if (priceObj) {
        return (
          <h6 onClick={onOpenUomModal}>
            ₹ {parseFloat(priceObj.price)} /{priceObj.unit}
          </h6>
        );
      }
    }
    if (!Array.isArray(item.multiple_uoms)) return <React.Fragment />;
    const uom = [...item.multiple_uoms][0]; // .sort((a, b) => a.uom_id - b.uom_id)
    return (
      <h6 onClick={onOpenUomModal}>
        ₹ {parseFloat(uom.price)} /{uom.unit}
      </h6>
    );
  };

  const renderPriceUOM = () => {
    if (item?.multiple_uoms.length > 1) {
      return (
        <>
          {renderPriceUOMDetail()}
          {isOpenUomModal && (
            <UOM
              isOpen={isOpenUomModal}
              onClose={onCloseUomModal}
              item={item}
            />
          )}
        </>
      );
    }
    return <></>;
  };

  const renderSearchItem = () => (
    <ListItem className="list-item">
      {item.is_seasonal && (
        <img className="tag" src={SeasonalSVG} alt="seasonal" />
      )}
      <Left className="list-item-left">
        <ReactImage
          src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
          alt={item.name}
        />
      </Left>
      <Details className="list-item-details">
        <Info className="details-info">
          <InfoTop className="info-top">
            <h5>{item.name}</h5>
            {renderPriceUOM()}
            {item?.multiple_uoms.length === 1 && (
              <h6>
                ₹ {parseFloat(item.multiple_uoms[0].price)} /
                {item.multiple_uoms[0].unit}
              </h6>
            )}
          </InfoTop>
          {renderMinMax()}
        </Info>
        <Value className="value">{renderButtons()}</Value>
      </Details>
    </ListItem>
  );

  const renderOutOfStockSearchItem = () => (
    <OutOfStockListItem className="list-item">
      {item.is_seasonal && (
        <img className="tag" src={SeasonalSVG} alt="seasonal" />
      )}
      <Left className="list-item-left">
        <ReactImage
          src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
          alt={item.name}
        />
      </Left>
      <Details className="list-item-details">
        <Info className="details-info">
          <InfoTop className="info-top">
            <h5>{item.name}</h5>
            {item?.multiple_uoms.length === 1 && (
              <h6>
                ₹ {parseFloat(item.multiple_uoms[0].price)} /
                {item.multiple_uoms[0].unit}
              </h6>
            )}
          </InfoTop>
          {renderMinMax()}
        </Info>
        <Value className="value">
          <OutOfStock>
            <span> Out of Stock</span>
          </OutOfStock>
        </Value>
      </Details>
    </OutOfStockListItem>
  );

  if (item?.multiple_uoms[0]?.is_available) return renderSearchItem();
  return renderOutOfStockSearchItem();
}

/**
 *
 * CarouselSection
 *
 */
import React, { memo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Slider from 'react-slick';

import Banner1Image from 'assets/capsicum.png';
import Banner2Image from 'assets/vegitables.png';
import Banner3Image from 'assets/tomato.png';

import CarouselSectionStyled from './index.styled';

interface Props {}

export const CarouselSection = memo((props: Props) => {
  const [settings] = useState({
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3200,
  });

  return (
    <CarouselSectionStyled className="carousel-section">
      <Slider {...settings}>
        <div>
          <a
            href="/login"
            style={{
              background: `linear-gradient(180deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 30%,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0) 60%), url(${Banner1Image})`,
            }}
          >
            <Container>
              <h2>ORDER FRESH FRUITS AND VEGGIES TO YOUR DOORSTEP</h2>
              <Button variant="warning">Explore</Button>
            </Container>
          </a>
        </div>
        <div>
          <a
            href="/login"
            style={{
              background: `linear-gradient(180deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 30%,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0) 60%), url(${Banner2Image})`,
            }}
          >
            <Container>
              <h2>THE WHOLESALE MARKET IN YOUR POCKET</h2>
              <Button variant="warning">Explore</Button>
            </Container>
          </a>
        </div>
        <div>
          <a
            href="/login"
            style={{
              background: `linear-gradient(180deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 30%,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0) 60%), url(${Banner3Image})`,
            }}
          >
            <Container>
              <h2>Eating healthy has never been so tasty</h2>
              <Button variant="warning">Explore</Button>
            </Container>
          </a>
        </div>
      </Slider>
    </CarouselSectionStyled>
  );
});

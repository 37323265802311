import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { authActions as actions } from '.';

function* getProfile() {
  try {
    const requestURL = `/api/v1/user/profile`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getProfileSuccess(response.data));
  } catch (err: any) {
    yield put(actions.getProfileError(err.response));
  }
}
export function* authSaga() {
  yield takeLatest(actions.getProfile.type, getProfile);
}

/**
 *
 * CartPreview
 *
 */
import {
  selectCartItems,
  selectSubTotal,
} from 'app/pages/Cart/slice/selectors';
import { homePageActions } from 'app/pages/HomePage/slice';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import GoIcon from 'assets/go.svg';
import { CartPreviewStyled, ItemPrice } from './index.styled';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export const CartPreview = memo((props: Props) => {
  const cartItems = useSelector(selectCartItems);
  const subTotal = useSelector(selectSubTotal);

  const dispatch = useDispatch();

  if (cartItems.length !== 0) {
    return (
      <CartPreviewStyled className="cart-preview">
        <ItemPrice className="item-price">
          <span>{cartItems.length} Items</span>
          <span>₹{subTotal.toFixed(2)}</span>
        </ItemPrice>
        <Button
          className="cart_btn"
          variant="link"
          onClick={() => {
            Mixpanel.track(actionConstants.CART);

            dispatch(homePageActions.setShowCart(true));
          }}
        >
          View Cart
          <img src={GoIcon} alt="GoIcon" />
        </Button>
      </CartPreviewStyled>
    );
  }
  return <></>;
});

/**
 *
 * PageFooter
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import actionConstants from 'app/mixpanel/constants/action';
import { Mixpanel } from 'app/mixpanel';

interface Props {}

export function PageFooter(props: Props) {
  return (
    <Footer className="footer">
      <FooterList className="footer-list">
        <FooterListItem className="footer-list-item">
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </FooterListItem>
        <FooterListItem>
          <Link to="/privacy-policy">Our Privacy Policy</Link>
        </FooterListItem>
        <FooterListItem>
          <Link to="/return-refund">Return and Refund Policy</Link>
        </FooterListItem>
        <FooterListItem>
          <Link
            to="/shipping-delivery"
            onClick={() => {
              Mixpanel.track(actionConstants.SHIPPING_POLICY);

              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            Shipping And Policy
          </Link>
        </FooterListItem>
      </FooterList>
    </Footer>
  );
}

const Footer = styled.footer``;
const FooterList = styled.ul`
  background-color: #00855b;
  list-style-type: none;
  padding: 12px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;
const FooterListItem = styled.li`
  a {
    color: white;
    font-weight: 500;
    text-decoration: none;
  }
  &:not(:first-child) {
    &:before {
      content: '|';
      color: white;
      font-weight: 500;
      margin-left: 12px;
      margin-right: 12px;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      margin-top: 8px;
    }
  }
`;

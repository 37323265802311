import React from 'react';
import { ReactImage } from 'app/components/ReactImage';
import { Card } from 'app/components/Card';
import { useSelector } from 'react-redux';

import {
  OrderedItemPicture,
  OrderedItemName,
  OrderedItemsStyled,
  OrderedListItem,
  OrderedList,
} from './index.styled';
import { selectStoreOrder } from '../../slice/selectors';

export const StoreOrderedItems = ({ item }: any) => {
  const order = useSelector(selectStoreOrder);

  /* Don't Render Items If API Fails */
  if (!order?.data?.items) return <></>;

  const renderOrderedItems = () => {
    return order?.data?.items.map(item => {
      return (
        <OrderedListItem className="ordered-list-item" key={item.name}>
          <OrderedItemPicture className="ordered-item-picture">
            <ReactImage
              src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
              alt="product image"
            />

            <OrderedItemName className="ordered-item-name">
              <h6>{item.name}</h6>

              <span
                key={item.pivot.id}
                style={{ display: 'block', marginTop: '0.5rem' }}
              >
                ₹{Number(item.pivot.price).toFixed(2)} /{item.pivot.unit}{' '}
                {item.pivot.cart_quantity}
              </span>
            </OrderedItemName>
          </OrderedItemPicture>
          <span>x {item.pivot.qty}</span>
        </OrderedListItem>
      );
    });
  };

  return (
    <OrderedItemsStyled className="ordered-items">
      <h5>Ordered Items</h5>
      <Card className="card">
        <OrderedList className="ordered-list">
          {renderOrderedItems()}
        </OrderedList>
      </Card>
    </OrderedItemsStyled>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * OrderDetails
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Button, Carousel, Image } from 'react-bootstrap';
import { header } from 'utils/auth';
import { selectProfile } from 'app/pages/slice/selectors';
import { Card } from 'app/components/Card';
import { Review } from 'app/components/Review';

import { SuccessModal } from 'app/components/SuccessModal';
import { selectOrder } from '../../slice/selectors';
import {
  LoadingWrapper,
  OrderDetailsStyled,
  PaymentDetailsList,
  PaymentDetailsListItem,
  DeliveryDetail,
  PaymentDetails,
  PaymentMethod,
  Intro,
  DeliveryOption,
  SelectDeliveryOption,
  SelectScheduleDelivery,
  ScheduleDeliveryDetail,
  Refunds,
  DelayMessage,
  Bill,
  BottomSticky,
  MethodStyled,
} from './index.styled';

import watch from 'assets/truck_next_day.svg';
import Thunder from 'assets/thunder.svg';
import Online from 'assets/online.svg';
import Note from 'assets/note.svg';
import Wallet from 'assets/wallet2.svg';
import UPI from 'assets/upi.svg';
import Visa from 'assets/visa.svg';
import Banking from 'assets/online-banking.svg';
import Pay from 'assets/razorpay.svg';
import Delay from 'assets/delay-image.png';
import coins from 'assets/mm-coin.svg';
import Clock from 'assets/pending_clock.svg';
import Tick from 'assets/paid_tick.svg';
import Alert from 'assets/info-circle-outlined.svg';
import {
  DELIVERED,
  NOT_DELIVERED,
  ORDER_IS_REJECTED,
  ORDER_NOT_ACCEPTED,
  REJECTED_BY_ADMIN,
} from 'utils/constants';
import { orderActions } from '../../slice';
import { FailureModal } from 'app/components/FailureModal';
import { DeliveryStatus } from '../DeliveryStatus';
import { OrderedItems } from '../OrderedItems';
import { EditOrderCounter } from '../EditOrderCounter';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SideSheetHeader } from 'app/components/SideSheet/index.styled';
import { SideSheetBody } from 'app/pages/Cart/index.styled';
import { PaymentMethodComponent } from '../PaymentMethod';
import Close from 'assets/close.svg';
import Back from 'assets/back-white.svg';
import { SideSheet } from 'app/components/SideSheet';
import RazorpayIcon from 'assets/razorpay.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Logo from 'assets/mandi.svg';
import NetBanking from 'assets/online-banking.svg';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { Callback } from '../RequestCallBackModal/index.styled';
import { CallbackRequestModal } from '../RequestCallBackModal';
import { selectLaunchAddressList } from 'app/pages/ManageAddress/slice/selectors';
import { BusinessRefundModal } from '../RefundModalForBusiness';
import { ChatEnableModal } from '../ChatEnableModal';

interface Props {
  match: any;
}

export function OrderDetails(props: Props) {
  const { match } = props;
  const launchAddressList = useSelector(selectLaunchAddressList);
  const order = useSelector(selectOrder);
  const profile = useSelector(selectProfile);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [showReview, setShowReview] = useState(true);
  const [isChangePaymentMethod, setIsChangePaymentMethod] = useState(false);
  const interval: any = useRef(null);
  const [time, setTime]: any = useState(null);
  const [activeBill, setActiveBill] = useState(0);
  // const [isCallbackModal, setIsCallbackModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { data: item } = order;

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isSupportEnableModalOpen, setIsSupportEnableOpen] = useState(false);

  // const openModal = () => {
  //   setIsRefundModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsRefundModalOpen(false);
  // };

  // React.useEffect(() => {
  //   const handleFocus = () => {
  //     window.location.reload();
  //   };

  //   window.addEventListener('focus', handleFocus);
  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //   };
  // }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      checkTime();
    }, 1000);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [item]);

  const getTotalTillRefundedAmount = React.useCallback(
    (index): number => {
      if (
        Boolean(item) &&
        Boolean(item?.bills) &&
        Array.isArray(item.bills) &&
        item.bills?.length > 0
      ) {
        const sortedBillItems = item.bills
          .slice()
          .sort((a, b) => a.bill_id - b.bill_id);

        let total: number = 0;

        sortedBillItems.forEach((bill, i) => {
          if (i < index) {
            if (bill?.payment_method === 'Refund') total -= bill.refund_amount;
            else if (bill?.reason_for_edit) total += bill.total;
            else total += bill.total;
          }
        });
        return total;
      }
      return 0;
    },
    [item],
  );

  const totalPayableAmount = React.useMemo(() => {
    if (
      Boolean(item) &&
      Boolean(item?.bills) &&
      Array.isArray(item.bills) &&
      item.bills?.length > 0
    ) {
      let payableAmount = 0;
      item.bills.forEach(bill => {
        if (bill.payment_status !== 'complete') {
          // Total Bill
          payableAmount += bill.total;

          // Wallet spent deduction
          if (Boolean(bill.wallet_spent)) {
            payableAmount -= bill.wallet_spent;
          }

          // Rewards spent deduction
          if (Boolean(bill.reward_spent)) {
            payableAmount -= bill.reward_spent;
          }
        }
      }, []);
      return payableAmount;
    }
    return 0;
  }, [item]);

  const grandTotalAmount = React.useMemo(() => {
    if (
      Boolean(item) &&
      Boolean(item?.bills) &&
      Array.isArray(item.bills) &&
      item.bills?.length > 0
    ) {
      let totalAmount = 0;
      item.bills.forEach(bill => {
        /* Total Bill */
        if (bill?.payment_method === 'Refund')
          totalAmount -= bill.refund_amount;
        else totalAmount += bill.total;
      }, []);
      return totalAmount;
    }
    return 0;
  }, [item]);

  const getReadableDate = (dateString: number) => {
    const dateValueInMS = dateString * 1000;
    const d = new Date(dateValueInMS);
    return (
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      ',' +
      ' ' +
      d.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    );
  };

  const renderDeliveryType = () => {
    if (item.delivery_type === 'instant') {
      if (item.time_slot === null) {
        return (
          <SelectDeliveryOption className="select-delivery-option one-hour-delivery">
            <img src={Thunder} alt="" />
            <p className="title">{item.immediate_order_title}</p>
          </SelectDeliveryOption>
        );
      }
      return (
        <SelectDeliveryOption className="select-delivery-option one-hour-delivery">
          <img src={Thunder} alt="" />
          <p className="title">
            {launchAddressList.data.instant_delivery_title}
          </p>
        </SelectDeliveryOption>
      );
    } else if (item.delivery_type === '' || item.delivery_type === 'app') {
      return (
        <SelectDeliveryOption className="select-delivery-option next-day-delivery">
          <img src={watch} alt="" />
          <p className="title"> Next day delivery</p>
        </SelectDeliveryOption>
      );
    }
  };

  const renderDriverName = () => {
    if (item.delivery_type === 'instant' && !!item.driver) {
      return (
        <DeliveryOption>
          <h6>Driver</h6>
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            {item.driver.name}
          </span>
        </DeliveryOption>
      );
    }
  };

  const renderBillNumber = () => {
    return (
      <Bill className="bill">
        <h4 style={{ fontSize: '16px' }}>
          Bill Number :
          {item.bills
            ?.slice()
            .sort((a, b) => a.bill_id - b.bill_id)
            .map((bill, index) => (
              <span
                onClick={() => {
                  setActiveBill(index);
                }}
                className={`bill_no ${
                  index === activeBill
                    ? 'activeBillNumber'
                    : 'inactiveBillNumber'
                }`}
              >
                {bill.bill_id}
              </span>
            ))}
        </h4>
      </Bill>
    );
  };

  const renderDelayMessage = () => {
    if (item.delay_message !== null) {
      return (
        <DelayMessage>
          <Card className="delay-card-wrapper">
            <div className="delay-img-wrapper">
              <Image className="delay-img" src={Delay} alt="delay" />
            </div>
            <div className="delay-detail">
              <h5>Apologies for Delay in Your Order</h5>
              <p className="delay-message">{item.delay_message}</p>
            </div>
          </Card>
        </DelayMessage>
      );
    }
  };

  const renderScheduleDelivery = () => {
    if (item.delivery_type === 'instant' && item.time_slot === null) {
      return <></>;
    } else {
      return (
        <SelectScheduleDelivery>
          <h6>Delivery Schedule</h6>
          <time className="time">{item.time_formatted}</time>
        </SelectScheduleDelivery>
      );
    }
  };

  const renderDeliveryInstructions = () => {
    if (item.instructions === '' || !item.instructions) {
      return <></>;
    }
    return (
      <div className="address_section" style={{ marginTop: '1rem' }}>
        <h6 className="address">Delivery Instructions</h6>
        <p className="address_name">{item.instructions}</p>
      </div>
    );
  };

  const checkTime = () => {
    const then = DateTime.fromJSDate(
      new Date(item.order_created_at * 1000),
    ).plus({ seconds: item.edit_order_time });
    const now = DateTime.now();
    if (now < then) {
      const countdown = then.diff(now);
      setTime(countdown);
    } else {
      setTime(null);
      if (interval.current) clearInterval(interval.current);
    }
  };

  const onSuccess = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      dispatch(orderActions.getOrder({ id: match.params.id }));
      // dispatch(orderActions.getStoreOrder(match.params.id));
    }, 3000);
  };

  const onFailure = () => {
    setIsFailure(true);
    setTimeout(() => {
      setIsFailure(false);
    }, 3000);
  };

  const onPayOnline = async (paymentMethod = 'upi') => {
    try {
      if (paymentMethod === 'cash') {
        await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URI}/api/v3/user/order/update/payment-method`,
          headers: {
            Authorization: header(),
            Accept: 'application/json',
            'MM-Device-Type': 'web',
            'MM-Build-Version': '1.0.0',
          },
          data: {
            order_id: item.order_id,
            payment_method: 'cash',
          },
        });
        onSuccess();
        return;
      }
      setLoading(true);
      const orderResponse = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URI}/api/v1/user/payment/razorpay/init/order/${item.order_id}`,
        headers: {
          Authorization: header(),
          Accept: 'application/json',
          'MM-Device-Type': 'web',
          'MM-Build-Version': '1.0.0',
        },
      });
      setLoading(false);
      var options = {
        key: orderResponse?.data?.data?.razorpay_key,
        amount: String(totalPayableAmount),
        currency: 'INR',
        name: 'Madras Mandi',
        description: 'Madras Mandi',
        order_id: orderResponse.data.data.id,
        handler: function (response) {
          onSuccess();
        },
        modal: {
          ondismiss: function () {
            onFailure();
          },
        },
        prefill: {
          name: profile.data.name,
          email: profile.data.email,
          contact: profile.data.phone,
          method: paymentMethod,
        },
        theme: {
          color: '#3399cc',
        },
      };

      // @ts-ignore
      var rzp1 = new Razorpay(options);
      rzp1.open();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  if (order.loading) {
    return (
      <LoadingWrapper className="loading-wrapper">
        <div className="loading" />
        <div className="loading" />
        <div className="loading" />
      </LoadingWrapper>
    );
  }
  const getImageSubPaymentMethod = sub_payment_method => {
    switch (sub_payment_method.toLowerCase()) {
      case 'upi':
        return UPI;

      case 'paylater':
      case 'razorpay':
        return RazorpayIcon;

      case 'payOnDelivery':
        return Note;

      case 'netbanking':
        return NetBanking;

      case 'card':
        return Visa;

      case 'wallet':
        return Wallet;
      default:
        return '';
    }
  };

  const getImagePaymentMethod = (payment_method: string) => {
    switch (payment_method.toLowerCase()) {
      case 'cash':
        return Note;

      default:
        return '';
    }
  };

  const renderDivider = () => (
    <div
      style={{
        borderBottom: '2px dashed #00855B',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}
    />
  );

  const renderPayments = (bill, index) => {
    return (
      <Carousel.Item>
        <Card className="edit_order_bill">
          <div className="edit_order_status">
            <h6>Payment Status</h6>
            {bill.payment_status === 'complete' ? (
              <h6 className="paid_status">Paid</h6>
            ) : (
              <h6 className="pending_status">Pending</h6>
            )}
          </div>
          <div className="edit_order_mode">
            <h6>Payment Mode</h6>
            <PaymentMethod>{renderPaymentMethod(bill)}</PaymentMethod>
          </div>
          {!!bill.reward_spent && renderRewardSpent()}

          {renderDivider()}
          <div className="edit_order_status">
            <h6>Subtotal</h6>
            <h6>₹ {Number(bill.sub_total).toFixed(2)}</h6>
          </div>
          {!!bill.discount && (
            <div className="edit_order_status">
              <h6 style={{ color: '#00855b' }}>Discount</h6>
              <h6 style={{ color: '#00855b' }}>
                {' '}
                -₹ {Number(bill.discount).toFixed(2)}
              </h6>
            </div>
          )}
          <div className="edit_order_status">
            <h6>Round Off</h6>
            <h6>{bill.round_off}</h6>
          </div>

          <div className="edit_order_status">
            <h6>Total</h6>
            <h6>₹ {Number(bill.total).toFixed(2)}</h6>
          </div>
        </Card>
      </Carousel.Item>
    );
  };
  const renderReturnPayments = (bill, index) => {
    const total: number = getTotalTillRefundedAmount(index);

    return (
      <Carousel.Item>
        <Card className="edit_order_bill">
          <div className="edit_order_status">
            <h6>Order Total:</h6>
            <h6>₹ {Number(total).toFixed(2)}</h6>
          </div>
          <div className="edit_order_status">
            <h6>Edited Amount</h6>
            <h6>₹ {Number(bill.total).toFixed(2)}</h6>
          </div>
          <div className="edit_reason">
            <Image src={Alert} alt="alert" className="edit_image" />
            <p className="edit_reason_text">{bill.reason_for_edit}</p>
          </div>
          <div className="edit_order_status">
            <h6>Total</h6>
            <h6>₹ {Number(bill.total + total).toFixed(2)}</h6>
          </div>
        </Card>
      </Carousel.Item>
    );
  };
  const renderRefundPayments = (bill, index) => {
    const total: number = getTotalTillRefundedAmount(index);
    return (
      <Carousel.Item>
        <Card className="edit_order_bill">
          <div className="edit_order_status">
            <h6>Order Total:</h6>
            <h6>₹ {Number(total).toFixed(2)}</h6>
          </div>
          <div className="edit_order_status">
            <h6>Refunded Amount</h6>
            <h6>₹ {Number(bill.refund_amount).toFixed(2)}</h6>
          </div>
          <div className="edit_reason">
            <Image src={Alert} alt="alert" className="edit_image" />
            <p className="edit_reason_text">{bill.reason_for_edit}</p>
          </div>
          <div className="edit_order_status">
            <h6>Total</h6>
            <h6>₹ {Number(total - bill.refund_amount).toFixed(2)}</h6>
          </div>
        </Card>
      </Carousel.Item>
    );
  };

  const renderRewardSpent = () => {
    if (item.bills[0].reward_spent) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image
              className="payment_img"
              src={coins}
              alt="coins"
              style={{ width: '25px' }}
            />
            <h5>Rewards</h5>
          </div>
          <p>{item.bills[0].reward_spent}</p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderCarousal = () => {
    if (item.bills?.length < 2) return <></>;
    const sortedBillItems = item.bills
      .slice()
      .sort((a, b) => a.bill_id - b.bill_id);
    return (
      <>
        <Carousel controls={false} activeIndex={activeBill}>
          {sortedBillItems?.map((bill, index) => {
            if (bill?.payment_method === 'Refund')
              return renderRefundPayments(bill, index);
            else if (bill?.reason_for_edit)
              return renderReturnPayments(bill, index);
            return renderPayments(bill, index);
          })}
        </Carousel>
        {renderGrandTotalAmount()}
      </>
    );
  };

  const renderPaymentSidebar = () => {
    if (isChangePaymentMethod) {
      return (
        <SideSheet
          isOpen={isChangePaymentMethod}
          onClose={() => setIsChangePaymentMethod(false)}
        >
          <SideSheetHeader className="sidesheet-header">
            <h5>
              <button
                className="button-icon"
                onClick={() => setIsChangePaymentMethod(false)}
              >
                <Image src={Back} alt="back" />
              </button>
              Change Payment method
            </h5>
            <button
              className="button-icon"
              onClick={() => {
                setIsChangePaymentMethod(false);
              }}
            >
              <Image src={Close} alt="close" />
            </button>
          </SideSheetHeader>
          <SideSheetBody className="sidesheet-body">
            <PaymentMethodComponent
              price={totalPayableAmount}
              payOnlineFunction={onPayOnline}
              onClose={() => {
                setIsChangePaymentMethod(false);
              }}
            />
          </SideSheetBody>
        </SideSheet>
      );
    }
    return <></>;
  };

  const renderPaymentDetails = () => {
    return (
      <PaymentDetailsList className="payment-details-list">
        <PaymentDetailsListItem className="payment-details-list-item">
          <h6>Sub total</h6>
          <span>₹ {Number(item.sub_total).toFixed(2)}</span>
        </PaymentDetailsListItem>
        {item.discount ? (
          <PaymentDetailsListItem className="payment-details-list-item">
            <h6 style={{ color: '#00855b' }}>Discount</h6>
            <span style={{ color: '#00855b' }}>
              {' '}
              -₹ {Number(item.bills[0].discount).toFixed(2)}
            </span>
          </PaymentDetailsListItem>
        ) : (
          <></>
        )}
        {item.bills[0].round_off ? (
          <PaymentDetailsListItem className="payment-details-list-item">
            <h6 style={{ color: 'gray' }}>Round Off</h6>
            <span style={{ color: 'gray' }}>{item.bills[0].round_off}</span>
          </PaymentDetailsListItem>
        ) : (
          <></>
        )}
        {item.delivery_fee ? (
          <PaymentDetailsListItem className="payment-details-list-item">
            <h6>Delivery Fee</h6>
            <span>₹ {Number(item.delivery_fee).toFixed(2)}</span>
          </PaymentDetailsListItem>
        ) : (
          <></>
        )}
        {item.tax ? (
          <PaymentDetailsListItem className="payment-details-list-item">
            <h6>Tax</h6>
            <span>₹ {Number(item.tax).toFixed(2)}</span>
          </PaymentDetailsListItem>
        ) : (
          <></>
        )}

        {item.individual_packaging_enabled ? (
          <PaymentDetailsListItem className="payment-details-list-item">
            <h6>Packaging Fee</h6>
            <span>₹ {Number(item.packaging_price).toFixed(2)}</span>
          </PaymentDetailsListItem>
        ) : (
          <></>
        )}
      </PaymentDetailsList>
    );
  };

  const renderTotalAmount = () => {
    if (item.bills[0].payment_status === 'pending') {
      return (
        <div className="payable">
          <h5>Total</h5>
          <p className="price">₹ {Number(item.bills[0].total).toFixed(2)}</p>
        </div>
      );
    } else {
      return;
    }
  };

  const renderPaymentStatus = () => {
    if (
      item.bills[0].payment_status === 'pending' ||
      item.bills[0].payment_status === 'failed'
    ) {
      return (
        <div className="pending">
          <p className="pending_status">Pending</p>
          <img src={Clock} alt="Clock" />
        </div>
      );
    } else {
      return (
        <div className="paid">
          <p className="paid_status">Paid</p>
          <img src={Tick} alt="Clock" />
        </div>
      );
    }
  };

  const renderPaymentTotal = bill => {
    if (bill.payment_status === 'complete') {
      if (bill.wallet_spent) {
        return <p> ₹ {bill.wallet_spent - bill.total}</p>;
      } else if (bill.reward_spent) {
        return <p> ₹ {bill.total - bill.reward_spent}</p>;
      } else {
        return <p>₹ {bill.total}</p>;
      }
    }
    return;
  };
  const renderPaymentMethod = bill => {
    if (
      (!!bill.payment_method && !!bill.sub_payment_method) ||
      (!!bill.payment_method && !bill.sub_payment_method)
    ) {
      switch (bill.payment_method.toLowerCase()) {
        case 'razorpay':
          return (
            <div className="method">
              <div className="img-div">
                <Image className="payment_img" src={NetBanking} alt="online" />
                <h5>Net Banking</h5>
              </div>
              {renderPaymentTotal(bill)}
            </div>
          );

        case 'cash':
          return (
            <div className="method">
              <div className="img-div">
                <Image className="payment_img" src={Note} alt="cash" />
                <h5>Cash</h5>
              </div>
              {renderPaymentTotal(bill)}
            </div>
          );

        case 'wallet':
          return (
            <div className="method">
              <div className="img-div">
                <Image className="payment_img" src={Wallet} alt="cash" />
                <h6>Wallet</h6>
              </div>
              <p className="price"> ₹ {bill.wallet_spent}</p>
            </div>
          );
        default:
          return;
      }
    } else {
      const payMethod = bill.payment_method?.toLowerCase();
      const subPayMethod = bill.sub_payment_method?.toLowerCase();
      if (payMethod === 'razorpay') {
        switch (subPayMethod) {
          case 'paylater':
            return (
              <MethodStyled>
                <div className="method">
                  <div className="img-div">
                    <Image className="payment_img" src={Pay} alt="cash" />
                    <h6>Pay Later</h6>
                  </div>
                  {renderPaymentTotal(bill)}
                </div>
              </MethodStyled>
            );

          case 'card':
            return (
              <MethodStyled>
                <div className="method">
                  <div className="img-div">
                    <Image className="payment_img" src={Visa} alt="cash" />
                    <h6>Card</h6>
                  </div>
                  {renderPaymentTotal(bill)}
                </div>
              </MethodStyled>
            );

          case 'upi':
            return (
              <MethodStyled>
                <div className="method">
                  <div className="img-div">
                    <Image className="payment_img" src={UPI} alt="cash" />
                    <h6>UPI</h6>
                  </div>
                  {renderPaymentTotal(bill)}
                </div>
              </MethodStyled>
            );

          case 'netbanking':
            return (
              <MethodStyled>
                <div className="method">
                  <div className="img-div">
                    <Image className="payment_img" src={Banking} alt="cash" />
                    <h6>Net Banking</h6>
                  </div>
                  {renderPaymentTotal(bill)}
                </div>
              </MethodStyled>
            );
          default:
            return (
              <MethodStyled>
                <div className="method">
                  <div className="img-div">
                    <Image className="payment_img" src={UPI} alt="cash" />
                    <h6>UPI</h6>
                  </div>
                  {renderPaymentTotal(bill)}
                </div>
              </MethodStyled>
            );
        }
      } else if (payMethod === 'wallet') {
        switch (subPayMethod) {
          case 'paylater':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Pay} alt="cash" />
                    <h6>Pay Later</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          case 'card':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Visa} alt="cash" />
                    <h6>Card</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          case 'upi':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={UPI} alt="cash" />
                    <h6>UPI</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          case 'netbanking':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Banking} alt="cash" />
                    <h6>Net Banking</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          case 'cash':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Note} alt="cash" />
                    <h6>Cash</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          case 'payOnDelivery':
            return (
              <div className="subpayment-method">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Wallet} alt="cash" />
                    <h6>Wallet</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.wallet_spent}
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="img-div">
                    <Image className="payment_img" src={Note} alt="cash" />
                    <h6>Pay On Delivery</h6>
                  </div>
                  <p className="price" style={{ marginTop: '1rem' }}>
                    ₹ {bill.total - bill.wallet_spent}
                  </p>
                </div>
              </div>
            );

          default:
            return <></>;
        }
      }
    }
  };

  const renderReview = () => {
    if (item.status !== 'Rejected by admin' && showReview) {
      if (item.status === 'Delivered' && !item.is_rated) {
        return <Review id={item.order_id} setShow={setShowReview} />;
      }
    }
  };
  const renderPayOnline = () => {
    let show = true;
    if (item.payment_status != null) {
      let paymentStatus = item.payment_status?.trim().toLowerCase();
      let orderStatus = item.status?.trim().toLowerCase();
      if (
        paymentStatus === 'complete' ||
        orderStatus === NOT_DELIVERED ||
        orderStatus === DELIVERED ||
        orderStatus === REJECTED_BY_ADMIN ||
        orderStatus === ORDER_IS_REJECTED ||
        orderStatus === ORDER_NOT_ACCEPTED
      ) {
        show = false;
      }
    }

    if (show) {
      return (
        <Button
          variant="warning"
          className="bottom-fixed"
          onClick={() => {
            Mixpanel.track(actionConstants.PAYMENT);
            setIsChangePaymentMethod(true);
          }}
        >
          {!loading
            ? `Total Payable ₹${Number(totalPayableAmount).toFixed(2)}`
            : 'Loading'}
        </Button>
      );
    }
  };

  const renderCounter = () => {
    const isOnlinePaymentPending = ({ payment_method, payment_status }) => {
      if (payment_method === 'Razorpay') {
        if (payment_status === 'pending') return false;
      }
      return true;
    };

    let orderStatus = item.status?.trim().toLowerCase();
    if (orderStatus !== REJECTED_BY_ADMIN) {
      if (
        item.is_edit &&
        item.is_order_editable &&
        time &&
        isOnlinePaymentPending(item)
      ) {
        return (
          <Link
            to={`/home/edit-order/${order.data.order_id}`}
            style={{ textDecoration: 'none' }}
            className="edit-order-bottom"
          >
            <EditOrderCounter time={time} />
          </Link>
        );
      }
    }
    return '';
  };

  const renderBill = () => {
    if (!item?.bills) return <></>;
    return (
      <>
        {renderBillNumber()}
        <PaymentDetails className="payment-details">
          {item?.bills?.length === 1 && (
            <React.Fragment>
              <h6>Payment Details</h6>
              <Card className="card">
                <PaymentMethod className="payment-method">
                  <div className="method-div">
                    <h6>Payment status</h6>
                    {renderPaymentStatus()}
                  </div>
                  <div>
                    <h6 style={{ fontSize: '17px', color: 'grey' }}>
                      Payment Mode
                    </h6>
                    {renderPaymentMethod(item.bills[0])}
                  </div>
                  {item.bills[0].reward_spent ? (
                    <div className="method">
                      <div className="img-div">
                        <Image
                          className="payment_img"
                          src={coins}
                          alt="coins"
                          style={{ width: '25px' }}
                        />
                        <h5>Rewards</h5>
                      </div>
                      <p>{item.bills[0].reward_spent}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {renderDivider()}
                </PaymentMethod>
                {renderPaymentDetails()}
                {renderTotalAmount()}
              </Card>
              {renderGrandTotalAmount()}
            </React.Fragment>
          )}
        </PaymentDetails>
        {renderCarousal()}
      </>
    );
  };

  const renderGrandTotalAmount = () => {
    return (
      <Card className="edit_order_bill" style={{ marginTop: '1rem' }}>
        <PaymentDetailsListItem className="payment-details-list-item">
          <h5>Grand Total</h5>
          <h5>₹ {Number(grandTotalAmount).toFixed(2)}</h5>
        </PaymentDetailsListItem>
      </Card>
    );
  };

  return (
    <OrderDetailsStyled
      className={`order-details ${
        item.is_edit && item.is_order_editable && time ? 'alert-active' : ''
      }`}
    >
      <Intro className="order-details-intro">
        <div>
          <h5>Order {item.order_id}</h5>
          <time>{getReadableDate(item.order_created_at)}</time>
        </div>
        {item.show_need_help === true && (
          <p
            className="need-help"
            onClick={() => {
              if (item.enable_need_help === true) {
                if (profile.data.profile.user_type === 'individual') {
                  history.push(`/home/chat/${order.data.order_id}`);
                } else {
                  setIsRefundModalOpen(true);
                }
              } else {
                setIsSupportEnableOpen(true);
              }
            }}
          >
            Need Help?
          </p>
        )}

        {/* <p className="need-help" onClick={() => setIsCallbackModal(true)}>
          Request Callback
        </p> */}
      </Intro>
      <DeliveryStatus item={item} />
      {renderDelayMessage()}
      <DeliveryDetail className="delivery-detail">
        <h6>Delivery Detail</h6>
        <Card className="delivery-details">
          <div className="address_section">
            <h6 className="address">Address:</h6>
            <p className="address_name">{item.address}</p>
          </div>
          <DeliveryOption className="delivery-option">
            <h6>Delivery Option</h6>
            <span>{renderDeliveryType()}</span>
            {renderDriverName()}
          </DeliveryOption>
          <ScheduleDeliveryDetail className="schedule-detail">
            <span>{renderScheduleDelivery()}</span>
          </ScheduleDeliveryDetail>
          {renderDeliveryInstructions()}
        </Card>
      </DeliveryDetail>
      <OrderedItems item={item} />
      {renderBill()}
      {/* <Refunds className="refunds">{renderRefund()}</Refunds> */}
      {renderReview()}

      {renderPayOnline()}
      {renderCounter()}

      <SuccessModal
        isSuccess={isSuccess}
        message="Thanks! Successfully received your payment"
      />
      <FailureModal isFailure={isFailure} message="Payment Failed" />
      {renderPaymentSidebar()}
      {/* {isRefundModalOpen && ( */}
      <BusinessRefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />

      <ChatEnableModal
        isOpen={isSupportEnableModalOpen}
        onClose={() => setIsSupportEnableOpen(false)}
      />
      {/* )} */}

      {/* <CallbackRequestModal
        isOpen={isCallbackModal}
        onClose={() => setIsCallbackModal(false)}
      /> */}
      {/* <BottomSticky className="bottom-sticky">{renderReview()}</BottomSticky> */}
    </OrderDetailsStyled>
  );
}

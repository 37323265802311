/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * Card
 *
 */
import React from 'react';
import { Col } from 'react-bootstrap';
import { CounterUOM } from 'app/components/CounterUOM';
import { Card as CardStyled } from 'app/components/Card';
import { useDispatch, useSelector } from 'react-redux';

import { ReactImage } from 'app/components/ReactImage';
import { cartActions } from 'app/pages/Cart/slice';
import {
  selectCartItemByItemCode,
  selectCartItems,
  selectIsIndividualPackingEnable,
} from 'app/pages/Cart/slice/selectors';
import {
  Top,
  Bottom,
  Small,
  Limit,
  OutOfStock,
  OutOfStockTop,
  MoreOption,
} from './index.styled';
import SeasonalSVG from 'assets/seasonal.svg';
import { UOM } from '../UomModal';
import { ChangeDeliveryModal } from 'app/pages/MainPage/components/ChangeDeliveryModal';
import {
  selectDeliveryType,
  selectIsEnableInstantDelivery,
} from 'app/pages/MainPage/slice/selectors';
import { mainPageActions } from 'app/pages/MainPage/slice';
import { categoryActions } from 'app/pages/CategoryPage/slice';
import { localStorageActions } from 'app/pages/Cart/helpers/storage';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { AnalyticsConstants } from 'utils/constants';
import useAnalyticsEventTracker from 'utils/hooks/useAnalyticsEventTracker';
interface Props {
  item: any;
  onClick: Function;
  isFavourite: boolean;
}

export function ItemCard(props: Props) {
  const [isOpenUomModal, setIsOpenUomModal] = React.useState(false);
  const [
    openChangeDeliveryTypeModal,
    setOpenChangeDeliveryTypeModal,
  ] = React.useState(false);
  const [changedDeliveryType, setChangedDeliveryType] = React.useState(false);

  const { item, onClick } = props;
  const cartItem: any = useSelector(state =>
    selectCartItemByItemCode(state, item.item_code),
  );
  const currentDeliveryType = useSelector(selectDeliveryType);
  const deliveryTypeEnable = useSelector(selectIsEnableInstantDelivery);
  const isIndividualPackingEnable = useSelector(
    selectIsIndividualPackingEnable,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (item.is_disabled) {
      if (currentDeliveryType === 'instant') {
        setChangedDeliveryType(true);
        dispatch(
          mainPageActions.setEnableInstantDelivery({
            show_instant_delivery: true,
          }),
        );
      } else if (currentDeliveryType === 'app') {
        setChangedDeliveryType(false);
        dispatch(
          mainPageActions.setEnableInstantDelivery({
            show_instant_delivery: false,
          }),
        );
      }
    }
  }, []);

  const handleChangeDeliveryModalClose = () => {
    setOpenChangeDeliveryTypeModal(false);
    setChangedDeliveryType(deliveryTypeEnable);
  };

  const handleSelectEventType = (show_instant_delivery = false) => (
    event: any,
  ) => {
    // Close change delivery type
    setOpenChangeDeliveryTypeModal(false);

    if (!show_instant_delivery && isIndividualPackingEnable) {
      dispatch(cartActions.setIndividualPacking(false));
    }

    dispatch(
      mainPageActions.setEnableInstantDelivery({ show_instant_delivery }),
    );
    dispatch(categoryActions.clearActiveTab());
    // Loading getCategories
    dispatch(mainPageActions.getCategories());

    setChangedDeliveryType(show_instant_delivery);

    const currentCartItems = localStorageActions.getCartItem(true);
    dispatch(cartActions.addItemsToCart(currentCartItems));
    history.push('/home');
  };

  const onOpenUomModal = () => {
    setIsOpenUomModal(true);
  };
  const onCloseUomModal = () => {
    setIsOpenUomModal(false);
  };

  const eventTracker = useAnalyticsEventTracker(AnalyticsConstants.ADD_TO_CART);

  const renderButtons = () => {
    // If multiple uom is there
    if (item?.multiple_uoms?.length > 1 && !cartItem) {
      return (
        <button
          className="add_btn"
          onClick={(Mixpanel.track(actionConstants.OPEN_MODAL), onOpenUomModal)}
        >
          {item?.multiple_uoms.length > 1 && (
            <MoreOption>
              <p>More Options</p>
            </MoreOption>
          )}
          Add
        </button>
      );
    } else if (parseFloat(cartItem?.uom ? cartItem?.uom[0].qty : 0) === 0) {
      return (
        <button
          className="add_btn"
          onClick={() => {
            Mixpanel.track(actionConstants.ADDING_ITRM_CART);
            if (window.fbq) {
              window.fbq('track', 'AddToCart', {
                content_ids: item.id,
                content_type: 'product',
                value: parseFloat(item?.multiple_uoms[0].price),
                currency: 'INR',
              });
            }

            dispatch(
              cartActions.setCartItem({
                ...item,
                uom: [
                  {
                    id: item.multiple_uoms[0].uom_id,
                    variant_id: item.multiple_uoms[0].variant_id,
                    qty: item.multiple_uoms[0].minimum_quantity,
                    price: parseFloat(item.multiple_uoms[0].price),
                    availability: item.multiple_uoms[0].is_available || true,
                    visibility: item.multiple_uoms[0].is_visible || true,
                  },
                ],
              }),
            );
          }}
        >
          Add
        </button>
      );
    }
    let tempValue = 0;
    if (cartItem) {
      if (cartItem.uom.length > 1) {
        tempValue = cartItem.uom.reduce((acc, curr) => {
          return acc + parseFloat(curr.qty);
        }, 0);
      } else {
        tempValue = parseFloat(cartItem.uom[0].qty);
      }
    }
    // If multiple_uom data is one and one cart item added in the cart
    return (
      <CounterUOM
        isMultipleUomInCart={cartItem.uom.length > 1}
        isMultipleUomInAPI={item?.multiple_uoms.length > 1}
        value={tempValue}
        onOpenUomModal={onOpenUomModal}
        unit={item.multiple_uoms[0].unit}
        minValue={item.multiple_uoms[0].minimum_quantity}
        maxValue={item.multiple_uoms[0].maximum_quantity}
        onChange={val => {
          const unit: any = [...item.multiple_uoms][0]; // .sort((a, b) => a.uom_id - b.uom_id)
          dispatch(
            cartActions.setCartItem({
              ...item,
              uom: [
                {
                  price: parseFloat(unit.price),
                  variant_id: unit.variant_id,
                  id: unit.uom_id,
                  qty: val,
                  availability: unit.is_available || true,
                  visibility: unit.is_visible || true,
                },
              ],
            }),
          );
        }}
      />
    );
  };

  const renderConversationText = () => {
    if (item?.multiple_uoms.length !== 1)
      return <Small style={{ color: 'white' }}>MM</Small>;
    if (!!item.multiple_uoms[0].conversion_text)
      return <Small>{item.multiple_uoms[0].conversion_text}</Small>;

    return <Small style={{ color: 'white' }}>MM</Small>;
  };

  const renderOutOfStockItemCard = () => (
    <Col
      xs={6}
      sm={6}
      xl={4}
      as="li"
      onClick={() => {
        if (item.is_disabled) {
          setOpenChangeDeliveryTypeModal(true);
        }
      }}
    >
      <CardStyled className="card">
        <OutOfStockTop
          className="card-top"
          style={item.is_disabled ? { cursor: 'pointer' } : { opacity: '0.6' }}
        >
          {renderConversationText()}
          <ReactImage
            src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
            alt="product image"
          />
          <h5>{item.name}</h5>
        </OutOfStockTop>
        {item.is_disabled ? (
          <></>
        ) : (
          <Bottom className="card-bottom-out-of-stock">
            <OutOfStock className="out-of-stock-btn">
              <span>Out of Stock</span>
            </OutOfStock>
          </Bottom>
        )}
      </CardStyled>
    </Col>
  );

  const renderPriceUOMDetail = () => {
    if (cartItem) {
      let priceObj = item.multiple_uoms.find(
        e => e.uom_id === cartItem.uom[0].id,
      );
      if (priceObj) {
        return (
          <Limit onClick={onOpenUomModal}>
            <span>
              ₹ {parseFloat(priceObj.price)} /{priceObj.unit}
            </span>
          </Limit>
        );
      }
    }
    if (!Array.isArray(item.multiple_uoms)) return <React.Fragment />;
    const uom = [...item.multiple_uoms][0];
    return (
      <Limit onClick={onOpenUomModal}>
        <span>
          ₹ {parseFloat(uom.price)} /{uom.unit}
        </span>
      </Limit>
    );
  };
  const renderPriceUOM = () => {
    if (item?.multiple_uoms.length > 1) {
      return <>{renderPriceUOMDetail()}</>;
    }
    return <></>;
  };
  const renderItemCard = () => (
    <>
      <Col xs={6} sm={6} xl={4} as="li">
        <CardStyled className="card">
          {item.is_seasonal && (
            <img className="tag" src={SeasonalSVG} alt="seasonal" />
          )}
          <Top
            onClick={() => {
              onClick();
            }}
            className="card-top"
          >
            {renderConversationText()}
            <ReactImage
              src={`${process.env.REACT_APP_API_URI}/${item.logo}`}
              alt="product image"
            />
            <h5>{item.name}</h5>
            {renderPriceUOM()}
            {item?.multiple_uoms.length === 1 && (
              <Limit>
                <span>
                  ₹ {parseFloat(item.multiple_uoms[0].price)} /
                  {item.multiple_uoms[0].unit}
                </span>
              </Limit>
            )}
            {item.pcs_per_kg && (
              <Limit>
                <span
                  style={{
                    color: '#B7B7B7',
                  }}
                >
                  {item.pcs_per_kg}
                </span>
              </Limit>
            )}
          </Top>
          <Bottom className="card-bottom">{renderButtons()}</Bottom>
        </CardStyled>
      </Col>
      {isOpenUomModal && (
        <UOM isOpen={isOpenUomModal} onClose={onCloseUomModal} item={item} />
      )}
    </>
  );

  if (item?.multiple_uoms[0]?.is_available && item.availability)
    return renderItemCard();
  return (
    <>
      {renderOutOfStockItemCard()}
      {item.is_disabled && (
        <ChangeDeliveryModal
          openChangeDeliveryTypeModal={openChangeDeliveryTypeModal}
          changedDeliveryType={changedDeliveryType}
          handleChangeDeliveryModalClose={handleChangeDeliveryModalClose}
          setChangedDeliveryType={setChangedDeliveryType}
          setOpenChangeDeliveryTypeModal={setOpenChangeDeliveryTypeModal}
          handleSelectEventType={handleSelectEventType}
        />
      )}
    </>
  );

  // return <></>;
}

/**
 *
 * PageHeader
 *
 */
import * as React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import LogoSvg from 'assets/logo-white.svg';
import { Link, useLocation } from 'react-router-dom';
import { Login, Brand, Header } from './index.styled';

interface Props {}

export function PageHeader(props: Props) {
  const { pathname } = useLocation();
  return (
    <Header className="header">
      <Container className="d-flex align-items-center justify-content-between">
        <Brand href="/" className="brand">
          <img src={LogoSvg} alt="" />
        </Brand>
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link
                as={Link}
                to="/"
                className={`${pathname === '/' ? 'active' : ''}`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="about"
                className={`${pathname === '/about' ? 'active' : ''}`}
              >
                About us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/how-it-works"
                className={`${pathname === '/how-it-works' ? 'active' : ''}`}
              >
                How it works
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/coming-soon"
                className={`${pathname === '/login' ? 'active' : ''}`}
              >
                Blog
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Login
          as={Link}
          to="/login"
          className={`${pathname === '/login' ? 'active' : ''}`}
        >
          Login/Sign Up
        </Login>
      </Container>
    </Header>
  );
}

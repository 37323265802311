import styled from 'styled-components/macro';

const Refund = styled.div`
  text-align: center;
  .heading {
    font-size: 18px;
  }
  .details {
    font-size: 20px;
    font-weight: 600;
  }
  .btn {
    display: flex;
    justify-content: space-around;
    .cancel_btn {
      background: none;
      color: gray;
      border: none;
      font-size: 16px;
      font-weight: 600;
    }
    .ok_btn {
      background: none;
      color: #00855b;
      border: none;
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

export { Refund };

/**
 *
 * PickOfTheMonthSection
 *
 */
import { ReactImage } from 'app/components/ReactImage';
import React, { memo } from 'react';
import { Container, Button } from 'react-bootstrap';

import PickOfTheMonthSectionStyled, {
  Intro,
  PickMonth,
  Title,
  PickOfTheMonth,
  ImageInfo,
  EachProduct,
} from './index.styled';
interface Props {}

const Fruits = [
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/614c5ef0-f32c-4d0c-a219-8105192b60ee_large.jpg`,
    name: 'Orange Australian',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/8df95975-1e43-46e2-8b3d-1780bd22e3fd_large.jpg`,
    name: 'Green Apple',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads//restorants//f0493ce2-4b24-48fd-b332-fa0d19beaf9b_large.jpg`,
    name: 'Avocado',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads//restorants//91c6646c-e88f-4671-afc3-82c532ff7250_large.jpg`,
    name: 'Dragon Fruit',
  },
];

const Vegetables = [
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/4a9baa91-b669-4e2b-abc2-4f765ba088fb_large.jpg`,
    name: 'Zucchini Green',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/2192c159-25c6-4079-8583-5f3b1e630149_large.jpg`,
    name: 'Baby Corn Peeled',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/7e99e31e-93cb-42ea-b125-4a81a71df95b_large.jpg`,
    name: 'Bok Choy',
  },
  {
    image: `${process.env.REACT_APP_API_URI}/uploads/restorants/483a18ec-f959-4561-a06b-79b8422fac50_large.jpg`,
    name: 'Iceberg Lettuce',
  },
];

export const PickOfTheMonthSection = memo((props: Props) => {
  return (
    <PickOfTheMonthSectionStyled className="pick-of-the-month-section bg-grey">
      <Container>
        <Intro className="intro full-width">
          <h2>Fresh produce of the season</h2>
        </Intro>
        <PickMonth className="pick-month">
          <Title className="title">
            <h4>Give into this Sweet Emotion</h4>
          </Title>
          <PickOfTheMonth className="pick-of-the-month">
            {Fruits.map(fruit => (
              <EachProduct
                className="each-product"
                key={`fruits-${fruit.name}`}
              >
                <a href="/login">
                  <ReactImage src={fruit.image} alt={fruit.name} />
                  <ImageInfo className="image-info">
                    <h5>{fruit.name}</h5>
                    <Button variant="success" className="success btn-sm">
                      Add
                    </Button>
                  </ImageInfo>
                </a>
              </EachProduct>
            ))}
          </PickOfTheMonth>
        </PickMonth>
        <PickMonth className="pick-month">
          <Title className="title">
            <h4>Explore our range of exotic vegetables</h4>
          </Title>
          <PickOfTheMonth className="pick-of-the-month">
            {Vegetables.map(veg => (
              <EachProduct
                className="each-product"
                key={`vegetables-${veg.name}`}
              >
                <a href="/login">
                  <ReactImage src={veg.image} alt={veg.name} />
                  <ImageInfo className="image-info">
                    <h5>{veg.name}</h5>
                    <Button variant="success" className="success btn-sm">
                      Add
                    </Button>
                  </ImageInfo>
                </a>
              </EachProduct>
            ))}
          </PickOfTheMonth>
        </PickMonth>
      </Container>
    </PickOfTheMonthSectionStyled>
  );
});

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.address || initialState;

export const selectAddress = createSelector([selectSlice], state => state);

export const selectAddressList = createSelector(
  [selectSlice],
  state => state.addressList,
);

export const selectDefaultAddress = createSelector(
  [selectSlice],
  state => state.selectedAddress,
);

export const selectAddressLoading = createSelector(
  [selectSlice],
  state => state.addressList.loading,
);
export const selectAddAddress = createSelector(
  [selectSlice],
  state => state.addAddress,
);
export const selectEditAddress = createSelector(
  [selectSlice],
  state => state.editAddress,
);
export const selectLaunchAddressList = createSelector(
  [selectSlice],
  state => state.launchAddressList,
);

import styled from 'styled-components/macro';

const PaymentMethods = styled.div`
  padding-bottom: 1.5rem;
  .payment_method {
    margin-top: 1.5rem;
    .card {
      border: none;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      cursor: pointer;
      .payment {
        display: flex;
        .img {
          margin-left: 1rem;
        }
        .payment_text {
          margin-left: 1rem;
          margin-top: 1rem;
        }
        .card-overlay {
          border-radius: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          cursor: default;
          background-color: rgba(128, 128, 128, 0.3);
        }
      }
    }
    .pay_btn {
      background: #00855b;
      border: 1px solid #00855b;
    }
  }
`;

export { PaymentMethods };

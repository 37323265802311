/**
 *
 * MainPage
 *
 */
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Col, Row } from 'react-bootstrap';
import { Card } from 'app/components/Card';
import { selectCategories } from './slice/selectors';
import {
  HomePage,
  CategoriesStyled,
  Figure,
  LoadingWrapper,
  ErrorOccured,
} from './index.styled';
import { useSkipLoginMainPageSlice } from './slice';
import { Carousel } from './components/Carousel';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';

interface Props extends RouteComponentProps {}

export function SkipLoginMainPage(props: Props) {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const { actions } = useSkipLoginMainPageSlice();

  const history = useHistory();

  React.useEffect(() => {
    Mixpanel.track(pageConstants.SKIP_LOGIN_MAIN_PAGE);
  });

  React.useEffect(() => {
    dispatch(actions.getCategories());
  }, []);

  const renderCategories = () => {
    if (categories.loading) {
      return (
        <LoadingWrapper className="loading-wrapper">
          <Row as="ul">
            <Col xs={6} sm={6} xl={4} as="li">
              <div className="loading" />
            </Col>
            <Col xs={6} sm={6} xl={4} as="li">
              <div className="loading" />
            </Col>
            <Col xs={6} sm={6} xl={4} as="li">
              <div className="loading" />
            </Col>
          </Row>
        </LoadingWrapper>
      );
    }
    if (categories.error) {
      return (
        <ErrorOccured className="error-occured">Error occured</ErrorOccured>
      );
    }
    return categories.data.map(category => (
      <Col xs={6} sm={6} xl={4} as="li" key={category.id}>
        {category.upcoming ? (
          <Card className="card" style={{ cursor: 'default' }}>
            <Figure>
              <Image
                className="img-fluid"
                src={`${category.image}`}
                alt="category image"
              />
            </Figure>
            <h4 className="text-center">{category.name}</h4>
            {category.upcoming ? (
              <div className="coming-soon">
                <span>Coming Soon</span>
              </div>
            ) : (
              <></>
            )}
          </Card>
        ) : (
          <Card
            className="card"
            onClick={() => {
              Mixpanel.track(actionConstants.CATEGORY_ID);

              history.push({
                pathname: `/sl/category/${category.id}`,
                // search: `?uom_type=${category.uom_enabled}`,
              });
            }}
          >
            <Figure>
              <Image
                className="img-fluid"
                src={`${category.image}`}
                alt="category image"
              />
            </Figure>
            <h4 className="text-center">{category.name}</h4>
          </Card>
        )}
      </Col>
    ));
  };

  return (
    <HomePage className="home-page">
      <h3>Welcome</h3>
      <Carousel />
      <CategoriesStyled className="explore-categoryies">
        <h3>Explore from our categories</h3>
        <Row as="ul">{renderCategories()}</Row>
      </CategoriesStyled>
    </HomePage>
  );
}

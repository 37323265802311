/**
 *
 * Callback modal
 *
 */

// CSS Style
import { Refund } from './index.styled';

// React Bootstrap
import { Button, Modal } from 'react-bootstrap';

// Prop Types Define
interface Props {
  isOpen: boolean;
  onClose: () => void;
  //   proceedToCheckout: () => void;
}

export function BusinessRefundModal(props: Props) {
  const { isOpen, onClose } = props;
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      dialogClassName="modal-width-max_content"
    >
      <Modal.Body>
        <Refund>
          <h4 className="heading">Please Call Our Customer Support </h4>

          <p className="details">8344621039</p>

          <div className="btn">
            <Button
              className="ok_btn"
              onClick={async () => {
                onClose();
              }}
            >
              OK
            </Button>
          </div>
        </Refund>
      </Modal.Body>
    </Modal>
  );
}

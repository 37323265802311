/**
 *
 * PrivacyPolicy
 *
 */
import React, { memo } from 'react';
import PrivacyPolicyStyled from './index.styled';

interface Props {}

export const PrivacyPolicy = memo((props: Props) => {
  return (
    <PrivacyPolicyStyled>
      <h6>Privacy Policy</h6>
      <div className="privacy-policy">
        <p>
          This privacy notice for Tech7 Phyll Private Limited (doing business as
          Madras Mandi) (Madras Mandi', 'we', 'us', or 'our',), describes how
          and why we might collect, store, use, and/or share ('process') your
          information when you use our services ('Services'), such as when you:
          Visit our website at https://madrasmandi.in or any website of ours
          that links to this privacy notice • Engage with us in other related
          ways, including any sales, marketing, or events Questions or concerns?
          Reading this privacy notice will help you understand your privacy
          rights and choices. If you disagree with our policies and practices,
          please do not use our Services.
        </p>
      </div>
      <div className="content">
        <h6>Personal information you disclose to us </h6>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and services, when you
          participate in activities on the Services, or otherwise when you
          contact us. The personal information that we collect depends on the
          context of your interactions with us and the services, the choices you
          make, and the products and features you use. The personal information
          we collect may include the following: phone numbers, email addresses,
          passwords, billing addresses, and debit/credit card numbers. We do not
          process sensitive information. How do we process your information? We
          process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with the law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. In what situations and with which
          types of parties do we share personal information? We may share
          information in specific situations and with specific categories of
          third parties.
        </p>
      </div>
      <div className="payment-data">
        <h6>Payment Data</h6>
        <p>
          We may collect data necessary to process your payment if you make
          purchases, such as your payment instrument number (such as a credit
          card number), and the security code associated with your payment
          instrument. All payment data is stored by Razor Pay. You may find
          their privacy notice link(s) here: https://razorpay.com/privacy/. All
          personal information that you provide to us must be true, complete,
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
      </div>
      <div className="information">
        <h6>Information automatically collected</h6>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser, and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
      </div>
      <div className="information">
        <h6>Your information safety</h6>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, the transmission of personal information to and
          from our Services is at your own risk. You should only access the
          Services within a secure environment.{' '}
          <b>
            If you have questions or comments about your privacy rights, you may
            email us at support@madrasmandi.in.
          </b>
        </p>
      </div>
    </PrivacyPolicyStyled>
  );
});

/**
 *
 * WhyMadrasMandiSection
 *
 */
import React, { memo } from 'react';
import { Container, Image, Row, Col, Card } from 'react-bootstrap';
import FarmerImage from 'assets/farmer.svg';
import TruckImage from 'assets/truck.svg';
import Step1Image from 'assets/arrow1.svg';
import Step2Image from 'assets/arrow2.svg';
import BgCircle from 'assets/bg-circle.svg';
import ProductImage from 'assets/products.svg';
import StoreIcon from 'assets/store.svg';
import OrderIcon from 'assets/order.svg';
import TeamIcon from 'assets/team.svg';

import WhyMadrasMandiSectionStyled, {
  ImageWrapper,
  ImageController,
  Figure,
  Details,
} from './index.styled';

interface Props {}

const StoreItem = [
  {
    name: '1 ,00,000+',
    details: 'Completed Orders',
    icon: OrderIcon,
  },
  {
    name: '1 ,00,000+',
    details: 'Satisfied Clients',
    icon: TeamIcon,
  },
  {
    name: 'Fresh produce supply to',
    details: '5000+ Businesses',
    icon: StoreIcon,
  },
];

export const WhyMadrasMandiSection = memo((props: Props) => {
  return (
    <WhyMadrasMandiSectionStyled className="why-madras-mandi">
      <Container>
        <h2 className="centered">
          Why<b>Madras Mandi?</b>
        </h2>
        <Image className="bg-image" src={BgCircle} />
        <ImageWrapper className="image-wrapper">
          <ImageController className="product">
            <Image className="product" src={ProductImage} alt="ProductImage" />
            <span>
              Choose from Over <b>200 + Products</b>
            </span>
            <Image
              className="steps step1"
              src={Step1Image}
              alt="ProductImage"
            />
          </ImageController>
          <ImageController className="truck">
            <Image className="truck" src={TruckImage} alt="TruckImage" />
            <span>
              Get <b>Free Delivery</b> for order Above ₹300
            </span>
            <Image
              className="steps step2"
              src={Step2Image}
              alt="ProductImage"
            />
          </ImageController>
          <ImageController className="farmer">
            <Image className="farmer" src={FarmerImage} alt="FarmerImage" />
            <span>
              Backed by <b>60 Years of experience</b>
            </span>
          </ImageController>
        </ImageWrapper>
        <Row>
          {StoreItem.map(s => (
            <Col sm={12} md={6} lg={4} key={`store-${s.name}`}>
              <Card>
                <Figure>
                  <Image src={s.icon} alt="Store Icon" />
                </Figure>
                <Details className="details">
                  <h5>{s.name}</h5>
                  <span>{s.details}</span>
                </Details>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </WhyMadrasMandiSectionStyled>
  );
});

import styled from 'styled-components/macro';

const ChangeDeliveryType = styled.div`
  display: flex;
  flex-direction: column;
  .card {
    &.card {
      padding: 1.5rem 1.5rem 0;
      .heading {
        margin-bottom: 8px;
        img {
          height: 20px;
        }
      }
      .timeslot {
        margin-top: 1.5rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        flex: 100%;
        @media screen and (max-width: 575px) {
          margin-top: 12px;
          margin-left: -12px;
          margin-right: -12px;
        }
      }
      &.isActiveNext {
        padding: 1.5rem;
        @media screen and (max-width: 575px) {
          padding: 12px;
        }
      }
      @media screen and (max-width: 575px) {
        padding: 12px 12px 0;
      }
    }
  }
  .gray {
    color: gray !important;
    cursor: default !important;
    .heading > img {
      filter: grayscale(1) !important;
    }
    p {
      color: gray !important;
    }
    .timeslot {
      background: rgba(245, 245, 245, 0.7) !important;
    }
  }

  .isActiveInstant {
    border: 3px solid #F09949 !important;
  }
  .isActiveNext {
    border: 3px solid #00855B !important;
  }

  .card {
    padding: 2rem;
    margin-top: 1.5rem;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    input {
      position: absolute;
      right: 1rem;
      &[type=checkbox] {
        accent-color: #00855B;
        width: 2rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }

    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;

      .img {
        height: 25px;
      }
      h5 {
        font-size: 20px;
        font-weight: 600;
        margin: 0 auto 0 1rem;
      }
    }

    .content {
      font-size: 15px;
      color: #000;
      p {
        margin-bottom: 0;
        overflow-wrap: break-word;
      }
    }
    .timeslot {
      background: rgba(255, 247, 214, 0.7);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0.5rem 1.5rem;
      .timeslot_text {
        margin: auto;
        font-size: 14px;
        color: black;
        font-weight: 500;
      }
    }
    
  }

  .subheading {
    color: #000;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #22292e;
  }
  .change {
    cursor: pointer;
    display: flex;
    color: #00855B;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;a
  }
  .change_button {
    display: flex;
    justify-content: space-evenly;
  }
  .cancel {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #8e8e8e;
    cursor: pointer;
  }
  .change_heading {
    margin: 2rem auto 1rem auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      margin: auto;
      width: 1.3rem;
    }
    .change_details {
      margin: auto auto auto 1rem;
      .change_line {
        margin-top: 1rem;
        color: gray;
      }
      .change_name {
        margin: 1rem auto 0.5rem 0rem;
      }
    }
  }
  .title {
    align-item: center;
    justify-content: center;
    margin: auto;
  }
  label {
    cursor: pointer;
  }
  .timing {
    color: #00855B;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
    h6 {
      font-size: 1rem;
      margin: auto 1rem;
    }
    p {
      font-size: 0.8rem;
      margin: auto 1rem;
      timing: {
        color: #00855B;
      }
    }
  }
`;

export { ChangeDeliveryType };

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { skipLoginMainPageSaga } from './saga';
import { SkipLoginMainPageState } from './types';

export const initialState: SkipLoginMainPageState = {
  categories: {
    data: [],
    loading: true,
    error: false,
  },
  show_instant_delivery:
    localStorage.getItem('Delivery-Type') === 'instant' ? true : false,
  deliveryType: localStorage.getItem('Delivery-Type') || 'app',
};

const slice = createSlice({
  name: 'skipLoginMainPage',
  initialState,
  reducers: {
    getCategories(state) {
      state.categories.data = [];
      state.categories.loading = true;
      state.categories.error = null;
    },
    getCategoriesSuccess(state, action: PayloadAction<any>) {
      state.categories.data = action.payload;
      state.categories.loading = false;
    },
    getCategoriesError(state, action: PayloadAction<any>) {
      state.categories.error = action.payload;
      state.categories.loading = false;
    },
    setEnableInstantDelivery(state, action: PayloadAction<any>) {
      state.show_instant_delivery = action.payload.show_instant_delivery;
      // Set DeliveryType in local storage
      const deliveryType = action.payload.show_instant_delivery
        ? 'instant'
        : 'app';

      // Updating state
      state.deliveryType = deliveryType;

      // Updating local storage
      localStorage.setItem('Delivery-Type', deliveryType);
    },
  },
});

export const { actions: skipLoginMainPageActions } = slice;

export const useSkipLoginMainPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: skipLoginMainPageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSkipLoginMainPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

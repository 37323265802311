import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.cart || initialState;

export const selectCart = createSelector([selectSlice], state => state);

export const selectCartItems = createSelector([selectSlice], state => {
  return state.cartItems;
});
export const selectCartState = createSelector(
  [selectSlice],
  state => state.cart,
);
export const selectStateVariablesState = createSelector(
  [selectSlice],
  state => state.stateVariables,
);

export const selectSubTotal = createSelector([selectSlice], state => {
  // todo: filter out the data for the unavailable item
  if (state?.cartItems.length > 0)
    return state?.cartItems.reduce((acc: number, curr: any) => {
      let uomTotalPrice = 0;

      // Calculating price of all the uom in the cart
      if (curr.uom && curr?.uom.length > 0) {
        uomTotalPrice = curr?.uom.reduce((previousValue, currentValue) => {
          return (
            previousValue +
            parseFloat(currentValue.price) * parseFloat(currentValue.qty)
          );
        }, 0);
      }

      return acc + uomTotalPrice;
    }, 0);

  return 0;
});

export const selectSubTotalForDiscount = createSelector(
  [selectSlice],
  state => {
    // todo: filter out the data for the unavailable item
    if (state?.cart.data?.data && state?.cart.data?.data.length > 0)
      return state?.cart?.data?.data.reduce((acc: number, curr: any) => {
        let uomTotalPrice = 0;

        // Calculating price of all the multiple_uoms in the cart
        if (curr.multiple_uoms && curr?.multiple_uoms.length > 0) {
          uomTotalPrice = curr?.multiple_uoms.reduce(
            (previousValue, currentValue) => {
              if (currentValue.is_not_discounted) return previousValue;
              return (
                previousValue +
                parseFloat(currentValue.price) *
                  parseFloat(currentValue.cart_quantity)
              );
            },
            0,
          );
        }

        return acc + uomTotalPrice;
      }, 0);

    return 0;
  },
);

export const selectCartItemByItemCode: any = createSelector(
  selectCartItems,
  (_: any, item_code: any) => item_code,
  (state: any, item_code) => {
    return state.find(e => e.item_code === item_code) || null;
  },
);

export const selectCheckOut = createSelector(
  [selectSlice],
  state => state.checkOut,
);

export const selectCoupon = createSelector(
  [selectSlice],
  state => state.coupon,
);

export const selectCoupons = createSelector(
  [selectSlice],
  state => state.coupons,
);
export const selectPaymentMethod = createSelector(
  [selectSlice],
  state => state.paymentMethod,
);

export const selectDeliveryFee = createSelector([selectSlice], state => {
  let deliveryFee = 0;
  if (state.deliveryType.instantDelivery && !state.deliveryType.timeSlot) {
    deliveryFee += parseInt(state.checkOut.data.immediate_delivery_fee || 0);
  } else if (
    !state.deliveryType.instantDelivery &&
    state.deliveryType.timeSlot
  ) {
    deliveryFee += parseInt(state.checkOut.data.delivery_fee || 0);
  }
  return deliveryFee;
});

export const selectTimeSlotDiscount = createSelector(
  [selectSlice, selectSubTotal, selectDeliveryFee],
  (state, subTotal, deliveryFee) => {
    let totalDiscount = 0;
    if (state.coupon.success.message) {
      totalDiscount = 0;
    }
    if (state.checkOut.loading) {
      totalDiscount = 0;
    }
    let value = subTotal + deliveryFee + parseInt(state.checkOut.data.tax);

    if (
      !!state.deliveryType.timeSlot &&
      state.checkOut.data &&
      state.checkOut.data?.data
    ) {
      const selectedTimeSlot = state.checkOut.data?.data.find(
        i => Number(i.id) === Number(state.deliveryType.timeSlot),
      );
      if (!!selectedTimeSlot) {
        if (
          selectedTimeSlot.discount_type === 'percentage' &&
          !isNaN(selectedTimeSlot.discount_value) &&
          selectedTimeSlot.discount_value > 0 &&
          !isNaN(selectedTimeSlot.maximum_discount)
        ) {
          const discountValue =
            (value * Number(selectedTimeSlot.discount_value)) / 100;
          if (discountValue < selectedTimeSlot.maximum_discount)
            totalDiscount += Number(discountValue.toFixed(2));
          else totalDiscount += selectedTimeSlot.maximum_discount;
        } else if (
          selectedTimeSlot.discount_type === 'fixed' &&
          !isNaN(selectedTimeSlot.discount_value) &&
          selectedTimeSlot.discount_value > 0
        ) {
          totalDiscount += Number(selectedTimeSlot.discount_value.toFixed(2));
        }
      }
    }

    return totalDiscount;
  },
);

export const selectIsIndividualPackingEnable = createSelector(
  [selectSlice],
  state => state.individualPacking.isEnable,
);
export const selectTimeslotDeliveryType = createSelector(
  [selectSlice],
  state => state.deliveryType,
);

export const selectDiscount = createSelector(
  [selectSlice, selectSubTotalForDiscount, selectDeliveryFee],
  (state, subTotal, deliveryFee) => {
    let totalDiscount = 0;
    if (state.coupon.success.message) {
      totalDiscount = 0;
    }
    if (state.checkOut.loading) {
      totalDiscount = 0;
    }
    let value = subTotal + deliveryFee + parseInt(state.checkOut.data.tax);

    if (state.coupon.success.discount_type === 'percentage') {
      const discountValue = (value * state.coupon.success.discount) / 100;
      if (discountValue < state.coupon.success.max_cart_value)
        totalDiscount = Number(discountValue.toFixed(2));
      else totalDiscount = state.coupon.success.max_cart_value;
    } else if (state.coupon.success.discount_type === 'fixed') {
      totalDiscount = Number(state?.coupon?.success?.discount.toFixed(2));
    }
    return totalDiscount;
  },
);

export const selectTotal = createSelector(
  [
    selectSlice,
    selectSubTotal,
    selectDiscount,
    selectTimeSlotDiscount,
    selectIsIndividualPackingEnable,
    selectDeliveryFee,
  ],
  (
    state,
    subTotal,
    discount,
    timeSlotDiscount,
    isIndividualPackingEnable,
    deliveryFee,
  ) => {
    const totalDiscount = discount + timeSlotDiscount;

    let total =
      subTotal +
      // calculation.getRoundOff(Number(subTotal)) +
      deliveryFee +
      parseInt(state.checkOut.data.tax || 0) -
      totalDiscount;
    if (
      isIndividualPackingEnable &&
      localStorage.getItem('Delivery-Type') === 'instant'
    )
      total +=
        state.cartItems.length *
        parseInt(state.checkOut.data.individual_package_price || 0);
    return total;
  },
);

export const selectIsMMCoinEnable = createSelector(
  [selectSlice],
  state => state.isMMCoinEnable,
);

export const selectRetryPaymentMethod = createSelector(
  [selectSlice],
  state => state.retryPaymentMethod,
);

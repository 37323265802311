import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { helpActions as actions } from '.';
import { maintenanceActions } from 'app/pages/Maintenance/slice';

function* sendMessage(action: PayloadAction<any>) {
  try {
    let requestURL;
    const formData = new FormData();
    if (action.payload.type === 'Feedback') {
      requestURL = `/api/v1/user/feedback`;
      formData.append('subject', action.payload.subject);
    } else {
      requestURL = `/api/v1/user/contact-us`;
      formData.append('subject', action.payload.type);
      formData.append('message', action.payload.message);
    }

    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
      body: formData,
    });
    yield put(actions.sendMessageSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.sendMessageError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.sendMessageError(err.response));
    } else {
      yield put(actions.sendMessageError(err));
    }
  }
}

function* getFeedback(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v1/user/feedback`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getFeedbackSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getFeedbackError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getFeedbackError(err.response));
    } else {
      yield put(actions.getFeedbackError(err.response));
    }
  }
}

function* getFaqs(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v1/user/faqs`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getFaqsSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getFaqsError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getFaqsError(err.response));
    } else {
      yield put(actions.getFaqsError(err.response));
    }
  }
}

export function* helpSaga() {
  yield takeLatest(actions.sendMessage.type, sendMessage);
  yield takeLatest(actions.getFaqs.type, getFaqs);
  yield takeLatest(actions.getFeedback.type, getFeedback);
}

import styled from 'styled-components/macro';

export const Home = styled.div`
  background-color: #fafafa;
  footer .container {
    padding-left: 0;
    padding-right: 0;
  }
  .full-width {
    margin-left: -12px;
    margin-right: -12px;
    flex: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .bg-grey {
    background-color: #f5f5f5;
  }
  .bg-brown {
    background-color: #f7dfc9;
  }
`;

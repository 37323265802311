/**
 *
 * Company
 *
 */
import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { selectSaveProfile } from '../../slice/selectors';
import { registerPageActions } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'app/pages/slice/selectors';

interface Props {
  type: string;
  onProceed: Function;
}

export function Company(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, onProceed } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const saveProfile = useSelector(selectSaveProfile);
  const userNameFormat = /^[\.a-zA-Z0-9!? ]*$/; //eslint-disable-line
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
  const gstinformat = /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/; //eslint-disable-line

  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (!!profile?.data?.name) {
      setValue('name', profile.data.name);
    }
    if (!!profile?.data?.email) {
      setValue('email', profile.data.email);
    }
  }, [profile]);

  useEffect(() => {
    if (saveProfile.success) {
      onProceed();
    }
  }, [saveProfile]);

  const onSubmit = async val => {
    try {
      dispatch(
        registerPageActions.saveProfile({
          name: `${val.name}`,
          email: val.email,
          user_type: type,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="company">
      <Form.Group className="user-name form-field">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          {...register('name', {
            required: true,
            pattern: userNameFormat,
          })}
          type="text"
          placeholder="User Name"
        />
        {errors.name && (
          <Form.Text className="required">Name is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="email">
        <Form.Label>Email ID</Form.Label>
        <Form.Control
          {...register('email', { required: false, pattern: mailformat })}
          type="email"
          placeholder="Your Email ID (Optional)"
        />
        {errors.email && (
          <Form.Text className="required">Invalid Email Id.</Form.Text>
        )}
      </Form.Group>
      {/* <Form.Group className="address">
        <Form.Label>Delivery Address</Form.Label>
        <AddressAutoComplete
          register={register}
          control={control}
          setValue={setValue}
        />
        {errors.addressVal && (
          <Form.Text className="required">Address is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Door No./Flat/Block Number</Form.Label>
        <Form.Control
          {...register('flat', { required: true })}
          type="text"
          placeholder="Door No./Flat/Block"
        />
        {errors.flat && (
          <Form.Text className="required">
            Door No./Flat/Block is required
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Street and Locality Area</Form.Label>
        <Form.Control
          {...register('locality', { required: true })}
          type="text"
          placeholder="Street and Locality Area"
        />
        {errors.locality && (
          <Form.Text className="required">
            Street and locality area is required.
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Landmark</Form.Label>
        <Form.Control
          {...register('landmark', { required: false })}
          type="text"
          placeholder="Landmark"
        />
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Address Name</Form.Label>
        <Form.Control
          {...register('address_name', { required: true })}
          type="text"
          placeholder="Ex: Home, Office"
        />
        {errors.name && (
          <Form.Text className="required">Address Name is required.</Form.Text>
        )}
      </Form.Group> */}
      <Form.Group className="gst-number">
        <Form.Label>GST Number</Form.Label>
        <Form.Control
          {...register('gst', { required: false, pattern: gstinformat })}
          type="text"
          placeholder="8AABCU9603R1ZM"
        />
        {errors.gst && (
          <Form.Text className="required">GST is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="terms">
        <Form.Check
          className="checkbox"
          type="checkbox"
          label={``}
          id={`terms-checkbox`}
          {...register('terms', { required: true })}
        />
        <a
          href="https://api.madrasmandi.in/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          className="terms-conditions"
        >
          I agree to terms & conditions
        </a>
      </Form.Group>
      <Button type="submit" variant="warning" disabled={saveProfile.loading}>
        Save
      </Button>
    </Form>
  );
}

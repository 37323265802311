import styled from 'styled-components/macro';

const HomePage = styled.div`
  flex-direction: column;
  h3 {
    font-weight: normal;
    font-size: 26px;
    margin-top: 2rem;
  }
  .product-list {
    .card {
      padding: 0;
      height: 100%;
      .counter {
        margin-left: auto;
      }
    }
  }
`;

const CategoriesStyled = styled.div`
  padding-bottom: 1.5rem;
  h3 {
    font-weight: normal;
    font-size: 26px;
    margin-top: 2rem;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-top: 24px;
      .card {
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .coming-soon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          background: #00855b;
          height: 45px;
          font-weight: 600;
          font-size: 16px;
          color: white;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
      h4 {
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 16px;
      }
      @media screen and (max-width: 575px) {
        h4 {
          font-size: 18px;
        }
      }
    }
  }
`;
const ErrorOccured = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;
const Figure = styled.figure`
  margin-bottom: 0;
  display: flex;
  img {
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    width: 100%;
    @media screen and (max-width: 575px) {
      max-width: 280px;
      height: initial;
    }
  }
`;
const LoadingWrapper = styled.div`
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    margin-top: 16px;
    li {
      margin-top: 24px;
      .loading {
        height: 270px;
      }
    }
  }
`;

export { HomePage, CategoriesStyled, Figure, LoadingWrapper, ErrorOccured };

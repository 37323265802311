import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useIsAuthenticatedHook from 'app/hooks/useIsAuthenticatedHook';
import LoadingGif from 'assets/mandi.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'app/pages/slice/selectors';
import { NotDeliverable } from '../NotDeliverable';
import { AwaitingConfirmation } from '../AwaitingConfirmation';
import { homePageActions } from 'app/pages/HomePage/slice';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import useCountLogger from 'app/hooks/useCountLogger';

const PrivateRoute = (props: any) => {
  const { logger } = useCountLogger(
    'src/app/components/PrivateRoute/index.tsx',
  );

  React.useEffect(() => {
    Mixpanel.track(componentConstants.PRIVATE_ROUTE);
  });
  const { ...rest } = props;

  const {
    state,
    checkIsAuthenticated,
    checkIsRegistered,
  } = useIsAuthenticatedHook();

  logger({ state });

  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    checkIsAuthenticated();
    checkIsRegistered();
  }, []);

  if (state.loading) {
    return (
      <div className="app-loader">
        <img src={LoadingGif} alt="loading..." />
      </div>
    );
  }

  if (!state.isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (profile.loading) {
    return (
      <div className="app-loader">
        <img src={LoadingGif} alt="loading..." />
      </div>
    );
  }
  if (
    profile?.data?.profile?.user_type === 'company' &&
    profile?.data?.status === 'pending'
  ) {
    return <AwaitingConfirmation />;
  }
  if (profile.data.status === 'pending') {
    dispatch(homePageActions.setShowAddress(true));
    return <NotDeliverable />;
  }
  return <Route {...rest} />;
};

export default PrivateRoute;

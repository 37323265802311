/**
 *
 * ItemPage
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LogoSvg from 'assets/logo.svg';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Thunder from 'assets/thunder.svg';
import watch from 'assets/truck_next_day.svg';
import { Card, Image, Spinner } from 'react-bootstrap';
import { cartActions } from '../Cart/slice';
import { mainPageActions } from '../MainPage/slice';
import { categoryActions } from '../CategoryPage/slice';
import {
  selectCartItems,
  selectIsIndividualPackingEnable,
} from '../Cart/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import location2 from 'assets/location2.svg';
import { useEffect, useMemo, useState } from 'react';
import { DeliveryOptionType } from './index.styled';
import { selectLaunchAddressList } from '../ManageAddress/slice/selectors';
import { addressActions } from '../ManageAddress/slice';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface MatchParams {
  id: string;
}

interface Props {
  type: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export function DeliveryTypeOption(props: Props) {
  const { type } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const launchAddressList = useSelector(selectLaunchAddressList);
  const cartItems = useSelector(selectCartItems);
  const isIndividualPackingEnable = useSelector(
    selectIsIndividualPackingEnable,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // dispatch(addressActions.getAddressList());
    setTimeout(() => {
      dispatch(addressActions.launchAddress());
    }, 1000);
  }, []);
  useEffect(() => {
    // dispatch(addressActions.getAddressList());
    if (!launchAddressList.loading) setLoading(false);
    else setLoading(true);
  }, [launchAddressList]);

  const defaultAddress = useMemo(() => {
    if (
      Object.keys(launchAddressList.data).length !== 0 &&
      !!launchAddressList.data.address &&
      launchAddressList.data.address.length > 0
    )
      return launchAddressList.data?.address.find(e => e.is_default);
    return {};
  }, [launchAddressList]);

  const handleSelectEventType = (show_instant_delivery = false) => (
    event: any,
  ) => {
    Mixpanel.track(actionConstants.DELIVERY_SELECT);

    if (!show_instant_delivery && isIndividualPackingEnable) {
      dispatch(cartActions.setIndividualPacking(false));
    }

    dispatch(
      mainPageActions.setEnableInstantDelivery({ show_instant_delivery }),
    );
    dispatch(categoryActions.clearActiveTab());
    // Loading getCategories
    dispatch(mainPageActions.getCategories());

    dispatch(cartActions.addItemsToCart(cartItems));
    history.push('/home');
  };

  const renderContent = () => {
    return (
      <div className="deliveryOption">
        <h4 className="main_heading">Delivery Type</h4>
        <div className="address_wrapper">
          <h6 className="delivery">
            <b>Delivering to:</b> {defaultAddress?.address_name}
          </h6>
          <div className="location">
            <img className="image" src={location2} alt="Location" />
            <p className="address">
              {defaultAddress?.address ? defaultAddress?.address : ' '}
            </p>
          </div>
        </div>

        <Card
          className={`instant_delivery 
          ${type === 'instant' ? 'active' : ''} 
          ${
            !defaultAddress?.enable_instant_delivery &&
            defaultAddress?.show_instant_delivery
              ? 'gray'
              : ''
          }
          `}
          onClick={event => {
            if (
              !defaultAddress?.enable_instant_delivery &&
              defaultAddress?.show_instant_delivery
            )
              return;
            handleSelectEventType(true)(event);
          }}
        >
          <div className="heading">
            <Image className="img" src={Thunder} alt="instant" />

            <p>{launchAddressList.data.instant_delivery_title}</p>
          </div>
          <div className="content">
            <p>
              Get
              <span> {launchAddressList.data.items_range_instant}+ </span>
              items delivered instantly{' '}
            </p>
          </div>
          <div className="timeslot">
            <p className="timeslot_text">
              {defaultAddress?.earliest_timeslot_instant}
            </p>
          </div>
        </Card>

        <Card
          className={`next_delivery ${type === 'next-day' ? 'active' : ''}`}
          onClick={event => {
            if (defaultAddress?.next_day_delivery_availability === false)
              return;
            handleSelectEventType()(event);
          }}
        >
          <div className="heading">
            <Image src={watch} className="next-img" alt="next-day" />
            <p>Next day Delivery</p>
          </div>

          <div className="content">
            <p>
              Get it{' '}
              <span>{launchAddressList.data.items_range_next_day}+ items</span>{' '}
              delivered nextday{' '}
            </p>
          </div>
          {!!defaultAddress?.earliest_timeslot ? (
            <div className="timeslot">
              <p className="timeslot_text">
                Earliest slot -{defaultAddress?.earliest_timeslot}
              </p>
            </div>
          ) : (
            ''
          )}
        </Card>
      </div>
    );
  };
  return (
    <DeliveryOptionType>
      <div className="authentication-page">
        <div className="box">
          {loading ? (
            <Spinner variant="success" animation="border" />
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </DeliveryOptionType>
  );
}

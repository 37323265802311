import React from 'react';

const useCountLogger = (name: string) => {
  const counter = React.useRef(0);

  const logger = (...rest: any) => {
    const consolePrefix = `[DEBUG]-[${name}]-[${new Date().toString()}]-[${++counter.current}]: `;

    console.log(consolePrefix, rest);
  };

  const errorLogger = (...rest: any) => {
    const consolePrefix = `[ERROR]-[${name}]-[${new Date().toString()}]-[${++counter.current}]: `;

    console.error(consolePrefix, rest);
  };

  return { logger, errorLogger };
};

export default useCountLogger;

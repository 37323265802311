/**
 *
 * TermsPage
 *
 */
import * as React from 'react';

import { PageHeader } from 'app/components/PageHeader';
import { PageFooter } from 'app/components/PageFooter';
import { Home } from 'app/components/Home';
import { Container } from 'react-bootstrap';
import { ShippingAndDelivery } from 'app/components/ShippingAndDelivery';
import { PolicyWrapper, PageWrapper } from './styled';
import { GetInTouch } from 'app/components/GetInTouch';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';

export function ShippingAndDeliveryPage() {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.SHIPPING_DELIVERY_PAGE);
  });
  return (
    <Home className="home">
      <PolicyWrapper>
        <PageHeader />
        <PageWrapper>
          <Container>
            <ShippingAndDelivery />
          </Container>
        </PageWrapper>
        <GetInTouch />
        <PageFooter />
      </PolicyWrapper>
    </Home>
  );
}

import styled from 'styled-components/macro';

const Forgotten = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 16px;
  background-color: #00855b;
  border-radius: 5px;
  margin-top: 16px;
  width: 100%;
`;
const Note = styled.div`
  display: flex;
  align-items: center;
`;
const Hint = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  h6 {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
    display: block;
  }
  span {
    text-decoration: underline;
  }
  * {
    color: white;
  }
  @media screen and (max-width: 575px) {
    margin-left: 6px;
  }
`;
const Timer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35px;
  height: 53px;
  img {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  time {
    color: white;
    font-weight: 600;
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: auto;
  }
`;

export { Forgotten, Note, Timer, Hint };

import styled from 'styled-components/macro';

const AccountType = styled.div`
  .btn {
    background-color: #00855b;
    border: none;
    cursor: pointer;
  }
  .active {
    background-color: #00855b !important;
    color: #f5f5f5 !important;
    .individual_img {
      filter: brightness(0) invert(1);
    }
    .business_img {
      filter: brightness(0) invert(1);
    }
  }

  .account_type {
    display: flex;
    margin-top: 1.5rem;
    border-radius: 10px;
    .individual_type {
      background-color: #f5f5f5;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      margin-left: 5.5rem;
      .individual_text {
        font-weight: 500;
        margin-bottom: -1rem;
        margin-left: 2rem;
      }
      .individual_detail {
        font-weight: 600;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
      .individual_img {
        margin: 2rem 4rem 1rem 4rem;
        width: 70px;
        height: 70px;
      }
    }
    .business_type {
      background-color: #f5f5f5;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      display: flex;
      flex-direction: column;
      margin-right: 4rem;
      margin-left: 1rem;
      .business_text {
        font-weight: 500;
        margin-bottom: -1rem;
        margin-left: 2rem;
      }
      .business_detail {
        font-weight: 600;
        margin-right: 3rem;
        margin-bottom: 1rem;
      }
      .business_img {
        margin: 2rem 4rem 1rem 4rem;
        width: 90px;
        height: 70px;
      }
    }
  }
`;

export { AccountType };

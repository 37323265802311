import styled from 'styled-components/macro';

const PickOfTheMonth = styled.ul`
  list-style-type: none;
  margin: 0 -12px;
  padding: 0;
  flex: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const PickOfTheMonthStyled = styled.section`
  list-style-type: none;
  margin: 0;
  padding: 6rem 0;
  flex: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 575px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const Intro = styled.div`
  position: relative;
  > * {
    max-width: 560px;
  }
  h4 {
    color: #acacac;
  }
  h2 {
    max-width: 1000px;
    font-weight: 800;
    letter-spacing: 0.04em;
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.75rem;
    }
  }
`;

const PickOfTheMonthSection = styled.section`
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media screen and (max-width: 991px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 575px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 100%;
  margin-left: -6px;
  margin-right: -6px;
  > * {
    margin-left: 6px;
    margin-right: 6px;
  }
  h4 {
    margin-bottom: 0;
    color: #00855b;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-left: auto;
      margin-top: 12px;
    }
  }
`;

const ImageInfo = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  overflow: hidden;
  > * {
    transition: all 0.25s ease-in-out 0s;
  }
  h5 {
    text-align: center;
    width: 100%;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transform: translateY(20px);
    line-height: 1.4;
    @media screen and (max-width: 991px) {
      transform: translateY(0);
    }
  }
  button {
    position: relative;
    left: 50%;
    transform: translate(-50%, 35px);
    opacity: 0;
    @media screen and (max-width: 991px) {
      opacity: 1;
      transform: translate(-50%, 10px);
    }
  }
`;

const EachProduct = styled.li`
  margin: 1.5rem 0 0;
  padding: 0 12px;
  max-width: 25%;
  width: 100%;
  a {
    box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    color: #232827;
    border-radius: 6px;
    overflow: auto;
    padding: 12px;
    &:hover {
      h5 {
        transform: translateY(0);
      }
      .image-info {
        button {
          opacity: 1;
          transform: translate(-50%, 10px);
        }
      }
    }
  }
  img {
    max-width: 140px;
    margin: auto;
  }
  @media screen and (max-width: 1199px) {
    max-width: calc(100% / 3);
  }

  @media screen and (max-width: 767px) {
    max-width: 50%;
  }
`;

const PickMonth = styled.div`
  margin-top: 1.5rem;
`;

export default PickOfTheMonthStyled;
export {
  Intro,
  PickOfTheMonth,
  PickOfTheMonthSection,
  Title,
  ImageInfo,
  EachProduct,
  PickMonth,
};

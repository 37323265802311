/**
 * Gets the auth header from localStorage.
 *
 * @return {object} Returns an object with header if logged in.
 */
export function header() {
  let user_token = localStorage.getItem('user_token');
  if (user_token) {
    return `Bearer ${user_token}`;
  }
  return '';
}

export function isAuthenticated() {
  return !!header();
}

/**
 * Gets the user profile from localStorage.
 *
 * @return {object} Returns an object with user profile if logged in.
 */
export function userProfile() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user_profile') || '{}');

  // Todo : Cleanup
  if (user === null && window.location.pathname !== '/') {
    localStorage.clear();
    window.location.href = '/';
  }

  user.name =
    user && user.first_name ? `${user.first_name} ${user.last_name}` : '';

  return user;
}

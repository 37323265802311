import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { localStorageActions } from '../helpers/storage';
import { cartSaga } from './saga';
import { CartState } from './types';

export const initialState: CartState = {
  cart: {
    data: {},
    loading: true,
    error: false,
  },
  cartItems:
    JSON.parse(localStorage.getItem('cartItem') || JSON.stringify([])) || [],
  checkOut: {
    data: {},
    loading: true,
    error: false,
  },
  coupon: {
    loading: false,
    success: false,
    error: false,
    code: '',
  },
  coupons: {
    data: [],
    loading: true,
    error: false,
  },
  individualPacking: {
    isEnable: false,
  },
  deliveryType: {
    timeSlot: null,
    instantDelivery: false,
  },
  paymentMethod: 'razorpay',
  isMMCoinEnable: false,
  retryPaymentMethod: {
    data: null,
    success: false,
    error: true,
  },
  stateVariables: {
    alternateNumber: '',
    enableAlternateNumberInput: false,
  },
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setStateVariables(
      state,
      action: PayloadAction<{
        key: keyof CartState['stateVariables'];
        value: string | boolean;
      }>,
    ) {
      const { key, value } = action.payload;
      const variables: any = { ...state.stateVariables };
      variables[key] = value;
      state.stateVariables = variables;
    },
    setTimeslotAndInstantDelivery(
      state,
      action: PayloadAction<{
        timeslot: string | number | null;
        instantDelivery: boolean;
      }>,
    ) {
      state.deliveryType.timeSlot = action.payload.timeslot;
      state.deliveryType.instantDelivery = action.payload.instantDelivery;
    },
    getCart(state) {
      state.cart.data = [];
      state.cart.loading = true;
      state.cart.error = null;
    },
    getCartSuccess(state, action: PayloadAction<any>) {
      state.cart.data = action.payload;
      state.cart.loading = false;

      const uom = multiUom => ({
        id: multiUom.uom_id,
        variant_id: multiUom.variant_id,
        price: parseFloat(multiUom.price),
        qty: multiUom.cart_quantity,
        availability: multiUom.is_available || true,
        visibility: multiUom.is_visible || true,
      });

      const newCartItems = action.payload.data.map(e => ({
        ...e,
        uom: e?.multiple_uoms.map(uom).filter(item => item.qty !== 0),
      }));

      state.cartItems = newCartItems;

      // setting cart item to the local storage
      localStorage.setItem('cartItem', JSON.stringify(newCartItems));
      localStorageActions.setCartItem(state.cartItems);
    },
    getCartError(state, action: PayloadAction<any>) {
      state.cart.error = action.payload;
      state.cart.loading = false;
    },
    getCartWithoutLoading(state) {
      state.cart.error = null;
    },
    getCartWithoutLoadingSuccess(state, action: PayloadAction<any>) {
      state.cart.data = action.payload;
      state.cart.error = null;
    },
    getCartWithoutLoadingError(state, action: PayloadAction<any>) {
      state.cart.error = action.payload;
    },
    setIndividualPacking(state, action: PayloadAction<any>) {
      state.individualPacking.isEnable = action.payload;
    },
    setCartItem(state, action: PayloadAction<any>) {
      const totalQty = action.payload.uom.reduce(
        (previousValue, currentValue) => {
          const totalQtyVal = previousValue + currentValue.qty;
          return totalQtyVal;
        },
        0,
      );
      if (totalQty !== 0) {
        const tempItems = state.cartItems.slice();
        let index = tempItems.findIndex(
          e => e.item_code === action.payload.item_code,
        );
        if (index !== -1) {
          tempItems[index] = action.payload;
          state.cartItems = tempItems;
          localStorage.setItem('cartItem', JSON.stringify(tempItems));
        } else {
          const newCartItem = [...state.cartItems, action.payload];
          state.cartItems = newCartItem;
          localStorage.setItem('cartItem', JSON.stringify(newCartItem));
        }
      } else {
        const newCartItem = state.cartItems.filter(
          e => e.item_code !== action.payload.item_code,
        );
        state.cartItems = newCartItem;
        localStorage.setItem('cartItem', JSON.stringify(newCartItem));
      }
      localStorageActions.setCartItem(state.cartItems);
    },
    updateCartItems(state) {},
    removeItem(state, action: PayloadAction<any>) {
      const newCartItem = state.cartItems.filter(
        e => e.item_code !== action.payload.item_code,
      );
      state.cartItems = newCartItem;

      // setting cart item to the local storage
      localStorage.setItem('cartItem', JSON.stringify(newCartItem));
      localStorageActions.setCartItem(state.cartItems);
    },
    setCart(state, action: PayloadAction<any>) {
      state.cart.data = action.payload;
      state.cart.loading = false;
    },
    repeatOrder(state, action: PayloadAction<any>) {
      state.cartItems = action.payload;

      // setting cart item to the local storage
      localStorage.setItem('cartItem', JSON.stringify(action.payload));
      localStorageActions.setCartItem(action.payload);
    },
    addItemsToCart(state, action: PayloadAction<any>) {
      state.cart.loading = true;
    },
    getCheckOut(state) {
      state.checkOut.loading = true;
      state.checkOut.error = null;
    },
    getCheckOutSuccess(state, action: PayloadAction<any>) {
      state.checkOut.data = action.payload;
      state.checkOut.loading = false;
    },
    getCheckOutError(state, action: PayloadAction<any>) {
      state.checkOut.error = action.payload;
      state.checkOut.loading = false;
    },
    getCoupons(state, action: PayloadAction<any>) {
      state.coupons.data = {};
      state.coupons.loading = true;
      state.coupons.error = null;
    },
    getCouponsSuccess(state, action: PayloadAction<any>) {
      state.coupons.data = action.payload;
      state.coupons.loading = false;
    },
    getCouponsError(state, action: PayloadAction<any>) {
      state.coupons.error = action.payload;
      state.coupons.loading = false;
    },
    validateCoupon(state, action: PayloadAction<any>) {
      state.coupon.loading = action.payload.loading;
      state.coupon.code = action.payload.code;
      state.coupon.error = null;
      state.coupon.success = false;
    },
    validateCouponSuccess(state, action: PayloadAction<any>) {
      state.coupon.loading = false;
      state.coupon.success = action.payload;
      state.coupon.code = '';
    },
    validateCouponError(state, action: PayloadAction<any>) {
      state.coupon.loading = false;
      state.coupon.error = action.payload;
      state.coupon.success = false;
      state.coupon.code = '';
    },
    resetCoupon(state) {
      state.coupon.loading = false;
      state.coupon.error = false;
      state.coupon.success = false;
    },
    setPaymentMethod(state, action: PayloadAction<any>) {
      state.paymentMethod = action.payload;
    },
    setIsMMCoinEnable(state, action: PayloadAction<any>) {
      state.isMMCoinEnable = action.payload;
    },
    resetPaymentMethod(state, action: PayloadAction<any>) {
      state.retryPaymentMethod.data = null;
      state.retryPaymentMethod.success = false;
      state.retryPaymentMethod.error = false;
    },
    resetPaymentMethodSuccess(state, action: PayloadAction<any>) {
      state.retryPaymentMethod.data = action.payload;
      state.retryPaymentMethod.success = true;
      state.retryPaymentMethod.error = false;
    },
    resetPaymentMethodError(state, action: PayloadAction<any>) {
      state.retryPaymentMethod.data = null;
      state.retryPaymentMethod.success = false;
      state.retryPaymentMethod.error = true;
    },
    resetResetPaymentMethod(state) {
      state.retryPaymentMethod.data = null;
      state.retryPaymentMethod.success = false;
      state.retryPaymentMethod.error = false;
    },
  },
});

export const { actions: cartActions } = slice;

export const useCartSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: cartSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * TermsPage
 *
 */
import * as React from 'react';

import { PageHeader } from 'app/components/PageHeader';
import { PageFooter } from 'app/components/PageFooter';
import { Home } from 'app/components/Home';
import { Container } from 'react-bootstrap';
import { Refund } from 'app/components/Refund';
import { PolicyWrapper, PageWrapper } from './styled';
import { GetInTouch } from 'app/components/GetInTouch';
import { useLocation } from 'react-router-dom';
import { TopHeader } from 'app/components/TopHeader';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function RefundsPage() {
  const query = useQuery();
  const viewType = query.get('view_type');

  if (viewType === 'app')
    return (
      <Home className="home">
        <PolicyWrapper>
          <TopHeader />
          <PageWrapper>
            <Container>
              <Refund />
            </Container>
          </PageWrapper>
        </PolicyWrapper>
      </Home>
    );

  return (
    <Home className="home">
      <PolicyWrapper>
        <PageHeader />
        <PageWrapper>
          <Container>
            <Refund />
          </Container>
        </PageWrapper>
        <GetInTouch />
        <PageFooter />
      </PolicyWrapper>
    </Home>
  );
}

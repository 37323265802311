/**
 *
 * SuccessModal
 *
 */
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';

import OrderlacedJSON from './assets/order_placed.json';

interface Props {
  isSuccess: boolean;
  message?: string;
}

export const SuccessModal = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.SUCCESS_MODAL);
  });
  const { isSuccess, message = 'Thanks for your order.' } = props;
  return (
    <Modal
      className="modal-style confirm-modal lottie-transition"
      show={isSuccess}
      centered
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: OrderlacedJSON,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={200}
      />
      <p>{message}</p>
    </Modal>
  );
});

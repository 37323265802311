import styled from 'styled-components/macro';
import VisitBg from 'assets/visit-bg.png';

const VisitOurExclusiveStyled = styled.div`
  padding: 6rem 0;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5) 55.85%,
      rgba(196, 196, 196, 0) 96.38%
    ),
    url(${VisitBg}) no-repeat center;
  background-size: cover;
  min-height: 650px;
  h2 {
    line-height: 1.5;
    color: white;
  }
  .slick-slider {
    margin: 2rem -10px 0;
  }
  @media screen and (max-width: 1399px) {
    min-height: 268px;
  }
  @media screen and (max-width: 1199px) {
    min-height: 650px;
  }
  @media screen and (max-width: 991px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 575px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const StoreCard = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 16px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h5 {
    font-size: 16px;
    color: #00855b;
    font-weight: 600;
  }
  a {
    margin-top: auto;
    color: #232827;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    height: 41px;
    border-radius: 50px;
    margin-top: 16px;
  }
  a,
  address {
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: 1399px) {
    min-height: 258px;
  }
  @media screen and (max-width: 1199px) {
    min-height: 240px;
  }
  @media screen and (max-width: 767px) {
    min-height: 258px;
  }
`;

const Tab = styled.li`
  cursor: pointer;
  padding: 4px 20px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 1rem;
  &:hover,
  &.active {
    background: #ffffff;
    span {
      color: black;
    }
  }
  span {
    color: white;
    font-weight: 600;
  }
`;

const NoStore = styled.div`
  h3 {
    margin-top: 2.5rem;
    margin-bottom: 0;
    color: white;
  }
`;

const Tabs = styled.ul`
  margin: 2.5rem -8px 0;
  padding: 0;
  display: flex;
  list-style-type: none;
`;

export default VisitOurExclusiveStyled;
export { Tabs, StoreCard, Tab, NoStore };

import { PayloadAction } from '@reduxjs/toolkit';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { itemActions as actions } from '.';

function* getItem(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v3/user/items/${action.payload}`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getItemSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getItemError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getItemError(err.response));
    } else {
      yield put(actions.getItemError(err));
    }
    yield put(actions.getItemError(true));
  }
}

export function* itemSaga() {
  yield takeLatest(actions.getItem.type, getItem);
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.skipLoginCategoryPage || initialState;

export const selectSubCategories = createSelector(
  [selectSlice],
  state => state.subCategories,
);

export const selectActiveTab = createSelector(
  [selectSlice],
  state => state.activeTab,
);

export const selectItems = createSelector([selectSlice], state => state.items);

export const selectActiveSortBy = createSelector(
  [selectSlice],
  state => state.activeSortBy,
);

export const selectFavourites = createSelector(
  [selectSlice],
  state => state.favourites,
);
export const selectType = createSelector([selectSlice], state => state.type);

export const selectCurrentCategoryId = createSelector(
  [selectSlice],
  state => state.currentCategoryId,
);

export const selectUomEnabled = createSelector(
  [selectSlice],
  state => state.uom_enabled,
);

/**
 *
 * Maintenance
 *
 */
import React, { memo } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { maintenanceActions } from './slice';
import { selectDisableOrder, selectRetrying } from './slice/selectors';
import TempClosed from 'assets/closed-temp.png';
import { CenterBlock, Figure } from './index.styled';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export const Maintenance = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.MAINTENANCE);
  });
  const disableOrder = useSelector(selectDisableOrder);
  const retrying = useSelector(selectRetrying);
  const dispatch = useDispatch();
  return (
    <Modal className="modal-custom" show={disableOrder} fullscreen={true}>
      <CenterBlock className="center-block">
        <Figure>
          <Image src={TempClosed} alt="" className="img-fluid" />
        </Figure>
        <h4 className="text-center">Thanks</h4>
        <h5 className="text-center">
          We are temporarily closed, please come back later.
        </h5>
        <Button
          disabled={retrying}
          variant="warning"
          onClick={() => {
            Mixpanel.track(actionConstants.MAINTENANCE);
            dispatch(maintenanceActions.retry());
          }}
        >
          {retrying ? 'Retrying' : 'Retry'}
        </Button>
      </CenterBlock>
    </Modal>
  );
});

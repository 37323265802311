/**
 *
 * Company
 *
 */
import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import { AddressAutoComplete } from 'app/components/AddressAutoComplete';
import { selectSaveProfile } from '../../slice/selectors';
import { registerPageActions } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from '../../index.styled';

interface Props extends RouteComponentProps {
  type: string;
}

export function Company(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, history } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm();
  const [title, setTitle] = useState('Ms');

  const handleTypeChange = (e: any) => {
    setTitle(e.target.value);
  };

  const dispatch = useDispatch();
  const saveProfile = useSelector(selectSaveProfile);
  const userNameFormat = /^[\.a-zA-Z0-9!? ]*$/; //eslint-disable-line
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
  const gstinformat = /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/; //eslint-disable-line

  useEffect(() => {
    if (saveProfile.success) {
      history.push('/home');
    }
  }, [saveProfile]);

  const onSubmit = async val => {
    try {
      let custom_address = val.flat + ', ' + val.locality;
      if (val.landmark !== '') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        custom_address = custom_address + ', Landmark: ' + val.landmark;
      }
      if (
        val.addressVal.address !== '' &&
        val.addressVal.lat !== 0 &&
        val.addressVal.lng !== 0 &&
        val.flat !== '' &&
        val.locality !== ''
      ) {
        dispatch(
          registerPageActions.saveProfile({
            name: `${title} ${val.name}`,
            email: val.email,
            user_type: type,
            address: val.addressVal.address,
            door_no: val.flat,
            locality: val.locality,
            landmark: val.landmark,
            latitude: val.addressVal.lat,
            longitude: val.addressVal.lng,
            address_name: val.address_name,
          }),
        );
      } else {
        setError('addressVal', {
          type: 'manual',
          message: 'Invalid Address',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addressTypeOptions = [
    {
      id: 1,
      type: 'Home',
      value: 'Home',
    },
    {
      id: 2,
      type: 'Office',
      value: 'Office',
    },
    {
      id: 3,
      type: 'Other',
      value: 'Other',
    },
  ];

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="company">
      <RadioGroup className="radio-group type-wrapper">
        <Form.Check
          className="radio"
          type="radio"
          value="Ms"
          label={`Ms`}
          id={`Ms`}
          checked={title === 'Ms'}
          onChange={handleTypeChange}
        />
        <Form.Check
          className="radio"
          type="radio"
          label={`Mr`}
          value="Mr"
          checked={title === 'Mr'}
          id={`Mr`}
          onChange={handleTypeChange}
        />
      </RadioGroup>
      <Form.Group className="user-name form-field">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          {...register('name', {
            required: true,
            pattern: userNameFormat,
          })}
          type="text"
          placeholder="User Name"
        />
        {errors.name && (
          <Form.Text className="required">Name is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="email">
        <Form.Label>Email ID</Form.Label>
        <Form.Control
          {...register('email', { required: false, pattern: mailformat })}
          type="email"
          placeholder="Your Email ID(Optional)"
        />
        {errors.email && (
          <Form.Text className="required">Invalid Email Id.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="address">
        <Form.Label>Delivery Address</Form.Label>
        <AddressAutoComplete
          register={register}
          control={control}
          setValue={setValue}
        />
        {errors.addressVal && (
          <Form.Text className="required">Address is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Door No./Flat/Block Number</Form.Label>
        <Form.Control
          {...register('flat', { required: true })}
          type="text"
          placeholder="Door No./Flat/Block"
        />
        {errors.flat && (
          <Form.Text className="required">
            Door No./Flat/Block is required
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Street and Locality Area</Form.Label>
        <Form.Control
          {...register('locality', { required: true })}
          type="text"
          placeholder="Street and Locality Area"
        />
        {errors.locality && (
          <Form.Text className="required">
            Street and locality area is required.
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Landmark</Form.Label>
        <Form.Control
          {...register('landmark', { required: false })}
          type="text"
          placeholder="Landmark"
        />
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Address Name</Form.Label>
        <RadioGroup className="radio-group">
          {addressTypeOptions.map(e => {
            return (
              <Form.Check
                key={`time-${e.id}`}
                type={'radio'}
                id={e.type}
                label={e.type}
                value={e.value}
                {...register('name', { required: true })}
              />
            );
          })}
        </RadioGroup>
        {errors.name && (
          <Form.Text className="required">Address Name is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="gst-number">
        <Form.Label>GST Number</Form.Label>
        <Form.Control
          {...register('gst', { required: false, pattern: gstinformat })}
          type="text"
          placeholder="8AABCU9603R1ZM"
        />
        {errors.gst && (
          <Form.Text className="required">GST is required.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="terms">
        <Form.Check
          className="checkbox"
          type="checkbox"
          label={``}
          id={`terms-checkbox`}
          {...register('terms', { required: true })}
        />
        <a
          href="https://api.madrasmandi.in/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          className="terms-conditions"
        >
          I agree to terms & conditions
        </a>
      </Form.Group>
      <Button type="submit" variant="warning" disabled={saveProfile.loading}>
        Save
      </Button>
    </Form>
  );
}

/**
 *
 * DownloadAppMobile
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Image, Button } from 'react-bootstrap';
import CloseDownload from 'assets/close-download.svg';
import BgDownloadMobile from 'assets/bg-download-mobile.svg';
import { isAndroid, isIOS } from 'react-device-detect';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {
  setShow: (show: boolean) => void;
}

export function DownloadAppMobile(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.DOWNLOAD_APP_MOBILE);
  });
  const { setShow } = props;
  const [open, setOpen] = useState(true);
  if (!open) {
    return <></>;
  }
  return (
    <DownloadAppMobileStyled className="header">
      <Button variant="link" className="close" onClick={() => setOpen(false)}>
        <Image src={CloseDownload} alt="close" className="img-fluid" />
      </Button>
      <h6>Download our exclusive app to order fruits & vegetables</h6>
      <Button
        variant="success"
        className="view"
        onClick={() => {
          Mixpanel.track(actionConstants.DOWNLOAD);
          if (isIOS) {
            window.open(
              'https://apps.apple.com/in/app/madras-mandi/id1584806709',
              '_blank',
            );
          } else if (isAndroid) {
            window.open(
              'https://play.google.com/store/apps/details?id=com.madrasmandi.user',
              '_blank',
            );
          } else {
            setShow(true);
          }
        }}
      >
        View
      </Button>
    </DownloadAppMobileStyled>
  );
}
const DownloadAppMobileStyled = styled.div`
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  height: 82px;
  padding: 12px;
  background: #ffffff;
  align-items: center;
  z-index: 2;
  justify-content: center;
  background-image: url(${BgDownloadMobile});
  background-size: cover;
  border-bottom: 3px solid #00855b;
  .view {
    padding: 6px 12px;
  }
  .close {
    img {
      width: 20px;
      height: 20px;
    }
  }
  h6 {
    color: #00855b;
    font-weight: 600;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    display: flex;
  }
`;

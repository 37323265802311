/**
 *
 * Cart
 *
 */
import React, { useMemo } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { CartItem } from './components/CartItem';
import Close from 'assets/close.svg';

import NoCart from 'assets/no-cart.svg';
import { selectCartItems } from 'app/pages/Cart/slice/selectors';
import { useHistory } from 'react-router-dom';
import { cartActions } from 'app/pages/Cart/slice';
import { homePageActions } from '../HomePage/slice';
import {
  CardItemList,
  SideSheetBody,
  CartEmpty,
  DeliveryOption,
  DeliveryType,
} from './index.styled';
import {
  SideSheetFooter,
  SideSheetHeader,
} from 'app/components/SideSheet/index.styled';
import { SideSheet } from 'app/components/SideSheet';

// PNG Image
import watch from 'assets/truck_next_day.svg';
import Thunder from 'assets/thunder.svg';
import { selectDeliveryType } from '../MainPage/slice/selectors';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';
import { selectLaunchAddressList } from '../ManageAddress/slice/selectors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface Props {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onlyNumbers(str): Boolean {
  return /^[0-9]+$/.test(str);
}

export function CartSL(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.SKIP_LOGIN_CART);
  });
  const cartItemsData = useSelector(selectCartItems);
  const launchAddressList = useSelector(selectLaunchAddressList);
  const deliveryType = useSelector(selectDeliveryType);
  const history = useHistory();
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = React.useState<any>([]);

  React.useEffect(() => {
    if (cartItemsData && Array.isArray(cartItemsData)) {
      setCartItems([...cartItemsData]);
    } else {
      setCartItems([]);
    }
  }, [cartItemsData]);

  const onClose = () => {
    dispatch(homePageActions.setShowCart(false));
    dispatch(cartActions.resetCoupon());
  };

  const checkIfItemUnavailable = useMemo(() => {
    const totalAvailableItem = cartItems.filter(
      item => item.availability === false,
    );

    if (totalAvailableItem.length > 0) {
      return true;
    }
    return false;
  }, [cartItems]);

  const renderCartItems = () => {
    if (!cartItems || (cartItems && cartItems.length === 0)) {
      return (
        <CardItemList className={`cart-empty-list`}>
          <CartEmpty className="cart-empty">
            <Image src={NoCart} alt="Cart Empty" />
            <h4>Your cart is empty!</h4>
            <p>Looks like you haven`t added anything to your cart yet</p>
            <Button
              variant="warning"
              onClick={() => {
                Mixpanel.track(actionConstants.GO_TO_SKIP_LOGIN);
                onClose();
                history.push('/sl');
              }}
            >
              Start Exploring
            </Button>
          </CartEmpty>
        </CardItemList>
      );
    }
    return (
      <CardItemList className={`cart-item-list`}>
        {cartItems.map(item => {
          return <CartItem item={item} key={item.id} />;
        })}
      </CardItemList>
    );
  };

  // const checkIfRemaining = useMemo(() => {
  //   return (cart.data.cart_minimum || 300) - subTotal > 0;
  // }, [subTotal, cart.data.cart_minimum]);

  // const renderRemaining = () => {
  //   if (checkIfRemaining) {
  //     return (
  //       <Worth className="worth">
  //         Add products worth{' '}
  //         {Number((cart.data.cart_minimum || 300) - subTotal).toFixed(2)} to
  //         checkout
  //       </Worth>
  //     );
  //   }
  //   return <span>Total : ₹{subTotal.toFixed(2)}</span>;
  // };

  const renderDeliveryType = () => {
    if (deliveryType === 'instant') {
      return (
        <DeliveryOption>
          <img src={Thunder} alt="" />
          <p className="logo_title">
            {launchAddressList.data.instant_delivery_title}
          </p>
        </DeliveryOption>
      );
    } else if (deliveryType === 'app') {
      return (
        <DeliveryOption>
          <img
            className="next_day_img"
            src={watch}
            alt=""
            style={{ width: '30px' }}
          />
          <p className="logo_title" style={{ marginTop: '1rem' }}>
            {' '}
            Next day delivery{' '}
          </p>
        </DeliveryOption>
      );
    }
  };

  const renderCheckoutButton = () => {
    return (
      <Button
        variant="warning"
        onClick={() => {
          Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN);

          onClose();
          history.push('/login?sl=0');
        }}
      >
        Login to proceed
      </Button>
    );
  };

  const renderContent = () => {
    return (
      <>
        <SideSheetHeader className="sidesheet-header">
          <h5>Cart ({cartItems.length} items)</h5>
          <button className="button-icon" onClick={() => onClose()}>
            <Image src={Close} alt="close" />
          </button>
        </SideSheetHeader>
        <SideSheetBody
          className={`sidesheet-body ${
            checkIfItemUnavailable ? 'out-of-stock out-of-stock-body' : ''
          }`}
        >
          {cartItems.length > 0 && (
            <DeliveryType>
              <Form.Group className="delivery-date form-group">
                <Form.Label>Delivery Option</Form.Label>
                {renderDeliveryType()}
              </Form.Group>
            </DeliveryType>
          )}
          {renderCartItems()}
        </SideSheetBody>
        {cartItems.length > 0 && (
          <SideSheetFooter
            className={`sidesheet-footer ${
              checkIfItemUnavailable ? 'out-of-stock out-of-stock-footer' : ''
            }`}
          >
            {/* {renderRemaining()} */}
            {renderCheckoutButton()}
          </SideSheetFooter>
        )}
      </>
    );
  };

  return (
    <SideSheet isOpen={true} onClose={onClose}>
      {renderContent()}
    </SideSheet>
  );
}

/**
 *
 * WhatsApp
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { Image } from 'react-bootstrap';
import WhatsappIcon from 'assets/whatsapp-fixed.svg';

export function WhatsApp() {
  return (
    <WhatsAppStyled className="list-item">
      <a
        href="https://api.whatsapp.com/send?phone=+918344621039"
        target="_blank"
        rel="noreferrer"
      >
        <Image src={WhatsappIcon} alt="WhatsApp" />
      </a>
    </WhatsAppStyled>
  );
}

const WhatsAppStyled = styled.div`
  position: fixed;
  left: 1.5rem;
  bottom: 3rem;
  z-index: 2;
  img {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 991px) {
    right: 1.5rem;
    left: initial;
  }
`;

import mixpanel from 'mixpanel-browser';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const token = process.env.REACT_APP_MIX_PANEL;
mixpanel.init('token');

const actions = {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: name => {
    mixpanel.track(name);
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;

import { call, put, takeLatest } from 'redux-saga/effects';
import { launchPageActions as actions } from '.';
import { header } from 'utils/auth';
import { request } from 'utils/request';

function* getCategories() {
  try {
    const requestURL = `/api/public/items`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    let categories = [
      {
        name: 'Give into this Sweet Emotion',
        items: response.data.slice(0, 10),
      },
      {
        name: 'Explore our range of exotic vegetables',
        items: response.data.slice(10, 20),
      },
    ];
    yield put(actions.getCategoriesSuccess(categories));
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.getCategoriesError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getCategoriesError(err.response));
    } else {
      yield put(actions.getCategoriesError(err));
    }
  }
}

export function* launchPageSaga() {
  yield takeLatest(actions.getCategories.type, getCategories);
}

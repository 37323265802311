/**
 *
 * PrivacyPolicy
 *
 */

interface Props {}

export function PrivacyPolicy(props: Props) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M5.67163 2.43449C4.65969 2.71338 3.65463 3.01664 2.65732 3.34399C2.56625 3.37349 2.4853 3.42798 2.42368 3.50124C2.36207 3.5745 2.32227 3.66359 2.30882 3.75837C1.72019 8.17518 3.08019 11.3977 4.70263 13.5206C5.38955 14.4284 6.20863 15.2281 7.13257 15.8932C7.50019 16.1524 7.82532 16.3394 8.08138 16.4595C8.20888 16.5201 8.31301 16.5604 8.39269 16.5849C8.42754 16.5971 8.46348 16.606 8.50001 16.6114C8.5361 16.6056 8.57165 16.5967 8.60626 16.5849C8.68701 16.5604 8.79113 16.5201 8.91863 16.4595C9.17363 16.3394 9.49982 16.1514 9.86744 15.8932C10.7914 15.2281 11.6104 14.4284 12.2974 13.5206C13.9198 11.3988 15.2798 8.17518 14.6912 3.75837C14.6779 3.66355 14.6381 3.5744 14.5765 3.50111C14.5148 3.42783 14.4338 3.37338 14.3427 3.34399C13.651 3.11768 12.4833 2.74899 11.3284 2.43555C10.149 2.11574 9.06419 1.8788 8.50001 1.8788C7.93688 1.8788 6.85101 2.11574 5.67163 2.43555V2.43449ZM5.38901 1.34012C6.54182 1.02668 7.76688 0.745117 8.50001 0.745117C9.23313 0.745117 10.4582 1.02668 11.611 1.34012C12.7904 1.65887 13.9793 2.03605 14.6784 2.26449C14.9707 2.36102 15.2299 2.53805 15.4261 2.77524C15.6223 3.01243 15.7477 3.30015 15.7877 3.60537C16.4209 8.36218 14.9515 11.8876 13.1686 14.2197C12.4126 15.2174 11.5111 16.096 10.4943 16.8261C10.1427 17.0787 9.77021 17.3009 9.38082 17.4901C9.08332 17.6304 8.76351 17.7451 8.50001 17.7451C8.23651 17.7451 7.91776 17.6304 7.61919 17.4901C7.2298 17.3009 6.85728 17.0787 6.50569 16.8261C5.48891 16.0959 4.58744 15.2174 3.83138 14.2197C2.04851 11.8876 0.579068 8.36218 1.21232 3.60537C1.25236 3.30015 1.37769 3.01243 1.5739 2.77524C1.77012 2.53805 2.02927 2.36102 2.32157 2.26449C3.33646 1.93173 4.35924 1.62351 5.38901 1.34012Z"
          className="fill-grey"
        />
        <path
          d="M11.5323 6.21271C11.5818 6.26206 11.621 6.32069 11.6478 6.38523C11.6746 6.44977 11.6884 6.51896 11.6884 6.58884C11.6884 6.65872 11.6746 6.72791 11.6478 6.79245C11.621 6.85699 11.5818 6.91562 11.5323 6.96497L8.34481 10.1525C8.29546 10.2019 8.23684 10.2412 8.1723 10.268C8.10776 10.2948 8.03857 10.3085 7.96869 10.3085C7.89881 10.3085 7.82962 10.2948 7.76508 10.268C7.70054 10.2412 7.64191 10.2019 7.59256 10.1525L5.99881 8.55872C5.94942 8.50932 5.91024 8.45068 5.88351 8.38615C5.85678 8.32161 5.84302 8.25244 5.84302 8.18259C5.84302 8.11274 5.85678 8.04357 5.88351 7.97903C5.91024 7.9145 5.94942 7.85586 5.99881 7.80646C6.04821 7.75707 6.10685 7.71789 6.17138 7.69116C6.23592 7.66443 6.30509 7.65067 6.37494 7.65067C6.44479 7.65067 6.51396 7.66443 6.5785 7.69116C6.64303 7.71789 6.70167 7.75707 6.75106 7.80646L7.96869 9.02515L10.7801 6.21271C10.8294 6.16324 10.888 6.12399 10.9526 6.09721C11.0171 6.07043 11.0863 6.05664 11.1562 6.05664C11.2261 6.05664 11.2953 6.07043 11.3598 6.09721C11.4243 6.12399 11.483 6.16324 11.5323 6.21271Z"
          className="fill-grey"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.745117)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

/**
 *
 * DownloadSection
 *
 */
import React, { memo } from 'react';

import { Container, Image } from 'react-bootstrap';

import AppStore from 'assets/app-store.png';
import PlayStore from 'assets/play-store.png';
import Image2 from 'assets/leaves1.png';
import Image3 from 'assets/leaves2.png';
import Image1 from 'assets/orange.png';
import PhoneImage from 'assets/mockup.png';
import DownloadSectionStyled, {
  DownloadAppStyled,
  ImageGroup,
} from './index.styled';

export const DownloadSection = memo(() => {
  return (
    <Container>
      <DownloadSectionStyled className="download-section">
        <Image src={Image1} alt="" className="image image1" />
        <Image src={Image2} alt="" className="image image2" />
        <Image src={Image3} alt="" className="image image3" />
        <DownloadAppStyled className="download-app">
          <h3>Download the App</h3>
          <p>Start filling up your cart by downloading the app</p>
          <ImageGroup className="image-group">
            <a href="https://play.google.com/store/apps/details?id=com.madrasmandi.user">
              <Image src={PlayStore} alt="play store" className="img-fluid" />
            </a>
            <a href="https://apps.apple.com/in/app/madras-mandi/id1584806709">
              <Image src={AppStore} alt="app store" className="img-fluid" />
            </a>
          </ImageGroup>
          <Image src={PhoneImage} alt="phone" className="image phone-image" />
        </DownloadAppStyled>
      </DownloadSectionStyled>
    </Container>
  );
});

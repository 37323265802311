import styled from 'styled-components/macro';

const Worth = styled.small`
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 8px;
`;
const FormStyled = styled.form`
  background-color: white;
  .form-label {
    margin-bottom: 0;
  }
`;

const CardItemList = styled.div`
  &.cart-empty-list {
    height: 100%;
    display: flex;
  }
`;

const SideSheetBody = styled.div`
  overflow: auto;
  height: calc(100vh - 60px - 80px);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (max-width: 575px) {
    padding-left: 12px;
    padding-right: 12px;
    &.out-of-stock-body {
      height: calc(100vh - 60px - 80px - 150px - 85px);
    }
    &.delivery-address-body {
      height: calc(100vh - 60px - 80px - 150px - 85px);
    }
  }
  &.out-of-stock-body {
    height: calc(100vh - 60px - 80px - 150px);
  }
  &.delivery-address-body {
    height: calc(100vh - 60px - 195px);
  }
`;

const CartEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 315px;
  width: 100%;
  margin: auto;
  > * {
    text-align: center;
  }
  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  button {
    margin-top: 1.5rem;
  }
`;

const DeliveryOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 133, 91, 0.1);
  padding: 1rem;
  border-radius: 5px;
  gap: 12px;
  .next_day_img {
    width: 30px;
  }
`;

const DeliveryType = styled.div`
  label {
    font-weight: 600;
    margin-top: 1rem;
  }
`;

const ItemOutOfStock = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  .containerDivOutOfStockWarning {
    display: flex;
  }
  .itemOutOfStock_img {
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .itemOutOfStock {
    margin-left: 1rem;
    .itemOutOfStock_name {
      font-size: 14px;
      margin-top: 1rem;
      font-weight: 600;
      color: #c83532;
    }

    .itemOutOfStock_details {
      color: #5c5c5c;
      font-size: 13px;
    }
  }
`;

const AddressLocation = styled.div`
  align-items: flex-start;
  p {
    margin-bottom: 0;
  }
  img {
    margin-right: 8px;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Address = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: column;
  border-radius: 5px;
  .address_location {
    display: flex;
  }
  .dropdown {
    display: flex;
    height: 40px !important;
    justify-content: flex-start;
    background-color: white;
    color: black;
    border: none;
  }
  .open-arrow {
    margin-bottom: 0.5rem;
    padding: 0.2rem;
    background-color: #e4e4e4;
    border-radius: 50%;
  }
  .rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .address_heading {
    margin-left: 1rem;
    font-weight: 600;
    font-size: 16px;
    color: #5c5c5c;
  }
  .address {
    font-size: 14px;
  }
`;

const Alternative = styled.div`
  align-items: center;
  .mobile {
    overflow: hidden;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 12px;
  }
  .number_form {
      display: flex;
      align-items: center;
      border: 1px solid #acacac;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      input.form-control {
        padding-right: 0;
      }
    }
  }
`;

const CheckoutFooterButton = styled.div`
  display: flex;
  height: 15px;
  width: 100%;
  .payment-container {
    margin-top: -0.8rem;
  }
  .payment-method-heading {
    font-size: 0.8rem;
    width: max-content;
  }
  .payment-option-container {
    display: flex;
    align-items: center;
    line-height: 0.2;
    .upi_img {
      margin-top: -1rem;
      margin-right: 0.2rem;
      height: 20px;
    }
  }
  .payment-method {
    width: max-content;
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }
  .change-payment-method {
    font-size: 0.6rem;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  .disable {
    color: gray;
  }

  .button-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const DropdownStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  .toggle_button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  .address_location {
  }
`;

export {
  Worth,
  FormStyled,
  CardItemList,
  SideSheetBody,
  CartEmpty,
  DeliveryOption,
  DeliveryType,
  ItemOutOfStock,
  Address,
  AddressLocation,
  Alternative,
  CheckoutFooterButton,
  DropdownStyle,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * ItemDetails
 *
 */
import * as React from 'react';
import { CounterUOM } from 'app/components/CounterUOM';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { selectItem } from '../slice/selectors';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'app/components/Card';
import { ReactImage } from 'app/components/ReactImage';
import { selectCartItemByItemCode } from 'app/pages/Cart/slice/selectors';
import { cartActions } from 'app/pages/Cart/slice';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { AnalyticsConstants } from 'utils/constants';
import useAnalyticsEventTracker from 'utils/hooks/useAnalyticsEventTracker';

interface Props {}

export function ItemDetails(props: Props) {
  const item = useSelector(selectItem);
  const dispatch = useDispatch();
  const cartItem: any = useSelector(state =>
    selectCartItemByItemCode(state, item.data.item_code),
  );

  const eventTracker = useAnalyticsEventTracker(AnalyticsConstants.ADD_TO_CART);

  if (item.loading) {
    return (
      <ItemDetailsStyled className="item-details">
        <Row>
          <Col sm={12} md={6}>
            <div className="loading" />
          </Col>
          <Col sm={12} md={6}>
            <div className="loading" />
          </Col>
        </Row>
      </ItemDetailsStyled>
    );
  }

  const getValueByUomId = (uomId: number) => {
    if (!cartItem || !cartItem.uom) return 0;

    const uomItem = cartItem.uom.find(uomType => uomType.id === uomId);

    if (!uomItem) return 0;
    return uomItem.qty;
  };

  const generateUomPayload = (uomPayload: any) => {
    if (!cartItem || !cartItem.uom) return [uomPayload];
    const restPayload = cartItem.uom.filter(item => item.id !== uomPayload.id);
    // If Qty is 0 then we will remove that uom from the array
    if (uomPayload.qty === 0) return [...restPayload];
    return [...restPayload, uomPayload];
  };

  const renderButtons = (multiUom, index) => {
    const enableAddButton = !parseInt(
      cartItem?.uom.find(item => item.id === multiUom.uom_id)?.qty || 0,
    );

    if (enableAddButton)
      return (
        <button
          className="add_btn"
          onClick={() => {
            Mixpanel.track(actionConstants.ADDING_ITRM_CART);
            if (window.fbq) {
              window.fbq('track', 'AddToCart', {
                content_ids: multiUom.uom_id,
                content_type: 'product',
                value: parseFloat(multiUom.price),
                currency: 'INR',
              });
            }

            dispatch(
              cartActions.setCartItem({
                ...item.data,
                uom: generateUomPayload({
                  id: multiUom.uom_id,
                  variant_id: multiUom.variant_id,
                  price: parseFloat(multiUom.price),
                  qty: multiUom.minimum_quantity,
                  availability: multiUom.is_available || true,
                  visibility: multiUom.is_visible || true,
                }),
              }),
            );
          }}
        >
          Add
        </button>
      );
    return (
      <CounterUOM
        value={getValueByUomId(multiUom.uom_id)}
        unit={multiUom.unit}
        minValue={multiUom.minimum_quantity}
        maxValue={multiUom.maximum_quantity}
        onChange={val => {
          dispatch(
            cartActions.setCartItem({
              ...item.data,
              uom: generateUomPayload({
                id: multiUom.uom_id,
                variant_id: multiUom.variant_id,
                price: parseFloat(multiUom.price),
                qty: val,
                availability: multiUom.is_available || true,
                visibility: multiUom.is_visible || true,
              }),
            }),
          );
        }}
      />
    );
  };

  const renderCard = (multiUom, index) => {
    if (multiUom.is_available) {
      return (
        <Card className="price_card">
          <div className="price_div">
            <div className="price">
              <div className="unit">
                <h5 className="kg">In {multiUom.unit}</h5>
                {multiUom.minimum_quantity > 1 ? (
                  <h6 className="max_min">
                    (MIN {multiUom.minimum_quantity} {multiUom.unit})
                  </h6>
                ) : (
                  <></>
                )}
              </div>
              <h6 className="rate">
                ₹ {multiUom.price}/{multiUom.unit}
              </h6>
            </div>
            <AddToCart className="add-card">
              <h6 className="text">{multiUom.conversion_text}</h6>
              {renderButtons(multiUom, index)}
            </AddToCart>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="price_card">
          <div className="price_div">
            <div className="price">
              <div className="unit">
                <h5 className="kg">In {multiUom.unit}</h5>
                {multiUom.minimum_quantity > 1 ? (
                  <h6 className="max_min">
                    (MIN {multiUom.minimum_quantity} {multiUom.unit})
                  </h6>
                ) : (
                  <></>
                )}
              </div>
              <h6 className="rate">
                ₹{multiUom.price}/{multiUom.unit}
              </h6>
            </div>
            <AddToCart className="add-card">
              <span>Out Of Stock</span>
            </AddToCart>
          </div>
        </Card>
      );
    }
  };

  return (
    <ItemDetailsStyled className="item-details">
      <Row>
        <ProductIntro>
          <h4>{item.data.name}</h4>
          {/* <p className="category-name">{item.data.category.name}</p> */}
        </ProductIntro>

        <div className="product-img">
          <ReactImage
            className="reduce-image-size img-fluid"
            src={
              item.data.logo.includes('https://')
                ? item.data.logo
                : `${process.env.REACT_APP_API_URI}/${item.data.logo}`
            }
            alt={item.data.name}
          />
        </div>
      </Row>
      <p className="pack">Choose Pack Sizes</p>
      {item.data.multiple_uoms.map(renderCard)}
      {item?.data?.extra_descriptions.length > 0 &&
        item?.data?.extra_descriptions.map(description => (
          <div className="detail_card" key={description?.title}>
            <h5>{description?.title}</h5>
            {description.description.split('\n').map(des => (
              <p className="description">{des}</p>
            ))}
          </div>
        ))}
    </ItemDetailsStyled>
  );
}

const ItemDetailsStyled = styled.div`
  .loading {
    height: 300px;
    margin-top: 1.5rem;
  }
  h5 {
    margin-bottom: 0;
  }
  h6 {
    margin-bottom: 0;
  }
  .product-image,
  .product-details {
    margin-top: 1.5rem;
    box-shadow: 1px 2px 2px 3px rgba(15, 15, 15, 0.2);
    &.card {
      @media screen and (max-width: 767px) {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
      }
    }
  }
  .pack {
    font-weight: 600;
    font-size: 20px;
    margin-top: 2rem;
    margin-left: 5rem;
  }
  .product-img {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reduce-image-size {
    max-width: 50%;
  }

  .price_card {
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 1px 2px 2px 3px rgba(15, 15, 15, 0.2);
    .unit {
      display: flex;
      align-items: center;

      gap: 8px;
      .max_min {
        color: #b1b1b2;
      }
    }
  }

  .price_div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      .rate {
        margin-right: 0.5rem;
      }
    }
  }
  .detail_card {
    h5 {
      margin-bottom: 1rem;
    }
    .description {
      font-size: 14px;
      color: gray;
    }
  }
`;
const AddToCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  .text {
    color: #acacac;
  }
  .add_btn {
    width: 120px;
    height: 40px;
    border: 2px solid #b1b1b2;
    border-radius: 5px;
    background-color: #ffffff;
    color: #00855b;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    .add_btn {
      width: 100px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 575px) {
    .add_btn {
      width: 90px;
      font-size: 14px;
    }
  }
`;
const ProductIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  flex: 100%;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
  h4 {
    font-weight: 500;
    font-size: 35px;
    margin-bottom: 0;
  }
  .category-name {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
  }
`;

import { current, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { categorySaga } from './saga';
import { CategoryState } from './types';

export const initialState: CategoryState = {
  subCategories: {
    data: [],
    loading: true,
    error: false,
  },
  activeTab: null,
  activeSortBy: '',
  type: false,
  uom_enabled: false,
  items: [],
  favourites: [],
  currentCategoryId: null,
};

const sort = (activeSortBy: string, data: any) => {
  let tempData = data.slice();
  if (activeSortBy === 'name_asc') {
    tempData = tempData.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  } else if (activeSortBy === 'most_ordered') {
    return tempData;
  }
  return tempData;
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getSubCategories(state, action) {
      state.subCategories.data = [];
      state.subCategories.loading = true;
      state.subCategories.error = null;
      state.items = [];
    },
    getSubCategoriesSuccess(state, action: PayloadAction<any>) {
      state.subCategories.data = action.payload;
      if (action.payload.length > 0) {
        if (!state.activeTab) {
          state.activeTab = action.payload[0];
          let tab = action.payload[0];
          state.items = sort(state.activeSortBy, tab.items);
        } else {
          const activeTabData = action.payload?.find(
            e => e.id === current(state.activeTab).id,
          );
          state.activeTab = activeTabData;
          let tab = activeTabData;
          state.items = sort(state.activeSortBy, tab.items);
        }
      }
      state.subCategories.loading = false;
    },
    getSubCategoriesError(state, action: PayloadAction<any>) {
      state.subCategories.error = action.payload;
      state.subCategories.loading = false;
    },
    setActiveSort(state, action: PayloadAction<any>) {
      state.activeSortBy = action.payload;
      state.items = sort(action.payload, state.items);
    },
    setActiveTab(state, action: PayloadAction<any>) {
      state.activeTab = action.payload;

      let tab = state.subCategories.data.find(
        (e: any) => Number(e.id) === action.payload.id,
      );
      state.items = sort(state.activeSortBy, tab.items);
    },
    setFavourites(state, action: PayloadAction<any>) {
      state.favourites = action.payload;
    },
    updateFavourites(state, action: PayloadAction<any>) {},
    setType(state, action: PayloadAction<any>) {
      state.type = action.payload;
    },
    setUomEnabled(state, action: PayloadAction<any>) {
      state.uom_enabled = action.payload;
    },
    clearActiveTab(state) {
      state.activeTab = null;
    },
    setCurrentCategoryId(state, action: PayloadAction<any>) {
      state.currentCategoryId = action.payload;
    },
  },
});

export const { actions: categoryActions } = slice;

export const useCategorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: categorySaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCategorySlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * CardList
 *
 */
import React, { useState } from 'react';
import { ItemCard } from './components/ItemCard';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NoFavourities from 'assets/no-favourities.svg';
import {
  selectFavourites,
  selectSubCategories,
  selectItems,
  selectActiveTab,
} from '../../slice/selectors';
import {
  Empty,
  Error,
  LoadingWrapper,
  ProductList,
  EmptyWrapper,
} from './index.styled';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export function CardList(props: Props) {
  const items: any = useSelector(selectItems);
  const favourites: any = useSelector(selectFavourites);
  const tabs: any = useSelector(selectSubCategories);
  const activeTab: any = useSelector(selectActiveTab);
  const history = useHistory();
  const [noDataInFavourites, setNoDataInFavourites] = useState(false);

  const renderList = () => {
    if (
      (items.length === 0 && !!activeTab && activeTab.name === 'Favourites') ||
      noDataInFavourites
    ) {
      return (
        <Empty className="favourities-empty">
          <Image src={NoFavourities} alt="Favourities Empty" />
          <h4>No Favourites Yet</h4>
          <p>To add favourite, tap on heart button below the product.</p>
          <Button
            variant="warning"
            onClick={() => {
              Mixpanel.track(actionConstants.GO_BACK_TO_HOME);

              history.push('/home');
            }}
          >
            Go Home
          </Button>
        </Empty>
      );
    }
    if (items.length === 0) {
      return (
        <EmptyWrapper className="empty-wrapper">
          <Empty className="item-empty">
            <Image src={NoFavourities} alt="Favourities Empty" />
            <h4>This category is very popular!</h4>
            <p>
              We're working to replenish our stock and will have more items
              available soon. Stay tuned for updates!
            </p>
          </Empty>
        </EmptyWrapper>
      );
    }
    let itemsToBeMapped = [...items];
    if (!!activeTab && activeTab.name === 'Favourites') {
      itemsToBeMapped = itemsToBeMapped.filter((i: any) => {
        const isFavourites = favourites.some(
          (e: any) => e.item_code === i.item_code,
        );
        return isFavourites;
      });
      if (itemsToBeMapped.length === 0) setNoDataInFavourites(true);
    }
    return itemsToBeMapped.map((item: any) => {
      return (
        <ItemCard
          key={item.id}
          item={item}
          isFavourite={
            favourites.findIndex(favourite => favourite.id === item.id) !== -1
          }
          onClick={() => {
            Mixpanel.track(actionConstants.ITEM_CARD);

            if (item.availability) history.push(`/sl/item/${item.id}`);
          }}
        />
      );
    });
  };

  if (tabs.loading) {
    return (
      <LoadingWrapper className="loading-wrapper">
        <Row as="ul">
          <Col xs={12} sm={6} xl={4} as="li">
            <div className="loading" />
          </Col>
          <Col xs={12} sm={6} xl={4} as="li">
            <div className="loading" />
          </Col>
          <Col xs={12} sm={6} xl={4} as="li">
            <div className="loading" />
          </Col>
        </Row>
      </LoadingWrapper>
    );
  }
  if (tabs.error) {
    return <Error className="error">Error</Error>;
  }
  return (
    <ProductList className="product-list">
      <Row as="ul">{renderList()}</Row>
    </ProductList>
  );
}

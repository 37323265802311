/**
 *
 * NotDeliverable
 *
 */
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Container, Card, Button, Image } from 'react-bootstrap';
import NotDeliverableImage from 'assets/not-deliverable.jpg';
import { homePageActions } from 'app/pages/HomePage/slice';
import { useDispatch } from 'react-redux';
import { addressActions } from 'app/pages/ManageAddress/slice';
import { ManageAddress } from 'app/pages/ManageAddress';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export const NotDeliverable = memo((props: Props) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.NOT_DELIVERABLE);
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addressActions.getAddressList());
  }, []);

  return (
    <NotDeliverableStyled className="not-deliverable">
      <Container>
        <Card className="card">
          <Illustration>
            <Image src={NotDeliverableImage} alt="" />
          </Illustration>
          <p>
            Thank you for your interest, we will be delivering to this area soon
          </p>
          <p>Currently, We are delivering in chennai only</p>
          <Manage
            onClick={() => {
              Mixpanel.track(actionConstants.DISPATCH_API);

              dispatch(homePageActions.setShowAddress(true));
            }}
          >
            <p className="manage">Manage Address</p>
          </Manage>
          <Button
            onClick={() => {
              Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN);
              localStorage.removeItem('user_token');
              window.location.reload();
            }}
            className="w-100 btn btn-warning"
          >
            Go back to login
          </Button>
        </Card>
      </Container>
      <ManageAddress />
    </NotDeliverableStyled>
  );
});

const Illustration = styled.figure`
  img {
    max-width: 380px;
  }
`;
const NotDeliverableStyled = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .container {
    background-color: #fafafa;
    border-radius: 1.5rem;
    min-height: calc(100vh - 3rem);
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      max-width: 420px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
      border-radius: 5px;
      border: 0;
      p {
        margin: 0;
      }
      button {
        margin-top: 1.5rem;
      }
    }
  }
`;

const Manage = styled.div`
  margin-top: 1rem;
  font-weight: 700;
  cursor: pointer;
`;

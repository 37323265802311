/**
 *
 * GetInTouch
 *
 */
import * as React from 'react';
import { Col, Image, Row, Container } from 'react-bootstrap';
import Logo from 'assets/logo-white.svg';
import FacebookIcon from 'assets/facebook.svg';
import LinkedInIcon from 'assets/linkedin.svg';
import InstagramIcon from 'assets/instagram.svg';
import ContactUs from 'assets/contact-us.svg';
import Location from 'assets/location.svg';
import Mail from 'assets/mail.svg';
import WhatsappIcon from 'assets/whatsapp-fixed.svg';

import {
  BrandLogo,
  ContactDetails,
  SocialListItem,
  SocialList,
  ListItem,
  List,
  ExploreProducts,
  OurLinks,
  FollowUs,
  GetInTouchSection,
} from './index.styled';

interface Props {}

export function GetInTouch(props: Props) {
  return (
    <GetInTouchSection className="get-in-touch">
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <ContactDetails className="contact-details">
              <BrandLogo to="/">
                <Image src={Logo} alt="logo" className="img-fluid" />
              </BrandLogo>
              <p>Tech7 Phyll Private Limited</p>
              <address>
                <Image src={Location} alt="location" />
                <span>
                  2nd floor, 838, 7th main road, 2nd stage, Indiranagar,
                  Bengaluru, Bengaluru Urban, Karnataka, 560038
                </span>
              </address>
              {/* <address>
                <Image src={Location} alt="location" />
                <span>
                  No. 14, Reddy Street, Nerkundram, Chennai, Tamil Nadu, 600107.
                </span>
              </address> */}
              <a className="phone" href="tel:04446276129">
                <Image src={ContactUs} alt="phone" />
                <span>04446276129</span>
              </a>
              <a className="phone" href="mailto:support@madrasmandi.in">
                <Image src={Mail} alt="mail" />
                <span>support@madrasmandi.in</span>
              </a>
              <a className="phone" href="tel:9611229845">
                <Image src={WhatsappIcon} alt="mail" />
                <span>9611229845</span>
              </a>
            </ContactDetails>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <ExploreProducts className="explore-products">
              <h5>Explore products</h5>
              <List className="list">
                <ListItem className="list-item">
                  <a href="https://madrasmandi.in/about-us">About us</a>
                </ListItem>
                <ListItem className="list-item">
                  <a href="https://madrasmandi.in/how-it-works">
                    How it works?
                  </a>
                </ListItem>
                {/* <ListItem className="list-item">
                  <a href="https://madrasmandi.in/help-and-faqs/?view_type=app#wallets">
                    FAQs
                  </a>
                </ListItem> */}
              </List>
            </ExploreProducts>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <OurLinks className="our-links">
              <h5>Our Links</h5>
              <List className="list">
                <ListItem className="list-item">
                  <a href="/contact-us">Contact Us</a>
                </ListItem>
                <ListItem className="list-item">
                  <a href="/careers">Careers</a>
                </ListItem>
                <ListItem className="list-item">
                  <a href="/our-clients">Our Clientele</a>
                </ListItem>
              </List>
            </OurLinks>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <FollowUs className="follow-us">
              <h5>Follow us on</h5>
              <SocialList className="social-list">
                <SocialListItem className="social-list-item">
                  <a href="https://www.linkedin.com/company/77159402/admin/">
                    <Image
                      src={LinkedInIcon}
                      alt="LinkedInIcon"
                      className="img-fluid"
                    />
                  </a>
                </SocialListItem>
                <SocialListItem className="social-list-item">
                  <a href="https://www.facebook.com/pages/category/Fruit---Vegetable-Store/Madras-Mandi-103741588175662/">
                    <Image
                      src={FacebookIcon}
                      alt="FacebookIcon"
                      className="img-fluid"
                    />
                  </a>
                </SocialListItem>
                <SocialListItem className="social-list-item">
                  <a href="https://instagram.com/madrasmandi?utm_medium=copy_link">
                    <Image
                      src={InstagramIcon}
                      alt="InstagramIcon"
                      className="img-fluid"
                    />
                  </a>
                </SocialListItem>
              </SocialList>
            </FollowUs>
          </Col>
        </Row>
      </Container>
    </GetInTouchSection>
  );
}

/**
 *
 * AwaitingConfirmation
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Container, Card, Button, Image } from 'react-bootstrap';
import NotDeliverableImage from 'assets/not-deliverable.jpg';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {}

export const AwaitingConfirmation = memo((props: Props) => {
  return (
    <NotDeliverableStyled className="not-deliverable">
      <Container>
        <Card className="card">
          <Illustration>
            <Image src={NotDeliverableImage} alt="" />
          </Illustration>
          <h5>Awaiting Confirmation</h5>
          <p>To verify please contact 04446276129 or support@madrasmandi.in</p>
          <Button
            onClick={() => {
              Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN);
              localStorage.removeItem('user_token');
              window.location.reload();
            }}
            className="w-100 btn btn-warning"
          >
            Go back to login
          </Button>
        </Card>
      </Container>
    </NotDeliverableStyled>
  );
});

const Illustration = styled.figure`
  img {
    max-width: 380px;
  }
`;
const NotDeliverableStyled = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .container {
    background-color: #fafafa;
    border-radius: 1.5rem;
    min-height: calc(100vh - 3rem);
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      max-width: 420px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
      border-radius: 5px;
      border: 0;
      p {
        margin: 0;
      }
      button {
        margin-top: 1.5rem;
      }
    }
  }
`;

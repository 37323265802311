import styled from 'styled-components/macro';

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  padding: 0 16px 16px;
  .add_btn {
    width: 120px;
    height: 40px;
    border: 2px solid #b1b1b2;
    border-radius: 5px;
    background-color: #ffffff;
    color: #00855b;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    position: relative;
  }
  @media screen and (max-width: 991px) {
    .add_btn {
      width: 100px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 575px) {
    .add_btn {
      width: 90px;
      font-size: 14px;
    }
  }
`;
const Top = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  h5 {
    margin-bottom: 0;
    &:not(:empty) {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 8px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  > img {
    max-width: 160px;
    height: 120px;
    /* object-fit: cover; */
    margin: 8px auto 0;
  }

  .uom_tag {
    display: flex;
    justify-content: space-around;
    border-radius: 15px;
    margin: 0.5rem 0 0.5rem 0;
    .uom_name {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      margin: 0.5rem auto 0.5rem 0.5rem;
      width: 70%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .arrow {
      color: #8a8a8e;
      width: 15px;
      margin: 0.5rem 0.5rem 0.5rem auto;
    }
  }
  @media screen and (max-width: 991px) {
    > img {
      max-width: 140px;
      height: 100px;
      /* object-fit: cover; */
      margin: 8px auto 0;
    }
    h5 {
      font-size: 15px;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 575px) {
    > img {
      max-width: 120px;
      height: 80px;
      /* object-fit: cover; */
      margin: 8px auto 0;
    }
    h5 {
      font-size: 13px;
      margin-top: 2rem;
    }
  }
`;

const OutOfStockTop = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  h5 {
    margin-bottom: 0;
    &:not(:empty) {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 8px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  > img {
    max-width: 160px;
    height: 120px;
    /* object-fit: cover; */
    margin: 8px auto 0;
  }
  @media screen and (max-width: 991px) {
    > img {
      max-width: 140px;
      height: 100px;
      /* object-fit: cover; */
      margin: 8px auto 0;
    }
    h5 {
      font-size: 15px;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 575px) {
    > img {
      max-width: 120px;
      height: 80px;
      /* object-fit: cover; */
      margin: 8px auto 0;
    }
    h5 {
      font-size: 13px;
      margin-top: 1.5rem;
    }
  }
`;

const Limit = styled.div`
  span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #484848;
  }
`;

const Small = styled.small`
  color: #b1b1b2;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
  @media screen and (max-width: 575px) {
    font-size: 10px;
    margin-bottom: 0.5rem;
  }
`;

const OutOfStock = styled.div`
  background-color: #ffffff;
  display: flex;
  border: 3px solid #8e8e8e;
  border-radius: 5px;
  text-align: center;
  span {
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
    width: 120px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #8e8e8e;
  }
`;

const MoreOption = styled.div`
  position: absolute;
  font-size: 11px;
  width: 120px;
  left: 0;
  top: -11px;
  p {
    color: gray;
    background-color: #ffffff;
    width: max-content;
    margin: 0.1rem auto;
    padding: 0 0.1rem 0 0.1rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 10px;
    width: 100px;
  }
  @media screen and (max-width: 575px) {
    font-size: 9px;
    width: 90px;
  }
`;

export { MoreOption, Top, Bottom, Small, Limit, OutOfStock, OutOfStockTop };

import styled from 'styled-components/macro';

const Worth = styled.small`
  text-align: center;
  padding: 0.4rem;
  color: #fff;
  font-size: 16px;
  background: #ff9900;
  margin-bottom: 1px;
  width: 100%;
`;
const FormStyled = styled.form`
  background-color: white;
  .form-label {
    margin-bottom: 0;
  }
`;

const CartBanner = styled.div`
  .banner {
    width: 100%;
    margin-top: 2rem;
  }
`;

const CardItemList = styled.div`
  &.cart-empty-list {
    min-height: calc(100vh - 100px);
    height: 100%;
    display: flex;
  }
`;

const SideSheetBody = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #f2f2f2;
  @media screen and (max-width: 575px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const CheckOutFooter = styled.div`
  margin-top: auto;
  position: sticky;
  flex-shrink: 0;
  padding: 10px 1.5rem;
  bottom: 0;
  background-color: white;
  z-index: 1;
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .total_footer {
      .total_items {
        color: gray;
        font-size: 14px;
      }
      .total {
        font-weight: 550;
      }
    }
    .checkout_btn {
      background: #00855b;
      font-size: 16px;
    }
  }
`;

const CartEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 315px;
  width: 100%;
  margin: auto;
  > * {
    text-align: center;
  }
  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  button {
    margin-top: 1.5rem;
  }
`;

const DeliveryOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 133, 91, 0.1);
  padding: 1rem;
  border-radius: 5px;
  gap: 12px;
  p {
    margin-bottom: 0;
  }
  .next_day_img {
    width: 30px;
  }
`;

const DeliveryType = styled.div`
  label {
    font-weight: 600;
    margin-top: 1rem;
  }
`;

const ItemOutOfStock = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  .containerDivOutOfStockWarning {
    display: flex;
  }
  .itemOutOfStock_img {
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .itemOutOfStock {
    margin-left: 1rem;
    .itemOutOfStock_name {
      font-size: 14px;
      margin-top: 1rem;
      font-weight: 600;
      color: #c83532;
    }

    .itemOutOfStock_details {
      color: #5c5c5c;
      font-size: 13px;
    }
  }
`;

const AddressLocation = styled.div`
  align-items: flex-start;
  p {
    margin-bottom: 0;
  }
  img {
    margin-right: 8px;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Address = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: column;
  border-radius: 5px;
  .address_location {
    display: flex;
  }
  .dropdown {
    display: flex;
    height: 40px !important;
    justify-content: flex-start;
    background-color: white;
    color: black;
    border: none;
  }
  .open-arrow {
    margin-bottom: 0.5rem;
    padding: 0.2rem;
    background-color: #e4e4e4;
    border-radius: 50%;
  }
  .rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .address_heading {
    margin-left: 1rem;
    font-weight: 600;
    font-size: 16px;
    color: #5c5c5c;
  }
  .address {
    font-size: 14px;
  }
`;

const CheckoutFooterButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  .payment-method-heading {
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    margin-bottom: 0;
  }
  .payment-option-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
    .upi_img {
      margin-right: 6px;
      width: 25px;
    }
  }
  .payment-method {
    width: max-content;
  }
  .payment-method-icon-button {
    width: max-content;
  }

  .change-payment-method {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  .disable {
    color: gray;
  }

  .button-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const DropdownStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  .toggle_button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

const CouponSelling = styled.div`
  text-align: center;
  background: #00855b;
  margin-bottom: 5px;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: calc(100%+3rem);
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  .description {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
  }
  .tooltip-wrapper {
    width: max-content;
    display: flex;
    align-items: center;
  }
  .popover-basic {
    background-color: black;
  }
`;

const ImmediateSelling = styled.div`
  text-align: center;
  padding: 0.2rem;
  color: #fff;
  font-size: 14px;
  background: #fcb415;
  margin-bottom: 5px;
  width: calc(100%+3rem);

  margin-bottom: -0.1rem;
`;

const RewardSelling = styled.div`
  text-align: center;
  padding: 0.2rem;
  color: #fff;
  font-size: 14px;
  background: #00855b;
  margin-bottom: 5px;
  width: 100%;
`;
export {
  Worth,
  FormStyled,
  CardItemList,
  SideSheetBody,
  CartEmpty,
  DeliveryOption,
  DeliveryType,
  ItemOutOfStock,
  Address,
  AddressLocation,
  CheckoutFooterButton,
  DropdownStyle,
  CouponSelling,
  CheckOutFooter,
  RewardSelling,
  ImmediateSelling,
  CartBanner,
};

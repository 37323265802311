import styled from 'styled-components/macro';

export const DeliveryStatusStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    margin-top: 0.1rem;
    width: 500px;
  }
  .card {
    width: 60%;
    margin: 0.5rem auto;
    margin-top: -4rem;
    .timer {
      display: flex;

      justify-content: space-between;
      margin: auto 2rem;
      .scooter-img {
        height: 70px;
      }
      p {
        font-size: 20px;
        margin: auto 0;
      }
      span {
        font-size: 16px;
        color: gray;
      }
    }
    .status {
      display: flex;
      margin: auto 1.5rem;
      .completed-img {
        height: 50px;
        margin-top: 0.3rem;
      }

      .time-status {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0.3rem;
        .status-time {
          font-size: 14px;
          font-weight: 400;
          margin: auto 0.3rem;
        }
        .status-name {
          font-size: 16px;
          margin: auto 0.3rem;
          font-weight: 500;
        }
      }
    }
  }
  @media (max-width: 767px) {
    & {
      // margin-top: 8rem;
      .image {
        width: 100%;
      }
      .card {
        width: 90%;
        .timer {
          margin: auto;
          .scooter-img {
          }
          p {
            font-size: 14px;
          }
          span {
            font-size: 14px;
            color: gray;
          }
        }
        .status {
          margin: auto 0;
          .completed-img {
          }

          .time-status {
            .status-time {
            }
            .status-name {
            }
          }
        }
      }
    }
  }
`;
export const PickedUpStatus = styled.div`
  width: 100%;
  position: relative;
  margin-top: 16px;
  border-radius: 5px;
  overflow: hidden;
  .picked-up-banner {
    position: absolute;
    max-width: 340px;
    bottom: 16px;
    left: 16px;
    z-index: 1;
    display: flex;
    gap: 12px;
    background: #ffffff;
    border-radius: 10px;
    padding: 16px;
    img {
      height: 30px;
      width: 30px;
    }
    .heading {
      display: flex;
      flex-direction: column;
      gap: 4px;
      h6 {
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        color: #acacac;
      }
    }
    @media screen and (max-width: 575px) {
      position: relative;
      left: initial;
      right: initial;
      max-width: 100%;
      bottom: inherit;
    }
  }
  .image {
    background-color: #ededed;
    width: 100%;
  }
  .map-background {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    display: flex;
  }
`;

export const DeliveryProgressBarStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 1rem;
  @media (max-width: 767px) {
    & {
      margin: 1rem 0;
    }
  }
`;
export const ActivatedProgress = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 9px;
  background: #00855b;
  border-radius: 5px;
`;
export const DangerProgress = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 9px;
  background: red;
  border-radius: 5px;
`;
export const InactivatedProgress = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 9px;
  background: rgba(0, 133, 91, 0.1);
  border-radius: 5px;
`;

import styled from 'styled-components/macro';
import Reward_Background from 'assets/reward_bg.svg';

const RedeemCoin = styled.div`
  padding: 1rem;
  .heading {
    margin: 1rem auto 3rem;
    text-align: center;
  }

  .congrats {
    position: relative;

    background: rgba(255, 153, 0, 0.1);
    border: none;
    border-radius: 25px;
    width: 100%;
    max-height: 100px;
    display: flex;
    margin: auto;
    .congrats_img {
      height: 100px;
    }
    .close-icon {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
    }
    .congrats_details {
      margin-top: 0.8rem;
      .congrats_line {
        font-size: 17px;
        font-weight: 500;
        color: #00855b;
      }
      p {
        font-size: 14px;
        font-weight: 500;
      }
      .bonus {
        color: #ff9900;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .coming_soon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .details {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0.5rem;
      text-align: center !important;
    }
  }
  .overview {
    margin: 2rem;
  }
`;

const HowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: rgba(255, 153, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  position: relative;

  .card-image-overlay {
    -webkit-mask-image: url(${Reward_Background});
    mask-image: url(${Reward_Background});
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: cover;

    z-index: 1;

    background-color: #ff9900;

    width: 100%;
    max-width: 100%;

    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    z-index: 2;

    .left-content-body {
      font-size: 32px;
      font-weight: 700;
      color: #00855b;
    }
    .left-content-sub-body {
      font-size: 18px;
      font-weight: 400;
      color: #00855b;
    }
  }

  .right-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2;

    .right-content-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto 0.5rem;

      .right-content-card-image {
        width: 30px;
        height: 30px;
      }

      .right-content-card-heading {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        max-width: 3rem;
        margin-top: 0.4rem;
      }
    }

    .right-content-card-next-arrow {
      margin: 0 0.4rem 1rem;
      width: 2rem;
    }
  }
`;

const OverviewBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem auto;

  .balance {
    display: flex;
    flex-direction: column;

    .text {
      color: #000;
      font-size: 14px;
      margin: auto;
    }
    .coin-div {
      display: flex;
      align-items: center;
      .number {
        margin: auto;
        margin-left: 0.5rem;
        font-size: 24px;
        color: #00855b;
        font-weight: 600;
      }
      .coin-img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .redeem-button {
    background-color: #00855b;
    box-shadow: 0px 0.540541px 10.8108px rgba(38, 38, 38, 0.1);
    border-radius: 50px;
    cursor: pointer;
    border: none;
    padding: 0.8rem 2rem;

    .redeem-button-text-wrapper {
      display: flex;
      align-items: center;

      color: white;
      font-size: 16px;
      .img-arrow {
        margin-left: 0.4rem;
      }
    }
  }
`;

const ChallengesStyled = styled.div`
  margin: 2rem auto;
  .challenge {
    display: flex;
    align-items: center;

    .trophy-img {
      height: 26px;
      width: 26px;
    }
    .challenge-text {
      font-weight: 500;
      font-size: 18px;
      margin: auto;
      margin-left: 1rem;
    }
  }

  .challenge-card {
    cursor: pointer;
    padding: 0.5rem;
    box-shadow: 0px 0.5px 10.8108px rgba(38, 38, 38, 0.1);
    border: none;
    margin: 1rem auto;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;

    .challenge-img {
      // width: 100px;
      height: 100%;
    }

    .challenge-content-wrapper {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-between;
      padding: 0.5rem;

      .challenge-heading-content-wrapper {
        .challenge-invite {
          font-size: 14px;
          color: #000;
          margin: auto;
        }

        .challenge-reward {
          font-size: 10px;
          color: #5c5c5c;
          margin: auto;
          padding-bottom: 0.3rem;
          .challenge-rupee {
            color: #ff9900;
            font-weight: 500;
          }
        }
      }
      .challenge-progress-bar {
        height: 8px !important;
      }
    }
  }

  .incoming-challenge-card {
    cursor: pointer;
    padding: 0.5rem;
    box-shadow: 0px 0.5px 10.8108px rgba(38, 38, 38, 0.1);
    border: none;
    margin: 1rem auto;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    position: relative;

    .incoming-challenge-coming-soon-tag {
      position: absolute;
      padding: 0.3rem 0.8rem;
      left: -9px;
      background-color: #fdc750;
      color: white;

      font-size: 10px;
      font-weight: 900;
      line-height: 12px;
      letter-spacing: 0.15em;

      z-index: 1;

      &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid #fdc750;
        border-top: 4px solid #fdc750;
        border-bottom: 4px solid transparent;
        left: 0px;
        bottom: -7px;
      }
    }

    .incoming-challenge-overlay {
      background: white;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .incoming-challenge-img {
      // width: 100px;
      height: 100%;
    }

    .incoming-challenge-content-wrapper {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-between;
      padding: 0.5rem;

      .incoming-challenge-heading-content-wrapper {
        .incoming-challenge-invite {
          font-size: 14px;
          color: #000;
          margin: auto;
          padding-left: 0.3rem;
        }

        .incoming-challenge-reward {
          font-size: 12px;
          color: #5c5c5c;
          margin: auto;
          .incoming-challenge-rupee {
            color: #ff9900;
            font-weight: 500;
          }
        }
      }
      .incoming-challenge-progress-bar {
        height: 8px !important;
      }
    }
  }
`;

const InviteButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(255, 153, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
`;

export { RedeemCoin, HowItWorks, OverviewBox, InviteButton, ChallengesStyled };

import styled from 'styled-components/macro';

const UomModal = styled.div`
  .uom_modal {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    .cancel_img {
      cursor: pointer;
    }
    .done_btn {
      font-size: 16px;
      font-weight: 500;
      color: #00855b;
    }
    .logo {
      width: 40px;
      height: 40px;
    }
    .item_details {
      margin-left: 12px;
      max-width: calc(100% - 50px - 90px);
      p {
        margin: 0;
      }
      .item_name {
        font-weight: 600;
        font-size: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
      }
    }
    .btn {
      margin-left: auto;
      diplay: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
      }
      &.cancel {
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }
  }

  .text {
    font-weight: 600;
    font-size: 18px;
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 1px 2px 2px 3px rgba(15, 15, 15, 0.2);
    margin-bottom: 1.5rem;
    .unit {
      font-weight: 600;
      font-size: 18px;
    }
    .qty {
      margin-left: 1rem;
      font-weight: 500;
      font-size: 16px;
      color: #b1b1b2;
    }
    .price {
      font-weight: 500;
      font-size: 16px;
      margin: 0rem 0 0rem 0;
    }
    .out_of_stock {
      margin-top: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 5px;
      background-color: ;
      color: red;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }
    .add_btn {
      width: 120px;
      height: 40px;
      border: 2px solid #b1b1b2;
      border-radius: 5px;
      background-color: #ffffff;
      color: #00855b;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }
    @media screen and (max-width: 991px) {
      .add_btn {
        width: 100px;
        font-size: 16px;
      }
    }
    @media screen and (max-width: 575px) {
      .add_btn {
        width: 90px;
        font-size: 14px;
      }
    }
  }
`;

export { UomModal };

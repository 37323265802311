import styled from 'styled-components/macro';

const CarouselSectionStyled = styled.section`
  position: relative;
  overflow: hidden;
  &.full-width {
    padding-left: 0;
    padding-right: 0;
  }
  .slick-list {
    background-color: #f5f5f5;
    .slick-slide {
      > div {
        display: flex;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-size: cover !important;
    height: 75vh;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    padding: 82px 0;
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    h2 {
      font-weight: 800;
      font-size: 2.5rem;
      max-width: 740px;
      line-height: 1.5;
      @media screen and (max-width: 991px) {
        font-size: 1.875rem;
        line-height: 1.4;
      }
      @media screen and (max-width: 575px) {
        font-size: 1.5rem;
        line-height: 1.3;
      }
    }
    button {
      margin-top: 1.5rem;
      font-size: 18px;
      max-width: 140px;
      width: 100%;
      @media screen and (max-width: 991px) {
        margin-top: 1.25rem;
      }
      @media screen and (max-width: 575px) {
        margin-top: 16px;
        font-size: 16px;
        max-width: 100px;
        width: 100%;
        padding: 6px 12px;
      }
    }
  }
`;

export default CarouselSectionStyled;

/**
 *
 * PaymentOptions
 *
 */
import React, { useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import Razorpay from 'assets/razorpay.svg';
import Visa from 'assets/visa.svg';
import UPI from 'assets/upi.svg';
import Logo from 'assets/mandi.svg';
import Note from 'assets/note.svg';
import NetBanking from 'assets/online-banking.svg';
import { PaymentMethods } from './index.styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'app/pages/slice/selectors';
import { cartActions } from 'app/pages/Cart/slice';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { selectOrder } from '../../slice/selectors';
// import { selectIsMMCoinEnable } from 'app/pages/Cart/slice/selectors';

interface Props {
  price: String | Number;
  onClose: Function;
  payOnlineFunction: Function;
}

export const PaymentMethodComponent = (props: Props) => {
  const { onClose, payOnlineFunction, price } = props;
  const [paymentPrefillMethod, setPaymentPrefillMethod] = React.useState('upi');
  const [activated, setActivated] = useState('upi');
  // const isMMCoinEnable = useSelector(selectIsMMCoinEnable);
  const order = useSelector(selectOrder);

  const profile = useSelector(selectProfile);
  // const { data: item } = order;

  const dispatch = useDispatch();
  const { data: item } = order;

  const onChangePaymentMethod = (value = 'upi') => {
    const paymentMethods = [
      'upi',
      'card',
      'netbanking',
      'paylater',
      'payOnDelivery',
      'cash',
    ];
    if (paymentMethods.includes(value)) {
      setPaymentPrefillMethod(value);
      setActivated(value);
      dispatch(cartActions.setPaymentMethod('razorpay'));
    }
  };

  const handelChange = (value = 'upi') => event => {
    Mixpanel.track(`${actionConstants.PAYMENT} - ${value}`);
    Mixpanel.track(actionConstants.PAYMENT);
    onChangePaymentMethod(value);
  };

  const onPayOnline = () => {
    Mixpanel.track(actionConstants.PAYMENT);
    payOnlineFunction(paymentPrefillMethod);
    onClose();
  };

  const renderIndividualPaymentMethod = () => {
    return (
      <PaymentMethods className="payment_method-wrapper">
        <div className="payment_method">
          <h5 className="payment_heading">Pay Later</h5>
          <Card className="card" onClick={handelChange('paylater')}>
            <div className="payment">
              <Image className="img" src={Razorpay} alt="razor" />
              <p className="payment_text">Paylater</p>
            </div>
            {activated === 'paylater' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>

        <div className="payment_method">
          <h5 className="payment_heading">Cards</h5>
          <Card className="card" onClick={handelChange('card')}>
            <div className="payment">
              <Image
                className="img"
                style={{ width: '35px' }}
                src={Visa}
                alt="razor"
              />
              <p className="payment_text">Visa, Rupay, Mastercard etc.</p>
            </div>
            {activated === 'card' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
        <div className="payment_method">
          <h5 className="payment_heading">Pay on Delivery</h5>
          <Card
            className="card"
            onClick={e => {
              Mixpanel.track(actionConstants.PAYMENT);
              if (
                !item.enable_cod_for_redemption &&
                item.bills[0].reward_spent !== null
              )
                return;
              handelChange('cash')(e);
            }}
          >
            <div className="payment">
              {!item.enable_cod_for_redemption &&
                item.bills[0].reward_spent !== null && (
                  <div className="card-overlay"></div>
                )}
              <Image
                className="img"
                style={{ width: '35px' }}
                src={Note}
                alt="razor"
              />
              <p className="payment_text">Pay on Delivery</p>
            </div>

            {activated === 'cash' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
          {!item.enable_cod_for_redemption &&
          item.bills[0].reward_spent !== null ? (
            <p style={{ fontSize: '12px', color: 'gray' }}>
              Not Applicable for Reward Redemption
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="payment_method">
          <h5 className="payment_heading">UPI</h5>
          <Card className="card" onClick={handelChange('upi')}>
            <div className="payment">
              <Image
                className="img"
                style={{ width: '30px' }}
                src={UPI}
                alt="razor"
              />
              <p className="payment_text">UPI</p>
            </div>
            {activated === 'upi' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>

        <div className="payment_method">
          <h5 className="payment_heading">Net Banking</h5>
          <Card className="card" onClick={handelChange('netbanking')}>
            <div className="payment">
              <Image className="img" src={NetBanking} alt="razor" />
              <p className="payment_text">Net Banking</p>
            </div>
            {activated === 'netbanking' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
      </PaymentMethods>
    );
  };

  const renderBusinessPaymentMethod = () => {
    return (
      <PaymentMethods>
        <div className="payment_method">
          <h5 className="payment_heading">Pay Later</h5>
          <Card className="card" onClick={handelChange('paylater')}>
            <div className="payment">
              <Image className="img" src={Razorpay} alt="razor" />
              <p className="payment_text">Simpl, LazyPay, FlexiPay etc.</p>
            </div>
            {activated === 'paylater' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
          <Card
            style={{ marginTop: '1.5rem' }}
            className="card"
            onClick={handelChange('cash')}
          >
            <div className="payment">
              <Image
                className="img"
                style={{ width: '35px' }}
                src={Logo}
                alt="razor"
              />
              <p className="payment_text">Pay Later</p>
            </div>
            {activated === 'cash' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
        <div className="payment_method">
          <h5 className="payment_heading">Cards</h5>
          <Card className="card" onClick={handelChange('card')}>
            <div className="payment">
              <Image
                className="img"
                style={{ width: '35px' }}
                src={Visa}
                alt="razor"
              />
              <p className="payment_text">Visa, Rupay, Mastercard etc.</p>
            </div>
            {activated === 'card' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
        <div className="payment_method">
          <h5 className="payment_heading">UPI</h5>
          <Card className="card" onClick={handelChange('upi')}>
            <div className="payment">
              <Image
                className="img"
                style={{ width: '30px' }}
                src={UPI}
                alt="razor"
              />
              <p className="payment_text">UPI</p>
            </div>
            {activated === 'upi' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
        <div className="payment_method">
          <h5 className="payment_heading">Net Banking</h5>
          <Card className="card" onClick={handelChange('netbanking')}>
            <div className="payment">
              <Image className="img" src={NetBanking} alt="razor" />
              <p className="payment_text">Net Banking</p>
            </div>
            {activated === 'netbanking' ? (
              <Button className="pay_btn" onClick={onPayOnline}>
                Pay ₹ {Number(price).toFixed(2)}
              </Button>
            ) : (
              <></>
            )}
          </Card>
        </div>
      </PaymentMethods>
    );
  };

  if (profile.data.profile.user_type === 'individual') {
    return renderIndividualPaymentMethod();
  } else {
    return renderBusinessPaymentMethod();
  }
};

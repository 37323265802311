/**
 *
 * Home
 *
 */
import * as React from 'react';

interface Props {}

export function Home(props: Props) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
      <path
        d="M16.575 8.10587C16.5745 8.10548 16.5741 8.10496 16.5737 8.10457L9.63906 1.17016C9.34347 0.874443 8.95048 0.71167 8.53246 0.71167C8.11443 0.71167 7.72144 0.874443 7.42573 1.17016L0.494693 8.10107C0.492358 8.1034 0.489894 8.10587 0.487689 8.1082C-0.119307 8.7187 -0.118269 9.70922 0.490672 10.3182C0.768879 10.5965 1.13619 10.7576 1.52905 10.7746C1.54513 10.7761 1.56122 10.7769 1.57743 10.7769H1.85369V15.8801C1.85369 16.8901 2.67547 17.7117 3.68544 17.7117H6.3985C6.6736 17.7117 6.89655 17.4886 6.89655 17.2137V13.2127C6.89655 12.7518 7.27151 12.377 7.73234 12.377H9.33258C9.7934 12.377 10.1682 12.7518 10.1682 13.2127V17.2137C10.1682 17.4886 10.3912 17.7117 10.6663 17.7117H13.3793C14.3894 17.7117 15.2111 16.8901 15.2111 15.8801V10.7769H15.4674C15.8853 10.7769 16.2783 10.6141 16.5741 10.3183C17.1837 9.70844 17.184 8.7161 16.575 8.10587ZM15.8697 9.61402C15.7622 9.72154 15.6193 9.78081 15.4674 9.78081H14.713C14.438 9.78081 14.215 10.0038 14.215 10.2789V15.8801C14.215 16.3408 13.8402 16.7156 13.3793 16.7156H11.1643V13.2127C11.1643 12.2027 10.3427 11.3809 9.33258 11.3809H7.73234C6.72223 11.3809 5.90046 12.2027 5.90046 13.2127V16.7156H3.68544C3.22475 16.7156 2.84978 16.3408 2.84978 15.8801V10.2789C2.84978 10.0038 2.62683 9.78081 2.35174 9.78081H1.61037C1.60259 9.78029 1.59494 9.7799 1.58703 9.77977C1.43865 9.77718 1.29948 9.7183 1.1952 9.61389C0.973415 9.3921 0.973415 9.03115 1.1952 8.80923C1.19533 8.80923 1.19533 8.8091 1.19546 8.80897L1.19585 8.80858L8.13026 1.87443C8.23765 1.76691 8.38045 1.70777 8.53246 1.70777C8.68434 1.70777 8.82714 1.76691 8.93466 1.87443L15.8675 8.80715C15.8685 8.80819 15.8697 8.80923 15.8708 8.81027C16.0914 9.03244 16.091 9.39262 15.8697 9.61402Z"
        className="fill-grey"
      />
    </svg>
  );
}

/**
 *
 * Redeem Coin Page
 *
 */
import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import {
  ChallengesStyled,
  HowItWorks,
  OverviewBox,
  RedeemCoin,
} from './index.styled';
import Coin from 'assets/shopping-coin.svg';
import Congrats from '../../../assets/congrats.svg';
import Trophy from 'assets/trophy.svg';
import Challenge from 'assets/onGoing_challenge_illustration.svg';
import Coming_Soon_Challenge from 'assets/coming_soon_challanges_illustraion.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useRewardsSlice } from './slice';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Reward_coins from 'assets/rewards.svg';
import Reward_gift from 'assets/reward_gift.svg';
import Reward_achivements from 'assets/achivements.svg';
import Close from 'assets/iconCross.svg';
import Reward_Arrow from 'assets/reward_arrow.svg';

import { selectRewards } from './slice/selectors';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';
import Arrow from 'assets/redeem_arrow.svg';
import { homePageActions } from '../HomePage/slice';
import { useQuery } from 'app/hooks/useQuery';

// interface Props extends RouteComponentProps {}

export function RedeemCoinPage() {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.REDEEM_COIN_PAGE);
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useRewardsSlice();
  const rewards = useSelector(selectRewards);
  const query = useQuery();
  // at -> Authorization Token
  const token = query.get('at');
  const [isCongratulationEnable, setIsCongratulationEnable] = React.useState(
    true,
  );

  useEffect(() => {
    if (rewards.error) {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    if (token) dispatch(actions.getRewardsWithToken({ token }));
    else dispatch(actions.getRewards());
  }, []);

  const calculatePercent = (
    current_count: number,
    max_count: number,
  ): number => {
    return (current_count / max_count) * 100;
  };

  const handelOnClick = milestone => event => {
    event.preventDefault();
    const pushLocation = {
      pathname: token ? '/challenge' : '/home/rewards/challenge',
    };
    if (milestone.challenge_id) {
      pushLocation['search'] = `?challenge_id=${milestone.challenge_id}`;
      if (token) pushLocation['search'] += `&at=${token}`;
    }
    history.push(pushLocation);
  };

  const renderChallenge = () => {
    if (!rewards.data?.challenges) return;

    return rewards.data?.challenges.map(challenge => {
      return challenge?.milestones.map(milestone => {
        if (milestone?.is_ongoing) return renderOngoingChallenge(milestone);
        return renderIncomingChallenge(milestone);
      });
    });
  };

  const renderIncomingChallenge = milestone => (
    <Card
      className="incoming-challenge-card"
      onClick={e => {
        Mixpanel.track(actionConstants.MILESTONE);
        // handelOnClick(milestone)(e);
      }}
    >
      <p className="incoming-challenge-coming-soon-tag">COMING SOON</p>
      <div className="incoming-challenge-overlay" />
      <Image
        className="incoming-challenge-img"
        src={Coming_Soon_Challenge}
        alt="incoming challenge"
      />
      <div className="incoming-challenge-content-wrapper">
        <div className="incoming-challenge-heading-content-wrapper">
          <p className="incoming-challenge-invite">{milestone?.title}</p>
          <p className="incoming-challenge-reward">{milestone?.description}</p>
        </div>
      </div>
    </Card>
  );

  const renderOngoingChallenge = milestone => (
    <Card
      className="challenge-card"
      onClick={e => {
        Mixpanel.track(actionConstants.MILESTONE);
        handelOnClick(milestone)(e);
      }}
    >
      <Image className="challenge-img" src={Challenge} alt="challenge" />

      <div className="challenge-content-wrapper">
        <div className="challenge-heading-content-wrapper">
          <p className="challenge-invite">{milestone?.title}</p>
          <p className="challenge-reward">
            {milestone?.description}
            {/* Complete the challenge and get reward worth{' '}
            <span className="challenge-rupee">Rs 200</span> */}
          </p>
        </div>
        <ProgressBar
          className="challenge-progress-bar"
          variant="success"
          now={calculatePercent(milestone.current_count, milestone.max_count)}
        />
      </div>
    </Card>
  );

  const renderHowItWorks = () => {
    const howItWorksSteps = [
      {
        id: 1,
        heading: 'Achieve milestones',
        image: Reward_achivements,
      },
      {
        id: 2,
        heading: 'Earn Rewards',
        image: Reward_coins,
      },
      {
        id: 3,
        heading: 'Redeem on next order',
        image: Reward_gift,
      },
    ];
    return (
      <HowItWorks>
        <div className="card-image-overlay" />
        <div className="left-content">
          <h5 className="left-content-body">HOW</h5>
          <h6 className="left-content-sub-body">{`it's works?`}</h6>
        </div>
        <div className="right-content">
          {howItWorksSteps.map((item, index) => (
            <>
              <div key={item.id} className="right-content-Gcard">
                <Image
                  className="right-content-card-image"
                  alt={item.heading}
                  src={item.image}
                />
                <p className="right-content-card-heading">{item.heading}</p>
              </div>
              {index !== howItWorksSteps.length - 1 && (
                <Image
                  className="right-content-card-next-arrow"
                  alt="next arrow"
                  src={Reward_Arrow}
                />
              )}
            </>
          ))}
        </div>
      </HowItWorks>
    );
  };

  // ! Unused Code
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderCongratulation = () => {
    if (!isCongratulationEnable) return <></>;

    const onHideBanner = () => {
      setIsCongratulationEnable(false);
    };

    return (
      <div className="congrats">
        <Image className="congrats_img" src={Congrats} alt="coming-soon" />

        <div className="congrats_details">
          <p className="congrats_line">Congratulations 🤩</p>
          <p>
            Sign up bonus{' '}
            <span className="bonus">{rewards.data?.signup_bonus}</span> credited
            to your wallet successfully
          </p>
        </div>
        <div className="close-icon" onClick={onHideBanner}>
          <Image src={Close} alt="close" />
        </div>
      </div>
    );
  };

  const renderOverviewCard = () => {
    return (
      <OverviewBox>
        <div className="balance">
          <p className="text">Reward coins</p>
          <div className="coin-div">
            <Image className="coin-img" src={Coin} alt="coin" />
            <p className="number">{rewards.data?.reward_balance}</p>
          </div>
        </div>
        <button className="redeem-button">
          <div
            className="redeem-button-text-wrapper"
            onClick={() => {
              dispatch(homePageActions.setShowCart(true));
              history.push('/home');
            }}
          >
            Go Redeem
            <Image className="img-arrow" src={Arrow} alt="arrow" />
          </div>
        </button>
      </OverviewBox>
    );
  };

  const renderChallenges = () => {
    return (
      <ChallengesStyled>
        <div className="challenge">
          <Image className="trophy-img" src={Trophy} alt="trophy" />
          <p className="challenge-text">Challenge for you</p>
        </div>
        {renderChallenge()}
      </ChallengesStyled>
    );
  };

  return (
    <RedeemCoin>
      <h4 className="heading">Earn Coin</h4>
      {renderHowItWorks()}
      {renderOverviewCard()}
      {renderChallenges()}
    </RedeemCoin>
  );
}

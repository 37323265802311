import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { HomePageState } from './types';

export const initialState: HomePageState = {
  showSideBar: false,
  showCart: false,
  showSearch: false,
  showAddress: false,
};

const slice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setShowSearch(state, action: PayloadAction<any>) {
      state.showSearch = action.payload;
      if (action.payload) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    setShowSideBar(state, action: PayloadAction<any>) {
      state.showSideBar = action.payload;
      if (action.payload) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    setShowCart(state, action: PayloadAction<any>) {
      state.showCart = action.payload;
      if (action.payload) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    setShowAddress(state, action: PayloadAction<any>) {
      state.showAddress = action.payload;
      if (action.payload) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
  },
});

export const { actions: homePageActions } = slice;

export const useHomePageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 *
 * OrderPage
 *
 */
import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { OrderDetails } from './components/OrderDetails';
import { useDispatch } from 'react-redux';
import { orderActions } from './slice';
import { StoreOrderDetails } from './components/StoreOrderDetails';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import { OrdersPayload } from './slice/types';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function OrderPage(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.ORDER_PAGE);
  });
  const { match } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const type = query.get('type');
  const authToken = query.get('authToken');

  useEffect(() => {
    /** Fetching the store order
     * match.param.id = orderId
     */

    const payload: OrdersPayload = {
      id: match.params.id,
    };
    if (!!authToken) payload.authToken = authToken;

    if (type === 'app') dispatch(orderActions.getOrder(payload));
    else if (type === 'store') dispatch(orderActions.getStoreOrder(payload));
    /**Go to previous page if query type doesn't exist */ else history.goBack();
  }, []);

  if (type === 'app') return <OrderDetails match={match} />;
  else return <StoreOrderDetails />;
}

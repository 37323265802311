import styled from 'styled-components/macro';

const DeliveryOptionType = styled.div`
  .authentication-page {
    min-height: 100vh;
    background-color: #00855b;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3.75rem 16px;

    .deliveryOption {
      display: flex;
      flex-direction: column;
      width: 95%;
    }
    .main_heading {
      margin: 0.5rem auto;
    }

    .gray {
      color: gray !important;
      cursor: default !important;
      .heading > img {
        filter: grayscale(1) !important;
      }
      p {
        color: gray !important;
      }
      .timeslot {
        background: rgba(245, 245, 245, 0.7) !important;
        .imeslot_text {
          font-weight: 400;
        }
      }
    }

    .address_wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .delivery {
        font-size: 16px;
      }
      .location {
        display: flex;
        align-items: flex-start;
        .image {
        }
        .address {
          font-size: 14px;
          color: #808588;
          margin-left: 0.5rem;
          margin-bottom: 0;
          margin-top: 0;
          text-align: justify;
        }

        @media screen and (max-width: 991px) {
          .address {
            font-size: 13px;
          }
        }
        @media screen and (max-width: 575px) {
          .address {
            font-size: 12px;
          }
        }
      }
    }
  }

  .active {
    border: 3px solid #00855b !important;
  }

  .instant_delivery {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 16px rgba(38, 38, 38, 0.16);
    cursor: pointer;
    border: none;
    border-radius: 10px;
    padding: 1.5rem;
    weight: 80%;
    margin-top: 2rem;

    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;

      .img {
        height: 25px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        margin: 0 auto 0 1rem;
      }
    }

    .content {
      font-size: 15px;
      font-weight: 400;
      margin-top: 0.3rem;
      color: #000;
      p {
        margin-bottom: 0;
        overflow-wrap: break-word;
      }
    }
    .timeslot {
      background: rgba(255, 247, 214, 0.7);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin: 1rem -1.5rem -1.5rem -1.5rem;
      padding: 0.8rem 1.8rem;
      .timeslot_text {
        margin: auto;
        font-size: 14px;
        color: black;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 575px) {
      .heading {
        .img {
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .content {
        margin-top: 0.5rem;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .next_delivery {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 16px rgba(38, 38, 38, 0.16);
    cursor: pointer;
    border: none;
    border-radius: 10px;
    padding: 1.5rem;
    weight: 80%;
    margin-top: 1rem;

    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;

      .next-img {
        width: 25px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        margin: 0 auto 0 1rem;
      }
    }

    .content {
      font-size: 15px;
      font-weight: 400;
      margin-top: 0.5rem;
      color: #000;
      p {
        margin-bottom: 0;
        overflow-wrap: break-word;
      }
    }

    .timeslot {
      background: rgba(219, 238, 232, 0.5);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin: 0.5rem -1.5rem -1.5rem -1.5rem;
      padding: 0.5rem 1.5rem;
      margin-bottom: -1.5rem;
      .timeslot_text {
        margin: auto;
        font-size: 14px;
        color: black;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 575px) {
      .heading {
        .next-img {
          width: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .content {
        margin-top: 0.3rem;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;
export { DeliveryOptionType };

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addressSaga } from './saga';
import { AddressState } from './types';
import { localStorageActions } from '../helpers/storage';

export const initialState: AddressState = {
  addressList: {
    data: [],
    loading: true,
    error: false,
  },
  launchAddressList: {
    data: {},
    loading: true,
    success: false,
    error: false,
  },
  selectedAddress: null,
  addAddress: {
    loading: false,
    success: false,
    error: false,
  },
  editAddress: {
    loading: false,
    success: false,
    error: false,
  },
};

const slice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    getAddressList(state) {
      state.addressList.data = [];
      state.addressList.loading = true;
      state.addressList.error = null;
    },
    getAddressListSuccess(state, action: PayloadAction<any>) {
      state.addressList.data = action.payload;
      state.addressList.loading = false;

      const selectedAddress = action.payload
        ? action.payload.find(e => e.is_default === true)
        : null;
      state.selectedAddress = selectedAddress;

      localStorage.setItem(
        'store-id',
        JSON.stringify(selectedAddress.store_id),
      );

      localStorageActions.setStoreId(selectedAddress.store_id);
    },

    getAddressListError(state, action: PayloadAction<any>) {
      state.addressList.error = action.payload;
      state.addressList.loading = false;
    },
    setSelectedAddress(state, action: PayloadAction<any>) {
      state.selectedAddress = action.payload;
    },
    addAddress(state, action: PayloadAction<any>) {
      state.addAddress.loading = true;
    },
    resetAddAddressSuccess(state) {
      state.addAddress.success = false;
    },
    addAddressSuccess(state, action: PayloadAction<any>) {
      state.addressList.data = action.payload;
      state.addAddress.loading = false;
      state.addAddress.success = true;
    },
    addAddressError(state, action: PayloadAction<any>) {
      state.addAddress.loading = false;
      state.addAddress.error = true;
      state.addAddress.success = false;
    },
    editAddress(state, action: PayloadAction<any>) {
      state.editAddress.loading = true;
      state.addressList.loading = true;
    },
    editAddressSuccess(state, action: PayloadAction<any>) {
      state.editAddress.loading = false;
      state.editAddress.success = true;
    },
    resetEditAddressSuccess(state) {
      state.editAddress.loading = false;
      state.editAddress.success = false;
      state.editAddress.false = false;
    },
    editAddressError(state, action: PayloadAction<any>) {
      state.editAddress.loading = false;
      state.editAddress.error = true;
      state.editAddress.success = false;
    },
    deleteAddress(state, action: PayloadAction<any>) {
      state.addressList.loading = true;
    },
    launchAddress(state) {
      state.launchAddressList.data = [];
      state.launchAddressList.loading = true;
      state.launchAddressList.error = null;
    },
    launchAddressSuccess(state, action: PayloadAction<any>) {
      state.launchAddressList.data = action.payload;
      state.launchAddressList.loading = false;
      state.launchAddressList.success = true;
      state.launchAddressList.error = true;
    },
    launchAddressError(state, action: PayloadAction<any>) {
      state.launchAddressList.loading = false;
      state.launchAddressList.error = true;
      state.launchAddressList.success = false;
    },
    resetAddAddress(state) {
      state.addAddress.loading = false;
      state.addAddress.error = false;
      state.addAddress.success = false;
    },
  },
});

export const { actions: addressActions } = slice;

export const useAddressSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addressSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddressSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

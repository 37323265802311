import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reviewSaga } from './saga';
import { ReviewState } from './types';

export const initialState: ReviewState = {
  feedback: {
    loading: false,
    success: false,
    error: false,
  },
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    sendFeedback(state, action: PayloadAction<any>) {
      state.feedback.loading = true;
      state.feedback.error = null;
      state.feedback.success = false;
    },
    sendFeedbackSuccess(state, action: PayloadAction<any>) {
      state.feedback.loading = false;
      state.feedback.success = true;
    },
    sendFeedbackError(state, action: PayloadAction<any>) {
      state.feedback.loading = false;
      state.feedback.error = action.payload;
      state.feedback.success = false;
    },
    resetFeedback(state) {
      state.feedback.loading = true;
      state.feedback.error = null;
      state.feedback.success = false;
    },
  },
});

export const { actions: reviewActions } = slice;

export const useReviewSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reviewSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useReviewSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.searchSL || initialState;

export const selectSearch = createSelector(
  [selectSlice],
  state => state.search,
);

export const selectQuery = createSelector([selectSlice], state => state.query);

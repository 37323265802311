import styled from 'styled-components/macro';

const LoadingWrapper = styled.div`
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    li {
      margin-top: 24px;
      .loading {
        height: 241px;
      }
    }
  }
`;

const SliderSection = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  .section-bg {
    position: absolute;
    top: -7rem;
    left: 0;
    @media screen and (max-width: 991px) {
      max-width: 200px;
      top: -4rem;
    }
    @media screen and (max-width: 767px) {
      max-width: 100px;
      top: -2rem;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
  .slick-slider {
    margin-top: 12px;
    .slick-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #00855b;
      &:before {
        content: '➔';
        font-size: 28px;
        top: -4px;
        position: relative;
        opacity: 1;
      }
      z-index: 1;
      &.slick-disabled {
        background-color: white;
        &:before {
          color: #232827;
        }
      }
      &.slick-prev {
        left: -10px;
        transform: translate(0, -50%) scaleX(-1);
        &:before {
          content: '➔';
        }
      }
      &.slick-next {
        right: -10px;
        &:before {
        }
      }
    }
    .slick-dots {
      li {
        width: 14px;
        height: 14px;
        margin-left: 2px;
        margin-right: 2px;
        button {
          padding: 0;
          top: 8px;
          line-height: initial;
          width: 14px;
          height: 14px;
          &:before {
            width: 14px;
            height: 14px;
            font-size: 12px;
          }
        }
        &.slick-active {
          button {
            &:before {
              color: #00855b;
            }
          }
        }
      }
    }
    .slick-slide {
      padding-bottom: 12px;
      padding-top: 12px;
      > div {
        margin-left: 10px;
        margin-right: 10px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
        padding: 16px;
        cursor: pointer;
        min-height: 285px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
          max-width: 150px;
        }
        button {
          margin-top: 12px;
        }
      }
    }
  }
  &.category-slider {
    .slick-slider {
      .slick-slide {
        > div {
          min-height: 241px;
        }
      }
    }
  }
  &.slider-testimonial {
    padding-top: 6rem;
    padding-bottom: 6rem;
    h3 {
      font-size: 2.5rem;
      font-weight: 800;
      color: #00855b;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 575px) {
        font-size: 1.75rem;
      }
    }
    .slick-slider {
      .slick-slide {
        > div {
          margin-top: 1.5rem;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          position: relative;
          overflow: hidden;
          .indicator {
            display: none;
          }
          p {
            margin-bottom: 0;
            margin-top: 12px;
          }
          &:hover {
            .indicator {
              display: flex;
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    @media screen and (max-width: 575px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
`;
export { LoadingWrapper, SliderSection };

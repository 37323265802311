import styled from 'styled-components/macro';

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  width: 100%;
  > * {
    text-align: center;
  }
  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 1.5rem;
    marign-bototm: 0;
  }
  button {
    margin-top: 1.5rem;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 180px);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const Error = styled.div`
  margin-top: 1.5rem;
`;
const LoadingWrapper = styled.div`
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    li {
      margin-top: 24px;
      .loading {
        height: 270px;
      }
    }
  }
`;
const ProductList = styled.div`
  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    li {
      margin-top: 24px;
    }
  }
`;

export { Empty, Error, LoadingWrapper, ProductList, EmptyWrapper };

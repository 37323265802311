import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import React, { memo, useRef, useEffect } from 'react';
import { SideSheetModal, SideSheetStyled } from './index.styled';

export const SideSheet = memo(
  (props: {
    isOpen: boolean;
    children: any;
    onClose: Function;
    backgroundColor?: string;
  }) => {
    React.useEffect(() => {
      Mixpanel.track(componentConstants.SIDE_SHEET);
    });
    const { isOpen, children, onClose, backgroundColor } = props;

    const sideSheetWrapperRef: any = useRef(null);
    const sideSheetRef: any = useRef(null);

    useEffect(() => {
      if (isOpen) {
        const sideSheetWrapperEL = sideSheetWrapperRef.current;
        sideSheetWrapperEL.addEventListener(
          'mousedown',
          handleOutsideClick,
          false,
        );
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
          sideSheetWrapperEL.removeEventListener(
            'mousedown',
            handleOutsideClick,
            false,
          );
          document.removeEventListener('keydown', handleKeyDown, false);
        };
      }
    }, [isOpen]);

    const handleOutsideClick = (e: any) => {
      const sideSheetEl = sideSheetRef.current;
      if (sideSheetEl && !sideSheetEl.contains(e.target)) {
        onClose();
      }
    };

    const handleKeyDown = (e: any) => {
      if (e.keyCode === 27 || e.code === 'Escape') {
        e.preventDefault();
        e.stopImmediatePropagation();
        onClose();
      }
    };

    if (!isOpen) {
      return null;
    }
    return (
      <SideSheetModal className="sidesheet-modal" ref={sideSheetWrapperRef}>
        <div style={{ flex: 1 }} id="sideSheetLeft" />
        <SideSheetStyled
          backgroundColor={backgroundColor}
          className="sidesheet"
          ref={sideSheetRef}
        >
          {children}
        </SideSheetStyled>
      </SideSheetModal>
    );
  },
);

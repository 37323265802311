/**
 *
 * Select Account Type
 *
 */
import { Button, Card, Image } from 'react-bootstrap';
import Individual from 'assets/individual.svg';
import Business from 'assets/business.svg';
import { AccountType } from './index.styled';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {
  onProceed: () => void;
  setType: Function;
  type: string;
}

export function SelectAccountType(props: Props) {
  const { onProceed, type, setType } = props;
  const onClick = accountType => event => {
    setType(accountType);
  };
  return (
    <AccountType>
      <div className="account_type">
        <Card
          className={`individual_type ${type === 'individual' ? 'active' : ''}`}
          onClick={
            (Mixpanel.track(actionConstants.ACCOUNT_TYPE_SELECT),
            onClick('individual'))
          }
        >
          <Image className="individual_img" src={Individual} alt="individual" />
          <span className="individual_text">I am an</span>
          <h4 className="individual_detail">Individual</h4>
        </Card>
        <Card
          className={`business_type ${type === 'company' ? 'active' : ''}`}
          onClick={
            (Mixpanel.track(actionConstants.ACCOUNT_TYPE_SELECT),
            onClick('company'))
          }
        >
          <Image className="business_img" src={Business} alt="business" />
          <span className="business_text">I represent</span>
          <h4 className="business_detail">Business</h4>
        </Card>
      </div>
      <Button
        className="btn"
        onClick={(Mixpanel.track(actionConstants.PROCEED), onProceed)}
        disabled={type === ''}
      >
        Continue
      </Button>
    </AccountType>
  );
}

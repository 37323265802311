/**
 *
 * OrderDetails
 *
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import { Card } from 'app/components/Card';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  DeliveryDetail,
  Intro,
  LoadingWrapper,
  PaymentDetails,
  StoreOrderDetailsStyled,
} from './index.styled';
import Note from 'assets/note.svg';
import UPI from 'assets/upi.svg';
import Visa from 'assets/visa.svg';
import RazorpayIcon from 'assets/razorpay.svg';
import coins from 'assets/mm-coin.svg';

import NetBanking from 'assets/online-banking.svg';
import StoreOrderImg from 'assets/storeOrder.svg';

import { selectStoreOrder } from '../../slice/selectors';
import { StoreOrderedItems } from '../StoreOrderedItems';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { orderActions } from '../../slice';

export function StoreOrderDetails() {
  const order = useSelector(selectStoreOrder);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: item } = order;

  React.useEffect(() => {
    if (!!order.error) {
      history.goBack();
      toast.error('No Order Found');
      return () => {
        dispatch(orderActions.resetState());
      };
    }
  }, [order]);

  const getReadableDate = (dateString: number) => {
    const dateValueInMS = dateString * 1000;
    const d = new Date(dateValueInMS);
    return (
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      ',' +
      ' ' +
      d.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    );
  };

  if (order.loading) {
    return (
      <LoadingWrapper className="loading-wrapper">
        <div className="loading" />
        <div className="loading" />
        <div className="loading" />
      </LoadingWrapper>
    );
  }

  if (!!order.error) {
    return <></>;
  }
  const getImageSubPaymentMethod = sub_payment_method => {
    switch (sub_payment_method.toLowerCase()) {
      case 'upi':
        return UPI;
      case 'paylater':
        return RazorpayIcon;
      case 'payOnDelivery':
        return Note;
      case 'netbanking':
        return NetBanking;
      case 'card':
        return Visa;

      default:
        return '';
    }
  };

  const renderDivider = () => (
    <div
      style={{
        borderBottom: '2px dashed #00855B',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}
    />
  );

  const getImagePaymentMethod = (payment_method: string) => {
    switch (payment_method.toLowerCase()) {
      case 'cash':
        return Note;

      case 'online':
        return RazorpayIcon;

      default:
        return '';
    }
  };

  const renderPaymentTotal = () => {
    if (order.data.payment_status === 'complete') {
      if (order.data.wallet_spent) {
        return <p> ₹ {order.data.wallet_spent - order.data.total}</p>;
      } else if (order.data.reward_spent) {
        return <p> ₹ {order.data.total - order.data.reward_spent}</p>;
      } else {
        return <p>₹ {order.data.total}</p>;
      }
    }
    return;
  };

  const renderPayments = () => {
    return (
      <Card className="edit_order_bill">
        <div className="edit_order_status">
          <h6>Payment Status</h6>
          {order.data.payment_status === 'complete' ? (
            <h6 className="paid_status">Paid</h6>
          ) : (
            <h6 className="pending_status">Pending</h6>
          )}
        </div>
        <div className="edit_order_mode">
          <h6>Payment Mode</h6>
          <div className="payment_text">
            <div className="img-div">
              <Image
                className="upi_img"
                src={
                  Boolean(order.data.sub_payment_method)
                    ? getImageSubPaymentMethod(order.data.sub_payment_method)
                    : getImagePaymentMethod(order.data.payment_method)
                }
                alt={
                  Boolean(order.data.sub_payment_method)
                    ? order.data.sub_payment_method
                    : order.data.payment_method
                }
              />
              <h6 className="mode_text">
                {Boolean(order.data.sub_payment_method)
                  ? order.data.sub_payment_method
                  : order.data.payment_method}
              </h6>
            </div>
            {renderPaymentTotal()}
          </div>
          {!!order.data.reward_spent ? (
            <div className="payment_text">
              <div className="img-div">
                <Image className="payment_img" src={coins} alt="coins" />
                <h6 className="mode_text">Rewards</h6>
              </div>
              <p>₹ {order.data.reward_spent}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        {renderDivider()}
        <div className="edit_order_status">
          <h6>Subtotal</h6>
          <h6>₹ {Number(order.data.sub_total).toFixed(2)}</h6>
        </div>
        <div className="edit_order_status">
          <h6>Grand Total</h6>
          <h6>₹ {Number(order.data.total).toFixed(2)}</h6>
        </div>
      </Card>
    );
  };

  return (
    <StoreOrderDetailsStyled className="store-order-details">
      <Intro className="order-details-intro">
        <h5 className="heading">Order {order.data.order_id}</h5>
        {!!order.data.order_created_at && (
          <time>{getReadableDate(order.data.order_created_at)}</time>
        )}
        <div className="store-img-wrapper">
          <Image className="store-img" src={StoreOrderImg} />
        </div>
      </Intro>

      <Card>
        <h5>Thank you for your purchase!</h5>
        <p>Place orders through our app for a more convenient experience.</p>
      </Card>
      {!!order.data.address && (
        <DeliveryDetail
          className="delivery-detail"
          style={{ marginTop: '2rem' }}
        >
          <h5 className="heading">Store Detail</h5>
          <Card className="delivery-details">
            <div className="address_section">
              <h6 className="address">Address:</h6>
              <p className="address_name">{order.data.address}</p>
            </div>
          </Card>
        </DeliveryDetail>
      )}

      <StoreOrderedItems item={item} />
      <PaymentDetails className="payment-details">
        <h5 className="heading">Payment Details</h5>
        {renderPayments()}
      </PaymentDetails>
    </StoreOrderDetailsStyled>
  );
}

import styled from 'styled-components/macro';

export const OrderedItemPicture = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
  }
`;
export const OrderedItemName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  margin-left: 16px;
  .price_details {
    display: flex;
  }
`;
export const OrderedItemsStyled = styled.ul`
  &.ordered-items {
    margin-top: 1.5rem;
  }
`;

export const OrderedListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:first-child) {
    padding-top: 8px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
  }
`;
export const OrderedList = styled.ul``;

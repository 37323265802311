/**
 *
 * ItemPage
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LogoSvg from 'assets/logo.svg';
import { useHistory } from 'react-router-dom';
import Thunder from 'assets/thunder.svg';
import watch from 'assets/truck_next_day.svg';
import { Card, Image } from 'react-bootstrap';
import { cartActions } from '../../../Cart/slice';
import { mainPageActions } from '../../../MainPage/slice';
import { categoryActions } from '../../../CategoryPage/slice';
import {
  selectCartItems,
  selectIsIndividualPackingEnable,
} from '../../../Cart/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import location2 from 'assets/location2.svg';
import { useEffect, useMemo, useState } from 'react';
import { DeliveryOptionType } from './index.styled';
import { selectLaunchAddressList } from '../../../ManageAddress/slice/selectors';
import { addressActions } from '../../../ManageAddress/slice';
import actionConstants from 'app/mixpanel/constants/action';
import { Mixpanel } from 'app/mixpanel';

export function DeliveryType() {
  const type = localStorage.getItem('Delivery-Type');
  const dispatch = useDispatch();
  const history = useHistory();
  const launchAddressList = useSelector(selectLaunchAddressList);
  const cartItems = useSelector(selectCartItems);
  const isIndividualPackingEnable = useSelector(
    selectIsIndividualPackingEnable,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deliveryType, setDeliveryType] = useState('');

  useEffect(() => {
    // dispatch(addressActions.getAddressList());
    dispatch(addressActions.launchAddress());
  }, []);

  const defaultAddress = useMemo(() => {
    if (
      launchAddressList.data !== '' &&
      !!launchAddressList.data.address &&
      launchAddressList.data.address.length > 0
    )
      return launchAddressList.data?.address.find(e => e.is_default === true);
    return [];
  }, [launchAddressList]);

  useEffect(() => {
    // inverse of falsy value will result in false and statement will be true. So checking this with specifically false
    if (defaultAddress?.show_instant_delivery === false)
      handleSelectEventType();
  }, [defaultAddress]);

  const handleSelectEventType = (show_instant_delivery = false) => (
    event: any,
  ) => {
    setDeliveryType(show_instant_delivery ? 'instant' : 'next-day');

    if (!show_instant_delivery && isIndividualPackingEnable) {
      dispatch(cartActions.setIndividualPacking(false));
    }

    dispatch(
      mainPageActions.setEnableInstantDelivery({ show_instant_delivery }),
    );
    dispatch(categoryActions.clearActiveTab());
    // Loading getCategories
    dispatch(mainPageActions.getCategories());

    dispatch(cartActions.addItemsToCart(cartItems));
    history.push('/home');
  };
  return (
    <DeliveryOptionType>
      <div className="deliveryOption">
        <h6 className="delivery">
          <b>Delivering to:</b> {defaultAddress?.address_name}
        </h6>
        <div className="location">
          <img className="image" src={location2} alt="Location" />
          <p className="address">{defaultAddress?.address}</p>
        </div>
        <Card
          className={`instant_delivery ${type === 'instant' ? 'active' : ''}`}
          onClick={
            (Mixpanel.track(actionConstants.ADDRESS_SELECT),
            handleSelectEventType(true))
          }
        >
          <div className="heading">
            <Image className="img" src={Thunder} alt="instant" />
            <h5>{launchAddressList.data.instant_delivery_title}</h5>
          </div>
          <div className="span">
            <p style={{ color: 'gray' }}>
              Get {launchAddressList.data.items_range_instant}+ delivered
              instantly
            </p>
            <p>{defaultAddress?.earliest_timeslot_instant}</p>
          </div>
        </Card>
        <Card
          className={`next_delivery ${type === 'next-day' ? 'active' : ''}`}
          // style={{ marginTop: '-1rem' }}
          onClick={
            (Mixpanel.track(actionConstants.ADDRESS_SELECT),
            handleSelectEventType(false))
          }
        >
          <div className="heading">
            <Image src={watch} alt="next-day" />
            <h5>Next day Delivery</h5>
          </div>
          <div className="span">
            <p style={{ color: 'gray' }}>
              Get {launchAddressList.data.items_range_next_day}+ delivered the
              Next Day
            </p>
            <p>Earliest slot: {defaultAddress?.earliest_timeslot}</p>
          </div>
        </Card>
      </div>
    </DeliveryOptionType>
  );
}

import styled from 'styled-components/macro';

export const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 16px;
  position: relative;
  .tag {
    position: absolute;
    left: -10px;
    z-index: 1;
    top: 16px;
    max-width: 100px;
  }
  .add-unit {
    display: flex;
    flex-direction: column;
  }
  &.cart-add {
    display: flex;
    align-items: center;
  }
`;

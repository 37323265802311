import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router';

import ReviewArrow from 'assets/review-arrow.svg';
import OrderSuccessfull from 'assets/order-successfull.svg';
import CloseOrder from 'assets/close-order.svg';
import { ReviewStyled, DeliverySuccess } from './index.styled';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';

export const Review = (props: { id: string; setShow: Function }) => {
  const { id, setShow } = props;
  const history = useHistory();

  const isReviewClosed = localStorage.getItem(`reviewClosed_${id}`);

  if (isReviewClosed) {
    return null;
  }

  const closeReviewBanner = () => {
    localStorage.setItem(`reviewClosed_${id}`, 'true');
    setShow(false);
  };

  return (
    <ReviewStyled className="review">
      <Image src={OrderSuccessfull} alt="" />
      <DeliverySuccess className="delivery-success">
        <span>Order Delivered Successfully</span>
        <time>Please provide us feedback about our product & services</time>
        <Button
          variant="link"
          onClick={() => {
            Mixpanel.track(actionConstants.SHIPPING_POLICY);
            history.push(`/home/orders/${id}/review`);
          }}
        >
          Review
          <Image src={ReviewArrow} alt="ReviewArrow" />
        </Button>
      </DeliverySuccess>
      <Button variant="link" className="close" onClick={closeReviewBanner}>
        <Image src={CloseOrder} alt="CloseOrder" className="img-fluid" />
      </Button>
    </ReviewStyled>
  );
};

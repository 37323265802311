import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { RewardsState } from './types';

export const initialState: RewardsState = {
  rewards: {
    data: {},
    loading: true,
    error: false,
  },
};

const slice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    getRewards(state) {
      state.rewards.data = {};
      state.rewards.loading = true;
      state.rewards.error = null;
    },
    getRewardsSuccess(state, action: PayloadAction<any>) {
      state.rewards.data = action.payload;
      state.rewards.loading = false;
    },
    getRewardsError(state, action: PayloadAction<any>) {
      state.rewards.error = action.payload;
      state.rewards.loading = false;
    },
    getRewardsWithToken(state, action: PayloadAction<any>) {
      state.rewards.data = {};
      state.rewards.loading = true;
      state.rewards.error = null;
    },
    getRewardsSuccessWithToken(state, action: PayloadAction<any>) {
      state.rewards.data = action.payload;
      state.rewards.loading = false;
    },
    getRewardsErrorWithToken(state, action: PayloadAction<any>) {
      state.rewards.error = action.payload;
      state.rewards.loading = false;
    },
  },
});

export const { actions: referAndEarnActions } = slice;

export const useRewardsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRewardsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

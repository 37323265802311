import styled from 'styled-components';

export const PolicyWrapper = styled.div`
  .header {
    background-color: #02855b;
  }
`;

export const PageWrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 80px;
`;

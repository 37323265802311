import { call, put, takeLatest } from 'redux-saga/effects';
import { addressActions as actions } from '.';
import ReactGA from 'react-ga';
import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { carouselActions } from 'app/pages/MainPage/components/Carousel/slice';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { AnalyticsConstants } from 'utils/constants';

function* getAddressList() {
  try {
    const requestURL = `/api/v1/user/address`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getAddressListSuccess(response.data));
    yield put(actions.launchAddress());
    yield put(carouselActions.getFeaturedImages());
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getAddressListError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getAddressListError(err.response));
    } else {
      yield put(actions.getAddressListError(err));
    }
  }
}

function* editAddress(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v1/user/address/${action.payload.id}`;
    yield call(request, requestURL, {
      method: 'PATCH',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload.object),
    });
    const getAddressRequestURL = `/api/v1/user/address`;
    const response = yield call(request, getAddressRequestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.editAddressSuccess(response.data));
    yield put(actions.getAddressListSuccess(response.data));
  } catch (err: any) {
    yield put(actions.getAddressListError(err));
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.editAddressError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.editAddressError(err.response));
    } else {
      yield put(actions.editAddressError(err));
    }
  }
}

function* addAddress(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v1/user/address`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.addAddressSuccess(response.data));
    console.log('confirm address', response.data);
    if (response.data.deliverable === true) {
      if (window.fbq) {
        window.fbq('track', 'registered_with_deliverable');
      }
      Mixpanel.track(actionConstants.REGISTERED_WITH_DELIVERABLE);
      ReactGA.event({
        category: AnalyticsConstants.REGISTERED_WITH_DELIVERABLE,
        action: 'register_with_deliverable',
        label: `${response.data.address}`,
      });
    } else {
      if (window.fbq) {
        window.fbq('track', 'registered_with_not_deliverable');
      }
      Mixpanel.track(actionConstants.REGISTERED_WITH_NOT_DELIVERABLE);
      ReactGA.event({
        category: AnalyticsConstants.REGISTERED_WITH_NOT_DELIVERABLE,
        action: 'register_with_not_deliverable',
        label: `${response.data.address}`,
      });
    }
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.addAddressError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.addAddressError(err.response));
    } else {
      yield put(actions.addAddressError(err));
    }
  }
}

function* deleteAddress(action: PayloadAction<any>) {
  try {
    const requestURL = `/api/v1/user/address/${action.payload}`;
    yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const getAddressRequestURL = `/api/v1/user/address`;
    const response = yield call(request, getAddressRequestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(actions.getAddressListSuccess(response.data));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getAddressListError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getAddressListError(err.response));
    } else {
      yield put(actions.getAddressListError(err));
    }
  }
}

function* launchAddressList() {
  try {
    const requestURL = `/api/v1/user/launch`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    localStorage.setItem(
      'instant_delivery_name',
      response.instant_delivery_title,
    );

    if (response.stop_next_day_delivery === true) {
      localStorage.setItem('Delivery-Type', 'instant');
    }

    yield put(actions.launchAddressSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.launchAddressError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.launchAddressError(err.response));
    } else {
      yield put(actions.launchAddressError(err));
    }
  }
}

export function* addressSaga() {
  yield takeLatest(actions.getAddressList.type, getAddressList);
  yield takeLatest(actions.addAddress.type, addAddress);
  yield takeLatest(actions.editAddress.type, editAddress);
  yield takeLatest(actions.deleteAddress.type, deleteAddress);
  yield takeLatest(actions.launchAddress.type, launchAddressList);
}

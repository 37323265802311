import { call, put, takeLatest } from 'redux-saga/effects';
import { searchActions as actions } from '.';
import { PayloadAction } from '@reduxjs/toolkit';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { maintenanceActions } from '../../../../Maintenance/slice';
function* getSearch(action: PayloadAction<any>) {
  try {
    if (action.payload) {
      // const requestURL = `/api/v1/user/items/search/${action.payload}?sort_by=price_asc`;
      const requestURL = `/api/public/skip-login/items?query=${action.payload}`;
      const response = yield call(request, requestURL, {
        method: 'GET',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
        },
      });
      yield put(actions.getSearchSuccess(response.data));
    } else {
      yield put(actions.getSearchSuccess([]));
    }
    if (window.fbq) {
      window.fbq('track', 'Search', action.payload);
    }
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(actions.getSearchError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(actions.getSearchError(err.response));
    } else {
      yield put(actions.getSearchError(err));
    }
  }
}

export function* searchSaga() {
  yield takeLatest(actions.getSearch.type, getSearch);
}

/**
 *
 * Stepper
 *
 */
import { ActiveHR, CardWrapper, HR, StepperWrapper } from './index.styled';
interface Props {
  stepDetails: any;
  currentStep: String;
}

export function Stepper(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stepDetails, currentStep } = props;

  const renderHrTag = (step, index) => {
    if (!(index !== stepDetails.length - 1)) return '';
    if (step.isDone) return <ActiveHR />;
    return <HR />;
  };

  const renderStepCard = (step, index) => {
    return (
      <>
        <CardWrapper>
          <div
            className={`step ${
              currentStep === step.stepName ? 'current_step' : ''
            } ${step.isDone ? 'isDone' : ''}`}
          >
            {step.isDone ? <h4>&#10004;</h4> : <h4>{index + 1}</h4>}
          </div>
          <p className="heading">{step.stepName}</p>
        </CardWrapper>
        {renderHrTag(step, index)}
      </>
    );
  };

  return <StepperWrapper>{stepDetails.map(renderStepCard)}</StepperWrapper>;
}

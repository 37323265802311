import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useIsAuthenticatedHook from 'app/hooks/useIsAuthenticatedHook';
import LoadingGif from 'assets/mandi.svg';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';

const PublicRoute = (props: any) => {
  React.useEffect(() => {
    Mixpanel.track(componentConstants.REGISTER_ROUTE);
  });
  const { ...rest } = props;

  const {
    state,
    checkIsAuthenticated,
    checkIsRegisteredBool,
  } = useIsAuthenticatedHook();

  useEffect(() => {
    checkIsAuthenticated();
  }, []);

  if (state.loading) {
    return (
      <div className="app-loader">
        <img src={LoadingGif} alt="loading..." />
      </div>
    );
  }

  if (checkIsRegisteredBool() && state.isAddressEntered) {
    return <Redirect to="/address-list" />;
  }
  if (!state.isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} />;
};

export default PublicRoute;

/**
 *
 * Carousel
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Carousel as RCarousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useCarouselSlice } from './slice';
import { selectFeaturedImages } from './slice/selectors';

interface Props {}

export function Carousel(props: Props) {
  const dispatch = useDispatch();
  const { actions } = useCarouselSlice();
  const featuredImages: any = useSelector(selectFeaturedImages);

  useEffect(() => {
    dispatch(actions.getFeaturedImages());
  }, []);

  if (featuredImages.loading) {
    return (
      <LoadingCarousel>
        <div className="loading" />
      </LoadingCarousel>
    );
  }
  if (featuredImages.error) {
    return <div>Error Occurred</div>;
  }
  return (
    <CarouselWrapper className="carousel-wrapper">
      <RCarousel
        indicators={true}
        controls={false}
        slide
        touch
        interval={3000}
        pause={'hover'}
      >
        {featuredImages.data.map((item: any) =>
          item.image ? (
            <RCarousel.Item key={item.image}>
              <img className="" src={item.image} alt={item.image} />
            </RCarousel.Item>
          ) : (
            <RCarousel.Item key={item.video}>
              <video autoPlay muted preload="auto" loop>
                <source src={item.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </RCarousel.Item>
          ),
        )}
      </RCarousel>
    </CarouselWrapper>
  );
}

const LoadingCarousel = styled.div`
  margin-top: 1.5rem;
  .loading {
    height: 340px;
  }
`;
const CarouselWrapper = styled.div`
  margin-top: 1.5rem;
  .carousel-inner {
    border-radius: 5px;
  }
  .carousel-indicators {
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }
  .carousel-item {
    height: 600px;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 1399px) {
      height: 535px;
    }
    @media screen and (max-width: 1199px) {
      height: 450px;
    }
    @media screen and (max-width: 991px) {
      height: 400px;
    }
    @media screen and (max-width: 767px) {
      height: 300px;
    }
    @media screen and (max-width: 575px) {
      height: 250px;
    }
  }
`;

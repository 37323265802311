/**
 *
 * TermsConditions
 *
 */
import React, { memo } from 'react';
import ShippingAndDeliveryStyled from './index.styled';

interface Props {}

export const ShippingAndDelivery = memo((props: Props) => {
  return (
    <ShippingAndDeliveryStyled>
      <h5>Shipping and Delivery Policy</h5>
      <div className="intro">
        <p>
          We ship the products on the next day of the order. While placing the
          order We will show the delivery date and timeslot to you. You can
          choose the date and time slot. Based on the timeslot we will try to
          deliver the products. The time of delivery may vary according to the
          conditions.
        </p>
      </div>
    </ShippingAndDeliveryStyled>
  );
});

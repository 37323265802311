import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'app/pages/slice';
import { selectProfile } from 'app/pages/slice/selectors';
import { mainPageActions } from 'app/pages/MainPage/slice';
import { addressActions } from 'app/pages/ManageAddress/slice';
import useCountLogger from './useCountLogger';
import { selectLaunchAddressList } from 'app/pages/ManageAddress/slice/selectors';
import { request } from 'utils/request';
import { header } from 'utils/auth';
import { useHistory } from 'react-router-dom';

interface StateProps {
  loading: boolean;
  isAuthenticated: boolean;
  isRegistered: boolean;
  isAddressEntered: boolean;
}
const useIsAuthenticatedHook = () => {
  const dispatch = useDispatch();
  const { logger } = useCountLogger('src/app/hooks/useIsAuthenticatedHook.ts');
  const [state, setState] = useState<StateProps>({
    loading: true,
    isAuthenticated: false,
    isRegistered: false,
    isAddressEntered: false,
  });
  const history = useHistory();
  const profile = useSelector(selectProfile);
  const launchAddressList = useSelector(selectLaunchAddressList);

  useEffect(() => {
    // If enable_instant_delivery is false then set Delivery Type to app
    if (
      profile?.data?.enable_instant_delivery === false &&
      !localStorage.getItem('Delivery-Type')
    ) {
      dispatch(
        mainPageActions.setEnableInstantDelivery({
          enable_instant_delivery: profile.data.enable_instant_delivery,
        }),
      );
    }
    if (!profile.loading && !launchAddressList.loading) {
      if (profile.error) {
        checkError(profile.error);
      } else {
        let userObj = profile.data;
        const isAddressExist =
          launchAddressList?.data?.address &&
          Array.isArray(launchAddressList?.data?.address) &&
          launchAddressList.data.address.length > 0;

        if (!userObj.name) {
          logger('inside loading', {
            profile,
            loading: false,
            isAuthenticated: true,
            isRegistered: true,
            isAddressEntered: isAddressExist,
          });

          setState({
            loading: false,
            isAuthenticated: true,
            isRegistered: true,
            isAddressEntered: isAddressExist,
          });
        } else {
          logger('inside else loading', {
            launchAddressList,
            loading: false,
            isAuthenticated: true,
            isRegistered: false,
          });
          setState({
            loading: false,
            isAuthenticated: true,
            isRegistered: false,
            isAddressEntered: isAddressExist,
          });
        }
      }
    }
  }, [profile, launchAddressList]);

  const checkIfNoAddress = () => {
    const isAddressExist =
      launchAddressList?.data?.address &&
      Array.isArray(launchAddressList?.data?.address) &&
      launchAddressList.data.address.length > 0;
    return !isAddressExist;
  };

  const checkIsAuthenticated: any = async () => {
    let token = localStorage.getItem('user_token');
    try {
      const isAddressExist =
        launchAddressList?.data?.address &&
        Array.isArray(launchAddressList?.data?.address) &&
        launchAddressList.data.address.length > 0;
      if (token) {
        dispatch(authActions.getProfile());
        dispatch(addressActions.launchAddress());
      } else {
        logger("if token doesn't exist", {
          loading: false,
          isAuthenticated: false,
          isRegistered: false,
        });
        setState(() => ({
          loading: false,
          isAuthenticated: false,
          isRegistered: false,
          isAddressEntered: isAddressExist,
        }));
      }
    } catch (err) {
      checkError(err);
    }
  };
  const checkError = err => {
    let token = localStorage.getItem('user_token');
    const isAddressExist =
      launchAddressList?.data?.address &&
      Array.isArray(launchAddressList?.data?.address) &&
      launchAddressList.data.address.length > 0;
    if (err && (err.status === 401 || err.status === 404)) {
      localStorage.removeItem('user_token');
      window.location.reload();
    } else if (token) {
      logger('if token ', {
        token,
        loading: false,
        isAuthenticated: true,
        isRegistered: false,
      });
      setState({
        loading: false,
        isAuthenticated: true,
        isRegistered: false,
        isAddressEntered: isAddressExist,
      });
    } else {
      logger('if token not', {
        token,
        loading: false,
        isAuthenticated: false,
        isRegistered: false,
      });
      setState({
        loading: false,
        isAuthenticated: false,
        isRegistered: false,
        isAddressEntered: isAddressExist,
      });
    }
  };

  const checkToken: any = () => {
    let token = localStorage.getItem('user_token');
    logger('if token exist check', {
      token,
      condition: !!token,
    });
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsRegistered = async (): Promise<void> => {
    let token = localStorage.getItem('user_token');
    if (token) {
      const requestURL = `/api/v1/user/launch`;
      const response: any = await request(requestURL, {
        method: 'GET',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
        },
      });
      console.log({ response });
      const userObj = response.profile;
      logger('checkIsRegistered', {
        userObj,
      });

      if (!userObj.name) {
        history.push(`/onboarding`);
        return;
      }
    }
    return;
  };

  const checkIsRegisteredBool = (): boolean => {
    let token = localStorage.getItem('user_token');
    logger('checkIsRegistered', {
      token,
      condition: !!token,
    });
    if (token) {
      let userObj = profile.data;
      logger('checkIsRegistered', {
        userObj,
      });

      if (userObj.name) {
        return true;
      }
    }
    return false;
  };

  return {
    state,
    checkIsAuthenticated,
    checkToken,
    checkIsRegistered,
    checkIfNoAddress,
    checkIsRegisteredBool,
  };
};

export default useIsAuthenticatedHook;

/**
 *
 * Header
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { MetaBar } from 'app/components/MetaBar';
import LogoSvg from 'assets/logo.svg';
import { Image, Form } from 'react-bootstrap';
import HamburgerIcon from 'assets/hamburger.svg';
import LocationIcon from 'assets/pin.svg';
// import GoIcon from 'assets/right-arrow.svg';
import Search from 'assets/search.svg';
import Back from 'assets/back.svg';
import { homePageActions } from 'app/pages/HomePage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { CartIcon } from '../CartIcon';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MetaBarBottom,
  MetaBarTop,
  DeliveringTo,
  Address,
  SearchStyled,
  MetaBarRight,
  MetabarLeft,
  Hamburger,
  RoundedIcon,
  SkipDeliveringTo,
} from './index.styled';
import { addressActions } from 'app/pages/ManageAddress/slice';
import {
  selectAddressLoading,
  selectDefaultAddress,
} from 'app/pages/ManageAddress/slice/selectors';
import { Mixpanel } from 'app/mixpanel';
import actionConstants from 'app/mixpanel/constants/action';
import { useQuery } from 'app/hooks/useQuery';
import useDefaultAddress from 'app/pages/ManageAddress/hooks/useDefaultAddress';

interface Props {}

export function Header(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const loading = useSelector(selectAddressLoading);
  const defaultAddress = useSelector(selectDefaultAddress);
  const { isDeliverable } = useDefaultAddress();

  let isHome =
    location.pathname === '/home' || location.pathname.includes('category');
  let isSkipLoginHome =
    location.pathname === '/sl' || location.pathname.includes('category');
  let isSkipLoginPage = location.pathname.includes('/sl');

  const onClickAddress = () => {
    Mixpanel.track(actionConstants.DISPATCH_API);
    dispatch(homePageActions.setShowAddress(true));
  };
  useEffect(() => {
    if (!isSkipLoginPage) dispatch(addressActions.getAddressList());
  }, []);

  const localAddress = React.useMemo(() => {
    if (isSkipLoginPage) {
      let address = localStorage.getItem('location_info');

      if (!!address) return JSON.parse(address);

      history.push('/check-delivery-location?sl=1');
    }
    return null;
  }, []);

  return (
    <MetaBar className="metabar">
      <MetaBarTop className="metabar-top">
        {location.pathname !== '/' && (
          <MetabarLeft className="metabar-left">
            <a href="/home" className="logo">
              <img src={LogoSvg} alt="" />
            </a>
            <Hamburger
              className="hamburger"
              onClick={() => {
                Mixpanel.track(actionConstants.DISPATCH_API);
                dispatch(homePageActions.setShowSideBar(true));
              }}
            >
              <Image src={HamburgerIcon} alt="hamburger" />
            </Hamburger>
            {!(isHome || isSkipLoginHome) && (
              <RoundedIcon
                className="back-navigation"
                onClick={() => {
                  Mixpanel.track(actionConstants.LINK);

                  if (location.pathname.includes('/home/orders/')) {
                    if (query.has('backToHome')) {
                      history.push('/home');
                    } else {
                      history.push('/home/orders');
                    }
                  } else if (location.pathname.includes('/home/orders')) {
                    history.push('/home');
                  } else if (history.action !== 'POP') {
                    history.goBack();
                  } else if (location.pathname.includes('/sl/')) {
                    history.push('/sl');
                  } else {
                    history.push('/home');
                  }
                }}
              >
                <Image src={Back} alt="back" />
              </RoundedIcon>
            )}
          </MetabarLeft>
        )}
        <MetaBarRight className="metabar-right">
          {(isHome || isSkipLoginHome) && (
            <SearchStyled
              className="search-bar"
              onClick={(e: any) => {
                Mixpanel.track(actionConstants.DISPATCH_API);

                dispatch(homePageActions.setShowSearch(true));
              }}
            >
              <Form.Control
                type="text"
                className="search"
                placeholder="Search for any vegetable or fruits"
                disabled={!isDeliverable}
              />
              <figure>
                <Image src={Search} alt="search" />
              </figure>
            </SearchStyled>
          )}

          <CartIcon />
        </MetaBarRight>
      </MetaBarTop>
      {!loading && defaultAddress && location.pathname === '/home' ? (
        <MetaBarBottom className="metabar-bottom">
          <h6>
            <b>Delivering to:</b> {defaultAddress.address_name}
          </h6>
          <DeliveringTo className="delivering-to">
            <Image className="pin" src={LocationIcon} alt="pin" />
            <Address onClick={onClickAddress}>
              <address>{defaultAddress.address}</address>
            </Address>
            {/* <Image className="go" src={GoIcon} alt="go" /> */}
          </DeliveringTo>
        </MetaBarBottom>
      ) : null}
      {!!localAddress && location.pathname === '/sl' ? (
        <MetaBarBottom className="metabar-bottom">
          <h6>
            <b>Delivering to:</b>
          </h6>
          <SkipDeliveringTo className="delivering-to-skip">
            <Image className="pin" src={LocationIcon} alt="pin" />
            <Address>
              <address>{localAddress.address}</address>
            </Address>
            {/* <Image className="go" src={GoIcon} alt="go" /> */}
          </SkipDeliveringTo>
        </MetaBarBottom>
      ) : null}
    </MetaBar>
  );
}

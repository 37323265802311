import styled from 'styled-components/macro';

const InfoTop = styled.div`
  h6 {
    margin-bottom: 0;
  }
`;
const ListItem = styled.li`
  display: flex;
  padding: 1px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  position: relative;
  .tag {
    position: absolute;
    left: 9px;
    z-index: 1;
    top: 9px;
    max-width: 70px;
  }
`;

const OutOfStockListItem = styled.li`
  opacity: 0.6;
  display: flex;
  padding: 1px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  position: relative;
  .tag {
    position: absolute;
    left: 9px;
    z-index: 1;
    top: 9px;
    max-width: 70px;
  }
`;

const Left = styled.div`
  display: flex;
  margin-right: 12px;
  > img {
    max-width: 50px;
    max-height: 50px;
    flex-shrink: 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  > h6 {
    margin-bottom: 0;
    padding-top: 6px;
    color: #acacac;
    margin-top: auto;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  z-index: 5;
  .add_btn {
    width: 120px;
    height: 40px;
    border: 2px solid #b1b1b2;
    border-radius: 5px;
    background-color: #ffffff;
    color: #00855b;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    position: relative;
  }
  @media screen and (max-width: 575px) {
    justify-content: flex-end;
    margin-top: 8px;
  }
  @media screen and (max-width: 991px) {
    .add_btn {
      width: 100px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 575px) {
    .add_btn {
      width: 90px;
      font-size: 14px;
    }
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
  .uom_tag {
    display: flex;
    width: 300px;
    cursor: pointer;
    justify-content: space-around;
    background-color: #f0f0f0;
    border-radius: 15px;
    margin: 0.8rem 0 0.8rem 0;
    .uom_name {
      font-weight: 500;
      font-size: 14px;
      color: #8a8a8e;
      margin: 0.3rem 5rem 0.3rem 1rem;
    }
    .arrow {
      color: #8a8a8e;
      width: 20px;
    }
  }
`;

const OutOfStock = styled.div`
  background: rgba(200, 53, 50, 0.1);
  display: flex;
  border-radius: 5px;
  padding: 0.5rem;

  span {
    z-index: 1;
    font-size: 0.8rem;
    font-weight: 600;
    color: #c83532;
  }
`;

const MoreOption = styled.div`
  position: absolute;
  font-size: 11px;
  width: 120px;
  left: 0;
  top: -10px;
  p {
    color: gray;
    background-color: #ffffff;
    width: max-content;
    margin: 0 auto;
    padding: 0 0.1rem 0 0.1rem;
  }
`;

export {
  InfoTop,
  ListItem,
  OutOfStockListItem,
  Left,
  Value,
  Details,
  Info,
  OutOfStock,
  MoreOption,
};

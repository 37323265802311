import { mainPageActions } from 'app/pages/MainPage/slice';
import { useDispatch } from 'react-redux';
import { header } from 'utils/auth';
import { request } from 'utils/request';

const useNextDayDelivery = () => {
  const dispatch = useDispatch();
  const getLaunchApiData = async () => {
    try {
      let token = localStorage.getItem('user_token');
      if (!token) return null;

      const requestURL = `/api/v1/user/launch`;
      const response: any = await request(requestURL, {
        method: 'GET',
        headers: {
          Authorization: header(),
          Accept: 'application/json',
        },
      });

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const isNextDayDeliveryOldFlowEnabled = async (): Promise<boolean> => {
    try {
      const launch = await getLaunchApiData();
      if (!launch) return false;
      if (launch.stop_next_day_delivery === true) {
        dispatch(
          mainPageActions.setEnableInstantDelivery({
            show_instant_delivery: true,
          }),
        );
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  };

  const setIsNextDayDelivery = async () => {
    try {
      const isNextDayOldFlowEnable = await isNextDayDeliveryOldFlowEnabled();
      localStorage.setItem(
        'Next-Day-Old-Flow',
        isNextDayOldFlowEnable ? 'true' : 'false',
      );
    } catch (error) {
      localStorage.setItem('Next-Day-Old-Flow', 'false');
    }
  };

  const checkIsNextDayDelivery = () => {
    const value = localStorage.getItem('Next-Day-Old-Flow');
    if (value) return value === 'true' ? true : false;
    return false;
  };

  return { checkIsNextDayDelivery, setIsNextDayDelivery };
};
export default useNextDayDelivery;

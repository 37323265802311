/**
 *
 * AddAddress
 *
 */
import React from 'react';
import { Form } from 'react-bootstrap';
// import { AddressAutoComplete } from 'app/components/AddressAutoComplete';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { selectProfile } from 'app/pages/slice/selectors';
import { AddressAutoComplete } from '../AddressAutoComplete';
interface Props {
  register: any;
  errors: any;
  setValue: Function;
  control: any;
}

const addressTypeOptions = [
  {
    id: 1,
    type: 'Home',
    value: 'Home',
  },
  {
    id: 2,
    type: 'Office',
    value: 'Office',
  },
  {
    id: 3,
    type: 'Other',
    value: 'Other',
  },
];

export const AddAddress = (props: Props) => {
  const { register, errors, setValue, control } = props;
  const profile = useSelector(selectProfile);

  return (
    <AddAddressStyled className="add-address">
      <Form.Group>
        <AddressAutoComplete
          register={register}
          control={control}
          setValue={setValue}
        />
        {errors.addressVal && (
          <Form.Text className="required">Address is invalid.</Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name" style={{ marginTop: '6rem' }}>
        <Form.Label>Door No./Flat/Block Number</Form.Label>
        <Form.Control
          {...register('flat', { required: true })}
          type="text"
          placeholder="Door No./Flat/Block"
        />
        {errors.flat && (
          <Form.Text className="required">
            Door No./Flat/Block is required
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Street and Locality Area</Form.Label>
        <Form.Control
          {...register('locality', { required: true })}
          type="text"
          placeholder="Street and Locality Area"
        />
        {errors.locality && (
          <Form.Text className="required">
            Street and locality area is required.
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Landmark</Form.Label>
        <Form.Control
          {...register('landmark', { required: false })}
          type="text"
          placeholder="Landmark"
        />
      </Form.Group>
      <Form.Group className="user-name">
        <Form.Label>Type of Address</Form.Label>
        {profile.data?.profile?.user_type === 'company' ? (
          <Form.Control
            {...register('name', { required: true })}
            type="text"
            placeholder="Ex: Home, Office"
          />
        ) : (
          <RadioGroup className="radio-group">
            {addressTypeOptions.map(e => {
              return (
                <Form.Check
                  key={`time-${e.id}`}
                  type={'radio'}
                  name="time"
                  id={e.type}
                  label={e.type}
                  value={e.value}
                  {...register('name', { required: true })}
                />
              );
            })}
          </RadioGroup>
        )}

        {errors.name && (
          <Form.Text className="required">Address Name is required.</Form.Text>
        )}
      </Form.Group>
    </AddAddressStyled>
  );
};

const AddAddressStyled = styled.div`
  // margin-top: 1.5rem;
  .user-name {
    margin-top: 1rem;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 12px 6px 0;
  }
  .form-check {
    padding-left: 0;
    label {
      background-color: #f0f0f0;
      border-radius: 5px;
      font-size: 14px;
      color: #000;
      cursor: pointer;

      padding: 12px;
    }
    input {
      display: none;
      &:checked {
        & + label {
          background-color: #00855b;
          color: white;
        }
      }
    }
  }
`;

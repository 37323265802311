/**
 *
 * ReactImage
 *
 */
import React, { memo } from 'react';

import { Img } from 'react-image';
import LogoSvg from 'assets/logo-vector.svg';

interface Props {
  src: string;
  alt?: string;
  className?: string;
  loading?: any;
}

export const ReactImage = memo((props: Props) => {
  const { src, className, alt, loading } = props;
  return (
    <Img
      src={[src, LogoSvg]}
      loader={
        loading || <img src={LogoSvg} alt="loading" className="loading-logo" />
      }
      className={loading ? 'loading-logo' : className}
      alt={alt}
    />
  );
});

/**
 *
 * Check Delivery Location
 *
 */
import React from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { AddressAutoComplete } from './components/AddressAutoComplete';
import { useLoginPageSlice } from '../LoginPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { selectLocationCheck } from '../LoginPage/slice/selectors';
import Not_Deliverable from 'assets/not_deliverable.svg';
import Deliverable from 'assets/deliverable.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link, useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CheckDeliveryLocationModal } from '../LoginPage/components/CheckDeliveryLocationModal';
import { useQuery } from 'app/hooks/useQuery';
import { CheckDeliveryLocationStyle } from './index.styled';
import { storage } from './storage';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';
// import debounce from 'lodash.debounce';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var debounce = require('lodash.debounce');

interface Props {
  register: any;
  errors: any;
  setValue: Function;
  control: any;
}

export const CheckDeliveryLocation = (props: Props) => {
  const { actions } = useLoginPageSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({});
  const watchAddress = useWatch({
    control,
    name: 'addressVal',
  });

  const locationCheck = useSelector(selectLocationCheck);

  const [isSkipLoginActivated, setIsSkipLoginActivated] = React.useState(false);
  const skipLogin = query.get('sl');

  React.useEffect(() => {
    /* Skip Login Activated */
    if (Number(skipLogin) === 1) setIsSkipLoginActivated(true);
    /* Get Latitude and Longitude */
    getLatLan();
  }, []);

  React.useEffect(() => {
    Mixpanel.track(pageConstants.CHECK_DELIVERY_LOCATION_PAGE);
  });

  React.useEffect(() => {
    const val = getValues();
    const payload = {
      latitude: val.addressVal.lat,
      longitude: val.addressVal.lng,
    };

    dispatch(actions.checkDeliveryLocation(payload));
  }, [watchAddress]);

  const getLatLan = async () => {
    navigator?.geolocation.getCurrentPosition(
      async ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        const payload = {
          latitude: pos.lat,
          longitude: pos.lng,
        };
        dispatch(actions.checkDeliveryLocation(payload));
      },
      () => {
        // alert('Sorry, browser does not support geolocation!');
      },
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true },
    );
  };

  const onSubmit = async val => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const payload = {
        latitude: val.addressVal.lat,
        longitude: val.addressVal.lng,
      };
    } catch (err) {
      console.log(err);
    }
  };

  const renderSelectedLocation = () => {
    const val = getValues();
    if (val?.addressVal?.address) {
      return (
        <div className="selected-location-container">
          <h5 className="heading">Selected Location</h5>
          <p className="address">{val.addressVal.address}</p>
        </div>
      );
    }
    return <></>;
  };

  const renderDeliveryStatus = () => {
    if (!locationCheck.data) {
      return <></>;
    } else {
      if (locationCheck.data.deliverable === true) {
        return (
          <div className="delivery-status-wrapper">
            <h5 className="heading">Location Status</h5>
            <div className="status">
              <Image className="img" src={Deliverable} alt="not-deliverable" />
              <p className="text">Deliverable</p>
            </div>
          </div>
        );
      }

      return (
        <div className="delivery-status-wrapper">
          <h5 className="heading">Location Status</h5>
          <div className="status">
            <Image
              className="img"
              src={Not_Deliverable}
              alt="not-deliverable"
            />
            <p className="text">Not Deliverable</p>
          </div>
          <p className="not-deliverable-text">
            We are sorry, we do not deliver to this location
          </p>
        </div>
      );
    }
  };

  const redirectToCategoriesPage = (): void => {
    const val = getValues();
    storage.setLocationInfo(val.addressVal);

    history.push('/sl');
  };

  const renderButton = () => {
    const style = {
      border: 'none',
      borderRadius: '30px',
      margin: '0 auto 1rem',
      // width: '',
    };

    if (isSkipLoginActivated && locationCheck?.data?.deliverable === true)
      return (
        <Button
          className="skip_login_btn"
          variant="warning"
          style={style}
          onClick={
            (Mixpanel.track(actionConstants.GO_BACK_TO_HOME),
            redirectToCategoriesPage)
          }
        >
          Confirm and proceed
        </Button>
      );

    return (
      <Button
        className="back_login_btn"
        variant="warning"
        style={style}
        onClick={() => {
          Mixpanel.track(actionConstants.GO_BACK_TO_LOGIN);

          history.push('/login');
        }}
      >
        Back To Login
      </Button>
    );
  };

  return (
    <div className="authentication-page login-page">
      <div
        className="box"
        style={{
          padding: '0',
        }}
      >
        <CheckDeliveryLocationStyle>
          <Form
            onChange={handleSubmit(onSubmit)}
            className="verify-form-wrapper"
            style={{
              margin: '3rem 1rem 5rem 1rem ',
            }}
          >
            <Form.Group>
              <AddressAutoComplete
                register={register}
                control={control}
                setValue={setValue}
              />
              {errors.addressVal && (
                <Form.Text className="required">Address is invalid.</Form.Text>
              )}
            </Form.Group>
          </Form>
          {renderSelectedLocation()}
          {renderDeliveryStatus()}
        </CheckDeliveryLocationStyle>
        <div>{renderButton()}</div>
      </div>
    </div>
  );
};

import styled from 'styled-components/macro';

const WhyMadrasMandiSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  overflow: hidden;
  .bg-image {
    position: absolute;
    bottom: -35%;
    right: -25%;
    width: 70%;
    @media screen and (max-width: 1399px) {
      bottom: -25%;
    }
    @media screen and (max-width: 1199px) {
      bottom: -20%;
    }
    @media screen and (max-width: 991px) {
      bottom: -2%;
      right: -22%;
    }
    @media screen and (max-width: 767px) {
      bottom: -3%;
      right: initial;
      left: -20%;
    }
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .centered {
    font-size: 4rem;
    line-height: 1;
    font-weight: 800;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    max-width: 310px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    margin-top: 2rem;
    transform: translateX(-50%);
    b {
      color: #00855b;
    }
    @media screen and (max-width: 991px) {
      position: relative;
      left: initial;
      top: initial;
      transform: initial;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 2.5rem;
    }
    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.75rem;
    }
  }
  .row {
    margin-top: 3.75rem;
  }
  .card {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border: 0;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 1.5rem;
    @media screen and (max-width: 1199px) {
      padding: 16px;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 575px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const ImageController = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  position: relative;
  span {
    text-transform: uppercase;
    text-align: center;
    max-width: 185px;
    width: 100%;
    margin: 1.5rem auto 0;
  }
  .steps {
    position: absolute;
    max-width: 200px;
    width: 100%;
    &.step1 {
      top: calc(100% + 1rem);
      left: 65%;
      @media screen and (max-width: 767px) {
        transform: rotate(-15deg);
        top: 100%;
        max-width: 85px;
      }
      @media screen and (max-width: 480px) {
        max-width: 60px;
        left: 50px;
      }
    }
    &.step2 {
      top: 0%;
      left: calc(100% + 1rem);
      @media screen and (max-width: 767px) {
        top: 100%;
        left: calc(-100% + 5rem);
        transform: scaleX(-1) rotate(65deg);
      }
      @media screen and (max-width: 480px) {
        left: 0;
      }
    }
    @media screen and (max-width: 1399px) {
      max-width: 180px;
    }
    @media screen and (max-width: 1199px) {
      max-width: 140px;
    }
    @media screen and (max-width: 991px) {
      max-width: 100px;
    }
    @media screen and (max-width: 480px) {
      max-width: 60px;
    }
  }
  &.truck {
    margin-top: 30rem;
    @media screen and (max-width: 1399px) {
      margin-top: 27rem;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 22rem;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 20rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
      margin-left: auto;
    }
  }
  &.farmer {
    margin-top: 17rem;
    @media screen and (max-width: 1399px) {
      margin-top: 12rem;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 7rem;
    }
    @media screen and (max-width: 991px) {
      margin-top: 6rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 1399px) {
    max-width: 260px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 240px;
  }
  @media screen and (max-width: 991px) {
    max-width: 200px;
  }
`;

const Figure = styled.figure`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(196, 196, 196, 0.2);
  margin-bottom: 0;
  flex-shrink: 0;
  margin-right: 16px;
`;

const Details = styled.div`
  span {
    font-size: 18px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 991px) {
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export default WhyMadrasMandiSectionStyled;
export { ImageController, ImageWrapper, Figure, Details };

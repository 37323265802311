import styled from 'styled-components/macro';

const ShippingAndDeliveryStyled = styled.div`
  font-size: 14px;
  margin-top: 1.5rem;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  b {
    font-weight: 600;
  }
  h5 {
    margin-bottom: 0;
  }
  h6 {
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .card {
    > div {
      margin-top: 2rem;
    }
  }
  ol {
    margin: 0;
    padding-left: 16px;
  }
`;

export default ShippingAndDeliveryStyled;

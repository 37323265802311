import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    &:not(:first-child) {
      margin-top: 16px;
      @media screen and (max-width: 575px) {
        margin-top: 1.5rem;
      }
    }
  }
  p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 280px;
    color: white;
    line-height: 1.8;
    font-weight: 100;
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
      text-align: center;
    }
  }
  address,
  .phone {
    color: #fff;
    font-weight: 100;
    display: flex;
    img {
      margin-right: 8px;
      max-width: 25px;
      @media screen and (max-width: 575px) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: center;
      span {
        margin-top: 12px;
      }
    }
  }
  .phone {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  address {
    max-width: 240px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 575px) {
      align-items: center;
      span {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 575px) {
    align-items: center;
  }
`;
const SocialListItem = styled.li`
  a {
    display: flex;
    width: 30px;
    height: 30px;
  }
`;
const SocialList = styled.ul`
  margin: 2.5rem 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  > * {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  @media screen and (max-width: 575px) {
    margin-top: 16px;
    justify-content: center;
  }
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
const ListItem = styled.li`
  margin-top: 1.5rem;
  a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: 100;
    img {
      width: 20px;
      margin-right: 8px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 575px) {
    margin-top: 16px;
    text-align: center;
  }
`;
const FollowUs = styled.div`
  @media screen and (max-width: 575px) {
    margin-top: 1.5rem;
  }
`;
const OurLinks = styled.div`
  margin-top: 1.5rem;
  ul {
    margin-top: 2.5rem;
    @media screen and (max-width: 575px) {
      margin-top: 16px;
    }
  }
`;
const ExploreProducts = styled.div`
  margin-top: 1.5rem;
  ul {
    margin-top: 2.5rem;
    @media screen and (max-width: 575px) {
      margin-top: 16px;
    }
  }
`;
const GetInTouchSection = styled.div`
  padding-top: calc(6rem - 1.5rem);
  padding-bottom: 6rem;
  background-color: #2a3d37;
  h5 {
    color: white;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
  .row {
    > * {
      margin-top: 1.5rem;
      @media screen and (max-width: 575px) {
        margin-top: 16px;
      }
    }
  }
  .get-in-touch-form {
    max-width: 320px;
    width: 100%;
    .form-label {
      font-size: 18px;
      font-weight: 500;
    }
    textarea {
      &:focus {
        border-color: #00855b;
      }
    }
    @media screen and (max-width: 1199px) {
      max-width: 280px;
    }
    @media screen and (max-width: 991px) {
      max-width: 360px;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: calc(4rem - 1.5rem);
    padding-bottom: 4rem;
    > * {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    padding-top: calc(2rem - 1.5rem);
    padding-bottom: 2rem;
  }
`;
const BrandLogo = styled(Link)`
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-shrink: 0;
`;

export {
  BrandLogo,
  ContactDetails,
  SocialListItem,
  SocialList,
  ListItem,
  List,
  ExploreProducts,
  OurLinks,
  FollowUs,
  GetInTouchSection,
};

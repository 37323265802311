const pageConstants = {
  HOME_PAGE: 'home page',
  ABOUT_PAGE: 'about page',
  ADDRESS_LIST: 'address list page',
  AWAITING_CONFIRMATION: 'awaiting confirmation',
  CAREERS_PAGE: 'career page',
  CART_PAGE: 'cart page',
  CATEGORY_PAGE: 'category page',
  CHALLANGE_PAGE: 'challange page',
  CHANGE_PASSWORD: 'change password',
  CHECK_DELIVERY_LOCATION_PAGE: 'check delivery location',
  COMING_SOON_PAGE: 'coming soon',
  CONTACT_US_PAGE: 'contact us',
  DELIVERY_SOON_PAGE: 'delivery soon',
  EDIT_ORDER_PAGE: 'edit order page',
  HELP_FAQ: 'help and faq',
  HELP_PAGE: 'help page',
  HOW_ITS_WORKS: 'how its work',
  ITEM_PAGE: 'item page',
  KYC_PAGE: 'kyc page',
  LAUNCH_PAGE: 'launch page',
  LOGIN_PAGE: 'login page',
  MAIN_PAGE: 'main page',
  MAINTENANCE: 'maintenance',
  MANAGE_ADDRESS_PAGE: 'manage address',
  MANAGE_ADDRESS_LIST: 'manage address list',
  NOT_FOUND_PAGE: 'not found page',
  ONBOARDING_SCREEN: 'onboarding',
  ORDER_PAGE: 'order page',
  ORDER_REVIEW_PAGE: 'order review page',
  ORDERS_PAGE: 'orders page',
  PROFILE_PAGE: 'profile page',
  REDEEM_COIN_PAGE: 'redeem coin page',
  REFER_EARN_PAGE: 'refer earn page',
  REGISTER_PAGE: 'register page',
  SHIPPING_DELIVERY_PAGE: 'shipping delivery page',
  SIDE_BAR: 'side bar',
  SKIP_LOGIN: 'skip login',
  SKIP_LOGIN_CART: 'skip login cart',
  SKIP_LOGIN_CATEGORY_PAGE: 'skip login category page',
  SKIP_LOGIN_ITEM_DETAILS: 'skip login item details',
  SKIP_LOGIN_MAIN_PAGE: 'skip login main page',
  SUPPORT_SYSTEM: 'support system',
  TRANSACTION_PAGE: 'transaction page',
  WALLET_REWARD_PAGE: 'wallet reward page',
};

export default pageConstants;

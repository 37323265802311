/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * ItemPage
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddAddress,
  selectAddressList,
  selectEditAddress,
} from '../ManageAddress/slice/selectors';
import { useEffect, useRef, useState } from 'react';
import { addressActions } from '../ManageAddress/slice';
import {
  LoadingWrapper,
  AddressListStyled,
  PriceChange,
  ButonGroup,
  AddressLists,
  AddAddressStyled,
  AddAdressTitle,
  TitleWithBack,
} from './index.styled';
import { AddressItem } from '../ManageAddress/components/AddressItem';
import { Form, Button, Image, Modal } from 'react-bootstrap';
import { categoryActions } from '../CategoryPage/slice';
import { mainPageActions } from '../MainPage/slice';
import Alert from 'assets/alert.svg';
import Back from 'assets/back.svg';
import LocationSvg from 'assets/location3.svg';
import ComingSoon from 'assets/comingSoon.svg';
import { AddAddress } from './components/AddAddress';
import { EditAddress } from './components/EditAddress';
import { useForm } from 'react-hook-form';
import { homePageActions } from '../HomePage/slice';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import React from 'react';
import actionConstants from 'app/mixpanel/constants/action';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { toast } from 'react-toastify';
import { CreateAddressStepwise } from './components/CreateAddressStepwise';
import { Box, Typography } from '@mui/material';
import { request } from 'utils/request';
import { header } from 'utils/auth';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export function ManageAddressList(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.MANAGE_ADDRESS_LIST);
  });
  const addressList = useSelector(selectAddressList);
  const addAddress = useSelector(selectAddAddress);
  const [addNew, setAddNew] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isDeliverableChecked, setIsDeliverableChecked] = React.useState({
    checked: false,
    valueEntered: false,
    isDeliverable: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editAddress, setEditAddress] = useState(null);
  const modalRef: any = useRef();
  const deleteModalRef: any = useRef();
  const editAddressRequest = useSelector(selectEditAddress);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    dispatch(addressActions.getAddressList());
  }, []);

  useEffect(() => {
    if (addAddress.success) {
      setAddNew(false);
      dispatch(addressActions.getAddressList());
      dispatch(addressActions.resetAddAddress());
      history.push('/address-list');
    }
  }, [addAddress]);

  useEffect(() => {
    if (editAddressRequest.success) {
      setEditAddress(null);
      dispatch(addressActions.getAddressList());
      dispatch(addressActions.resetEditAddressSuccess());
    }
  }, [editAddressRequest]);

  useEffect(() => {
    if (showModal || deleteModal) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleKeyDown, false);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick, false);
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }
  }, [showModal, deleteModal]);

  const handleOutsideClick = (e: any) => {
    if (modalRef.current || deleteModalRef.current) {
      const element: any = modalRef.current;
      if (element && !element.contains(e.target)) {
        setShowModal(null);
      }
      const deleteElement: any = deleteModalRef.current;
      if (deleteElement && !deleteElement.contains(e.target)) {
        setDeleteModal(null);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopImmediatePropagation();
      setShowModal(null);
      setDeleteModal(null);
    }
  };

  const onClose = () => {
    reset();
    setAddNew(false);
    setDeleteModal(null);
    setShowModal(null);
    setEditAddress(null);
    dispatch(homePageActions.setShowAddress(false));
  };

  const onSubmit = async val => {
    try {
      const verify = await getCoordinates(val.addressVal.address);
      if (!verify) {
        toast.error(`Please provide full address`);
        return;
      }
      // let custom_address = val.flat + ', ' + val.locality;
      // if (val.landmark !== '') {
      //   custom_address = custom_address + ', Landmark: ' + val.landmark;
      // }
      // custom_address = custom_address + ', ' + val.addressVal.address;

      if (
        val.addressVal.address !== '' &&
        val.addressVal.lat !== 0 &&
        val.addressVal.lng !== 0 &&
        val.flat !== '' &&
        val.locality !== ''
      ) {
        dispatch(
          addressActions.addAddress({
            address_name: val.name,
            address: val.addressVal.address,
            door_no: val.flat,
            locality: val.locality,
            landmark: val.landmark,
            latitude: val.addressVal.lat,
            longitude: val.addressVal.lng,
          }),
        );
      } else {
        setError('addressVal', {
          type: 'manual',
          message: 'Invalid Address',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeDefault = item => {
    dispatch(
      mainPageActions.setEnableInstantDelivery({
        show_instant_delivery: item.show_instant_delivery,
      }),
    );

    dispatch(categoryActions.clearActiveTab());

    dispatch(addressActions.setSelectedAddress(item));
    dispatch(
      addressActions.editAddress({
        id: item.address_id,
        object: {
          ...item,
          is_default: true,
        },
      }),
    );
  };

  const renderAddressList = () => {
    if (addressList.loading) {
      return (
        <LoadingWrapper className="loading-wrapper">
          <li className="loading" />
          <li className="loading" />
          <li className="loading" />
        </LoadingWrapper>
      );
    }
    return (
      <>
        <AddressListStyled className="address-list">
          {addressList.data.map(item => (
            <AddressItem
              item={item}
              key={item.address_id}
              checked={checked}
              setShowModal={setShowModal}
              setDeleteModal={setDeleteModal}
              setEditAddress={setEditAddress}
              onChangeDefault={onChangeDefault}
            />
          ))}
        </AddressListStyled>
        <Modal
          className="modal-style price-change-modal"
          show={!!showModal}
          centered
        >
          <PriceChange className="price-change" ref={modalRef}>
            <Image src={Alert} alt="Alert" />
            <h5 className="mt-4">
              Prices and availability might change based on location
            </h5>
            <Button
              variant="warning mt-3"
              onClick={() => {
                Mixpanel.track(actionConstants.PROCEED);

                onChangeDefault(showModal);
                setShowModal(null);
              }}
            >
              Yes, I understand and proceed
            </Button>
            <Form.Group className="mt-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Don't show again"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </Form.Group>
          </PriceChange>
        </Modal>
        <Modal
          className="modal-style delete-modal"
          show={!!deleteModal}
          centered
        >
          <PriceChange className="price-change" ref={deleteModalRef}>
            <Image src={Alert} alt="Alert" />
            <h5 className="mt-4">Are your sure to delete this address?</h5>
            <ButonGroup className="button-group">
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.CANCEL);

                  setDeleteModal(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.PROCEED);

                  dispatch(addressActions.deleteAddress(deleteModal));
                  setDeleteModal(null);
                }}
              >
                Yes, remove it
              </Button>
            </ButonGroup>
          </PriceChange>
        </Modal>
      </>
    );
  };

  if (editAddress) {
    return (
      <div className="authentication-page login-page">
        <div className="box-edit-order">
          <EditAddress
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            onClose={onClose}
          />
        </div>
      </div>
    );
  }

  if (addNew) {
    const checkDeliverable = async (addressVal: any) => {
      let addressValue = addressVal;
      if (!addressValue) {
        addressValue = getValues('addressVal');
      }
      if (addressValue.lat && addressValue.lng) {
        const requestURL = `/api/v3/public/check-deliverable`;
        const response: any = await request(requestURL, {
          method: 'POST',
          headers: {
            Authorization: header(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            latitude: addressValue.lat,
            longitude: addressValue.lng,
          }),
        });
        console.log({ response });
        if (response.deliverable) {
          setIsDeliverableChecked({
            valueEntered: true,
            checked: false,
            isDeliverable: true,
          });
          return;
        } else {
          setIsDeliverableChecked({
            valueEntered: true,
            checked: false,
            isDeliverable: false,
          });
        }
      } else {
        setIsDeliverableChecked({
          valueEntered: false,
          checked: false,
          isDeliverable: false,
        });
      }
    };

    const renderAddNewButtons = () => {
      if (!isDeliverableChecked.checked) return <></>;
      return (
        <Button
          style={{ borderRadius: '10px' }}
          variant="warning"
          type="submit"
          disabled={addAddress.loading}
        >
          {addAddress.loading ? 'Adding' : 'Confirm Location'}
        </Button>
      );
    };

    const renderNextPartButton = () => {
      if (isDeliverableChecked.checked) return <></>;
      return (
        <Button
          variant="success"
          style={{ borderRadius: '10px' }}
          disabled={!isDeliverableChecked.valueEntered}
          onClick={() => {
            setIsDeliverableChecked(prevState => ({
              ...prevState,
              checked: true,
            }));
          }}
          type="button"
        >
          Proceed
        </Button>
      );
    };

    return (
      <>
        <AddAddressStyled className="authentication-page add-address">
          <div className="box-edit-order">
            <AddAdressTitle className="title">
              <div
                className="icon"
                onClick={() => {
                  if (isDeliverableChecked.checked) {
                    setIsDeliverableChecked(prevState => ({
                      ...prevState,
                      checked: false,
                    }));
                  } else {
                    setIsDeliverableChecked({
                      valueEntered: false,
                      checked: false,
                      isDeliverable: false,
                    });
                    setAddNew(false);
                    history.push('/address-list');
                  }
                }}
              >
                <Image
                  style={{
                    filter: 'brightness(0) invert(0)',
                  }}
                  src={Back}
                  alt="back"
                />
              </div>
              <h5>Add New Address</h5>
            </AddAdressTitle>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <CreateAddressStepwise
                register={register}
                errors={errors}
                setValue={setValue}
                control={control}
                checkDeliverable={checkDeliverable}
                isDeliverableChecked={isDeliverableChecked}
              />

              <Box
                sx={{
                  height: '100px',
                }}
              >
                {getValues('addressVal')?.address && (
                  <Box>
                    <Typography variant="body1">Selected Location:</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Image src={LocationSvg} alt="back" width={`20px`} />

                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: '0.2rem',
                        }}
                      >
                        {getValues('addressVal')?.address}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {!isDeliverableChecked.checked &&
                  isDeliverableChecked.valueEntered &&
                  !isDeliverableChecked.isDeliverable && (
                    <Box
                      sx={{
                        backgroundColor: '#39855B0A',
                        padding: '0.5rem 1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Image src={ComingSoon} alt="back" width={`20px`} />

                      <Typography
                        sx={{
                          color: '#39855B',
                          fontSize: '16px',
                          marginLeft: '0.2rem',
                        }}
                        variant="body1"
                      >
                        Coming soon to your location
                      </Typography>
                    </Box>
                  )}
              </Box>
              {renderAddNewButtons()}
            </Form>
            {renderNextPartButton()}
          </div>
        </AddAddressStyled>
      </>
    );
  }
  return (
    <AddressLists className="address-lists">
      <div className="authentication-page login-page">
        <div className="box">
          <TitleWithBack className="title-width-back">
            <Link to={`/address-list`}>
              <Image src={Back} alt="back" width={`20px`} />
            </Link>
            <h5
              style={{
                fontWeight: '600',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Manage address
            </h5>
          </TitleWithBack>
          {renderAddressList()}
          <Button
            variant="warning"
            onClick={() => {
              Mixpanel.track(actionConstants.ADDRESS_ADD);

              setAddNew(true);
            }}
          >
            Add New
          </Button>
        </div>
      </div>
    </AddressLists>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * SideBar
 *
 */
/* stylelint-disable no-descending-specificity */

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import LogoSvg from 'assets/logo.svg';
import Phone from './assets/phone.svg';
import OrderIcon from './assets/order.svg';
import Price from './assets/price.svg';
import Products from './assets/products.svg';
import Discount from './assets/discount.svg';
import New from 'assets/NewBanner.svg';
import CloseIcon from './assets/close.svg';
import SecureDelivery from './assets/secure-delivery.svg';
import { Home } from './svg/Home';
import { Profile } from './svg/Profile';
import { Order } from './svg/Order';
import { Help } from './svg/Help';
import { TeamsConditions } from './svg/TeamsConditions';
import { PrivacyPolicy } from './svg/PrivacyPolicy';
import { Logout } from './svg/Logout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BannerList } from './components/BannerList';
import { Button, Card, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowSideBar } from '../HomePage/slice/selectors';
import { homePageActions } from '../HomePage/slice';
// import { Wallet } from './svg/wallet';
import { selectLaunchAddressList } from '../ManageAddress/slice/selectors';
import { selectProfile } from '../slice/selectors';
import { Mixpanel } from 'app/mixpanel';
import pageConstants from 'app/mixpanel/constants/page';
import { Rewards } from './svg/Rewards';
// import { Wallet } from './svg/wallet';

interface Props extends RouteComponentProps {
  isDisable?: boolean;
}

const checkPath = (pathname: string, key) => {
  let array = pathname.split('/');
  if (array.length >= 3) {
    if (array[2] === key) {
      return true;
    }
    if (array[2] === 'item' && key === '') {
      return true;
    }
    if (array[2] === 'category' && key === '') {
      return true;
    }
  } else if ((array.length = 2)) {
    if (key === '') {
      return true;
    }
  }
  return false;
};

export function SideBar(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.SIDE_BAR);
  });
  const {
    location: { pathname },
  } = props;

  const showSideBar = useSelector(selectShowSideBar);
  const launchAddressList = useSelector(selectLaunchAddressList);
  const profile = useSelector(selectProfile);
  let isSkipLoginPage = pathname.includes('/sl');

  const dispatch = useDispatch();
  const close = () => {
    dispatch(homePageActions.setShowSideBar(false));
  };
  const modalRef: any = useRef(null);

  const handleOutsideClick = (e: any) => {
    if (modalRef !== null && modalRef.current !== null) {
      if (!modalRef.current.contains(e.target)) {
        close();
      }
    }
  };

  useEffect(() => {
    let timeoutID: any = null;
    if (showSideBar) {
      timeoutID = setTimeout(() => {
        document.addEventListener('click', handleOutsideClick);
      }, 0);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      if (timeoutID !== null) {
        clearTimeout(timeoutID);
      }
    };
  }, [showSideBar]);

  const isNewActiveOnWallet = () => {
    const isWalletEnable = !!launchAddressList.data?.profile?.profile
      ?.reward_enabled;
    const isNewEnable = !localStorage.getItem('disableNewWallet');

    return isWalletEnable && isNewEnable;
  };

  const disableNewWallet = () => {
    localStorage.setItem('disableNewWallet', '1');
  };

  const getLink = (linkToRedirect: string): string => {
    if (props?.isDisable) {
      if (linkToRedirect.includes('/terms')) return '/sl/terms';
      else if (linkToRedirect.includes('/privacy')) return '/sl/privacy';
      else if (
        linkToRedirect.includes('/orders') ||
        linkToRedirect.includes('/profile')
      )
        return '/login?sl=0';
      else if (pathname.includes('/sl/')) return '/sl';
      else return '/login?sl=0';
    }
    return linkToRedirect;
  };

  const renderWallet = () => {
    return (
      <Tab className={checkPath(pathname, 'wallet') ? 'active' : ''}>
        <Link
          to={getLink('/home/wallet')}
          onClick={() => {
            disableNewWallet();
            close();
          }}
        >
          <figure>
            {isNewActiveOnWallet() ? <Image src={New} alt="alt" /> : <></>}
            <Rewards />
          </figure>
          {/* Wallet and Rewards */}
          MM Rewards
        </Link>
      </Tab>
    );
  };

  // const renderRewards = () => {
  //   return (
  //     <Tab className={checkPath(pathname, 'reward') ? 'active' : ''}>
  //       <Link to={getLink('/home/rewards')} onClick={() => close()}>
  //         <figure>
  //           <Rewards />
  //         </figure>
  //         Rewards
  //       </Link>
  //     </Tab>
  //   );
  // };

  // const renderRewards = () => {
  //   return (
  //     <Tab className={checkPath(pathname, 'reward') ? 'active' : ''}>
  //       <Link to={getLink('/home/rewards')} onClick={() => close()}>
  //         <figure>
  //           <Wallet />
  //         </figure>
  //         Rewards
  //       </Link>
  //     </Tab>
  //   );
  // };

  return (
    <SideBarStyled className={`${showSideBar ? 'active' : ''}`} ref={modalRef}>
      <HamburgerController className="hamburger-controller">
        <Link to={getLink('/home')} className="logo">
          <img src={LogoSvg} alt="" />
        </Link>
        <Close onClick={() => close()}>
          <Image src={CloseIcon} alt="close" />
        </Close>
      </HamburgerController>
      <Tabs className="tabs">
        <Tab className={checkPath(pathname, '') ? 'active' : ''}>
          <Link to={getLink('/home')} onClick={() => close()}>
            <>
              <figure>
                <Home />
              </figure>
              Home
            </>
          </Link>
        </Tab>
        <Tab className={checkPath(pathname, 'profile') ? 'active' : ''}>
          <Link to={getLink('/home/profile')} onClick={() => close()}>
            <figure>
              <Profile />
            </figure>
            Profile
          </Link>
        </Tab>
        <Tab className={checkPath(pathname, 'orders') ? 'active' : ''}>
          <Link to={getLink('/home/orders')} onClick={() => close()}>
            <figure>
              <Order />
            </figure>
            Your Orders
          </Link>
        </Tab>
        <Tab
          className={
            checkPath(pathname, 'help') || checkPath(pathname, 'support-system')
              ? 'active'
              : ''
          }
        ></Tab>
        <Tab className={checkPath(pathname, 'wallet') ? 'active' : ''}>
          {!!launchAddressList.data?.profile?.profile?.reward_enabled
            ? renderWallet()
            : ''}
        </Tab>
        {/* <Tab className={checkPath(pathname, 'reward') ? 'active' : ''}>
          {!!launchAddressList.data?.profile?.profile?.reward_enabled
            ? renderRewards()
            : ''}
        </Tab> */}
        {/* <Tab className={checkPath(pathname, 'refer') ? 'active' : ''}>
          <Link to="/home/refer-earn" onClick={() => close()}>
            <figure>
              <Order />
            </figure>
            Refer and Earn
          </Link>
        </Tab> */}
        {!isSkipLoginPage && (
          <Tab className={checkPath(pathname, 'help') ? 'active' : ''}>
            <Link to={getLink('/home/help')} onClick={() => close()}>
              <figure>
                <Help />
              </figure>
              Need Help
            </Link>
          </Tab>
        )}
        <Tab className={checkPath(pathname, 'terms') ? 'active' : ''}>
          <Link to={getLink('/home/terms')} onClick={() => close()}>
            <figure>
              <TeamsConditions />
            </figure>
            Terms & Conditions
          </Link>
        </Tab>
        <Tab className={checkPath(pathname, 'privacy') ? 'active' : ''}>
          <Link to={getLink('/home/privacy')} onClick={() => close()}>
            <figure>
              <PrivacyPolicy />
            </figure>
            Privacy Policy
          </Link>
        </Tab>
        {!!profile?.data?.profile && (
          <Tab
            className={`logout ${
              checkPath(pathname, 'logout') ? 'active' : ''
            }`}
            onClick={() => {
              localStorage.removeItem('user_token');
              localStorage.removeItem('disableNewWallet');
              localStorage.removeItem('Delivery-Type');
              window.location.reload();
            }}
          >
            <figure>
              <Logout />
            </figure>
            Logout
          </Tab>
        )}
      </Tabs>
      {launchAddressList.data?.profile?.profile?.user_type === 'individual' ? (
        <Refer>
          <Card className="refer_card">
            <div>
              <h5 className="heading">Own a business?</h5>
              <div>
                <p className="details">Reach out to us for your bulk orders</p>
                <p style={{ fontSize: '13px' }}>
                  You can call on:{' '}
                  <strong>{launchAddressList.data?.business_contact}</strong>
                </p>
              </div>
            </div>
          </Card>
        </Refer>
      ) : (
        <></>
      )}

      {/* {profile?.data?.profile?.reward_enabled ? (
        <Refer>
          <Card className="refer_card">
            <div>
              <h5 className="heading">Refer & Earn</h5>
              <div>
                <p className="details">
                  Share with your friends and get your reward after their first
                  successfully purchase
                </p>
              </div>
            </div>
            <Link
              to={getLink('/home/refer-earn')}
              style={{ textDecoration: 'none' }}
            >
              <Button className="btn">Invite</Button>
            </Link>
          </Card>
        </Refer>
      ) : (
        <></>
      )} */}

      <BannerList
        list={[
          {
            icon: Phone,
            name: 'phone',
            title: 'Download the Madras Mandi App to your Phone.',
            type: 'Download',
          },
          {
            icon: OrderIcon,
            name: 'order',
            title: 'Order now so you dont miss the opportunities.',
          },
          {
            icon: SecureDelivery,
            name: 'secure delivery',
            title: '100% Secure delivery without contacting the courier',
          },
        ]}
      />
      <BannerList
        list={[
          {
            icon: Products,
            name: 'phone',
            title: 'Everyday fresh products',
          },
          {
            icon: Price,
            name: 'order',
            title: 'Best price on the market',
          },
          {
            icon: Discount,
            name: 'secure delivery',
            title: 'Daily Mega Discounts',
          },
        ]}
      />
    </SideBarStyled>
  );
}

const SideBarStyled = styled.aside`
  z-index: 3;
  width: 230px;
  flex-shrink: 0;
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  padding-bottom: 1.5rem;
  .banners {
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    transform: translateX(-230px);
    opacity: 0;
    position: fixed;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 7%);
    border-radius: 0 5px 5px 0;
  }
  &.active {
    @media screen and (max-width: 991px) {
      opacity: 1;
      transform: translateX(0px);
    }
    @media screen and (max-width: 540px) {
      transform: translateX(-12px);
    }
  }
`;

const Close = styled.figure`
  margin-bottom: 0;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 991px) {
    display: flex;
  }
`;

const HamburgerController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  .logo {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      height: 78px;
      @media screen and (max-width: 991px) {
        height: 40px;
      }
    }
  }
`;

const Tabs = styled.ul`
  list-style-type: none;
  padding: 0 16px;
  margin-bottom: 0;
`;
const Tab = styled.li`
  a {
    font-size: 14px;
    color: #acacac;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .fill-grey {
    fill: #acacac;
  }
  .stroke-grey {
    stroke: #acacac;
  }
  figure {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 16px;
    background-color: #f0f0f0;
  }
  &:not(:first-child) {
    margin-top: 16px;
  }
  &.logout {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #acacac;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: #013a25;
      .fill-grey {
        fill: #013a25;
      }
      .stroke-grey {
        stroke: #013a25;
      }
    }
  }
  &:hover,
  &.active {
    a {
      color: #013a25;
      .fill-grey {
        fill: #013a25;
      }
      .stroke-grey {
        stroke: #013a25;
      }
    }
  }
`;

const Refer = styled.div`
  .refer_card {
    list-style-type: none;
    border: none;
    border-radius: 10px;
    margin: 1.5rem 16px 0;
    padding: 0 12px;
    background: #f8fffb;
    box-shadow: 0px 0.964286px 15.4286px rgba(0, 0, 0, 0.16);

    .heading {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 18px;
    }
    .details {
      font-weight: 500;
      font-size: 13px;
      color: #b1b1b1;
    }
    .btn {
      background-color: #00855b;
      border: none;
      border-radius: 10px;
      width: 90px;
      height: 35px;
      margin: auto;
      margin-bottom: 1rem;
      text-decoration: none;
    }
  }
`;

/**
 *
 * Callback modal
 *
 */

// CSS Style
import { useSelector } from 'react-redux';
import { Refund } from './index.styled';

// React Bootstrap
import { Button, Modal } from 'react-bootstrap';
import { selectOrder } from '../../slice/selectors';

// Prop Types Define
interface Props {
  isOpen: boolean;
  onClose: () => void;
  //   proceedToCheckout: () => void;
}

export function ChatEnableModal(props: Props) {
  const { isOpen, onClose } = props;
  const order = useSelector(selectOrder);

  const { data: item } = order;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      dialogClassName="modal-width-max_content"
    >
      <Modal.Body>
        <Refund>
          {!!item.need_help_message ? (
            <h4 className="heading">{item.need_help_message} </h4>
          ) : (
            <h4 className="heading">Contact us After some time </h4>
          )}

          <div className="btn">
            <Button
              className="ok_btn"
              onClick={async () => {
                onClose();
              }}
            >
              OK
            </Button>
          </div>
        </Refund>
      </Modal.Body>
    </Modal>
  );
}

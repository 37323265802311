/**
 *
 * TermsConditions
 *
 */
import React, { memo } from 'react';
import RefundStyled from './index.styled';

interface Props {}

export const Refund = memo((props: Props) => {
  return (
    <RefundStyled>
      <h5>RETURN AND REFUND POLICY</h5>
      <div className="intro">
        <h6>RETURN AND REFUND POLICY</h6>
        <p>
          Our Return and Refund Policy forms are part of and must be read in
          conjunction with the website’s Terms and Conditions. We reserve the
          right to change this Return and Refund Policy at any time.
        </p>
        {/* <p>
            These terms and conditions supersede all previous representations,
            understandings, or agreements and shall prevail notwithstanding any
            variance with any other terms of any order submitted. By using
            Madrasmandi You agree to be bound by the Terms and Conditions. Not
            having read these terms and conditions is no reason to be no bound
            by these terms and conditions.
          </p> */}
      </div>
      <div>
        <h6>RETURN POLICY</h6>
        <p>
          If the customer is not satisfied with the quality of our product and
          wants to return it, they can do it within 6 hours after delivery.
          After 6 hours of delivery of the product, we shall be not liable to
          accept a return from the Customers.
        </p>
        <h6>Eligibility for Return:</h6>
        <p>
          <ul>
            <li>
              All customers must contact support@madrasmandi.in / +91 83446
              21039 for approval before sending or returning any item to us.
            </li>
            <li>
              https://madrasmandi.in will not be responsible for the returned
              item without prior notification to https://madrasmandi.in.
            </li>
          </ul>
          Note: https://madrasmandi.in reserves the right to make any changes to
          the return policy. The changes will be updated on our website.
        </p>
        <h6>DAMAGED OR INCORRECT PRODUCT</h6>
        <p>
          When the product is incorrect from the one that the customer ordered
          or when the item is damaged, the customer has to send photos or a
          video within 12 hours after delivery that shows the item is incorrect
          or damaged.
        </p>
        <p>
          In this case, we will reship a new item or issue a refund after
          inspecting the item. And, the customer shall be liable to return the
          product as per our return policy (mentioned above). The Replacement
          will be made at our sole discretion.
        </p>
        <p>
          In case of a damaged product, the shipping cost shall be paid by the
          Company.
        </p>
        <p>
          All refunds will be an issue as per our refund policy. Please contact
          us via email. We will respond within 2 (Two) business days.
        </p>
        <p>
          Moreover, if we remove or suspend you for your breach of the website
          terms, then you will not receive a refund for our service
        </p>
        <p>
          In a determination to accomplish user satisfaction, if there is an
          issue, you can contact us through our email at support@madrasmandi.in.
        </p>
        <h6>REFUND CYCLE:</h6>
        <p>
          The complete refund process usually takes about 7 to 10 working days
          from the date of confirmation of the refund.
        </p>
        <h6>CANCELLATION POLICY:</h6>
        <p>
          You may request to cancel your order for a full refund, up to 6 hours
          before the order has been shipped. Cancellation requests made after 6
          hours from the order created time may not receive a refund nor a
          transfer.
        </p>
      </div>
    </RefundStyled>
  );
});

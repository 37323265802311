import styled from 'styled-components/macro';

const LoadingWrapper = styled.div`
  .loading {
    margin-top: 1.5rem;
    height: 180px;
  }
`;

const OrderDetailsStyled = styled.div`
  > * {
    margin-top: 1.5rem;
    margin: 1rem;
    font-size: 14px;
    &:not(.order-details-intro) {
      time {
        font-size: 12px;
      }
    }
  }
  .card {
    margin-top: 16px;
    .method {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px;
      p {
        margin-bottom: 0;
      }
      .img-div {
        display: flex;
        align-items: center;
        img {
          margin-right: 6px;
        }
        h5 {
          margin-bottom: 0;
          font-size: 14px;
        }
        h6 {
          margin-left: 1rem;
        }
      }
      .price {
        margin-top: 1rem;
      }
    }

    .payable {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }
  h6 {
    margin-bottom: 0;
  }
  time {
    color: #c4c4c4;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .delivery-details {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .bottom-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    background-color: #00855b;
    border-radius: 5px;
    width: 96%;
  }
  &.alert-active {
  }
  .edit_order_bill {
    .edit_order_status {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .edit_reason {
      margin-top: 1rem;
      display: flex;
      align-items: flex-start;
      .edit_image {
        width: 25px;
        margin: 0.3rem;
      }
      .edit_reason_text {
        margin: 0.3rem;
        font-size: 16px;
      }
    }
    .edit_order_mode {
      margin-top: 1rem;
      .payment_text {
        display: flex;
        margin-top: 0.5rem;
      }
      .mode_text {
        margin-top: 0.3rem;
        margin-left: 0.5rem;
        text-transform: capitalize;
      }
    }
    .pending_status {
      border-radius: 5px;
      color: #ff9900;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
    img {
      margin-right: 6px;
    }
    .paid_status {
      border-radius: 5px;
      color: #00855b;
      font-weight: 500;
      font-size: 16px;
      width: 70px;
      text-align: center;
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;

const MethodStyled = styled.div`
  .method {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .img-div {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      h6 {
        margin-left: 1rem;
      }
    }
    .price {
      margin-top: 1rem;
    }
  }
`;

const Bill = styled.div`
  .bill_no {
    border-radius: 5px;
    padding: 0.4rem;
    cursor: pointer;
    font-size: 12px;
    width: 190px;
    text-align: center;
    margin: auto 0.4rem;
  }
  .inactiveBillNumber {
    background: #ffffff;
    border: 1px solid #00855b;
    color: #00855b;
  }
  .activeBillNumber {
    background: #00855b;
    border: 1px solid #00855b;
    color: #ffffff;
  }
`;

const BottomSticky = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 40px;
  max-width: 1018px;
  width: 100%;
  @media screen and (max-width: 1399px) {
    max-width: 838px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 658px;
  }
  @media screen and (max-width: 991px) {
    max-width: 696px;
  }
  @media screen and (max-width: 767px) {
    max-width: 516px;
  }
  @media screen and (max-width: 575px) {
    bottom: 1.5rem;
  }
`;

const PaymentDetailsList = styled.ul``;

const PaymentDetailsListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const DeliveryDetail = styled.div`
  .delivery-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .address_section {
      .address_name {
        margin-top: 0.5rem;
      }
      .address {
        color: black;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`;

const PaymentDetails = styled.div`
  h5,
  h6,
  p {
    margin-bottom: 0;
  }
`;
const PaymentMethod = styled.div`
  .method-div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 12px;
    .pending {
      display: flex;
      flex-direction: row-reverse;
      .pending_status {
        border-radius: 5px;
        color: #ff9900;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        margin-bottom: 0;
      }
      img {
        margin-right: 6px;
      }
    }

    .paid {
      display: flex;
      flex-direction: row-reverse;
      .paid_status {
        border-radius: 5px;
        color: #00855b;
        font-weight: 500;
        font-size: 18px;
        height: 30px;
        width: 70px;
        text-align: center;
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .method {
    display: flex;
    justify-content: space-between;
    .img-div {
      display: flex;
      align-items: center;
    }
  }
  .subpayment-method {
    display: flex;
    flex-direction: column;
    .img-div {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      h6 {
        margin-left: 1rem;
      }
    }
  }
`;
const Intro = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    margin-bottom: 0;
  }
  time {
    margin-top: 4px;
    display: block;
  }
  .need-help {
    font-size: 18px;
    color: #00855b;
    font-weight: 500;
    cursor: pointer;
  }
`;

const SelectDeliveryOption = styled.div`
  display: flex;
  img {
    width: 30px;
  }
  .title {
    margin-left: 1rem;
    padding-top: 1rem;
    font-weight: 600;
  }
`;
const DeliveryOption = styled.div`
  h6 {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 16px;
  }
`;

const DeliveryType = styled.div`
  h6 {
    margin-top: 1rem;
  }
`;

const SelectScheduleDelivery = styled.div`
  h6 {
    font-weight: 600;
    font-size: 16px;
  }
  .time {
    color: black;
    padding-top: 1rem;
    font-weight: 600;
    font-size: 16px;
  }
`;

const ScheduleDeliveryDetail = styled.div`
  h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Refunds = styled.div`
  .refund {
    display: flex;
    .refund_text {
      margin-top: 1rem;
    }
    justify-content: space-between;
    .refund_amount {
      display: flex;
      flex-direction: column-reverse;
      .refund_status {
        color: #00855b;
        font-size: 16px;
      }
      .refund_price {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .refund-message {
    color: #acacac;
  }
`;

const DelayMessage = styled.div`
  .delay-card-wrapper {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .delay-img-wrapper {
      height: 100%;
      width: max-content;
      .delay-img {
        width: 40px;
        height: 40px;
      }
    }
    .delay-detail {
      margin-left: 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .delay-message {
        margin-bottom: 0;
      }
    }
  }
`;

const StoreOrderDetailsStyled = styled.div``;

export {
  LoadingWrapper,
  OrderDetailsStyled,
  PaymentDetailsList,
  PaymentDetailsListItem,
  DeliveryDetail,
  PaymentDetails,
  PaymentMethod,
  Intro,
  DeliveryOption,
  SelectDeliveryOption,
  DeliveryType,
  SelectScheduleDelivery,
  ScheduleDeliveryDetail,
  Refunds,
  DelayMessage,
  Bill,
  BottomSticky,
  StoreOrderDetailsStyled,
  MethodStyled,
};

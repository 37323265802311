// import { PayloadAction } from '@reduxjs/toolkit';
import { maintenanceActions } from 'app/pages/Maintenance/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { rewardsInfoActions } from '.';

function* getRewardsInfo() {
  try {
    const requestURL = `/api/v3/public/rewards-info`;
    const response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
    });
    yield put(rewardsInfoActions.getRewardsInfoSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 522) {
      yield put(maintenanceActions.setDisableOrder(true));
    } else if (err.response?.status === 404) {
      yield put(rewardsInfoActions.getRewardsInfoError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(rewardsInfoActions.getRewardsInfoError(err.response));
    } else {
      yield put(rewardsInfoActions.getRewardsInfoError(err));
    }
  }
}

export function* RewardsInfoSaga() {
  yield takeLatest(rewardsInfoActions.getRewardsInfo.type, getRewardsInfo);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { selectDefaultAddress } from '../slice/selectors';
import { selectProfile } from 'app/pages/slice/selectors';

const useDefaultAddress = () => {
  const selectedAddress = useSelector(selectDefaultAddress);
  const profile = useSelector(selectProfile);

  const isIndividual = React.useMemo(() => {
    return profile.data?.profile?.user_type === 'individual';
  }, [profile]);

  const isDeliverable = React.useMemo(() => {
    if (!isIndividual) return true;

    if (selectedAddress?.deliverable && !selectedAddress?.show_instant_delivery)
      return false;

    return selectedAddress?.deliverable;
  }, [selectedAddress]);

  return { isDeliverable };
};

export default useDefaultAddress;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.loginPage || initialState;

export const selectLoginPage = createSelector([selectSlice], state => state);
export const selectSendOtp = createSelector(
  [selectSlice],
  state => state.sendOtp,
);

export const selectEnterOtp = createSelector(
  [selectSlice],
  state => state.enterOtp,
);

export const selectLoginWithPassword = createSelector(
  [selectSlice],
  state => state.loginWithPassword,
);

export const selectAccountDeleteIOSError = createSelector(
  [selectSlice],
  state => state.accountDeleteIOSError,
);

export const selectLocationCheck = createSelector(
  [selectSlice],
  state => state.locationCheck,
);

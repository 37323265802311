/**
 *
 * Order
 *
 */

interface Props {}

export function Order(props: Props) {
  return (
    <svg width="20" height="25" viewBox="0 0 17 15" fill="none">
      <path
        d="M2.83325 5.81646L8.49992 2.57837L13.3506 5.35018C13.5985 5.49179 13.8046 5.69645 13.9479 5.9434C14.0912 6.19035 14.1666 6.4708 14.1666 6.75632V10.5433C14.1666 10.8288 14.0912 11.1092 13.9479 11.3562C13.8046 11.6031 13.5985 11.8078 13.3506 11.9494L9.30297 14.2622C9.05838 14.4019 8.78159 14.4754 8.49992 14.4754C8.21825 14.4754 7.94145 14.4019 7.69687 14.2622L3.64925 11.9494C3.40133 11.8078 3.19527 11.6031 3.05196 11.3562C2.90866 11.1092 2.8332 10.8288 2.83325 10.5433V8.27499"
        className="stroke-grey"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73267 8.64167C7.96853 8.76864 8.23222 8.8351 8.50009 8.8351C8.76797 8.8351 9.03165 8.76864 9.26752 8.64167L13.762 6.22119"
        className="stroke-grey"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 9.05444V14.3163"
        className="stroke-grey"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83336 5.81641L8.50002 9.0545L6.07145 9.86402L0.404785 6.62593L2.83336 5.81641Z"
        className="stroke-grey"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2.57828L14.1667 5.81637L15.7857 4.19732L10.119 0.959229L8.5 2.57828Z"
        className="stroke-grey"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import React from 'react';

import { Forgotten, Note, Timer, Hint } from './index.styled';
import { Image } from 'react-bootstrap';
import AlertIcon from 'assets/appointment-reminders.svg';
import TimerIcon from 'assets/timer.svg';
import { useSelector } from 'react-redux';

import { selectDeliveryType } from 'app/pages/MainPage/slice/selectors';
import { Link } from 'react-router-dom';

export const EditOrderCounter = ({ time }: { time: any }) => {
  const deliveryType = useSelector(selectDeliveryType);

  const renderTime = () => {
    if (time) {
      return (
        <Timer className="timer">
          <Image src={TimerIcon} alt="timer" />
          <time>{time.toFormat('mm:ss')}</time>
        </Timer>
      );
    }
  };

  if (deliveryType === 'app') {
    return (
      <Forgotten className="forgotten">
        <Note className="note">
          <Image src={AlertIcon} alt="alter" />
          <Hint className="hint">
            <h6>Forgot to add an item?</h6>
            <p>
              To add more to your list,{' '}
              <span style={{ textDecoration: 'none' }}>tap here</span>
            </p>
          </Hint>
        </Note>
        {renderTime()}
      </Forgotten>
    );
  } else {
    return (
      <Link to={`/home`} style={{ textDecoration: 'none' }}>
        <Forgotten className="forgotten">
          <Note className="note">
            <Image src={AlertIcon} alt="alter" />
            <Hint className="hint">
              <h6>Forgot to add an item?</h6>
              <span>
                To add more to your list, please select next day delivery
              </span>
            </Hint>
          </Note>
        </Forgotten>
      </Link>
    );
  }
};

const getDeliveryType = (): string => {
  return localStorage.getItem('Delivery-Type') || 'app';
};

const setCartItem = (payload): void => {
  const key = `${getDeliveryType()}_cartItem`; // <- eg., app_cartItem, instant_cartItem
  localStorage.setItem(key, JSON.stringify(payload));
};

const getCartItem = (parsed = false) => {
  const key = `${getDeliveryType()}_cartItem`;
  const cartItem = localStorage.getItem(key);
  if (parsed) return JSON.parse(cartItem || '[]');
  return cartItem;
};

export const localStorageActions = Object.freeze({
  getDeliveryType,
  setCartItem,
  getCartItem,
});

import React from 'react';
import { ReactImage } from 'app/components/ReactImage';
import { Card } from 'app/components/Card';

import {
  OrderedItemPicture,
  OrderedItemName,
  OrderedItemsStyled,
  OrderedListItem,
  OrderedList,
} from './index.styled';

export const OrderedItems = ({ item }: any) => {
  const renderOrderedItems = () => {
    return item?.items?.map(obj => {
      return (
        <OrderedListItem className="ordered-list-item" key={obj.name}>
          <OrderedItemPicture className="ordered-item-picture">
            <ReactImage
              src={
                obj.logo?.includes('https://')
                  ? obj.logo
                  : `${process.env.REACT_APP_API_URI}/${obj.logo}`
              }
              alt={obj.name}
            />

            <OrderedItemName className="ordered-item-name">
              <h6>{obj.name}</h6>
              {obj.ordered_uoms?.map(ordered_uom => {
                if (ordered_uom.cart_quantity === 0) return '';
                return (
                  <span
                    key={ordered_uom.uom_id}
                    style={{ display: 'block', marginTop: '0.5rem' }}
                  >
                    ₹{Number(ordered_uom.price).toFixed(2)} /{ordered_uom.unit}{' '}
                    x {ordered_uom.cart_quantity}
                  </span>
                );
              })}
            </OrderedItemName>
          </OrderedItemPicture>
          {/* <span>x {obj.pivot.qty}</span> */}
        </OrderedListItem>
      );
    });
  };

  return (
    <OrderedItemsStyled className="ordered-items">
      <h6>Ordered Items</h6>
      <Card className="card">
        <OrderedList className="ordered-list">
          {renderOrderedItems()}
        </OrderedList>
      </Card>
    </OrderedItemsStyled>
  );
};

import styled from 'styled-components/macro';

const Rating = styled.div`
  margin-left: -2px;
  margin-right: -2px;
  display: flex;
  align-items: center;
  > * {
    margin-left: 2px;
    margin-right: 2px;
  }
`;
const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  margin-top: auto;
  img {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }
`;
const Indicator = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  background-color: #00855b;
`;
const AvatarInfo = styled.div`
  h5 {
    margin: 0;
  }
  span {
    color: #acacac;
  }
`;

export { Rating, CustomerInfo, AvatarInfo, Indicator };

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { helpSaga } from './saga';
import { HelpState } from './types';

export const initialState: HelpState = {
  message: {
    type: '',
    loading: false,
    success: false,
    error: false,
  },
  faqs: {
    data: [],
    loading: true,
    error: false,
  },
  feedback: {
    data: [],
    loading: true,
    error: false,
  },
};

const slice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    sendMessage(state, action: PayloadAction<any>) {
      state.message.loading = true;
      state.message.error = null;
      state.message.success = false;
      state.message.type = action.payload.type;
    },
    sendMessageSuccess(state, action: PayloadAction<any>) {
      state.message.loading = false;
      state.message.success = true;
    },
    sendMessageError(state, action: PayloadAction<any>) {
      state.message.loading = false;
      state.message.error = action.payload;
      state.message.success = false;
    },
    getFeedback(state) {
      state.feedback.data = [];
      state.feedback.loading = true;
      state.feedback.error = null;
    },
    getFeedbackSuccess(state, action: PayloadAction<any>) {
      state.feedback.data = action.payload;
      state.feedback.loading = false;
    },
    getFeedbackError(state, action: PayloadAction<any>) {
      state.feedback.error = action.payload;
      state.feedback.loading = false;
    },
    getFaqs(state) {
      state.faqs.data = [];
      state.faqs.loading = true;
      state.faqs.error = null;
    },
    getFaqsSuccess(state, action: PayloadAction<any>) {
      state.faqs.data = action.payload;
      state.faqs.loading = false;
    },
    getFaqsError(state, action: PayloadAction<any>) {
      state.faqs.error = action.payload;
      state.faqs.loading = false;
    },
  },
});

export const { actions: helpActions } = slice;

export const useHelpSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: helpSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useHelpSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

/**
 *
 * UOM Counter
 *
 */
import React, { useEffect, useState } from 'react';
import { Image, Form, Button } from 'react-bootstrap';
import styled from 'styled-components/macro';

import Plus from './assets/plus.svg';
import Minus from './assets/minus.svg';
import useLongPress from 'app/hooks/useLongPress';
import { toast } from 'react-toastify';
import { Mixpanel } from 'app/mixpanel';
import componentConstants from 'app/mixpanel/constants/components';
import actionConstants from 'app/mixpanel/constants/action';

interface Props {
  value: number;
  minValue: number;
  maxValue?: number;
  onChange: Function;
  isMultipleUomInCart?: boolean;
  isMultipleUomInAPI?: boolean;
  onOpenUomModal?: Function;
  unit?: boolean;
}

export function CounterUOM(props: Props) {
  const {
    minValue,
    maxValue,
    value: valueProp,
    onChange,
    isMultipleUomInCart,
    isMultipleUomInAPI,
    onOpenUomModal,
    unit,
  } = props;

  const [value, setValue] = useState(valueProp || 0);

  React.useEffect(() => {
    Mixpanel.track(componentConstants.COUNTER_UOM);
  });

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onClickPlus = () => {
    Mixpanel.track(actionConstants.COUNTER_BTN);
    if (isMultipleUomInAPI) {
      if (onOpenUomModal) {
        onOpenUomModal();
      }
    } else {
      setValue(prevState => {
        let newVal = prevState + 1;
        if (maxValue && prevState >= maxValue) {
          newVal = maxValue;
          if (!!unit) {
            toast.error(
              'Please choose a different pack size to add more quantity',
            );
          } else {
            toast.error('Max quantity for this item has been added to cart');
          }
          // toast.error(
          //   `max quantity for this item in ${
          //     unit ?? 'Kg'
          //   } has been added in cart`,
          // );
        } else if (prevState === 0 && minValue > 0) {
          newVal = minValue;
        } else if (prevState >= 999) {
          newVal = 999;
          toast.error('You can not add more then 999');
        }
        onChange(newVal);
        return newVal;
      });
    }
  };
  const onClickMinus = () => {
    Mixpanel.track(actionConstants.COUNTER_BTN);
    let isClear = false;
    if (isMultipleUomInCart || isMultipleUomInAPI) {
      if (onOpenUomModal) {
        onOpenUomModal();
      }
    } else {
      setValue(prevState => {
        if (prevState > 0) {
          let newVal = prevState - 1;
          if (prevState <= minValue) {
            newVal = 0;
            isClear = true;
          }
          onChange(newVal);
          return newVal;
        }
        return prevState;
      });
      if (isClear) {
        longPressMinusEvent.clear();
      }
    }
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 300,
  };

  const longPressPlusEvent = useLongPress(
    onClickPlus,
    onClickPlus,
    defaultOptions,
  );
  const longPressMinusEvent = useLongPress(
    onClickMinus,
    onClickMinus,
    defaultOptions,
  );

  const onChangeInput = e => {
    if (isMultipleUomInCart) return;
    let val = parseInt(e.target.value);
    if (Number.isNaN(val)) {
      setValue(e.target.value);
      onChange(+e.target.value);
    } else {
      if (maxValue && val > maxValue) {
        setValue(maxValue);
        onChange(maxValue);
        if (!!unit) {
          toast.error(
            'Please choose a different pack size to add more quantity',
          );
        } else {
          toast.error('Max quantity for this item has been added to cart');
        }
        return;
      } else if (minValue && val < minValue) {
        toast.error('You can not remove less then min amount');
        setValue(minValue);
        onChange(minValue);
      } else if (val >= 999) {
        if (!!unit) {
          toast.error(
            'Please choose a different pack size to add more quantity',
          );
        } else {
          toast.error('Max quantity for this item has been added to cart');
        }
        setValue(999);
        onChange(999);
      } else {
        setValue(val);
        onChange(+val);
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getDisabled = () => {
    if (isMultipleUomInAPI) {
      return false;
    }
    if ((maxValue && value >= maxValue) || value >= 999) {
      return true;
    }
    return false;
  };
  return (
    <CounterStyled className="counter">
      {isMultipleUomInAPI && <Legend>More Options</Legend>}
      <Button variant="light" className="minus" {...longPressMinusEvent}>
        <Image className="counter_img" src={Minus} alt="minus" />
      </Button>
      <Form.Control
        type="number"
        placeholder="0"
        value={value === 0 ? '' : value}
        onChange={onChangeInput}
      />
      <Button
        variant="light"
        className="minus"
        {...longPressPlusEvent}
        // disabled={getDisabled()}
      >
        <Image className="counter_img" src={Plus} alt="plus" />
      </Button>
    </CounterStyled>
  );
}

const CounterStyled = styled.fieldset`
  background-color: #ffffff;
  width: 120px;
  height: 40px;
  display: flex;
  border: 2px solid #00855b;
  border-radius: 5px;
  position: relative;
  input {
    &.form-control {
      width: 100%;
      height: 36px;
      font-size: 14px;
      border: 0;
      padding: 0;
      text-align: center;
      background-color: transparent;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  button {
    width: 40px;
    height: 40px;
    &.btn {
      &.minus {
        padding: 0;
        width: 36px;
        height: 36px;
      }
    }
    background-color: transparent !important;
    .counter_img {
      color: #00855b;
    }
    &.minus {
      border-radius: 5px;
    }
    &.plus {
      border-radius: 5px;
    }
  }
  @media screen and (max-width: 991px) {
    width: 100px;
    input {
      &.form-control {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    width: 90px;
    input {
      &.form-control {
        font-size: 12px;
      }
    }
  }
`;

const Legend = styled.legend`
  font-size: 11px;
  position: absolute;
  display: inline-flex;
  align-items: center;
  background-color: white;
  top: -10px;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  @media screen and (max-width: 991px) {
    font-size: 10px;
  }
  @media screen and (max-width: 575px) {
    font-size: 9px;
  }
`;

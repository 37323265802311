import styled from 'styled-components/macro';

const DownloadSectionStyled = styled.section`
  padding: 6rem 3rem;
  position: relative;
  > .image {
    position: absolute;
    max-width: 200px;
    z-index: 1;
    &.image1 {
      left: -1rem;
      top: 0;
      @media screen and (max-width: 767px) {
        top: 3rem;
      }
    }
    &.image2 {
      right: -3rem;
      top: 0;
      @media screen and (max-width: 767px) {
        top: 3rem;
        right: -1rem;
      }
    }
    &.image3 {
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      @media screen and (max-width: 767px) {
        bottom: 3rem;
      }
    }
    @media screen and (max-width: 767px) {
      max-width: 100px;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const DownloadAppStyled = styled.div`
  background-color: #00855b;
  border-radius: 10px;
  padding: 3rem;
  color: white;
  position: relative;
  overflow: hidden;
  p {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  > * {
    max-width: 320px;
    z-index: 2;
    position: relative;
  }
  h3 {
    font-size: 2.5rem;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.75rem;
    }
  }
  .phone-image {
    position: absolute;
    bottom: -7rem;
    right: 1rem;
    z-index: 1;
    max-width: 400px;
    @media screen and (max-width: 991px) {
      max-width: 220px;
    }
    @media screen and (max-width: 767px) {
      right: -1rem;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 16px;
  }
`;

const ImageGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 100%;
  margin: 12px -6px 0;
  > * {
    margin: 12px 6px 0;
    max-width: 160px;
  }
`;

export default DownloadSectionStyled;
export { DownloadAppStyled, ImageGroup };

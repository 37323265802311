import React, { useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { Card } from 'app/components/Card';

// IMAGES
import placed from 'assets/placed.svg';
import confirmation from 'assets/confirmation.svg';
import preparingDelivery from 'assets/preparingDelivery.svg';
import delivered from 'assets/delivered.svg';
import rejectedByAdmin from 'assets/rejectedByAdmin.svg';
import orderNotDelivered from 'assets/orderNotDeivered.svg';
// import nearBy from 'assets/nearBy.svg';
import Scooter from 'assets/Scooter-arrving.svg';
import Completed from 'assets/completed.svg';
import NotCompleted from 'assets/not-completed.svg';
import onTheWay from 'assets/on-the-way.svg';
import processing from 'assets/processing.svg';
import arrivedAtLocation from 'assets/Arrived_at_location.svg';
import CouponDelay from 'assets/coupon-delay.svg';
import Arriving from 'assets/arriving.svg';

import {
  DeliveryStatusStyled,
  // PickedUpStatus,
  DeliveryProgressBarStyled,
  ActivatedProgress,
  InactivatedProgress,
  DangerProgress,
} from './index.styled';
import { useSelector } from 'react-redux';
import { selectStoreOrder } from '../../slice/selectors';
// import { OrderTracking } from '../OrderTracking';

export const DeliveryStatus = ({ item }: { item: any }) => {
  const [activatedProgress, setActivatedProgress] = React.useState(1);
  const [isRedActive, setIsRedActive] = useState(false);
  const order = useSelector(selectStoreOrder);

  const counterRef: any = React.useRef(null);
  const immediateCounterRef: any = React.useRef(null);
  const [count, setCount]: any = React.useState(null);
  const [countImmediate, setCountImmediate]: any = React.useState(null);

  const setETATime = ({ previous_order_started_at, eta }) => {
    const then = DateTime.fromJSDate(
      new Date(previous_order_started_at * 1000),
    ).plus({ seconds: eta });
    const now = DateTime.now();
    if (now < then) {
      let countdown = then.diff(now);
      if (countdown.minutes <= 2) {
        countdown = Duration.fromObject({ minutes: 2 });
      }
      setCount(countdown);
    } else {
      setCount(0);
      if (counterRef.current) clearInterval(counterRef.current);
    }
  };

  React.useEffect(() => {
    if (item?.driver?.eta && item?.driver?.previous_order_started_at) {
      counterRef.current = setInterval(() => {
        setETATime(item?.driver);
      }, 1000);
      return () => {
        clearInterval(counterRef.current);
      };
    }
  }, [item]);

  const setETAImmediateTime = ({ previous_order_started_at, eta }) => {
    const then = DateTime.fromISO(previous_order_started_at)
      .plus({
        minutes: eta,
      })
      .toUTC();
    const now = DateTime.now().toUTC();
    if (now < then) {
      let countdown = then.diff(now);
      const minutesDiff = then.diff(now, 'minutes').toObject().minutes;
      if (minutesDiff <= 2) {
        countdown = Duration.fromObject({ minutes: 2 });
      }
      setCountImmediate(countdown);
    } else {
      setCountImmediate(0);
      if (immediateCounterRef.current)
        clearInterval(immediateCounterRef.current);
    }
  };

  React.useEffect(() => {
    const acceptedStatus = item?.status_list?.find(
      li => li.status_message.toLowerCase() === 'accepted',
    );
    if (
      item?.immediate_timer &&
      !isNaN(Number(item?.immediate_timer)) &&
      acceptedStatus
    ) {
      immediateCounterRef.current = setInterval(() => {
        setETAImmediateTime({
          previous_order_started_at: acceptedStatus.time,
          eta: Number(item?.immediate_timer),
        });
      }, 1000);
      return () => {
        clearInterval(immediateCounterRef.current);
      };
    }
  }, [item]);

  React.useEffect(() => {
    const currentStatus =
      !!item && !!item.status_list
        ? item.status_list[
            item.status_list?.length - 1
          ].status_message.toLowerCase()
        : 'out for delivery';

    switch (currentStatus) {
      case 'pending':
        return setActivatedProgress(1);

      case 'accepted':
        return setActivatedProgress(1);

      case 'preparing for dispatch':
        return setActivatedProgress(3);

      case 'on the way':
        return setActivatedProgress(4);

      case 'delivered':
        return setActivatedProgress(5);

      case 'rejected by admin': {
        setIsRedActive(true);
        return setActivatedProgress(2);
      }

      case 'not delivered': {
        setIsRedActive(true);
        return setActivatedProgress(5);
      }

      case 'out for delivery':
        return setActivatedProgress(4);

      case 'nearby':
        return setActivatedProgress(4);

      case 'processing':
        return setActivatedProgress(2);

      case 'arrived at location':
        return setActivatedProgress(4);

      default:
        return setActivatedProgress(1);
    }
  }, [item]);

  const renderImage = () => {
    const currentStatus =
      !!item && !!item.status_list
        ? item.status_list[
            item.status_list?.length - 1
          ].status_message.toLowerCase()
        : 'out for delivery';

    switch (currentStatus) {
      case 'pending':
        return <img className="image" src={placed} alt="order placed" />;

      case 'accepted':
        return (
          <img
            className="image"
            src={confirmation}
            alt="order confirm"
            style={{
              marginTop: '-5rem',
              width: '400px',
            }}
          />
        );

      case 'preparing for dispatch':
        return (
          <img
            className="image"
            src={preparingDelivery}
            alt="order prepare"
            style={{
              marginTop: '0.1rem',
              width: '500px',
            }}
          />
        );
      case 'processing':
        return <img className="image" src={processing} alt="order prepare" />;

      case 'on the way':
        return <img className="image" src={onTheWay} alt="order prepare" />;

      case 'out for delivery':
        return (
          <img
            className="image"
            src={preparingDelivery}
            alt="order prepare"
            style={{ marginTop: '1rem' }}
          />
        );

      case 'arrived at location':
        return (
          <img
            className="image"
            src={arrivedAtLocation}
            alt="order prepare"
            style={{ marginTop: '1rem' }}
          />
        );
      case 'nearby':
        return (
          <img
            src={preparingDelivery}
            alt="order prepare"
            style={{ marginTop: '1rem' }}
          />
        );

      case 'delivered':
        return <img className="image" src={delivered} alt="order delivered" />;

      case 'rejected by admin':
        return (
          <img
            className="image"
            src={rejectedByAdmin}
            alt="order rejected by admin"
          />
        );

      case 'not delivered':
        return (
          <img
            className="image"
            src={orderNotDelivered}
            alt="order not delivered"
          />
        );

      default:
        return <img className="image" src={placed} alt="order placed" />;
    }
  };

  const renderDeliveryProgressBar = () => {
    const total = 5;
    const inactivatedProgress = total - activatedProgress;
    const activated = [...new Array(activatedProgress)].map((_, i) => i);
    const inactivated = [...new Array(inactivatedProgress)].map((_, i) => i);

    return (
      <DeliveryProgressBarStyled>
        {activated.map((i, index) => {
          if (index === activated.length - 1 && isRedActive)
            return <DangerProgress key={i} />;
          return <ActivatedProgress key={i} />;
        })}
        {inactivated.map(i => (
          <InactivatedProgress key={i} />
        ))}
      </DeliveryProgressBarStyled>
    );
  };

  const renderStatus = () => {
    const isStatusExist = !!item && !!item.status_list;
    if (!isStatusExist) return <></>;

    const currentStatus = item.status_list[item.status_list?.length - 1];

    let time = DateTime.fromISO(currentStatus.time).toFormat(
      'hh:mm a, dd-MMM-yyyy',
    );

    switch (currentStatus.status_message.toLowerCase()) {
      case 'pending':
        return (
          <div className="time-status">
            <p className="status-name">Pending</p>
            <p className="status-time">Waiting for confirmation.</p>
          </div>
        );

      case 'accepted':
        return (
          <div className="time-status">
            <p className="status-name">Order confirmed</p>{' '}
            <p className="status-time">
              Order received and confirmed. Thank you!
            </p>
          </div>
        );

      case 'preparing for dispatch':
        return (
          <div className="time-status">
            <p className="status-name">Preparing for dispatch</p>
            <p className="status-time">Your order will be shipped soon.</p>
          </div>
        );

      case 'on the way':
        return (
          <div className="time-status">
            <p className="status-name">On the way</p>
            <p className="status-time">
              Your order is on its way! Track its journey.
            </p>
          </div>
        );

      case 'out for delivery':
        return (
          <div className="time-status">
            <p className="status-name">On the way</p>
            <p>Out for delivery.</p>
          </div>
        );

      case 'delivered':
        return (
          <div className="time-status">
            <p className="status-name">Order delivered</p>
            <p className="status-time">Your order has been delivered. Enjoy!</p>
          </div>
        );

      case 'rejected by admin':
        return (
          <div className="time-status">
            <p className="status-name">Order Not Accepted</p>
            <p className="status-time">Please contact our customer support</p>
          </div>
        );

      case 'not delivered':
        return (
          <div className="time-status">
            <p className="status-name">Order Not Delivered</p>{' '}
            <p className="status-time">Please contact our customer support</p>
          </div>
        );
      case 'nearby':
        return (
          <div className="time-status">
            <p className="status-name">On the way</p>
            <p className="status-time">We are delivering an order nearby.</p>
          </div>
        );

      case 'processing':
        return (
          <div className="time-status">
            <p className="status-name">Packing in progress</p>
            <p className="status-time">We are packing your items carefully.</p>
          </div>
        );

      case 'arrived at location':
        return (
          <div className="time-status">
            <p className="status-name">Arrived at location</p>
            <p className="status-time">
              {' '}
              Your order has arrived at your doorstep.
            </p>
          </div>
        );
      default:
        return (
          <div>
            <p className="status-name">{currentStatus.status_message}</p>{' '}
            <p className="status-time">{time}</p>
          </div>
        );
    }
  };

  const renderETA = () => {
    const isStatusDelivered =
      item.status_list[item.status_list?.length - 1]?.status_message ===
      'Delivered';
    const isStatusNotDelivered =
      item.status_list[item.status_list?.length - 1]?.status_message ===
      'Not Delivered';
    const isStatusRejectedByAdmin =
      item.status_list[item.status_list?.length - 1]?.status_message ===
      'Rejected by admin';

    const currentStatus = item.status_list[item.status_list?.length - 1];

    const useEtaFor =
      currentStatus.status_message.toLowerCase() === 'nearby' ||
      currentStatus.status_message.toLowerCase() === 'on the way' ||
      currentStatus.status_message.toLowerCase() === 'out for delivery';
    const useImmediateFor =
      currentStatus.status_message.toLowerCase() === 'accepted' ||
      currentStatus.status_message.toLowerCase() === 'preparing for dispatch' ||
      currentStatus.status_message.toLowerCase() === 'processing' ||
      currentStatus.status_message.toLowerCase() === 'delivered';

    const isETAExist = useEtaFor || useImmediateFor;

    if (
      isETAExist &&
      !isStatusDelivered &&
      !isStatusNotDelivered &&
      !isStatusRejectedByAdmin &&
      (!!count || !!countImmediate)
    ) {
      if (
        useImmediateFor &&
        !!countImmediate &&
        item.delivery_type === 'instant' &&
        item.time_slot === null
      ) {
        return (
          <div className="timer">
            <div>
              <p style={{ marginTop: '1rem' }}>
                Freshness landing in{' '}
                <strong>{countImmediate.toFormat('mm')} minutes</strong>
              </p>
              <span>{item?.driver?.delay_message}</span>
            </div>

            <img className="scooter-img" src={Scooter} alt="" />
          </div>
        );
      } else if (useEtaFor && !!count) {
        return (
          <div className="timer">
            <div>
              <p style={{ marginTop: '1rem' }}>
                Freshness landing in{' '}
                <strong>{count.toFormat('mm')} minutes</strong>
              </p>
              <span>{item?.driver?.delay_message}</span>
            </div>

            <img className="scooter-img" src={Scooter} alt="" />
          </div>
        );
      } else {
        return <></>;
      }
    }

    return <></>;
  };

  const renderCouponDelayMsg = () => {
    if (!!order.data.coupon_delay_message) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            background: 'rgba(133, 40, 0, 0.12)',
            padding: '0.5rem',
            width: '60%',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          <img
            style={{
              width: '30px',
            }}
            src={CouponDelay}
            alt="coupon-delay"
          />
          <p
            style={{
              color: '#852800',
              fontSize: '14px',
              marginLeft: '0.3rem',
              marginTop: '0.3rem',
              fontWeight: '500',
            }}
          >
            {order.data.coupon_delay_message}
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderArrivingMsg = () => {
    if (!!order.data.order_arriving_on_time) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            background: 'rgba(0, 133, 91, 0.12)',
            padding: '0.5rem',
            width: '60%',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          <img
            style={{
              width: '30px',
            }}
            src={Arriving}
            alt="coupon-delay"
          />
          <p
            style={{
              color: '#00855b',
              fontSize: '14px',
              marginLeft: '0.3rem',
              fontWeight: '500',
              marginTop: '0.3rem',
            }}
          >
            {order.data.order_arriving_on_time}
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <DeliveryStatusStyled className="delivery-status">
      <figure>{renderImage()}</figure>
      <Card className="card">
        {renderETA()}
        {renderDeliveryProgressBar()}
        <div className="status">
          {item.status_list[item.status_list?.length - 1]?.status_message ===
            'Not Delivered' ||
          item.status_list[item.status_list?.length - 1]?.status_message ===
            'Rejected by admin' ? (
            <img className="completed-img" src={NotCompleted} alt="" />
          ) : (
            <img className="completed-img" src={Completed} alt="" />
          )}
          {renderStatus()}
        </div>
      </Card>
      {renderCouponDelayMsg()}
      {renderArrivingMsg()}
    </DeliveryStatusStyled>
  );
};

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
  let requestUrl = url;
  if (url.startsWith('/')) {
    requestUrl = `${process.env.REACT_APP_API_URI}${url}`;
  }

  const storeId = localStorage.getItem('store-id');

  const headers = {
    ...options?.headers,
    'MM-Device-Type': 'web',
    'MM-Build-Version': '1.0.1',
  };

  const deliveryType = localStorage.getItem('Delivery-Type');

  if (deliveryType && !('Delivery-Type' in (options?.headers || {}))) {
    headers['Delivery-Type'] = deliveryType || 'instant';
  }

  if (!checkIsNextDayDelivery()) {
    headers['Delivery-Type'] = 'instant';
  }

  if (storeId && storeId !== 'null') {
    headers['store-id'] = storeId;
  } else {
    // headers['Delivery-Type'] = 'app';
  }

  const fetchResponse = await fetch(requestUrl, {
    ...options,
    headers,
  });
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}

const checkIsNextDayDelivery = () => {
  const value = localStorage.getItem('Next-Day-Old-Flow');
  if (value) return value === 'true' ? true : false;
  return false;
};

/**
 *
 * Logout
 *
 */

interface Props {}

export function Logout(props: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 17 18" fill="none">
      <path
        d="M3.30553 3.57823H10.8611V8.20601H11.8055V3.57823C11.8055 3.32775 11.706 3.08753 11.5289 2.91041C11.3518 2.73329 11.1116 2.63379 10.8611 2.63379H3.30553C3.05505 2.63379 2.81482 2.73329 2.63771 2.91041C2.46059 3.08753 2.36108 3.32775 2.36108 3.57823V14.9116C2.36108 15.162 2.46059 15.4023 2.63771 15.5794C2.81482 15.7565 3.05505 15.856 3.30553 15.856H10.8611C11.1116 15.856 11.3518 15.7565 11.5289 15.5794C11.706 15.4023 11.8055 15.162 11.8055 14.9116H3.30553V3.57823Z"
        className="fill-grey"
      />
      <path
        d="M13.2979 8.90505C13.2075 8.82769 13.0913 8.78727 12.9725 8.79186C12.8536 8.79645 12.7409 8.84571 12.6568 8.92982C12.5727 9.01392 12.5234 9.12665 12.5188 9.2455C12.5142 9.36435 12.5547 9.48055 12.632 9.57089L14.2281 11.1339H7.38091C7.25567 11.1339 7.13556 11.1837 7.047 11.2723C6.95844 11.3608 6.90869 11.4809 6.90869 11.6062C6.90869 11.7314 6.95844 11.8515 7.047 11.9401C7.13556 12.0286 7.25567 12.0784 7.38091 12.0784H14.2281L12.632 13.7123C12.5826 13.7546 12.5424 13.8067 12.5141 13.8653C12.4858 13.9239 12.4698 13.9877 12.4673 14.0527C12.4648 14.1178 12.4758 14.1826 12.4995 14.2432C12.5232 14.3038 12.5593 14.3588 12.6053 14.4049C12.6513 14.4509 12.7063 14.4869 12.7669 14.5106C12.8275 14.5344 12.8924 14.5453 12.9574 14.5428C13.0224 14.5403 13.0863 14.5244 13.1448 14.496C13.2034 14.4677 13.2555 14.4275 13.2979 14.3781L16.0556 11.6392L13.2979 8.90505Z"
        className="fill-grey"
      />
    </svg>
  );
}

const actionConstants = {
  GO_BACK_TO_LOGIN: 'go back to login',
  GO_TO_SKIP_LOGIN: 'GO TO SKIP LOGIN',
  GO_BACK_TO_HOME: 'go back to home',
  MAINTENANCE: 'maintenance',
  PROCEED: 'proceed',
  PLACE_ORDER: 'place_order',
  CART: 'cart',
  COUNTER_BTN: 'counter btn',
  CANCEL: 'cancel',
  CANCEL_COUPON: 'cancel coupon',
  CHANGE: 'change',
  DOWNLOAD: 'download',
  RETRY: 'retry',
  FAVORITE: 'favorite',
  DISPATCH_API: 'dispatch api',
  LINK: 'link',
  SHIPPING_POLICY: 'shipping policy',
  BACK_ORDER_ID: 'back to order id',
  ADDRESS_SELECT: 'select address',
  ADDRESS_ADD: 'address add',
  DELIVERY_SELECT: 'select delivery type',
  ACCOUNT_TYPE_SELECT: 'account type select',
  PROCEED_CHECKOUT: 'proceed checkout',
  APPLY_COUPON: 'apply coupon',
  CHANGE_PAYMENT: 'change payment',
  DELETE: 'delete',
  DELETE_ITEM: 'delete item',
  PAYMENT: 'payment',
  CATEGORY_ID: ' category id',
  ORDER_ID: 'order id',
  ITEM_CARD: 'item card',
  OPEN_MODAL: 'open uom modal',
  ADDING_ITRM_CART: 'add item cart',
  INVITE: 'invite',
  SENT_OTP: 'sent otp',
  VERIFY_OTP: 'verify otp',
  PASSWORD: 'password',
  OTP: 'OTP',
  CHANGE_PASSWORD: 'change password',
  CHANGE_MOBILE: 'change mobile number',
  SEARCH_ITEM: 'search item',
  ADD_MONEY_WALLET: 'add money wallet',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  PHONE: 'phone',
  KYC_FORM: 'kyc form',
  LOCATION_PERMISSION: 'location permission',
  ENTER_LOCATION_MANUALLY: 'enter location manually',
  REORDER: 'reorder',
  MILESTONE: 'milestone',
  COPY_CODE: 'copy code',
  AMOUNT: 'amount',
  AMOUNT_SUBMIT: 'amount submit',
  ACCEPT_INSTANT_DELIVERY_DISABLE:
    'Accept {launchAddressList.data.instant_delivery_title} disable',
  REGISTERED_WITH_DELIVERABLE: 'registered_with_deliverable',
  REGISTERED_WITH_NOT_DELIVERABLE: 'registered_with_not_deliverable',
};

export default actionConstants;

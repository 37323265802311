/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * AddressList
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddAddress,
  selectAddressList,
  selectEditAddress,
  selectLaunchAddressList,
} from '../ManageAddress/slice/selectors';
import { useEffect, useRef, useState } from 'react';
import { addressActions } from '../ManageAddress/slice';
import {
  LoadingWrapper,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SideSheetFooter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SideSheetHeader,
  AddressListStyled,
  PriceChange,
  ButonGroup,
  AddressLists,
} from './index.styled';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Form, Button, Image, Modal } from 'react-bootstrap';
import { categoryActions } from '../CategoryPage/slice';
import { mainPageActions } from '../MainPage/slice';
import Alert from 'assets/alert.svg';
import Pick from 'assets/pick.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Back from 'assets/back-white.svg';
// import { EditAddress } from './components/EditAddress';
import { useForm } from 'react-hook-form';
import { homePageActions } from '../HomePage/slice';
import { AddressItem } from './components/AddressItem';
import { Mixpanel } from 'app/mixpanel';
import React from 'react';
import pageConstants from 'app/mixpanel/constants/page';
import actionConstants from 'app/mixpanel/constants/action';
import useNextDayDelivery from 'app/hooks/useNextDayDelivery';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export function AddressList(props: Props) {
  React.useEffect(() => {
    Mixpanel.track(pageConstants.ADDRESS_LIST);
  });
  const { checkIsNextDayDelivery, setIsNextDayDelivery } = useNextDayDelivery();
  const addressList = useSelector(selectAddressList);
  const addAddress = useSelector(selectAddAddress);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addNew, setAddNew] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [checked, setChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editAddress, setEditAddress] = useState(null);
  const modalRef: any = useRef();
  const deleteModalRef: any = useRef();
  const editAddressRequest = useSelector(selectEditAddress);
  const launchAddressList = useSelector(selectLaunchAddressList);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsNextDayDelivery();
    // dispatch(addressActions.getAddressList());
    dispatch(addressActions.launchAddress());
  }, []);

  function checkName(obj: any) {
    if (isObject(obj.data) && isObject(obj.data.profile)) {
      return !obj.data.profile.name;
    }

    return false;
  }

  function isObject(value: unknown) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  useEffect(() => {
    if (launchAddressList.success) {
      if (checkName(launchAddressList)) {
        history.push('/onboarding');
      }
    }
  }, [launchAddressList]);

  useEffect(() => {
    if (addAddress.success) {
      setAddNew(false);
      dispatch(addressActions.getAddressList());
    }
  }, [addAddress]);

  useEffect(() => {
    if (editAddressRequest.success) {
      setEditAddress(null);
      dispatch(addressActions.getAddressList());
      dispatch(addressActions.resetEditAddressSuccess());
    }
  }, [editAddressRequest]);

  useEffect(() => {
    if (showModal || deleteModal) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleKeyDown, false);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick, false);
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }
  }, [showModal, deleteModal]);

  const handleOutsideClick = (e: any) => {
    if (modalRef.current || deleteModalRef.current) {
      const element: any = modalRef.current;
      if (element && !element.contains(e.target)) {
        setShowModal(null);
      }
      const deleteElement: any = deleteModalRef.current;
      if (deleteElement && !deleteElement.contains(e.target)) {
        setDeleteModal(null);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopImmediatePropagation();
      setShowModal(null);
      setDeleteModal(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClose = async () => {
    // reset();
    setAddNew(false);
    setDeleteModal(null);
    setShowModal(null);
    setEditAddress(null);
    dispatch(homePageActions.setShowAddress(false));
  };

  const onChangeDefault = async item => {
    dispatch(
      mainPageActions.setEnableInstantDelivery({
        show_instant_delivery: item.show_instant_delivery,
      }),
    );

    dispatch(categoryActions.clearActiveTab());

    dispatch(addressActions.setSelectedAddress(item));

    dispatch(
      addressActions.editAddress({
        id: item.address_id,
        object: {
          ...item,
          is_default: true,
        },
      }),
    );

    const isOldFlowDelivered = checkIsNextDayDelivery();

    if (isOldFlowDelivered) {
      if (!item.show_instant_delivery) {
        history.push('/home');
      } else {
        history.push('/delivery-type');
      }
    } else {
      history.push(`/home`);
    }
  };

  const renderAddressList = () => {
    if (launchAddressList.loading) {
      return (
        <LoadingWrapper className="loading-wrapper">
          <li className="loading" />
          <li className="loading" />
          <li className="loading" />
        </LoadingWrapper>
      );
    }
    const noDeliveryAvailable = launchAddressList.data?.address.every(
      item =>
        !item.show_instant_delivery &&
        !item.enable_instant_delivery &&
        !item.next_day_delivery_availability,
    );

    return (
      <>
        {launchAddressList.data?.address.length > 0 && noDeliveryAvailable && (
          <div
            className="no-delivery-msg"
            style={{
              color: 'red',
            }}
          >
            Due to some reason, we are not delivering.
          </div>
        )}
        {launchAddressList.data?.address.length === 0 && (
          <div
            className="no-delivery-msg"
            style={{
              color: 'black',
            }}
          >
            Please add the address from below.
          </div>
        )}
        <AddressListStyled className="address-list">
          {Object.keys(launchAddressList.data).length !== 0 &&
            launchAddressList.data?.address.length > 0 &&
            launchAddressList.data?.address.map(item => (
              <AddressItem
                item={item}
                key={item.address_id}
                checked={checked}
                setShowModal={setShowModal}
                setDeleteModal={setDeleteModal}
                setEditAddress={setEditAddress}
                onChangeDefault={onChangeDefault}
              />
            ))}
        </AddressListStyled>
        <Modal
          className="modal-style delete-modal"
          show={!!deleteModal}
          centered
        >
          <PriceChange className="price-change" ref={deleteModalRef}>
            <Image src={Alert} alt="Alert" />
            <h5 className="mt-4">Are your sure to delete this address?</h5>
            <ButonGroup className="button-group">
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.CANCEL);
                  setDeleteModal(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="warning mt-3"
                onClick={() => {
                  Mixpanel.track(actionConstants.DISPATCH_API);

                  dispatch(addressActions.deleteAddress(deleteModal));
                  setDeleteModal(null);
                }}
              >
                Yes, remove it
              </Button>
            </ButonGroup>
          </PriceChange>
        </Modal>
      </>
    );
  };

  return (
    <AddressLists className="address-lists">
      <div className="authentication-page login-page">
        <div className="box">
          <Image src={Pick} alt="pick" />
          <h5 style={{ fontWeight: '600', marginTop: '1rem' }}>
            Pick your delivery address
          </h5>
          {renderAddressList()}
          <Link
            className="manage-address-link"
            to={`/manage-address`}
            style={{ textDecoration: 'none' }}
          >
            <Button className="continue_btn">Add New Address</Button>
          </Link>
        </div>
      </div>
    </AddressLists>
  );
}

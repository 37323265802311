import { call, put, takeLatest } from 'redux-saga/effects';
import { registerPageActions as actions } from '.';
import { header } from 'utils/auth';
import { request } from 'utils/request';
import { PayloadAction } from '@reduxjs/toolkit';

function* saveProfile(action: PayloadAction<any>) {
  try {
    const formData = new FormData();
    let requestURL = `/api/v1/user/profile`;
    if (action.payload.user_type === 'individual') {
      formData.append('name', action.payload.name);
      formData.append('email', action.payload.email);
      formData.append('user_type', action.payload.user_type);
      formData.append('address', action.payload.address);
      formData.append('door_no', action.payload.door_no);
      formData.append('landmark', action.payload.landmark);
      formData.append('locality', action.payload.locality);
      formData.append('latitude', action.payload.latitude);
      formData.append('longitude', action.payload.longitude);
      formData.append('scope', 'client');
      formData.append('address_name', action.payload.address_name);
      formData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
      formData.append(
        'client_secret',
        process.env.REACT_APP_CLIENT_SECRET || '',
      );
    } else {
      formData.append('name', action.payload.name);
      formData.append('email', action.payload.email);
      formData.append('user_type', action.payload.user_type);
      formData.append('address', action.payload.address);
      formData.append('door_no', action.payload.door_no);
      formData.append('landmark', action.payload.landmark);
      formData.append('locality', action.payload.locality);
      formData.append('latitude', action.payload.latitude);
      formData.append('longitude', action.payload.longitude);
      formData.append('gst_no', action.payload.gst_no);
      formData.append('scope', 'client');
      formData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
      formData.append('address_name', action.payload.address_name);
      formData.append(
        'client_secret',
        process.env.REACT_APP_CLIENT_SECRET || '',
      );
    }
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
      },
      body: formData,
    });
    let deviceRequestURL = `/api/v1/user/devices`;
    yield call(request, deviceRequestURL, {
      method: 'POST',
      headers: {
        Authorization: header(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app_version: '1.0',
        os_version: '11',
        type: 'web',
        device_id: 'web',
        device_model: 'web',
        device_token: 'web',
      }),
    });
    yield put(actions.saveProfileSuccess(response));
  } catch (err: any) {
    yield put(actions.saveProfileError(err.response));
  }
}

export function* registerPageSaga() {
  yield takeLatest(actions.saveProfile.type, saveProfile);
}

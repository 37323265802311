export const NOT_DELIVERED = 'not delivered';
export const DELIVERED = 'delivered';
export const REJECTED_BY_ADMIN = 'rejected by admin';
export const ORDER_IS_REJECTED = 'Order Is Rejected';
export const ORDER_NOT_ACCEPTED = 'Order Not Accepted';

export const AnalyticsConstants = {
  SIGN_UP: 'registered',
  LOGIN: 'logged_in',
  FIRST_OPEN: 'first_open',
  ADD_TO_CART: 'cart',
  COUPON_CODE: 'coupon_selected',
  APP_STORE_SUBSCRIPTION_CONVERT_UK: 'app_store_subscription_convert_uk',
  SHIPPING_INFO_OR_CHECKOUT: 'checkout',
  PAYMENT_PAGE_VIEWED: 'payment_method',
  PAYMENT_VERF_GATEWAY_UK: 'payment_verification_gateway_uk',
  TRANSACTION_COMPLETE: 'payment_success',
  TRANSACTION_FAILED: 'payment_failed',
  REGISTERED_WITH_DELIVERABLE: 'register_with_deliverable',
  REGISTERED_WITH_NOT_DELIVERABLE: 'register_with_not_deliverable',
};

/**
 *
 * AddressAutoComplete
 *
 */
import React from 'react';
import { MapPicker } from 'app/pages/CheckDeliveryLocation/components/MapPicker';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import NotServiceable from 'assets/not-service.svg';
import { Image } from 'react-bootstrap';

export const getCoordinates = async (address: string) => {
  try {
    const results = await geocodeByAddress(address);
    const values = await getLatLng(results[0]);
    return {
      latitude: String(values.lat),
      longitude: String(values.lng),
    };
  } catch (err) {
    console.log(err);
  }
  return null;
};
interface Props {
  register: Function;
  control: any;
  setValue: any;
  notServiceableLocation?: boolean;
  customContainerHeight?: string;
}

export function AddressAutoComplete(props: Props) {
  const { register, control, setValue, customContainerHeight } = props;

  return (
    <>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Controller
          name="addressVal"
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <MapPicker
                position={{
                  lat: field.value ? field.value.lat : 0,
                  lng: field.value ? field.value.lng : 0,
                }}
                address={field.value ? field.value.address : ''}
                register={register}
                mapContainerStyle={{
                  height: customContainerHeight
                    ? customContainerHeight
                    : '400px',
                  width: '100%',
                }}
                mapOptions={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  zoom: 18,
                  mapTypeId: 'roadmap',
                  gestureHandling: 'greedy',
                }}
                defaultPosition={{
                  lat: 27.9878,
                  lng: 86.925,
                }}
                onChange={({ position, address, flat, locality }: any) => {
                  setValue(
                    'addressVal',
                    {
                      lat: position.lat,
                      lng: position.lng,
                      address: address,
                      flat: flat,
                      locality: locality,
                    },
                    { shouldValidate: true },
                  );
                }}
                // onReset={() => {
                //   setValue('addressVal', {
                //     lat: 0,
                //     lng: 0,
                //     address: '',
                //     flat: '',
                //     locality: '',
                //   });
                // }}
              />
            );
          }}
        ></Controller>

        {props.notServiceableLocation && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '10px',
              right: '50%',
              transform: 'translate(50%, -50%)',
              backgroundColor: 'black',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.3rem 0.5rem',
              borderRadius: '4px',
            }}
          >
            <Image
              style={{
                marginRight: '0.3rem',
                width: '15px',
              }}
              src={NotServiceable}
              alt="not-service"
            />
            <Typography
              variant="subtitle2"
              sx={{
                color: 'white',
                fontSize: '14px',
              }}
            >
              This location is not serviceable
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
